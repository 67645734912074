
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { take } from 'rxjs/operators';
import { ConfigService } from '../../../config/config.service';
import { IWeekDto } from '../../../services/bpp.webapi/client';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/enums';
import { GetBroadcasterEventsAction } from 'src/app/store/fixture/actions';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-fixtures-broadcasters-table',
  templateUrl: './fixtures-broadcasters-table.component.html'  
})
export class FixturesBroadcastersTableComponent extends BaseComponent implements OnInit {
  dataLoaded = false;
  isLog = false;
  isSpinner: boolean;
  byGameWeek: { [key: string]: IWeekDto } = {};
  rounds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  round: number = 1;
  nonLiveInUK: boolean = false;
  user: User;
  isBroadcaster: boolean;
  isMatchFilterEnabled: boolean = false;

  get matches() {
    if (!this.round || !this.byGameWeek[this.round]) {
      return [];
    }
    return this.byGameWeek[this.round].matches;
  }

  constructor(private store: Store<AppState>, private router: Router, private config: ConfigService) {
    super();
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store
    this.isLog = this.config.isDebug();

    this.store
      .select(state => state.fixtures).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Fixtures-league:', state.broadcasterByGameWeek); }
          if (state.byGameWeek) {
            this.dataLoaded = true;
            this.isSpinner = false;
            this.byGameWeek = state.broadcasterByGameWeek;
          }
        }
      });

    this.store.select(x => x.fixtures.filter).pipe(take(1)).subscribe(x => {
      this.round = x.matchRound;
      this.nonLiveInUK = !!x.isNonLiveInUK;
    });

    this.store.select(state => state.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
          this.isBroadcaster = state.currentUser.role === Roles.Broadcaster || state.currentUser.role === Roles.PLPBroadcaster;
          this.isMatchFilterEnabled = state.currentUser.isCompanyEnabledForNonLiveInUK && this.config.hasFeature("matchFilter.broadcasters");
          this.store.dispatch(new GetBroadcasterEventsAction(this.round, this.nonLiveInUK));
        }
      });
  }

  selectionChange() {
    this.store.dispatch(new GetBroadcasterEventsAction(this.round, this.nonLiveInUK));
  }
}
