
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { AdditionalContentService } from '../../services/additional-content.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';
import {StatusHelper} from '../../helpers/statuses-helper';

@Injectable()
export class AdditionalContentEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: AdditionalContentService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetAdditionalContentEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetAdditionalContentEventsAction>(actions.GET_ADDITIONAL_CONTENT_EVENTS),
    switchMap(action => this.service.getAll(action.isPast, action.clubId).pipe(
      switchMap((result) => of(new actions.GetAdditionalContentEventsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetAdditionalContentEventsErrorAction(error));
      }))));

  @Effect()
  GetAdditionalContentEventById$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetAdditionalContentEventByIdAction>(actions.GET_ADDITIONAL_CONTENT_EVENT_BY_ID),
    switchMap(action => this.service.getById(action.id, action.contentType).pipe(
      switchMap((result) => of(new actions.GetAdditionalContentEventByIdSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetAdditionalContentEventByIdErrorAction(error));
      }))));

  @Effect()
  CreateAdditionalContentEvent$ = this.action$.pipe(
    ofType<actions.CreateAdditionalContentEventAction>(actions.CREATE_ADDITIONAL_CONTENT_EVENT),
    switchMap(action => this.service.createEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Additional Content event created. Status: ${StatusHelper.getStatusText(result.status)}`;
        this.alertService.success(message);
        return of(new actions.CreateAdditionalContentEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateAdditionalContentEventErrorAction(error));
      }))));

  @Effect()
  UpdateAdditionalContentEvent$ = this.action$.pipe(
    ofType<actions.UpdateAdditionalContentEventAction>(actions.UPDATE_ADDITIONAL_CONTENT_EVENT),
    switchMap(action => this.service.updateEvent(action.payload).pipe(
      switchMap(result => {
        const message = `Additional content status updated.`;
        this.alertService.success(message);
        return of(new actions.UpdateAdditionalContentEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateAdditionalContentEventErrorAction(error));
      })
    )));

  @Effect()
  CreateClubContentSessionRequest$ = this.action$.pipe(
    ofType<actions.CreateClubContentSessionRequestAction>(actions.CREATE_CLUB_CONTENT_SESSION_REQUEST),
    switchMap(action => this.service.createRequest(action.payload).pipe(
      switchMap(result => {
        const message = `Request created`;
        this.alertService.success(message);
        return of(new actions.CreateClubContentSessionRequestSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateClubContentSessionRequestErrorAction(error));
      }))));

  @Effect()
  UpdateClubContentSessionRequest$ = this.action$.pipe(
    ofType<actions.UpdateClubContentSessionRequestAction>(actions.UPDATE_CLUB_CONTENT_SESSION_REQUEST),
    switchMap(action => this.service.updateRequest(action.payload).pipe(
      switchMap(result => {
        const message = `Request updated`;
        this.alertService.success(message);
        return of(new actions.UpdateClubContentSessionRequestSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateClubContentSessionRequestErrorAction(error));
      })
    )));

  @Effect()
  GetClubContentSessionParticipants$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubContentSessionParticipantsAction>(actions.GET_CLUB_CONTENT_SESSION_PARTICIPANTS),
    switchMap(action => this.service.getParticipants().pipe(
      switchMap((result) => of(new actions.GetClubContentSessionParticipantsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubContentSessionParticipantsErrorAction(error));
      }))));

  @Effect()
  GetClubContentSessionParticipantsByCompany$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubContentSessionParticipantByCompanyAction>(actions.GET_CLUB_CONTENT_SESSION_PARTICIPANTS_BY_COMPANY),
    switchMap(action => this.service.getParticipantsByCompany(action.payload).pipe(
      switchMap((result) => of(new actions.GetClubContentSessionParticipantsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubContentSessionParticipantsErrorAction(error));
      }))));

  @Effect()
  CreateClubContentSessionParticipant$ = this.action$.pipe(
    ofType<actions.CreateClubContentSessionParticipantAction>(actions.CREATE_CLUB_CONTENT_SESSION_PARTICIPANT),
    switchMap(action => this.service.createParticipant(action.payload).pipe(
      switchMap((result) => {
        return of(new actions.CreateClubContentSessionParticipantSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateClubContentSessionParticipantErrorAction(error));
      }))));
}
