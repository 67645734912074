import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as actions from './actions';
import { LocationService } from '../../services/location.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';
import {GetInterviewLocationsAction} from './actions';

@Injectable()
export class LocationEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: LocationService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetLocations$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetLocationsAction>(actions.GET_LOCATIONS),
    switchMap(action => this.service.getLocations(action.clubId).pipe(
      switchMap((result) => of(new actions.GetLocationsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.LocationApiErrorAction(error));
      }))));

  @Effect()
  CreateLocationEvent$ = this.action$.pipe(
    ofType<actions.CreateLocationAction>(actions.CREATE_LOCATION),
    switchMap(action => this.service.createLocation(action.payload).pipe(
      switchMap((result) => of(new actions.CreateLocationSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateLocationErrorAction(error));
      }))));


  @Effect()
  UpdateLocationEvent$ = this.action$.pipe(
    ofType<actions.UpdateLocationAction>(actions.UPDATE_LOCATION),
    switchMap(action => this.service.updateLocation(action.payload).pipe(
      switchMap((result) => {
        if (action.payload.clubId > 0) {
          // refresh locations for club
          this.store.dispatch(new actions.GetLocationsAction(action.payload.clubId));
        } else {
          // refresh locations for league
          this.store.dispatch(new actions.GetInterviewLocationsAction());
        }
        return of(new actions.UpdateLocationSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateLocationErrorAction(error));
      }))));

  @Effect()
  DeleteLocationEvent$ = this.action$.pipe(
    ofType<actions.DeleteLocationAction>(actions.DELETE_LOCATION),
    switchMap(action => this.service.deleteLocation(action.locationId).pipe(
      switchMap((result) => {
        return of(new actions.DeleteLocationSuccessAction(result));
      }),
      catchError(error => {
        if (error && error.response) {
          const m = JSON.parse(error.response);
          if (m && m.length > 0) {
            const message = m[0].message;
            this.alertService.warning(message);
          }
        } else {
          this.alertService.error(error);
        }
        return of(new actions.DeleteLocationErrorAction(error));
      }))));

  @Effect()
  GetInterviewLocations$ = this.action$.pipe(
    ofType<actions.GetInterviewLocationsAction>(actions.GET_INTERVIEW_LOCATIONS),
    switchMap(action => this.service.getForLeague().pipe(
      switchMap(res => of(new actions.GetInterviewLocationsActionSuccess(res))),
      catchError(error => of(new actions.GetInterviewLocationsActionError(error))))),
    catchError(error => of(new actions.GetInterviewLocationsActionError(error))));
}
