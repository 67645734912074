import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { Roles } from 'src/app/models/enums';
import { AlertService } from 'src/app/services/alert.service';
import { AppInsightClientService } from 'src/app/services/app-insight.service';
import { RequestStatus, NoteDto, MatchInterviewEventDetailsDto, InterviewEventDetailsDto, RequestDetailsDto, NoteStatus, CameraOperator, TeamDto, EventStatus } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { ConfirmWithdrawPopupComponent } from '../../common/withdraw-request/confirm-withdraw-popup.component';
import { IFixtureHeader } from '../../fixture-details/fixture-header/fixture-header.component';
import * as helper from '../../../helpers/common';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import { ClubInterviewMapper } from '../../../mappers/club-interview-event.mapper';
import { CreateClubInterviewRequestAction, GetClubInterviewDetailsForLeagueBroadcasterAction, UpdateClubInterviewRequestAction } from 'src/app/store/club-interview/actions';
import { IClubInterviewAccordionItem, IClubInterviewIdentity } from 'src/app/models/club-interview-accordion-item';
import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'app-club-interview-broadcaster',
  templateUrl: './club-interview-broadcaster.component.html',
  styleUrls: ['./club-interview-broadcaster.component.scss']
})
export class ClubInterviewBroadcasterComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isSpinner: boolean;
  accordionItems: IClubInterviewAccordionItem[];
  isLog = false;
  modalSubscription: any;
  matchId: any;
  lastSaved = '';
  companyId: string;
  matchEvents: MatchInterviewEventDetailsDto;
  match: MatchInterviewEventDetailsDto;
  eventSubscription: Subscription;
  userSubscription: Subscription;
  header: IFixtureHeader;
  userRole: Roles;
  withDrawSubscription: any;
  user: any;
  mapper: ClubInterviewMapper;
  activeIds:string[] = [];
  isLfp:boolean;

  constructor(public config: ConfigService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private store: Store<AppState>) {
    this.isLog = config.isDebug();
    this.mapper = new ClubInterviewMapper(config);
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.isSpinner = true;
    this.checkSubscriptions();

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            if (user && user.companyId) {
              this.user = user;
              this.userRole = user.role;
              this.companyId = user.companyId;
              this.matchId = params['id'];
              if (this.matchId) {
                this.accordionItems = [];
                this.store.dispatch(new GetClubInterviewDetailsForLeagueBroadcasterAction(this.matchId));
              }
            }
          });
      });
  }

  checkSubscriptions() {
    this.eventSubscription = this.store
      .select(state => state.clubInterviews).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (result.loading) {
          return;
        }
        this.matchEvents = result.detailsForLeagueBroadcaster[this.matchId];
        if (this.matchEvents && (this.matchEvents.homeEvent || this.matchEvents.awayEvent)) {

          if (this.isLog) { console.log('this match events', this.matchEvents); }

          this.match = this.matchEvents;
          this.header = {
            homeTeam: this.match.homeTeam,
            awayTeam: this.match.awayTeam,
            matchDate: this.match.matchDate
          };

          let eventList: InterviewEventDetailsDto[] = [];
          if (this.match.homeEvent) {
            eventList.push(this.match.homeEvent)
          }
          if (this.match.awayEvent) {
            eventList.push(this.match.awayEvent)
          }

          eventList.forEach(matchEvent => {
            const identity: IClubInterviewIdentity = {
              matchId: this.matchId,
              eventId: matchEvent.eventId,
            };
            const requests = matchEvent.requests;
            if (requests.length === 0) {
              const request = new RequestDetailsDto();
              request.init({
                id: helper.getEmptyGuid(),
                participants: [],
                broadcaster: this.user.copanyName,
                cameraOperator: CameraOperator.AudioOnly,
                isReportersEnabled: false,
                isProducersEnabled: false,
                isOthersEnabled: false,
                status: RequestStatus.Unknown,
                createdBy: this.user,
                createdByName: this.user.name,
                createOn: moment(),
                updatedBy: this.user,
                updatedByName: this.user.name,
                updatedOn: moment(),
                broadcasterNote: new NoteDto({ date: moment(), id: helper.getEmptyGuid(), status: NoteStatus.Draft, message: '', user: this.user, userName: this.user.name }),
                clubNote: undefined,
                leagueNote: undefined,
                companyId: this.user.companyId
              });
              requests.push(request);
            }
            this.lastSaved = requests[0] ? helper.getLastSavedText(requests[0].updatedOn, requests[0].updatedByName, this.getRequestStatusText(requests[0].status)) : '';
            const index = this.accordionItems.findIndex(x => x.id === matchEvent.eventId);
            if (index > -1) {
              this.accordionItems[index].status = this.getRequestStatusText(requests[0].status);
              this.accordionItems[index].identity = identity;
              this.accordionItems[index].model = matchEvent;
              this.accordionItems[index].status = this.getRequestStatusText(requests[0] ? requests[0].status : 0);
              this.accordionItems[index].isPastEvent = fixtureHelper.isPastClubInterview(matchEvent);
            } else {
              this.accordionItems.push({
                id: matchEvent.eventId,
                isOpen: true,
                isReadOnly: true,
                model: matchEvent,
                notes: '',
                status: this.getRequestStatusText(requests[0] ? requests[0].status : 0),
                identity,
                isPastEvent: fixtureHelper.isPastClubInterview(matchEvent),
                title: matchEvent.clubId === this.match.homeTeam.id ? GetClubName(this.match.homeTeam) : GetClubName(this.match.awayTeam)
              });
            }
          });

          this.accordionItems.forEach(item => {
            if (item.model.requests.length > 1) {
              item.model.requests = item.model.requests.filter(r => {
              return helper.isGuidNotEmpty(r.id)
              });
            }
          });

          this.accordionItems.sort((a, b) => a.title > b.title ? 1 : -1);
          if (this.config.isLfp() && this.accordionItems.length===1){
              this.activeIds =["panel-0"];
              this.accordionItems[0].isOpen= true;
          }

          if (this.isLog) { console.log('accordion items', this.accordionItems); }
          this.isSpinner = false;
        }
      });
  }

  isInterviewInValidation(item:InterviewEventDetailsDto){
    return item.status === EventStatus.Submitted;
  }

  isInterviewInValidationOrRejected(item:InterviewEventDetailsDto){
    return this.isInterviewInValidation(item) || this.isInterviewRejected(item)
  }

  isInterviewRejected(item:InterviewEventDetailsDto){
    return item.status === EventStatus.Reject;
  }

  getEventStatusText(status) {
    return `Event ${StatusHelper.getStatusText(status)}`;
  }

  getRequestStatusText(status: RequestStatus) {
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  getTeamName(event: InterviewEventDetailsDto) {
    return event.clubId === this.match.homeTeam.id ? this.match.homeTeam.name : this.match.awayTeam.name;
  }

  submitRequest(model: { request: RequestDetailsDto, slotId: string }, evt) {
    const requestModel = new RequestDetailsDto();
    requestModel.init(model.request);
    requestModel.status = RequestStatus.Submitted;
    this.saveOrSubmit({ request: requestModel, slotId: model.slotId }, evt);
  }

  saveRequest(model: { request: RequestDetailsDto, slotId: string }, evt: InterviewEventDetailsDto) {
    if (model.request.status==RequestStatus.Unknown){
      model.request.status = RequestStatus.Draft
    }
    this.saveOrSubmit(model, evt);
  }

  withdrawRequest(request, evt) {
    const modalRef = this.modalService.open(ConfirmWithdrawPopupComponent, { centered: true });
    this.withDrawSubscription = modalRef.componentInstance.withdraw
      .subscribe(doWithdraw => {
        if (doWithdraw === true) {
          if (this.isLog) { console.log('onWithdraw request', event); }
          const model =  { request: new RequestDetailsDto(), slotId: request.slotId};
          model.request.init(request.request);
          model.request.status = RequestStatus.Withdrawn;
          this.saveOrSubmit(model, evt);
          this.withDrawSubscription.unsubscribe();
        }
      });
  }

  saveOrSubmit(model: { request: RequestDetailsDto, slotId: string }, evt: InterviewEventDetailsDto) {
    if (helper.isGuidNotEmpty(model.request.id)) {
      this.store.dispatch(new UpdateClubInterviewRequestAction(this.mapper.requestUpdate(model.request, evt), model.slotId, this.matchId));
    } else {
      this.store.dispatch(new CreateClubInterviewRequestAction(this.mapper.requestCreate(model.request, evt), model.slotId, this.matchId));
    }
    this.isSpinner = true;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    const id = Number($event.panelId.substr(6, $event.panelId.length));
    this.accordionItems[id].isOpen = $event.nextState;
  }

  navigateBack() {
    this.router.navigate([`club-interviews`]);
  }
}

function GetClubName(team: TeamDto) {
  console.log('name',team.name)
  console.log('official',team.officialName)
  console.log('result',team.name!= null ? team.name : team.officialName)
  return team.name!= null ? team.name : team.officialName;
}
