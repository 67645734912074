import { Component, OnInit } from '@angular/core';
import { ImportService } from '../../services/import.service';
import { HttpEventType } from '@angular/common/http';
import { ImportResultDto } from '../../services/bpp.webapi/client';

@Component({
  selector: 'app-events-import',
  templateUrl: './events-import.component.html'
})
export class EventsImportComponent implements OnInit {

  file: any;
  messages: string[] = [];
  isSpinner = false;

  constructor(private importService: ImportService) { }

  ngOnInit() {
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
    }
  }

  importFiles() {
    if (this.file) {
      this.isSpinner = true;
      this.importService.import(this.file).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const perscent = Math.round(100 * event.loaded / event.total);
          if (perscent < 100) {
            this.messages = ['Uploading ' + perscent.toString()];
          } else {
            this.messages = ['Processing'];
          }
        } else if (event.type === HttpEventType.Response) {
          this.isSpinner = false;
          this.messages = (<ImportResultDto>event.body).messages;
        }
      }, error => {
        this.isSpinner = false;
        this.messages = [JSON.stringify(error)];
      });
    }
  }

}
