
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs';
import { NgbPanelChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateFixtureEventAction } from '../../../store/fixture/actions';
import {
  IFixtureEventDetailsDto, LanguageDto, PlayerDto,
  UpdateFixtureEventDto, RequestStatus, UpdateFixtureRequestDto, NoteDto, EventStatus, RequestTotal, IRequestTotal, ParticipantType, NoteStatus, SaveNoteDto
} from '../../../services/bpp.webapi/client';
import { GetPlayerListByTeamAction } from '../../../store/bpp/actions';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { ConfigService } from '../../../config/config.service';
import * as _ from 'lodash';
import { IDentity, FxRequest, IAccordionItem } from '../../../store/fx-request/state';
import { GetEventsByMatchAction, UpdateFixtureRequestAction } from '../../../store/fx-request/actions';
import { Roles } from '../../../models/enums';
import { IFixtureHeader } from '../fixture-header/fixture-header.component';
import * as moment from 'moment';
import { FixtureMapper } from '../../../mappers/fixture.mapper';
import * as helper from '../../../helpers/common';
import { ReportService } from '../../../services/report.service';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { NotesService } from '../../../services/notes.service';
import { User } from '../../../models/user.model';
import { ValidationService } from '../../../services/validation.service';
import { RejectConfirmComponent } from '../../common/reject-confirm/reject-confirm.component';
import { isNonEmptyNotes } from '../../../helpers/league.helper';


@Component({
  selector: 'app-league-fixture-detail',
  templateUrl: './league-fixture-detail.component.html',
  styleUrls: ['./league-fixture-detail.component.scss']
})
export class LeagueFixtureDetailComponent implements OnInit, OnDestroy {

  private fixtureSubscription: Subscription;
  private componentDestroyed: Subject<any> = new Subject();

  isLog = false;
  teamIds$: Subject<number[]> = new Subject();
  originEvents: Array<IFixtureEventDetailsDto>;
  isSpinner = false;
  playerStream: Observable<Map<number, PlayerDto[]>>;
  pickedLanguages: Array<LanguageDto> = [];
  accordionItems: IAccordionItem[];
  playersSubscription: Subscription;
  players;
  teamId;
  isReadOnly = true;
  isEditMessage = true;
  editFixtureModel: IFixtureEventDetailsDto;
  identity: IDentity;
  companyId: string;
  matchId: any;
  eventSubscription: Subscription;
  match: IFixtureEventDetailsDto;
  requests: any;
  teamIds: any[];
  fixtureHeader: IFixtureHeader;
  lastSaved = 'To Be Saved';
  mapper: FixtureMapper;
  userRole: Roles;
  user: User;
  isPrivateNoteEditable: any = {};
  adminLeagueNoteMessages: any = {};
  isAdminLeagueMode: boolean;
  nonLiveInUK: boolean = false;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private config: ConfigService,
    private appInsightsService: AppInsightClientService,
    private router: Router,
    private alertService: AlertService,
    private translationService: TranslateService,
    private notesService: NotesService,
    private validator: ValidationService,
    private reportService: ReportService,
    private modalService: NgbModal) {
    this.isLog = this.config.isDebug();
    this.mapper = new FixtureMapper(config);
    this.isLfp = config.isLfp();
  }

  ngOnInit() {

    this.isSpinner = true;

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            this.user = user;
            this.isAdminLeagueMode = this.user.role === Roles.Admin;
            this.userRole = user.role;
            if (user && (user.role === Roles.League || user.role === Roles.Admin)) {
              // this.companyId = user.companyId;
              this.matchId = params['id'];
              this.nonLiveInUK = params['nonLive'] === 'true';
              if (this.matchId) {
                this.identity = {
                  matchId: this.matchId,
                  nonLiveInUK: this.nonLiveInUK
                };
                this.accordionItems = [];
                this.originEvents = [];
                this.store.dispatch(new GetEventsByMatchAction(this.identity));
              }
            }
          });
      });

    this.checkSubscriptions();

    this.store.select(state => state.bpp.playersByTeam).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(p => {
        this.players = p;
        // if (this.isLog) { console.log('this.players:', this.players); }
      });
  }


  showApproveBtn(item: IAccordionItem) {
    return (!item.isReadOnly) || (item.isReadOnly && item.fixtureModel.status !== EventStatus.Approved);
  }

  checkSubscriptions() {

    this.eventSubscription = this.store
      .select(state => state.fxRequests).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (!result.loading) {
          const matchEvents = result.events[this.matchId];
          if (matchEvents && matchEvents.length > 0) {
            if (this.isLog) { console.log('match events', matchEvents); }

            this.match = matchEvents[0].details;
            this.fixtureHeader = {
              homeTeam: this.match.homeTeam,
              awayTeam: this.match.awayTeam,
              matchDate: this.match.matchDate
            };

            this.lastSaved = helper.getLastSavedText(this.match.updatedOn, this.match.updatedByName, StatusHelper.getStatusText(this.match.status));

            matchEvents.forEach((event) => {
              this.requests = [];
              const identity: IDentity = {
                matchId: this.matchId,
                eventId: event.details.eventId,
                companyId: this.companyId,
                nonLiveInUK: event.details.isNonLiveInUK,
                lastUpdated: event.details.updatedOn ? helper.getLastSavedText(event.details.updatedOn, event.details.updatedByName, StatusHelper.getStatusText(event.details.status)) : this.lastSaved
              };

              event.requests.forEach(r => {
                if (r.status > 0) {
                  this.requests.push(r);
                }
              });
              const teamIds = [this.match.homeTeam.id, this.match.awayTeam.id];
              this.store.dispatch(new GetPlayerListByTeamAction(teamIds));

              const fixtureTitle = event.details.clubId === event.details.homeTeam.id ? event.details.homeTeam.name : event.details.awayTeam.name;
              const requestStatistics = this.getRequestStatistics(event.details.requestTotal);

              const index = this.accordionItems.findIndex(x => x.id === event.details.eventId);
              if (index > -1) {
                this.accordionItems[index].requests = this.requests;
                this.accordionItems[index].status = this.getEventStatusText(event.details.status);
                this.accordionItems[index].identity = identity;
                this.accordionItems[index].fixtureModel = event.details;
                this.accordionItems[index].fixtureTitle = fixtureTitle;
                this.accordionItems[index].requestStatistics = requestStatistics;

                this.originEvents[index] = _.cloneDeep(event.details);
              } else {
                this.accordionItems.push({
                  id: event.details.eventId,
                  isOpen: false,
                  isReadOnly: true,
                  fixtureModel: event.details,
                  requests: this.requests,
                  identity,
                  status: this.getEventStatusText(event.details.status),
                  fixtureTitle,
                  requestStatistics,
                  isPastFixture: false // fixtureHelper.isPastMatch(event.details.matchDate)
                });
                this.originEvents.push(_.cloneDeep(event.details));
              }
            });
            this.accordionItems.sort((a, b) => a.fixtureTitle > b.fixtureTitle ? -1 : 1);

            this.isSpinner = false;
            if (this.isLog) { console.log('accordionItems:', this.accordionItems); }
          }
        } else {
          this.isSpinner = true;
        }
      });
  }

  getRequestStatistics(total: IRequestTotal) {
    const received = total.requested;
    const rejected = total.rejected;
    const approved = total.approved;
    const pending = total.requested - rejected - approved;
    if (received > 0) {
      const s = `${received} requests received: ${pending} pending, ${rejected} rejected, ${approved} approved`;
      return s;
    } else {
      return '';
    }
  }

  updateRequest(event) {
    const rq = event.request as FxRequest;
    this.appInsightsService.logEvent('Update Fixture Request (League)', {
      requestId: rq.id,
      status: StatusHelper.getRequestStatusText(rq.status)
    });
    const participants = [];

    rq.sections.forEach(s => {
      if (s.items) {
        s.items.forEach(item => participants.push(item));
      }
    });
    const leagueNote = new NoteDto();
    leagueNote.init({
      message: rq.note,
      date: moment().utc()
    });
    const request = new UpdateFixtureRequestDto();
    request.init({
      fixtureRequestId: rq.id,
      fixtureEventId: event.identity.eventId,
      status: rq.status,
      participants,
      leagueNote
    });

    rq.sections.forEach(s => {
      switch (s.sectionType) {
        case (ParticipantType.Camera): { request.cameraOperator = s.cameraOperator; break; }
        case (ParticipantType.Other): { request.isOthersEnabled = s.require; break; }
        case (ParticipantType.Producer): { request.isProducersEnabled = s.require; break; }
        case (ParticipantType.Reporter): { request.isReportersEnabled = s.require; break; }
      }
    });
    if (this.isLog) { console.log('Request model for Approve/Reject:', request); }
    this.store.dispatch(new UpdateFixtureRequestAction(request));

    // TODO: move refresh events to Effects
    setTimeout(() => {
      this.store.select(state => state.fxRequests.error).subscribe(e => {
        if (e === false) {
          this.store.dispatch(new GetEventsByMatchAction(event.identity));
        }
      }).unsubscribe();
    }, 1000);
  }

  public beforeChange($event: NgbPanelChangeEvent) {

    const id = Number($event.panelId.substr(6, $event.panelId.length));
    this.accordionItems[id].isOpen = $event.nextState;
  }

  getEventStatusText(status) {
    return `Event ${StatusHelper.getStatusText(status)}`;
  }

  getRequestStatusText(status: RequestStatus) {
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  // Buttons //

  editPrivateNote(fixtureModel: IFixtureEventDetailsDto) {
    this.isPrivateNoteEditable[fixtureModel.eventId] = true;
    this.adminLeagueNoteMessages[fixtureModel.eventId] = fixtureModel.adminLeagueNote.message;
  }

  cancelEditPrivateNote(fixtureModel: IFixtureEventDetailsDto) {
    this.isPrivateNoteEditable[fixtureModel.eventId] = false;
    this.adminLeagueNoteMessages[fixtureModel.eventId] = '';
  }

  savePrivateNote(fixtureModel: IFixtureEventDetailsDto) {
    this.isPrivateNoteEditable[fixtureModel.eventId] = false;
    fixtureModel.adminLeagueNote.message = this.adminLeagueNoteMessages[fixtureModel.eventId];
    this.adminLeagueNoteMessages[fixtureModel.eventId] = '';
    this.notesService.saveLeagueAdminBPPNote(SaveNoteDto.fromJS({ status: NoteStatus.Draft, message: fixtureModel.adminLeagueNote.message, objectId: fixtureModel.eventId }))
      .subscribe((resp) => {
        if (resp) {
          this.translationService.get('NoteUpdated').subscribe((value) => {
            this.alertService.success(value);
          });
          fixtureModel.adminLeagueNote = resp;
        }
      }, (err) => {
        this.alertService.error(err);
      });
  }

  editBtnClick(index) {
    this.accordionItems[index].isReadOnly = false;
  }

  rejectBtnClick(index) {
    if (!this.validator.isFixtureValid(this.accordionItems[index].fixtureModel)) {
      return;
    }
    const model = this.accordionItems[index].fixtureModel;

    if (isNonEmptyNotes(model.leagueNote)) {
      this.confirmedReject(index);
    } else {
      const modalRef = this.modalService.open(RejectConfirmComponent);
      const instance = modalRef.componentInstance as RejectConfirmComponent;
      return instance.submit
        .subscribe((result: boolean) => {
          if (result) {
            this.confirmedReject(index);
          }
        });
    }
  }

  confirmedReject(index) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Reject);
    const eventName = 'Reject ICS (Fixture) (League)';
    const eventId = this.accordionItems[index].fixtureModel.eventId;
    this.accordionItems[index].isReadOnly = true;

    const updatedModel = this.updateModel(this.accordionItems[index].fixtureModel, EventStatus.Reject);
    if (this.isLog) { console.log('Reject model:', updatedModel); }

    this.isSpinner = true;
    this.store.dispatch(new UpdateFixtureEventAction(updatedModel));
    this.navigateBack();
    this.appInsightsService.logEvent(eventName, { eventId, status });
  }

  approveBtnClick(index) {
    if (!this.validator.isFixtureValid(this.accordionItems[index].fixtureModel)) {
      return;
    }
    const status = StatusHelper.getStatusText(EventStatus.Approved);
    const eventName = 'Approve ICS (Fixture) (League)';
    const eventId = this.accordionItems[index].fixtureModel.eventId;
    this.appInsightsService.logEvent(eventName, { eventId, status });
    this.accordionItems[index].isReadOnly = true;
    const model = this.updateModel(this.accordionItems[index].fixtureModel, EventStatus.Approved);
    if (this.isLog) { console.log('Approve model:', model); }
    this.isSpinner = true;
    this.store.dispatch(new UpdateFixtureEventAction(model));
  }

  cancelBtnClick(index) {
    this.accordionItems[index].isReadOnly = true;
    this.accordionItems[index].fixtureModel = _.cloneDeep(this.originEvents[index]);
  }

  updateModel(fixture: IFixtureEventDetailsDto, status: EventStatus): UpdateFixtureEventDto {
    const model = this.mapper.update(fixture);
    model.leagueNote = this.mapper.updateNote(fixture.leagueNote);
    model.adminLeagueNote = this.mapper.updateNote(fixture.adminLeagueNote);
    model.status = status;
    return model;
  }

  getRequestsReport(matchId: number, fixtureId: string) {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Fixture Requests Report (League)', {
      fixtureId: fixtureId,
      userRole: this.user.role,
      userEmail: this.user.email,
      companyId: this.user.companyId,
      companyName: this.user.companyName
    });
    this.reportService.getFixtureRequestsReport(matchId, fixtureId)
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = this.isLfp ? 'InterviewsCallSheet.docx' : 'ICSCallSheet.docx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();

    if (this.fixtureSubscription) {
      this.fixtureSubscription.unsubscribe();
    }

    if (this.playersSubscription) {
      this.playersSubscription.unsubscribe();
    }
  }

  navigateBack() {
    this.router.navigate([`fixtures`]);
  }

}
