import * as actions from './actions';
import { FxRequest, FixtureRequests, MatchEvent, IDentity, initSection, ERequestType } from './state';
import { IParticipantDto, IFixtureEventDetailsDto, ParticipantType } from '../../services/bpp.webapi/client';
import * as helper from '../../helpers/common';
import { StatusHelper } from '../../helpers/statuses-helper';

export type Action = actions.All;
const isLog = true;

enum sectionAction {
  Remove = 1,
  Add = 2
}

export function FixtureRequestReducer(state = new FixtureRequests(), action: Action): FixtureRequests {
  switch (action.type) {

    case actions.FXRQ_ENABLE_SECTION: {

      const updated = { ...state.events };
      const identity = action.identity;
      let matchEvents = updated[identity.matchId];
      if (matchEvents) {
        const matchEvent = matchEvents.find(event => event.details.eventId === identity.eventId);
        if (matchEvent) {
          const request = matchEvent.requests.find(r => r.companyId === identity.companyId);
          if (request) {
            const section = request.sections.find(s => s.key === identity.sectionKey);
            section.require = action.enable;
            request.sections = updateArrayItem(request.sections, identity.sectionKey, 'key', section);
            matchEvent.requests = updateArrayItem(matchEvent.requests, identity.companyId, 'companyId', request);
            matchEvents = updateArrayItem(matchEvents, identity.eventId, 'eventId', matchEvent);
          }
        }
        updated[identity.matchId] = matchEvents;
      }
      return {
        ...state,
        events: updated
      };
    }

    case actions.FXRQ_SET_PROPERTY: {
      const updated = { ...state.events };
      const identity = action.identity;
      let matchEvents = updated[identity.matchId];
      if (matchEvents) {
        const matchEvent = matchEvents.find(event => event.details.eventId === identity.eventId);
        if (matchEvent) {
          const request = matchEvent.requests.find(r => r.companyId === identity.companyId);
          if (request) {
            request[action.prop] = action.value;
            matchEvent.requests = updateArrayItem(matchEvent.requests, identity.companyId, 'companyId', request);
            matchEvents = updateArrayItem(matchEvents, identity.eventId, 'eventId', matchEvent);
          }
        }
        updated[identity.matchId] = matchEvents;
      }
      return {
        ...state,
        events: updated
      };
    }

    case actions.FXRQ_GET_PARTICIPANTS: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case actions.FXRQ_GET_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        participants: action.items
      };
    }

    case actions.FXRQ_GET_PARTICIPANTS_BY_COMPANY_SUCCESS: {
      const updated = { ...state.participantsByCompany };

      updated[action.companyId] = action.items;
      return {
        ...state,
        loading: false,
        error: false,
        participantsByCompany: updated
      };
    }

    case actions.FXRQ_NEW_PARTICIPANT: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case actions.FXRQ_NEW_PARTICIPANT_SUCCESS: {

      const participants = addItem(state.participants, action.item);

      const updated = { ...state.events };
      const identity = action.identity;
      let matchEvents = updated[identity.matchId];
      if (matchEvents) {
        const matchEvent = matchEvents.find(event => event.details.eventId === identity.eventId);
        if (matchEvent) {
          const request = matchEvent.requests.find(r => r.companyId === identity.companyId);
          if (request) {
            const section = request.sections.find(s => s.key === identity.sectionKey);
            section.items = addItem(section.items, action.item);
            request.sections = updateArrayItem(request.sections, identity.sectionKey, 'key', section);
            matchEvent.requests = updateArrayItem(matchEvent.requests, identity.companyId, 'companyId', request);
            matchEvents = updateArrayItem(matchEvents, identity.eventId, 'eventId', matchEvent);
          }
        }
        updated[identity.matchId] = matchEvents;
      }
      return {
        ...state,
        events: updated,
        participants
      };
    }

    case actions.FXRQ_SELECT_PARTICIPANT: {
      const updated = { ...state.events };
      const identity = action.identity;
      let matchEvents = updated[identity.matchId];
      if (matchEvents) {
        const matchEvent = matchEvents.find(event => event.details.eventId === identity.eventId);
        if (matchEvent) {
          const request = matchEvent.requests.find(r => r.companyId === identity.companyId);
          if (request) {
            const section = request.sections.find(s => s.key === identity.sectionKey);
            section.items = addItem(section.items, action.item);
            request.sections = updateArrayItem(request.sections, identity.sectionKey, 'key', section);
            matchEvent.requests = updateArrayItem(matchEvent.requests, identity.companyId, 'companyId', request);
            matchEvents = updateArrayItem(matchEvents, identity.eventId, 'eventId', matchEvent);
          }
        }
        updated[identity.matchId] = matchEvents;
      }
      return {
        ...state,
        events: updated
      };
    }

    case actions.FXRQ_REMOVE_PARTICIPANT: {
      const updated = { ...state.events };
      const identity = action.identity;
      const matchEvents = updated[identity.matchId];
      if (matchEvents) {
        updated[identity.matchId] = updateSectionItems(matchEvents, identity, action.item, sectionAction.Remove);
      }
      return {
        ...state,
        events: updated
      };
    }
    case actions.CREATE_FIXTURE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case actions.CREATE_FIXTURE_REQUEST_SUCCESS: {
      const events = { ...state.events };
      // const matchEvents = events[action.payload.identity.matchId];
      if (action.payload !== undefined && action.payload !== null) {
        const event = events[action.payload.matchId].find(x => x.details.eventId === action.payload.eventId);
        if (event !== undefined) {
          events[action.payload.matchId].splice(events[action.payload.matchId].indexOf(event), 1);
        }
        events[action.payload.matchId].push(singleEventMapper(action.payload));
      } else {
      }

      return {
        ...state,
        events,
        loading: false,
        error: false
      };
    }

    case actions.UPDATE_FIXTURE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case actions.UPDATE_FIXTURE_REQUEST_SUCCESS: {
      const events = { ...state.events };
      // const matchEvents = events[action.payload.identity.matchId];
      if (action.payload !== undefined && action.payload !== null) {
        const event = events[action.payload.matchId].find(x => x.details.eventId === action.payload.eventId);
        if (event !== undefined) {
          events[action.payload.matchId].splice(events[action.payload.matchId].indexOf(event), 1);
        }
        events[action.payload.matchId].push(singleEventMapper(action.payload));
      } else {
      }

      return {
        ...state,
        events,
        loading: false,
        error: false
      };
    }

    case actions.GET_FIXTURE_EVENTS_BYMATCH: {
      const events = { ...state.events };
      return {
        ...state,
        events,
        loading: true,
        error: false
      };
    }

    case actions.GET_FIXTURE_EVENTS_BYMATCH_SUCCESS: {
      const events = { ...state.events };
      // const matchEvents = events[action.payload.identity.matchId];
      if (action.payload.events.length > 0) {
        events[action.payload.identity.matchId] = eventMapper(action.payload.events, action.payload.identity);
      } else {
        events[action.payload.identity.matchId] = null;
      }

      return {
        ...state,
        events,
        loading: false,
        error: false
      };
    }

    case actions.FXRQ_GET_PARTICIPANTS_ERROR:
    case actions.FXRQ_NEW_PARTICIPANT_ERROR:
    case actions.CREATE_FIXTURE_REQUEST_ERROR:
    case actions.UPDATE_FIXTURE_REQUEST_ERROR:
    case actions.GET_FIXTURE_EVENTS_BYMATCH_ERROR: {
      return {
        ...state,
        error: true,
        loading: false
      };
    }
  }
  return state;
}

function updateSectionItems(matchEvents: MatchEvent[], identity: IDentity, item: IParticipantDto, action: sectionAction) {
  if (matchEvents) {
    const matchEvent = matchEvents.find(event => event.details.eventId === identity.eventId);
    if (matchEvent) {
      const request = matchEvent.requests.find(r => r.companyId === identity.companyId);
      if (request) {
        const section = request.sections.find(s => s.key === identity.sectionKey);

        switch (action) {
          case sectionAction.Add: section.items = addItem(section.items, item); break;
          case sectionAction.Remove: section.items = deleteItem(section.items, item); break;
        }
        request.sections = updateArrayItem(request.sections, identity.sectionKey, 'key', section);
        matchEvent.requests = updateArrayItem(matchEvent.requests, identity.companyId, 'companyId', request);
        matchEvents = updateArrayItem(matchEvents, identity.eventId, 'eventId', matchEvent);
      }
    }
    return matchEvents;
  }
  return [];
}

function eventMapper(fx: IFixtureEventDetailsDto[], identity: IDentity): MatchEvent[] {
  const result = new Array<MatchEvent>();

  fx.sort((a, b) => a.homeTeam.name > b.homeTeam.name ? 1 : -1)
    .forEach(event => {
      const matchEvent = singleEventMapper(event);
      result.push(matchEvent);
    });
  return result;
}

function singleEventMapper(fxEvent: IFixtureEventDetailsDto) {
  const matchEvent = new MatchEvent();
  matchEvent.details = { ...fxEvent };
  matchEvent.requests = [];
  if (fxEvent.requestDetails.length > 0) {
    fxEvent.requestDetails.forEach(r => {
      const request = new FxRequest();
      request.type = ERequestType.Fixture;
      const p = r.participants || [];
      request.companyId = r.companyId;
      request.companyName = r.broadcaster;
      request.status = r.status;
      request.id = r.id;

      request.clubNote = r.clubNote;
      request.broadcasterNote = r.broadcasterNote;
      request.leagueNote = r.leagueNote;

      const updatedOn = r.updatedOn.format('DD/MM/YYYY');
      const updatedBy = r.updatedBy;
      request.lastSaved = helper.getLastSavedText(r.updatedOn, r.updatedByName, StatusHelper.getRequestStatusText(r.status));

      const sections = request.sections.map(s => initSection(s.key, p.filter(x => x.type === s.sectionType)));
      request.sections = sections;
      request.sections.forEach(s => {
        switch (s.sectionType) {
          case (ParticipantType.Camera): { s.cameraOperator = r.cameraOperator; break; }
          case (ParticipantType.Other): { s.require = r.isOthersEnabled; break; }
          case (ParticipantType.Producer): { s.require = r.isProducersEnabled; break; }
          case (ParticipantType.Reporter): { s.require = r.isReportersEnabled; break; }
        }
      });
      request.note = r.broadcasterNote ? r.broadcasterNote.message : '';
      matchEvent.requests.push(request);
      request.isReadOnly = true;
      request.date = r.updatedOn;
    });
  }
  return matchEvent;
}

function addItem(array: Array<IParticipantDto>, item: IParticipantDto): IParticipantDto[] {
  const newArray = array.slice();
  if (newArray.findIndex(x => x.id === item.id) > -1) {
    return newArray;
  }
  newArray.splice(newArray.length, 0, item);
  return newArray;
}

function deleteItem(array: Array<IParticipantDto>, item: IParticipantDto): IParticipantDto[] {
  const newArray = array.slice();
  return newArray.filter((element) => element.id !== item.id);
}

function updateArrayItem(array: any[], key: string, property: string, newItem) {
  return array.map((item, index) => {
    if (item[property] !== key) {
      return item;
    }
    return newItem;
  });
}
