import {
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
  Action,
} from '@ngrx/store';

import { environment } from '../../environments/environment';
import { UserState } from './user/state';
import * as UserReducer from './user/reducer';
import { TrainingReducer } from './training/reducer';
import { BppState } from './bpp/state';
import { BppReducer } from './bpp/reducer';
import { TrainingState } from './training/state';
import { FixturesReducer } from './fixture/reducer';
import { FixtureState } from './fixture/state';
import { LocationState } from './location/state';
import { LocationReducer } from './location/reducer';
import { FixtureRequests } from './fx-request/state';
import { FixtureRequestReducer } from './fx-request/reducer';
import { DashboardState } from './dashboard/state';
import { DashboardReducer } from './dashboard/reducer';
import { ReportState } from './report/state';
import { ReportReducer } from './report/reducer';
import { ClubsState } from './clubs/state';
import { ClubsReducer } from './clubs/reducer';
import { FilmingState } from './filming/state';
import { FilmingReducer } from './filming/reducer';
import { InterviewsState } from './interviews/state';
import { InterviewsReducer } from './interviews/reducer';
import { TeamMembersState } from './team-members/state';
import { TeamMembersReducer } from './team-members/reducer';
import { MatchesState } from './matches/state';
import { MatchesReducer } from './matches/reducer'
import {LocationTypesState} from './location-types/state';
import {LocationTypesReducer} from './location-types/reducer';
import {AdditionalContentReducer} from './additional-content/reducer';
import {AdditionalContentState} from './additional-content/state';
import { InterviewTypesState } from './interview-types/state';
import { InterviewTypesReducer } from './interview-types/reducer';
import { ClubInterviewState } from './club-interview/state';
import { ClubInterviewsReducer } from './club-interview/reducer';
import { NewsState } from './news/state';
import { NewsReducer } from './news/reducer';
export interface AppState {
  user: UserState;
  bpp: BppState;
  trainings: TrainingState;
  fixtures: FixtureState;
  location: LocationState;
  locationTypes: LocationTypesState;
  fxRequests: FixtureRequests;
  dashboard: DashboardState;
  report: ReportState;
  clubs: ClubsState;
  filming: FilmingState;
  interviews: InterviewsState;
  interviewTypes: InterviewTypesState;
  teamMembers: TeamMembersState;
  matches: MatchesState;
  additionalContent: AdditionalContentState;
  clubInterviews: ClubInterviewState;
  news: NewsState;
}

export interface IStoreData {
  loading: boolean;
  error?: boolean;
}

export type Reducer<T> = (state: T, action: Action) => T;

export const reducers: ActionReducerMap<AppState> = {
  user: UserReducer.UserReducer,
  bpp: BppReducer,
  trainings: TrainingReducer,
  fixtures: FixturesReducer,
  location: LocationReducer,
  locationTypes: LocationTypesReducer,
  fxRequests: FixtureRequestReducer,
  dashboard: DashboardReducer,
  report: ReportReducer,
  clubs: ClubsReducer,
  filming: FilmingReducer,
  interviews: InterviewsReducer,
  teamMembers: TeamMembersReducer,
  matches: MatchesReducer,
  additionalContent: AdditionalContentReducer,
  interviewTypes: InterviewTypesReducer,
  clubInterviews: ClubInterviewsReducer,
  news: NewsReducer
};


export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: any): AppState {

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];
