import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/services/alert.service";
import { TeamMembersService } from "src/app/services/team-members.service";
import { AppState } from "../app.state";
import * as actions from './actions';

@Injectable()
export class TeamMembersEffects {
	constructor(
		protected store: Store<AppState>,
		protected service: TeamMembersService,
		protected action$: Actions,
		private alertService: AlertService
	) { }

	@Effect()
	GetTeamMembers$: Observable<Action> = this.action$.pipe(
		ofType<actions.GetTeamMembersAction>(actions.GET_TEAM_MEMBERS),
		switchMap(action => this.service.getTeamMembers(action.skip, action.take).pipe(
			switchMap(result => of(new actions.GetTeamMembersActionSuccess(result))),
			catchError(error => {
				this.alertService.error(error);

				return of(new actions.GetTeamMembersActionError(error));
			}))));

	@Effect()
	GetTeamMember$: Observable<Action> = this.action$.pipe(
		ofType<actions.GetTeamMemberAction>(actions.GET_TEAM_MEMBER),
		switchMap(action => this.service.getTeamMember(action.id).pipe(
			switchMap(result => of(new actions.GetTeamMemberActionSuccess(result.value))),
			catchError(error => {
				this.alertService.error(error);

				return of(new actions.GetTeamMemberActionError(error));
			}))));

	@Effect()
	UpdateTeamMember$: Observable<Action> = this.action$.pipe(
		ofType<actions.UpdateTeamMemberAction>(actions.UPDATE_TEAM_MEMBER),
		switchMap(action => this.service.updateTeamMember(action.model).pipe(
			switchMap(result => {
        this.alertService.success('Team member updated successfully');
        return of(new actions.UpdateTeamMemberActionSuccess(result));
      }),
			catchError(error => {
				this.alertService.error(error);

				return of(new actions.UpdateTeamMemberActionError(error));
			}))));

	@Effect()
	GetTeamMembersByClubId$: Observable<Action> = this.action$.pipe(
		ofType<actions.GetTeamMembersByClubIdAction>(actions.GET_TEAM_MEMBERS_BY_CLUB_ID),
		switchMap(action => this.service.getTeamMembersByClubId(action.clubId, action.skip, action.take).pipe(
			switchMap(result => of(new actions.GetTeamMembersByClubIdActionSuccess(result))),
			catchError(error => {
				this.alertService.error(error);

				return of(new actions.GetTeamMembersByClubIdActionError(error));
			}))));
}
