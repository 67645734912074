import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface IDefaultsInfo {
    teamBio: string;
}

@Injectable()
export class DefaultsService {

    constructor(private httpClient: HttpClient) { }

    getDefaults(): Observable<IDefaultsInfo> {
        const headers = { 'content-type': 'application/json' };
        return this.httpClient.get('assets/defaults.json', { headers }).pipe(map(res => {
            if (res) {
                return res as IDefaultsInfo;
            }
        }));
    }
}
