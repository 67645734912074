import { EventEmitter, Output, OnInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserModel } from './user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-d3-login-info',
  templateUrl: './login-info.component.html',
  styleUrls: ['./login-info.component.scss']
})
export class LoginInfoComponent implements OnInit {

  user: UserModel;
  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  @Input('user') get _user(): UserModel {
    return this.user;
  }
  set _user(v: UserModel) {
    this.user = v;
    setInterval(() => {
      this.changedetector.markForCheck();
    }, 100);
  }

  @Input() template: string = 'default';

  constructor(protected changedetector: ChangeDetectorRef) { }


  ngOnInit() {
  }

  onLogOutClick(evt) {
    this.logout.emit(true);
  }

  onEdit(evt) {
    this.edit.emit(true);
  }
}
