import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IFilmingLocationDto } from '../../../../services/bpp.webapi/client';
import { ITimePicker } from '../../../common/combo-picker/combo-picker.component';
import { IFilmingLocationItem } from '../../../../store/filming/state';
import { Moment } from 'moment';

export interface IFilmingItemFormConfig {
  startLabelId: string;
  endLabelId: string;
  flexDirectionRow: boolean;
  timeHintTextId?: string;
  invertedTimeLogic?: boolean;
}

export interface ITimeLimits {
  start: {
    min: Moment,
    max: Moment
  };
  end: {
    min: Moment,
    max: Moment
  };
}

@Component({
  selector: 'app-filming-form-item',
  templateUrl: './filming-form-item.component.html',
  styleUrls: ['./filming-form-item.component.scss']
})
export class FilmingFormItemComponent implements OnInit, AfterViewInit {
  modelValue: IFilmingLocationItem;
  @Input() get model() {
    return this.modelValue;
  }
  @Output() modelChange = new EventEmitter<IFilmingLocationItem>();
  set model(value) {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  @Input() locations: IFilmingLocationDto[] = [];
  @Input() timeLimits: ITimeLimits;
  @Output() locationChange = new EventEmitter();
  @Input() position: number;
  @Input() config: IFilmingItemFormConfig;

  minStart: Subject<ITimePicker> = new Subject();
  maxStart: Subject<ITimePicker> = new Subject();
  minEnd: Subject<ITimePicker> = new Subject();
  maxEnd: Subject<ITimePicker> = new Subject();
  minutesStep = 5;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.minStart.next({
      hour: this.timeLimits.start.min.hours(),
      minute: this.timeLimits.start.min.minutes()
    });

    this.maxStart.next({
      hour: this.timeLimits.start.max.hours(),
      minute: this.timeLimits.start.max.minutes()
    });

    if (this.model.startTime.hour === 0 && this.model.endTime.hour === 0) {
      this.model.startTime.hour = this.timeLimits.start.min.hours();
      this.model.endTime.hour = this.timeLimits.end.min.hours();
    }

    if (this.config.invertedTimeLogic) {
      this.minEnd.next({
        hour: 0,
        minute: 0
      });

      this.maxEnd.next({
        hour: this.model.startTime.hour,
        minute: 55 // this.model.startTime.minute
      });
    } else {
      this.minEnd.next({
        hour: this.model.startTime.hour,
        minute: this.model.startTime.minute + this.minutesStep
      });

      this.maxEnd.next({
        hour: this.timeLimits.end.max.hours(),
        minute: this.timeLimits.end.max.minutes()
      });
    }

  }

  onLocationChange(event: IFilmingLocationDto) {
    this.model.locationId = event.locationId;
    this.model.title = event.locationName;
    this.locationChange.emit();
  }

  onStartTimeChanged(event: ITimePicker) {
    this.model.startTime = event;
    if (this.config.invertedTimeLogic) {
      this.maxEnd.next({
        hour: event.hour === 0 ? 0 : event.hour - 1,
        minute: event.minute !== this.model.startTime.minute ? event.minute - this.minutesStep : 55
      });
    } else {
      this.minEnd.next({
        hour: event.hour,
        minute: event.minute + this.minutesStep
      });
    }
  }

  convertEndTimeLimit(start: ITimePicker, limit: ITimePicker) {
    if (start.minute === 60 - this.minutesStep) {
      return {
        hour: start.hour + 1,
        minute: 0
      };
    } else {
      return {
        hour: start.hour,
        minute: start.minute + this.minutesStep
      };
    }
  }

  onEndTimeChanged(event: ITimePicker) {
    this.model.endTime = event;
  }
}
