import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../base-component/base.component';
import { EventStatus } from '../../services/bpp.webapi/client';
import { StatusHelper } from '../../helpers/statuses-helper';
import { GetInterviewTypesAction } from 'src/app/store/interview-types/actions';

@Component({
  selector: 'app-interview-details-wrapper',
  templateUrl: './interview-details-wrapper.component.html'
})
export class InterviewDetailsWrapperComponent extends BaseComponent implements OnInit {
  Roles = Roles;
  role: Roles;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }
}
