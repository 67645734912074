
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLocationComponent } from '../add-location/add-location.component';
import { CreateLocationDto, LocationDto, LocationDetailsDto, ILocationDto, LocationTypes, LocationTypeDto } from '../../../services/bpp.webapi/client';
import { CreateLocationAction, GetLocationsAction, UpdateLocationAction, DeleteLocationAction } from '../../../store/location/actions';
import { Subject } from 'rxjs';
import { ConfigService } from '../../../config/config.service';
import { cloneDeep } from 'lodash';
import {GetAvailableLocationTypesAction, GetLocationTypesAction} from '../../../store/location-types/actions';

@Component({
  selector: 'app-locations-picker',
  templateUrl: './locations-picker.component.html',
  styleUrls: ['./locations-picker.component.scss']
})
export class LocationsPickerComponent implements OnInit, OnDestroy, OnChanges {
  private componentDestroyed: Subject<void> = new Subject();
  modalSubscription;
  modalDelSubscription;
  selectedLocation: LocationDto;
  locations: Array<LocationDto> = new Array<LocationDto>();
  isLog = false;

  @Output() modelChange: EventEmitter<LocationDto> = new EventEmitter<LocationDto>();
  @Input() isCanEdit: Boolean = true;
  @Input() clubId: number;
  @Input() type: LocationTypeDto;
  @Input() interviewMode = false;
  @Input()
  get location(): LocationDto {
    return this.selectedLocation;
  }
  set location(value) {
    this.selectedLocation = value;
    this.modelChange.emit(this.selectedLocation);
  }

  get filteredLocations() {
    return this.type ? this.locations.filter(x => x.type === this.type.id) : this.locations;
  }

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    private config: ConfigService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store
      .select(state => state.location).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state && state.items && state.items.length > 0) {
          if (this.isLog) { console.log('Location-picker init:', state.items, this.clubId); }

          if (!this.interviewMode) {
            // tslint:disable-next-line:triple-equals
            this.locations = state.items.filter(x => x.clubId == this.clubId && x.title !== undefined);
          } else {
            this.locations = state.items;
          }
          this.onLocationChange(cloneDeep(this.locations.find(x => x.locationId === this.selectedLocation.locationId)));
        } else if (this.clubId && this.clubId > 0) {
          this.store.dispatch(new GetLocationsAction(this.clubId));
        }
      });

    this.store.dispatch(new GetLocationTypesAction());
    this.store.dispatch(new GetAvailableLocationTypesAction(this.clubId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  // Modal Location
  openAddLocation() {
    const modalRef = this.modalService.open(AddLocationComponent);
    this.modalSubscription = modalRef.componentInstance.save.subscribe(location => this.saveLocation(location));
  }

  openUpdateLocation(item: LocationDto) {
    const modalRef = this.modalService.open(AddLocationComponent);
    modalRef.componentInstance.id = item.locationId;
    this.modalSubscription = modalRef.componentInstance.update.subscribe(location => this.updateLocation(location));
    this.modalDelSubscription = modalRef.componentInstance.delete.subscribe(location => this.deleteLocation(location));
  }

  saveLocation(location: CreateLocationDto) {
    location.leagueType = this.interviewMode;
    location.clubId = this.clubId;
    this.store.dispatch(new CreateLocationAction(location));
    this.modalSubscription.unsubscribe();
  }

  updateLocation(location: LocationDetailsDto) {
    this.store.dispatch(new UpdateLocationAction(location));

    this.modalSubscription.unsubscribe();
    this.modalDelSubscription.unsubscribe();
  }

  deleteLocation(location: LocationDetailsDto) {
    this.store.dispatch(new DeleteLocationAction(location.locationId, this.clubId));

    this.modalSubscription.unsubscribe();
    this.modalDelSubscription.unsubscribe();
  }

  onLocationChange(location: LocationDto) {
    if(location) {
      this.selectedLocation.init(location);
    }
  }

  getPlaceholder(location: ILocationDto) {
    return location && location.title
      ? (location.title.length > 28)
        ? (location.title.slice(0, 28))
        + '...'
        : location.title
      : 'Select...';
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
