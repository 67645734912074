import { Component, OnInit, Input } from '@angular/core';
import { IFilmingMatchDto, IFilmingLocation, IFilmingLocationItem } from '../../../store/filming/state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilmingModalComponent } from '../../common/filming-modal/filming-modal.component';
import { BaseComponent } from '../../base-component/base.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ConfigService } from '../../../config/config.service';
import { UpdateFilmingLocationLeagueAction, UpdateFilmingLocationClubAction } from '../../../store/filming/actions';
import { Roles } from '../../../models/enums';
import { ITimePicker } from '../../common/combo-picker/combo-picker.component';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-filming-match',
  templateUrl: './filming-match.component.html',
  styleUrls: ['./filming-match.component.scss']
})
export class FilmingMatchComponent extends BaseComponent implements OnInit {
  @Input() match: IFilmingMatchDto;
  userRole: Roles;
  updateInProgress = false;

  constructor(private modalService: NgbModal, private store: Store<AppState>, config: ConfigService) {
    super();
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store.select(s => s.user.currentUser)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(res => {
        if (res) {
          this.userRole = res.role;
        }
      });
    this.store.select(s => s.filming.updateStatus)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(res => {
        this.updateInProgress = res.loading && res.matchId === this.match.matchId;
      });
  }

  onEdit(match: IFilmingMatchDto) {
    const modalRef = this.modalService.open(FilmingModalComponent, { backdrop: 'static', keyboard: false });
    const instance = modalRef.componentInstance as FilmingModalComponent;
    instance.match = match;
    instance.locations$ = this.store
      .select(s => s.filming.locations.byClubId)
      .pipe(
        takeUntil(this.componentDestroyed),
        map(res => res[this.match.homeTeam.id]));

    return instance.submit
      .subscribe((result: IFilmingLocation) => {
        if (result) {
          switch (this.userRole) {
            case Roles.Admin:
            case Roles.League:
              this.store.dispatch(new UpdateFilmingLocationLeagueAction(result, match.date, match.matchDay));
              break;
            case Roles.Club:
              this.store.dispatch(new UpdateFilmingLocationClubAction(result, match.date));
              break;
          }
          if (this.isLog) {
            console.log('Update location: ', result);
          }
        }
      });
  }

  getTimeString(item: IFilmingLocationItem) {
    return `${this.getHourMinute(item.startTime)} - ${this.getHourMinute(item.endTime)}`;
  }

  getHourMinute(source: ITimePicker) {
    return `${this.addZeroes(source.hour)}:${this.addZeroes(source.minute)}`;
  }

  addZeroes(item: number) {
    return item > 9 ? item : `0${item}`;
  }

  getToolTip() {
    const note = this.match.filmingLocation.note;
    if (note && note.length > 100) {
      return note.substring(0, 100) + '...';
    }
    if (note && note.length > 0) {
      return note;
    }
  }

}
