
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './actions';
import { FixtureService } from '../../services/fixture.service';
import { StatusHelper } from '../../helpers/statuses-helper';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class FixtureRequestEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: FixtureService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetRequestParticipants$: Observable<Action> = this.action$.pipe(
    ofType<actions.FxRequestGetParticipantsAction>(actions.FXRQ_GET_PARTICIPANTS),
    switchMap(action => this.service.getParticipants().pipe(
      switchMap((result) => of(new actions.FxRequestGetParticipantsActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.FxRequestGetParticipantsActionError(error));
      }))));

  @Effect()
  GetParticipantsByCompany$: Observable<Action> = this.action$.pipe(
    ofType<actions.FxRequestGetParticipantsByCompanyAction>(actions.FXRQ_GET_PARTICIPANTS_BY_COMPANY),
    switchMap(action => this.service.getParticipantsByCompany(action.companyId).pipe(
      switchMap((result) => of(new actions.FxRequestGetParticipantsByCompanyActionSuccess(action.companyId, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.FxRequestGetParticipantsByCompanyActionError(error));
      }))));

  @Effect()
  CreateParticipant$: Observable<Action> = this.action$.pipe(
    ofType<actions.FxRequestCreateParticipantAction>(actions.FXRQ_NEW_PARTICIPANT),
    switchMap((action) => this.service.createParticipant(action.item).pipe(
      switchMap(result => of(new actions.FxRequestCreateParticipantActionSuccess(action.identity, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.FxRequestCreateParticipantActionError(error));
      }))));

  @Effect()
  CreateFixtureRequest$ = this.action$.pipe(
    ofType<actions.CreateFixtureRequestAction>(actions.CREATE_FIXTURE_REQUEST),
    switchMap(action => this.service.createRequest(action.payload).pipe(
      switchMap((result) => {
        const success$ = of(new actions.CreateFixtureRequestSuccessAction(result));
        const message = `Fixture Request created, status set to ${StatusHelper.getRequestStatusText(action.payload.status)}`;
        this.alertService.success(message);
        return success$;
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateFixtureRequestErrorAction(error));
      }))));

  @Effect()
  UpdateFixtureRequest$ = this.action$.pipe(
    ofType<actions.UpdateFixtureRequestAction>(actions.UPDATE_FIXTURE_REQUEST),
    switchMap(action => this.service.updateRequest(action.payload).pipe(
      switchMap((result) => {
        const success$ = of(new actions.UpdateFixtureRequestSuccessAction(result));
        const message = `Fixture Request updated, status set to ${StatusHelper.getRequestStatusText(action.payload.status)}`;
        this.alertService.success(message);
        return success$;
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateFixtureRequestErrorAction(error));
      }))));

  @Effect()
  GetEventsByMatch$ = this.action$.pipe(
    ofType<actions.GetEventsByMatchAction>(actions.GET_FIXTURE_EVENTS_BYMATCH),
    switchMap(action => this.service.getEventsByMatchId(action.identity.matchId, action.identity.nonLiveInUK).pipe(
      switchMap(events => of(new actions.GetEventsByMatchActionSuccess({ identity: action.identity, events }))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetEventsByMatchActionError(error));
      }))));
}
