import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { ConfigService } from '../../../config/config.service';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-picker-wrapper',
  templateUrl: './date-picker-wrapper.component.html',
  styleUrls: ['./date-picker-wrapper.component.scss']
})
export class DatePickerWrapperComponent implements OnInit {

  @Input() maxDate: { year: number; month: number; day: number; };
  @Input() minDate: { year: number; month: number; day: number; };

  @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  timeZone: string;
  time: Moment;

  constructor(public configService: ConfigService) {
    this.timeZone = configService.get('defaultTimeZone');
  }

  model = {
    year: moment().utc().year(),
    month: moment().utc().month(),
    day: moment().utc().date()
  };

  @Input()
  get ngModel(): moment.Moment {
    return moment(this.time)
    .tz(this.timeZone)
    .date(this.model.day)
    .month(this.model.month - 1)
    .year(this.model.year)
    .utc();
  }
  set ngModel(value) {
    this.model = {
      year: moment.utc(value).tz(this.timeZone).year(),
      month: moment.utc(value).tz(this.timeZone).month() + 1,
      day: moment.utc(value).tz(this.timeZone).date()
    };
    this.time = moment.utc(value).tz(this.timeZone);
  }

  onPickerChange() {
    console.log('onPickerChange: ngModel', this.ngModel);
    // console.log('onPickerChange: model', this.model);
    this.dateChange.emit(this.ngModel);
  }

  ngOnInit() {

    console.log('onPickerInit ngModel', this.ngModel);
    console.log('onPickerInit model', this.model);

  }

}
