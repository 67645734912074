import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../authentication/auth.service";
import { IResultOfTeamMemberDto, PagedListOfTeamMemberDto, TeamMemberDto, TeamMembersClient } from "./bpp.webapi/client";

@Injectable()
export class TeamMembersService {
	constructor(protected authService: AuthService,
		protected client: TeamMembersClient) { }

	getTeamMembers(skip: number, take: number): Observable<PagedListOfTeamMemberDto> {
		return this.client.get(skip, take);
	}

	getTeamMembersByClubId(id: number, skip: number, take: number): Observable<PagedListOfTeamMemberDto> {
		return this.client.getByClubId(id, skip, take);
	}

	getTeamMember(memberId: number): Observable<IResultOfTeamMemberDto> {
		return this.client.get2(memberId);
	}

	updateTeamMember(model: TeamMemberDto): Observable<TeamMemberDto> {
		return this.client.post(model);
	}
}