import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order',
  pure: false
})
export class OrderPipe implements PipeTransform {
  transform(array: Array<string>, filter: { arg: string, desc: boolean, isDate: boolean }): Array<string> {
    if (!array) {
      return array;
    }
    array.sort((a: any, b: any) => {
      const v1 = filter.isDate ? a[filter.arg].valueOf() : a[filter.arg];
      const v2 = filter.isDate ? b[filter.arg].valueOf() : b[filter.arg];
      if ((v1 < v2 && filter.desc !== true) || (v1 > v2 && filter.desc === true)) {
        return -1;
      } else if ((v1 > v2 && filter.desc !== true) || (v1 < v2 && filter.desc === true)) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
 }


