import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getComponentByRole, IComponetByRole } from 'src/app/helpers/component-by-role';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { ClubInterviewsBroadcasterComponent } from '../club-interviews-broadcaster/club-interviews-broadcaster.component';
import { ClubInterviewsClubComponent } from '../club-interviews-club/club-interviews-club.component';
import { ClubInterviewsLeagueComponent } from '../club-interviews-league/club-interviews-league.component';

@Component({
  selector: 'app-club-interviews-wrapper',
  templateUrl: './club-interviews-wrapper.component.html'
})
export class ClubInterviewsWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) fixtureRef;
  interval: any;
  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: ClubInterviewsLeagueComponent },
    { role: Roles.Admin, component: ClubInterviewsLeagueComponent },
    { role: Roles.Broadcaster, component: ClubInterviewsBroadcasterComponent },
    { role: Roles.Club, component: ClubInterviewsClubComponent }
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>) { }

  ngOnInit() {

    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
        }
      });
  }

  loadComponent(role) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.fixtureRef.clear();
    const ref = this.fixtureRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userInfo) {
      this.userInfo.unsubscribe();
    }
  }

}
