
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    TrainingClient, ITrainingDto, ICreateTrainingDto, CreateTrainingDto, ITrainingDetailsDto, IUpdateTrainingDto, UpdateTrainingDto, TrainingParticipantClient, ParticipantType,
    IParticipantDto, ICreateParticipantDto, CreateParticipantDto, ParticipantDto, TrainingRequestClient, TrainingDetailsDto, ICreateTrainingRequestDto, CreateTrainingRequestDto,
    IUpdateTrainingRequestDto, UpdateTrainingRequestDto
} from './bpp.webapi/client';
import { Roles } from '../models/enums';

@Injectable()
export class TrainingService {
    constructor(protected apiClient: TrainingClient,
        protected participantsApiClient: TrainingParticipantClient, protected requestsApiClient: TrainingRequestClient) {
    }

    public getTrainingEvents(role: Roles) {
        switch (role) {
            case Roles.Club: return this.getEventsForClub();
            case Roles.Admin:
            case Roles.League: return this.getEventsForLeague();
            case Roles.Broadcaster:
            case Roles.PLPBroadcaster: return this.getEventsForBroadcaster();
        }
    }

    private getEvents(): Observable<Array<ITrainingDto>> {
        return this.apiClient.getAll();
    }

    private getEventsForClub(): Observable<Array<ITrainingDto>> {
        return this.apiClient.getAll().pipe(
            map(items => {
                const maped = items.map((item, i) => {
                    const date = item.date.format('MMMM/DD');
                    return {
                        club: `${date} Training Session`,
                        id: item.id,
                        date: item.date,
                        status: item.status,
                        location: item.location
                    } as ITrainingDto;
                });
                const sorted = maped.sort((a, b) => b.date.valueOf() - a.date.valueOf());
                return sorted;
            }));
    }

    private getEventsForLeague() {
        return this.getEvents();
    }

    private getEventsForBroadcaster() {

        return this.getEvents();
    }

    getEventById(id: string): Observable<ITrainingDetailsDto> {
        return this.apiClient.get(id);
    }

    createEvent(model: ICreateTrainingDto) {
        return this.apiClient.post(model as CreateTrainingDto);
    }

    updateEvent(model: IUpdateTrainingDto) {
        return this.apiClient.put(model as UpdateTrainingDto);
    }

    getParticipants(): Observable<Array<IParticipantDto>> {
        return this.participantsApiClient.getAll();
    }

    getParticipantsByCompany(companyId: string): Observable<Array<IParticipantDto>> {
        return this.participantsApiClient.getByCompany(companyId);
    }

    createParticipant(model: ICreateParticipantDto): Observable<ParticipantDto> {
        return this.participantsApiClient.post(model as CreateParticipantDto);
    }

    createRequest(model: ICreateTrainingRequestDto): Observable<TrainingDetailsDto> {
        return this.requestsApiClient.post(model as CreateTrainingRequestDto);
    }

    updateRequest(model: IUpdateTrainingRequestDto): Observable<TrainingDetailsDto> {
        return this.requestsApiClient.put(model as UpdateTrainingRequestDto);
    }
}
