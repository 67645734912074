import { NewsState } from './state';
import * as actions from './actions';

export type Action = actions.All;
const defaultState = new NewsState();

export function NewsReducer(
    state = defaultState,
    action: Action
): NewsState {
    switch (action.type) {

        case actions.GET_NEWS: {
            const newState = { ...state };
            newState.getLoading = true;
            newState.isError = false;
            return newState;
        }

        case actions.GET_NEWS_SUCCESS: {
            const newState = { ...state };
            newState.getLoading = false;
            newState.allNews = action.payload;
            return newState;
        }

        case actions.GET_NEWS_BY_ID: {
            const newState = { ...state };
            newState.getLoading = true;
            newState.detailNews = null;
            newState.isError = false;
            return newState;
        }

        case actions.GET_NEWS_BY_ID_SUCCESS: {
            const newState = { ...state };
            newState.getLoading = false;
            newState.detailNews = action.payload;
            return newState;
        }

        case actions.GET_RSS_NEWS: {
            const newState = { ...state };
            newState.getLoading = true;
            newState.isError = false;
            return newState;
        }

        case actions.GET_RSS_NEWS_SUCCESS: {
            const newState = { ...state };
            newState.getLoading = false;
            newState.rssNews = action.payload;
            return newState;
        }

        case actions.GET_NEWS_ERROR: {
            const newState = { ...state };
            newState.getLoading = false;
            newState.isError = true;
            newState.allNews = { items: [] };
            // handleWebApiError(action.payload);
            return newState;
        }

    }

    return state;
}
