import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Subscription } from 'rxjs';
import { Roles } from '../../../../models/enums';
import { ConfigService } from '../../../../config/config.service';

@Component({
  selector: 'app-news-detail-rp',
  templateUrl: './news-detail-rp.component.html',
  styleUrls: ['./news-detail-rp.component.scss']
})
export class NewsDetailRpComponent implements OnInit, OnDestroy {

  private newsSubscription: Subscription;
  private userSubscription: Subscription;

  allNews;
  news;
  oneNews;
  slug;
  isLeagueRole = false;
  settings;
  forgeUrl: string;

  constructor(private router: Router, private route: ActivatedRoute, protected store: Store<AppState>, private configService: ConfigService) { }

  ngOnInit() {
    this.settings = this.configService.get('features.documents');
    this.forgeUrl = this.configService.get('forgeUrl');

    this.route.params.subscribe((params) => {
      this.slug = params['id'];
      this.changeNews();
    });

    this.userSubscription = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.isLeagueRole = u.role === Roles.League;
        }
      });

    this.newsSubscription = this.store
      .select(state => state.news)
      .subscribe(result => {
        if (result && result.getLoading === false && result.allNews) {

          this.allNews = result.allNews.items;
          this.changeNews();
        }
      });

  }

  changeNews() {
    if (this.allNews) {
      this.oneNews = this.allNews.find(item => item.slug === this.slug);
      this.news = this.allNews.filter(item => item.contentDate > this.oneNews.contentDate).slice(-5);
    }
  }

  readClick(item) {
    this.router.navigate([`/news/detail/${item.slug}`]);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.newsSubscription) {
      this.newsSubscription.unsubscribe();
    }
  }

}
