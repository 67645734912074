import * as actions from './actions';
import { FilmingState } from './state';

export type Action = actions.All;
const defaultState = new FilmingState();

export function FilmingReducer(state = defaultState, action: Action): FilmingState {
    switch (action.type) {
        case actions.GET_LEAGUE_FILMING_FIXTURES: {
            return {
                ...state,
                fixtures: {
                    ...state.fixtures,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.GET_LEAGUE_FILMING_FIXTURES_SUCCESS: {
            return {
                ...state,
                fixtures: {
                    items: action.payload,
                    loading: false,
                    error: false
                }
            };
        }
        case actions.GET_LEAGUE_FILMING_FIXTURES_ERROR: {
            return {
                ...state,
                fixtures: {
                    ...state.fixtures,
                    loading: false,
                    error: true
                }
            };
        }
        case actions.GET_CLUB_FILMING_MATCHES: {
            return {
                ...state,
                clubMatches: {
                    ...state.clubMatches,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.GET_CLUB_FILMING_MATCHES_SUCCESS: {
            return {
                ...state,
                clubMatches: {
                    items: action.payload,
                    loading: false,
                    error: false
                }
            };
        }
        case actions.GET_CLUB_FILMING_MATCHES_ERROR: {
            return {
                ...state,
                clubMatches: {
                    ...state.clubMatches,
                    loading: false,
                    error: true
                }
            };
        }
        case actions.GET_FILMING_LOCATIONS: {
            return {
                ...state,
                locations: {
                    ...state.locations,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.GET_FILMING_LOCATIONS_SUCCESS: {
            return {
                ...state,
                locations: {
                    byClubId: action.payload,
                    loading: false,
                    error: false
                }
            };
        }
        case actions.GET_FILMING_LOCATIONS_ERROR: {
            return {
                ...state,
                locations: {
                    ...state.locations,
                    loading: false,
                    error: true
                }
            };
        }
        case actions.UPDATE_FILMING_LOCATION_CLUB:
        case actions.UPDATE_FILMING_LOCATION_LEAGUE: {
            return {
                ...state,
                updateStatus: {
                    loading: true,
                    matchId: action.dto.matchId
                }
            };
        }
        case actions.UPDATE_FILMING_LOCATION_LEAGUE_SUCCESS: {
            const upcoming = state.fixtures.items.upcoming.slice();
            if (action.updatedLocation && action.matchRound) {
                const round = upcoming.find(w => w.weekNumber === action.matchRound);
                if (round) {
                    const match = round.matches.find(m => m.matchId === action.updatedLocation.matchId);
                    if (match) {
                        match.filmingLocation = action.updatedLocation;
                    }
                }
            }

            return {
                ...state,
                fixtures: {
                    ...state.fixtures,
                    items: {
                        ...state.fixtures.items,
                        upcoming: upcoming,
                    },
                },
                updateStatus: {
                    loading: false,
                    matchId: action.updatedLocation.matchId
                }
            };
        }
        case actions.UPDATE_FILMING_LOCATION_CLUB_SUCCESS: {
            const upcoming = state.clubMatches.items.upcoming.slice();
            const match = upcoming.find(m => m.matchId === action.updatedLocation.matchId);
            if (match) {
                match.filmingLocation = action.updatedLocation;
            }
            return {
                ...state,
                clubMatches: {
                    ...state.clubMatches,
                    items: {
                        ...state.clubMatches.items,
                        upcoming: upcoming,
                    }
                },
                updateStatus: {
                    loading: false,
                    matchId: action.updatedLocation.matchId
                }
            };
        }
        case actions.UPDATE_FILMING_LOCATION_ERROR: {
            return {
                ...state,
                updateStatus: {
                    loading: false,
                    matchId: undefined
                }
            };
        }
    }
    return state;
}
