import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { ConfigService } from '../config/config.service';

/**
* A moment timezone pipe to support parsing based on time zone abbreviations
* covers all cases of offset variation due to daylight saving.
*
* Same API as DatePipe with additional timezone abbreviation support
* Official date pipe dropped support for abbreviations names from Angular V5
*/
@Pipe({
    name: 'momentDate',
    pure: false
})
export class MomentDatePipe extends DatePipe implements PipeTransform {
    defaultTimeZone: string;
    constructor(private config: ConfigService) {
        super(config.get('locale'));
        this.defaultTimeZone = this.config.get('defaultTimeZone');
    }

    transform(
        value: string | Date,
        format: string = 'mediumDate',
        timezone: string = this.defaultTimeZone, // 'GMT'
        locale:string = this.config.get('locale'),
        isDuration:boolean = false
    ): string {
        const timezoneOffset = moment(value).tz(timezone).format('Z');
        if (this.config.isLfp() && format.includes('HH:mm') && !isDuration) {
            return super.transform(value, format, timezoneOffset) + " CET";
        }
        return super.transform(value, format, timezoneOffset);
    }
}
