import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export class BaseComponent implements OnDestroy {
    protected componentDestroyed: Subject<void> = new Subject();
    protected isLog: boolean;
    isSpinner: boolean;

    ngOnDestroy(): void {
        if (this.componentDestroyed) {
            this.componentDestroyed.next();
            this.componentDestroyed.unsubscribe();
        }
    }
}
