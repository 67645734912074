import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-something-wrong',
  templateUrl: './something-wrong.component.html',
  styleUrls: ['./something-wrong.component.scss']
})
export class SomethingWrongComponent implements OnInit {
@Input() errorMessage: string;
backRoute: string;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams
    .subscribe(p => {
      this.errorMessage = p.errorMessage;
      this.backRoute = p.backRoute;
    });

    if (!this.backRoute) { this.backRoute = '/dashboard'; }
  }

  navigate() {
    console.log('Something wrong back link', this.backRoute);
    this.router.navigate([this.backRoute]);
  }

}
