import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-reject-confirm-box',
  templateUrl: './approve-reject-confirm.component.html',
  styleUrls: ['./approve-reject-confirm.component.scss']
})
export class ApproveRejectConfirmComponentLfp implements OnInit {

  @Input() note:string;
  @Input() isReject:boolean = false;
  @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();

  submitted:boolean;

  constructor(public activeModal: NgbActiveModal) {

   }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted=true;
    if (!this.hasNote() && this.isReject){
      return;
    }
    this.submit.emit(true);
    this.activeModal.close();
  }

  onCancel() {
    this.submit.emit(false);
    this.activeModal.close();
  }
  hasNote(){
    return this.note!="";
  }
}
