import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { Roles } from 'src/app/models/enums';
import { User } from 'src/app/models/user.model';
import { AppInsightClientService } from 'src/app/services/app-insight.service';
import { PlayerDto, IMatchInfoDto, EventStatus, ClubMatchInterviewEventDetailsDto, CreateInterviewEventDetailsDto, InterviewEventDetailsDto, IClubMatchInterviewEventDetailsDto } from 'src/app/services/bpp.webapi/client';
import { ValidationService } from 'src/app/services/validation.service';
import { AppState } from 'src/app/store/app.state';
import { GetPlayerListAction } from 'src/app/store/bpp/actions';
import { CreateFixtureEventAction } from 'src/app/store/fixture/actions';
import { FxRequest } from 'src/app/store/fx-request/state';
import { IFixtureHeader } from '../../fixture-details/fixture-header/fixture-header.component';
import * as helper from '../../../helpers/common';
import * as interviewHelper from '../../../helpers/fixture-helper';
import * as _ from 'lodash';
import { CreateClubInterviewEventAction, GetClubInterviewDetailsForClubAction, UpdateClubInterviewEventAction } from 'src/app/store/club-interview/actions';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ClubInterviewMapper } from 'src/app/mappers/club-interview-event.mapper';

@Component({
  selector: 'app-club-interview-club',
  templateUrl: './club-interview-club.component.html',
  styleUrls: ['./club-interview-club.component.scss']
})
export class ClubInterviewClubComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isLog = false;
  matchId;
  clubInterviewEvent: InterviewEventDetailsDto;
  originEvent: InterviewEventDetailsDto;
  teamIds: any[];
  header: IFixtureHeader;
  lastSaved: string;
  isSpinner: boolean;
  isReadOnly = true;
  players: PlayerDto[];
  userRole: Roles;
  clubId: string;
  matchInfo: IMatchInfoDto;
  mapper: ClubInterviewMapper;
  isCreate: boolean;
  isPastInterviewFlag = false;
  user: User;
  timezone:string;


  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private appInsightsService: AppInsightClientService,
    private config: ConfigService,
    private validator: ValidationService) {
    this.isLog = this.config.isDebug();
    this.timezone = config.get('defaultTimeZone');
    this.mapper = new ClubInterviewMapper(config);
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });
    this.clubInterviewEvent = undefined;
    this.isReadOnly = true;
    this.store.dispatch(new GetPlayerListAction());

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            if (user) {
              this.userRole = user.role;
              this.clubId = user.companyId;
              this.matchId = params['id'];
              if (this.matchId) {
                this.store.dispatch(new GetClubInterviewDetailsForClubAction(this.matchId));
              }
              this.isSpinner = false;
            } else { this.isSpinner = true; }
          });
      });

    const event$ = new Subject<IClubMatchInterviewEventDetailsDto>();

    this.store.select(state => state.clubInterviews).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (!result.loading) {
          const matchEvent = result.detailsForClub[this.matchId];
          if (this.isLog) { console.log('result!', matchEvent); }

          if (matchEvent.event) {
            const event = matchEvent;
            event$.next(event);
            this.lastSaved = helper.getLastSavedText(event.event.updatedOnUtc, event.event.updatedBy, StatusHelper.getStatusText(event.event.status));
          } else {
            this.matchInfo = {
              date: matchEvent.matchDate,
              matchId: this.matchId,
              awayTeam: matchEvent.awayTeam,
              homeTeam: matchEvent.homeTeam
            };
            event$.next({ matchDate: matchEvent.matchDate, awayTeam: matchEvent.awayTeam, homeTeam: matchEvent.homeTeam, event: this.initNewModel(this.matchInfo)});
            this.lastSaved = 'To be Saved';
            this.isReadOnly = false;
            this.isCreate = true;
            if (this.isLog) { console.log('no events, create new!'); }
          }
        }
      });

    event$.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(event => {
        if (event) {

          if (this.isPastClubIntreview(event.event)) { this.isReadOnly = true; }

          this.header = {
            homeTeam: event.homeTeam,
            awayTeam: event.awayTeam,
            matchDate: event.matchDate
          };

          this.clubInterviewEvent = event.event;
          this.isSpinner = false;

          if (this.isLog) { console.log('event', this.clubInterviewEvent); }
        } else {
          this.clubInterviewEvent = undefined;
        }
      });

    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

  }

  isPastClubIntreview(interview: InterviewEventDetailsDto) {
    if(!interview) {
      return true;
    }
    this.isPastInterviewFlag = interviewHelper.isPastClubInterview(interview);
    return this.isPastInterviewFlag;
  }

  initNewModel(match: IMatchInfoDto): InterviewEventDetailsDto {
    const model = this.mapper.init(match);
    return model;
  }

  saveOrSubmit(status: EventStatus) {
    if (this.validator.isClubInterviewValid(this.clubInterviewEvent)) {
      this.isSpinner = true;
      if (this.isCreate) {
        this.appInsightsService.logEvent('Create Club Interview (Club)', {
          status: StatusHelper.getStatusText(status)
        });
        const model = this.createNewModel(this.clubInterviewEvent, status);
        if (this.isLog) { console.log('CreateDto:', model); }
        this.store.dispatch(new CreateClubInterviewEventAction(model));

      } else {
        this.appInsightsService.logEvent('Update Fixture (Club)', {
          fixtureId: this.clubInterviewEvent.eventId,
          status: StatusHelper.getStatusText(status)
        });
        const model = this.updateModel(this.clubInterviewEvent, status);
        if (this.isLog) { console.log('UpdateDto:', model); }
        this.store.dispatch(new UpdateClubInterviewEventAction(model));
      }

      setTimeout(() => {
        this.store.select(state => state.fixtures.error).subscribe(e => {
          if (e === false) {
            this.router.navigate([`club-interviews`]);
          }
        }).unsubscribe();
      }, 1000);
    }
  }

  submitBtnClick() {
    this.saveOrSubmit(EventStatus.Submitted);
  }

  saveBtnClick() {
    this.saveOrSubmit(EventStatus.Draft);
  }

  editBtnClick() {
    this.isReadOnly = false;
    this.originEvent = _.cloneDeep(this.clubInterviewEvent);
  }

  cancelBtnClick() {
    this.isReadOnly = true;
    this.clubInterviewEvent = _.cloneDeep(this.originEvent);
  }

  updateModel(interview: InterviewEventDetailsDto, status: EventStatus): InterviewEventDetailsDto {
    interview.locationId = interview.location.locationId;
    interview.duration =interview.duration ? momentTz.duration(interview.duration.asMinutes()+momentTz.tz(this.timezone).utcOffset(),'minutes') :  null
    interview.status = status;
    return interview;
  }

  createNewModel(interview: InterviewEventDetailsDto, status: EventStatus): CreateInterviewEventDetailsDto {

    const model = this.mapper.create(interview);
    model.duration = model.duration ? momentTz.duration(model.duration.asMinutes()+momentTz.tz(this.timezone).utcOffset(),'minutes') :  null
    model.locationId = interview.location.locationId;
    model.status = status;

    return model;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  navigateBack() {
    this.router.navigate([`club-interviews`]);
  }
}
