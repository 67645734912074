import { Action } from '@ngrx/store';
import { IFilmingFixturesResult, IFilmingClubMatchesResult, IFilmingLocation } from './state';
import { FilmingLocationDto } from '../../services/bpp.webapi/client';
import { Moment } from 'moment';

export const GET_LEAGUE_FILMING_FIXTURES = 'get-league-filming-fixtures-action';
export const GET_LEAGUE_FILMING_FIXTURES_SUCCESS = 'get-league-filming-fixtures-action-success';
export const GET_LEAGUE_FILMING_FIXTURES_ERROR = 'get-league-filming-fixtures-actions-error';
export const GET_CLUB_FILMING_MATCHES = 'get-club-filming-mathces-action';
export const GET_CLUB_FILMING_MATCHES_SUCCESS = 'get-club-filming-mathces-action-success';
export const GET_CLUB_FILMING_MATCHES_ERROR = 'get-club-filming-mathces-action-error';
export const GET_FILMING_LOCATIONS = 'get-filming-locations-action';
export const GET_FILMING_LOCATIONS_SUCCESS = 'get-filming-locations-action-success';
export const GET_FILMING_LOCATIONS_ERROR = 'get-filming-locations-action-error';
export const UPDATE_FILMING_LOCATION_LEAGUE = 'update-filming-locations-league-action';
export const UPDATE_FILMING_LOCATION_CLUB = 'update-filming-locations-club-action';
export const UPDATE_FILMING_LOCATION_LEAGUE_SUCCESS = 'update-filming-locations-action-league-success';
export const UPDATE_FILMING_LOCATION_CLUB_SUCCESS = 'update-filming-locations-action-club-success';
export const UPDATE_FILMING_LOCATION_ERROR = 'update-filming-locations-action-error';
export const UPDATE_FOR_ALL_MATCHES = 'update-filming-for-all-mathces';
export const UPDATE_FOR_ALL_MATCHES_SUCCESS = 'update-filming-for-all-mathces-success';
export const UPDATE_FOR_ALL_MATCHES_ERROR = 'update-filming-for-all-mathces-error';

export class GetLeagueFilmingFixturesAction implements Action {
    readonly type = GET_LEAGUE_FILMING_FIXTURES;
    constructor() { }
}

export class GetLeagueFilmingFixturesActionSuccess implements Action {
    readonly type = GET_LEAGUE_FILMING_FIXTURES_SUCCESS;
    constructor(public payload: IFilmingFixturesResult) { }
}

export class GetLeagueFilmingFixturesActionError implements Action {
    readonly type = GET_LEAGUE_FILMING_FIXTURES_ERROR;
    constructor(public payload: any) { }
}

export class GetClubFilmingMatchesAction implements Action {
    readonly type = GET_CLUB_FILMING_MATCHES;
    constructor() { }
}

export class GetClubFilmingMatchesActionSuccess implements Action {
    readonly type = GET_CLUB_FILMING_MATCHES_SUCCESS;
    constructor(public payload: IFilmingClubMatchesResult) { }
}

export class GetClubFilmingMatchesActionError implements Action {
    readonly type = GET_CLUB_FILMING_MATCHES_ERROR;
    constructor(public payload: any) { }
}

export class GetFilmingLocationsAction implements Action {
    readonly type = GET_FILMING_LOCATIONS;
    constructor() { }
}

export class GetFilmingLocationsActionSuccess implements Action {
    readonly type = GET_FILMING_LOCATIONS_SUCCESS;
    constructor(public payload: { [key: string]: FilmingLocationDto[] }) { }
}

export class GetFilmingLocationsActionError implements Action {
    readonly type = GET_FILMING_LOCATIONS_ERROR;
    constructor(public payload: any) { }
}

export class UpdateFilmingLocationLeagueAction implements Action {
    readonly type = UPDATE_FILMING_LOCATION_LEAGUE;
    constructor(public dto: IFilmingLocation, public matchDateUtc: Moment, public matchRound: number) {}
}

export class UpdateFilmingLocationClubAction implements Action {
    readonly type = UPDATE_FILMING_LOCATION_CLUB;
    constructor(public dto: IFilmingLocation, public matchDateUtc: Moment) {}
}

export class UpdateFilmingLocationLeagueActionSuccess implements Action {
    readonly type = UPDATE_FILMING_LOCATION_LEAGUE_SUCCESS;
    constructor(public updatedLocation: IFilmingLocation, public matchRound?: number) {}
}

export class UpdateFilmingLocationClubActionSuccess implements Action {
    readonly type = UPDATE_FILMING_LOCATION_CLUB_SUCCESS;
    constructor(public updatedLocation: IFilmingLocation, public matchRound?: number) {}
}

export class UpdateFilmingLocationActionError implements Action {
    readonly type = UPDATE_FILMING_LOCATION_ERROR;
    constructor(public payload: any) {}
}

export class UpdateForAllMatches implements Action {
    readonly type = UPDATE_FOR_ALL_MATCHES;
    constructor(public payload: IFilmingLocation) {}
}

export class UpdateForAllMatchesSuccess implements Action {
    readonly type = UPDATE_FOR_ALL_MATCHES_SUCCESS;
    constructor(public payload: any) {}
}

export class UpdateForAllMatchesError implements Action {
    readonly type = UPDATE_FOR_ALL_MATCHES_ERROR;
    constructor(public payload: any) {}
}

export type All = GetLeagueFilmingFixturesAction
    | GetLeagueFilmingFixturesActionError
    | GetLeagueFilmingFixturesActionSuccess
    | GetClubFilmingMatchesAction
    | GetClubFilmingMatchesActionError
    | GetClubFilmingMatchesActionSuccess
    | GetFilmingLocationsAction
    | GetFilmingLocationsActionSuccess
    | GetFilmingLocationsActionError
    | UpdateFilmingLocationLeagueAction
    | UpdateFilmingLocationClubAction
    | UpdateFilmingLocationActionError
    | UpdateFilmingLocationLeagueActionSuccess
    | UpdateFilmingLocationClubActionSuccess
    | UpdateForAllMatches
    | UpdateForAllMatchesSuccess
    | UpdateForAllMatchesError;
