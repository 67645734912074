import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AlertService } from '../../services/alert.service';
import * as actions from './actions';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { InterviewTypesService } from 'src/app/services/interview-types.service';

@Injectable()
export class InterviewTypesEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: InterviewTypesService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetInterviewTypes$ = this.action$.pipe(
    ofType<actions.GetInterviewTypesAction>(actions.GET_INTERVIEW_TYPES_ACTION),
    switchMap(action => this.service.getTypes().pipe(
      switchMap(res => of(new actions.GetInterviewTypesActionSuccess(res))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetInterviewTypesActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetInterviewTypesActionError(error));
    }));
}
