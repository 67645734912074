import { Action } from '@ngrx/store';
import { IPagedListOfDashboardItemDto } from '../../services/bpp.webapi/dashboard.client';

export const GET_DASHBOARD_LIST = 'get_dashboard_list';
export const GET_DASHBOARD_LIST_SUCCESS = 'get_dashboard_list_success';
export const GET_DASHBOARD_LIST_ERROR = 'get_dashboard_list_error';

export class GetDashboardListAction implements Action {
    readonly type = GET_DASHBOARD_LIST;
    constructor(public pgNum, public pgSize) { }
}

export class GetDashboardListActionSuccess implements Action {
    readonly type = GET_DASHBOARD_LIST_SUCCESS;
    constructor(public payload: IPagedListOfDashboardItemDto) { }
}

export class GetDashboardListActionError implements Action {
    readonly type = GET_DASHBOARD_LIST_ERROR;
    constructor(public payload: any) { }
}

export type All = GetDashboardListAction | GetDashboardListActionError | GetDashboardListActionSuccess;
