import { IFixtureEventDetailsDto, IMatchFixtureEventDto, FixtureEventType, InterviewEventType, InterviewEventDetailsDto, Players } from '../services/bpp.webapi/client';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

// Past or Upcoming Fixture criteria
export function isPastFixture(fixture: IFixtureEventDetailsDto | IMatchFixtureEventDto) {
    return fixture.matchDate.valueOf() < moment().utc().valueOf();
}

export function isFutureFixture(fixture: IFixtureEventDetailsDto | IMatchFixtureEventDto) {
    return fixture.matchDate.valueOf() >= moment().utc().valueOf();
}

export function isPastMatch(matchDate: moment.Moment, offsetHours: number = 0) {
    return matchDate.valueOf() < moment().utc().add(offsetHours, 'hours').valueOf();
}

export function isFutureMatch(matchDate: moment.Moment) {
    return matchDate.valueOf() >= moment().utc().valueOf();
}

export function getBroadcasterDeadline(eventDate: moment.Moment, offsetHours: number, configTimezone: string) {
    // const utcOffset = momentTz().tz(configTimezone).utcOffset();
    const deadline = moment.utc(eventDate).add(-offsetHours, 'hours').hours(12).minutes(0);
    const str = deadline.format('DD/mm/YYYY | HH:mm');
    return deadline;
}

export function getBroadcasterDeadlineStr(eventDate: moment.Moment, offsetHours: number) {
    // const utcOffset = momentTz().tz(configTimezone).utcOffset();
    const deadline = moment.utc(eventDate).add(-offsetHours, 'hours').hours(12).minutes(0);
    const str = deadline.format('DD/MM/YYYY | HH:mm');
    return str;
}

export function isBroadcasterDeadlinePassed(eventDate: moment.Moment, offsetHours: number, configTimezone: string) {
    const deadline = getBroadcasterDeadline(eventDate, offsetHours, configTimezone);
    const result = deadline.valueOf() < moment.utc().valueOf();
    return result;
}

export const fixtureEventTypes = [
  {
    key: FixtureEventType.Standard,
    name: 'Standard'
  },
  {
    key: FixtureEventType.Double,
    name: 'Double'
  },
  {
    key: FixtureEventType.Extended,
    name: 'Extended'
  },
  {
    key: FixtureEventType.DoubleExtended,
    name: 'Combined Double/Extended'
  },
  {
    key: FixtureEventType.ExtendedBroadcasterExclusive,
    name: 'Extended Broadcaster Exclusive'
  },
  {
    key: FixtureEventType.ExtendedMasterclass,
    name: 'Extended Masterclass'
  }
];

export function getFixtureType(key: FixtureEventType) {
  const found = fixtureEventTypes.find(t => t.key === key);
  return found && found.name || ' - ';
}


export const clubInterviewEventTypes = [
  {
    key: InterviewEventType.SingleInterview,
    name: 'Single session'
  },
  {
    key: InterviewEventType.MultiSessions,
    name: 'Multi sessions'
  }
];

export const playersTypes = [
  {
    key: Players.Single,
    name: 'Single'
  },
  {
    key: Players.Multiple,
    name: 'Multiple'
  }
];

export function getClubInterviewEventType(key: InterviewEventType) {
  const found = clubInterviewEventTypes.find(t => t.key === key);
  return found && found.name || ' - ';
}

export function isPastClubInterview(interview: InterviewEventDetailsDto) {
  return interview.startUtc.valueOf() < moment().utc().valueOf();
}

export function isFutureClubInterview(interview: InterviewEventDetailsDto) {
  return interview.startUtc.valueOf() >= moment().utc().valueOf();
}