import { PlayerDto } from '../services/bpp.webapi/client';
import { AttendeeDto } from '../services/bpp.webapi/dashboard.client';

export function isManager(player: PlayerDto) {
    if (player.role) {
        return player.role.toLowerCase() === 'staff'
            || player.role.toLowerCase() === 'manager'
            || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
}

export function isManagerForDashboard(player: AttendeeDto) {
    if (player.role) {
        return player.role.toLowerCase() === 'staff'
            || player.role.toLowerCase() === 'manager'
            || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
}

export function isAssistantManager(player: PlayerDto) {
    if (player.role) {
        return player.role.toLowerCase() === 'assistant manager';
    }
    return false;
}
