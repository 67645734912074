import { CameraOperator } from '../services/bpp.webapi/client';

export function getCameraOptionTextId(option: CameraOperator) {
  if (option !== undefined && option !== null) {
    switch (option) {
      case CameraOperator.PLPCamera: return 'PlpCamera';
      case CameraOperator.BroadcasterCamera: return 'broadcasterCamera';
      case CameraOperator.AudioOnly: return 'audioOnly';
      default: return '';
    }
  }
  return '';
}
