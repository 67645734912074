
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { User } from '../models/user.model';
import { UserClient } from '../services/bpp.webapi/client';
import { Roles } from '../models/enums';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class AuthService {

  private userRole: Roles | undefined;
  private isAuth = false;
  private redirectUrl = '/';
  private isLog = false;
  private previousUrl: string;
  private currentUrl: string;


  constructor(private userClient: UserClient, private configService: ConfigService,private router: Router) {
    this.isLog = this.configService.isDebug();
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  login() {
    if (this.isLog) { console.log('AuthService => Login...', this.redirectUrl); }
    window.location.href = this.redirectUrl;
  }

  logout() {
    if (this.isLog) { console.log('AuthService => Logout...'); }
    this.isAuth = false;
    window.location.href = this.redirectUrl;
  }

  getUser(): Observable<User> {

    return this.userClient.getUser().pipe(
      map(result => {
        if (result) {
          console.log('Auth result:', result.authenticated);

          this.isAuth = result.authenticated;
          this.redirectUrl = result.redirectUrl;
          this.userRole = Roles[result.roles[0]];

          const user = new User();
          user.email = result.account;
          user.givenName = result.name;
          user.role = this.userRole;

          return user;
        }
      }));
  }

  getUserRole(): Roles | undefined {
    return this.userRole;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  isAuthenticated(): boolean {
    return this.isAuth && (this.userRole === Roles.Broadcaster ||
      this.userRole === Roles.PLPBroadcaster ||
      this.userRole === Roles.Club ||
      this.userRole === Roles.League ||
      this.userRole === Roles.Admin);
  }
}
