import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';
import { FxRequest, IDentity, IRequestSection } from '../../../store/fx-request/state';
import { IParticipantDto, NoteDto, NoteStatus, RequestStatus, CreateParticipantDto, ParticipantDto, ParticipantType, CameraOperator } from '../../../services/bpp.webapi/client';
import { Roles } from '../../../models/enums';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../config/config.service';
import { ValidationService } from '../../../services/validation.service';
import { selectCurrentUserRole } from '../../../store/user/state';
import { FxRequestGetParticipantsAction, FxRequestGetParticipantsByCompanyAction } from '../../../store/fx-request/actions';
import { AddContactComponent } from '../../common/add-contact/add-contact.component';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


enum SectionType {
  YesNo=0,
  Camera=1,
  ExtendedCamera=2
}
@Component({
  selector: 'app-interview-request',
  templateUrl: './interview-request.component.html',
  styleUrls: ['./interview-request.component.scss']
})

export class InterviewRequestComponent extends BaseComponent implements OnInit {
  @Input() request: FxRequest;
  @Input() isPastEvent = false;
  @Input() identity: IDentity;
  @Output() submit: EventEmitter<{ identity: IDentity, request: FxRequest }> = new EventEmitter();
  @Output() save: EventEmitter<{ identity: IDentity, request: FxRequest }> = new EventEmitter();
  @Output() withdraw: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<{ identity: IDentity, request: FxRequest }> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  participants: IParticipantDto[];
  participantsByType: { [type: number]: IParticipantDto[] } = {};
  isLeagueMode: boolean;
  isBroadcasterMode: boolean;
  containerMode: Roles;
  isLfp:boolean;
  SectionType=SectionType;
  CameraOperator =CameraOperator;
  hasCameraOptionPlp = true;
  hasCameraOptionBroadcaster = true;
  hasCameraOptionAudio = true;


  constructor(private store: Store<AppState>,
    private modalService: NgbModal, config: ConfigService,
    private validator: ValidationService,
    private translate: TranslateService) {
    super();
    this.isLog = config.isDebug();
    if (config.hasFeature("environment.lfp")){
      this.isLfp = true;
    }
    this.hasCameraOptionPlp = config.hasFeature('requestCameraOptions.plp');
    this.hasCameraOptionBroadcaster = config.hasFeature('requestCameraOptions.broadcaster');
    this.hasCameraOptionAudio = config.hasFeature('requestCameraOptions.audioOnly');
  }

  ngOnInit() {
    const role$ = this.store
      .select(selectCurrentUserRole()).pipe(
        takeUntil(this.componentDestroyed));

    role$.subscribe(role => {
      this.containerMode = role;
      if (role === Roles.League || role === Roles.Admin) {
        this.isLeagueMode = true;
        this.request.isReadOnly = true;
        this.request.note = this.request.leagueNote ? this.request.leagueNote.message : '';
        this.store.dispatch(new FxRequestGetParticipantsByCompanyAction(this.request.companyId));
        this.store.select(state => state.fxRequests.participantsByCompany).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(p => {
            if (p && p[this.request.companyId] && p[this.request.companyId].length > 0) {
              this.setParticipantsByType(p[this.request.companyId]);
            }
          });
      } else if (role === Roles.Broadcaster || role === Roles.PLPBroadcaster) {
        this.request.note = this.request.broadcasterNote ? this.request.broadcasterNote.message : '';
        this.isBroadcasterMode = true;

        this.store.dispatch(new FxRequestGetParticipantsAction());
        this.store.select(state => state.fxRequests.participants).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(p => {
            this.setParticipantsByType(p);
          });
      }
    });

    // this.store.dispatch(new FxRequestGetParticipantsAction());
    const participants$ = this.store.select(state => state.fxRequests.participants).pipe(takeUntil(this.componentDestroyed));

    participants$.subscribe(p => {
      this.setParticipantsByType(p);
    });

  }

  getSectionType(section: IRequestSection){
    if (!section.isCameraSection) return SectionType.YesNo;
    if (this.isLfp){
      return SectionType.ExtendedCamera;
    }
    else {
      return SectionType.Camera;
    }
  }

  onCameraSection(option: CameraOperator, section: IRequestSection) {
    const require = option === CameraOperator.BroadcasterCamera;
    section.cameraOperator = option;
    section.require = require;
  }

  setParticipantsByType(p: IParticipantDto[]) {

    if (p && p.length > 0) {
      this.participants = p;
      this.participantsByType = p.reduce((rv, x) => {
        (rv[x['type']] = rv[x['type']] || []).push(x);
        return rv;
      }, {});

      if (this.isLog) { console.log('participants', this.participants); }
    }

  }

  getYesOption(section: IRequestSection) {
    return section.isCameraSection ? this.translate.instant('PlpCamera') : 'Yes';
  }

  getNoOption(section: IRequestSection) {
    return section.isCameraSection ? this.translate.instant('BroadcasterCamera') : 'No';
  }

  isNotEmptyNotes(notes: NoteDto) {
    return notes && notes.message && notes.message !== '';
  }

  isSubmittedNotes(notes: NoteDto) {
    return notes && notes.status === NoteStatus.Submitted;
  }

  getIdentity() {
    return { ...this.identity, companyId: this.request.companyId };
  }

  isCanChange() {
    return this.request.status !== RequestStatus.Withdrawn && this.request.status !== RequestStatus.Reject && this.isPastEvent !== true;
  }

  onSave() {
    this.save.emit({ identity: this.getIdentity(), request: this.request });
  }

  onWithdraw() {
    this.withdraw.emit({ identity: this.getIdentity(), request: this.request });
  }

  onSubmit() {
    this.submit.emit({ identity: this.getIdentity(), request: this.request });
  }

  onEdit() {
    
  }

  onCancel() {
    this.cancel.emit(true);
  }

  onReject() {
    this.request.status = RequestStatus.Reject;
    this.update.emit({ identity: this.getIdentity(), request: this.request });
  }

  onApprove() {
    this.request.status = RequestStatus.Approved;
    this.update.emit({ identity: this.getIdentity(), request: this.request });
  }


  onSectionEnable(value: string, section: IRequestSection) {
    if (this.isLog) { console.log('onSectionEnable'); }
    const require = value === 'Yes';
    section.value = require;

    if (!section.isCameraSection)
      section.require = section.value;
  }

  newParticipant(model: CreateParticipantDto, identity: IDentity) {
    if (this.isLog) { console.log('New Participant', model); }
    const participant = ParticipantDto.fromJS({
      id: '00000000-0000-0000-0000-000000000000',
      name: model.name,
      phone: model.phone,
      email: model.email,
      type: model.type
    });
    const section = this.request.sections.find(s => s.sectionType === participant.type);
    section.items.push(participant);
  }

  popupNewParticipant(section: IRequestSection) {
    const identity = { ...this.getIdentity(), sectionKey: section.key };

    const modalRef = this.modalService.open(AddContactComponent, { centered: true });
    const instance = modalRef.componentInstance as AddContactComponent;
    instance.type = section.sectionType;
    instance.save.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(contact => this.newParticipant(contact, identity));
  }

  removeParticipant(model: IParticipantDto, section: IRequestSection,) {
    const index = section.items.findIndex(item => item.id === model.id);
    if (index > -1) {
      this.participantsByType[section.sectionType].push(section.items.splice(index, 1)[0]);
    }
  }

  selectParticipant(id, section: IRequestSection) {
    const index = this.participantsByType[section.sectionType].findIndex(x => x.id === id);
    const exists = section.items.find(s => s.id === id);
    if (index > -1 && !exists) {
      section.items.push(this.participantsByType[section.sectionType].splice(index, 1)[0]);
    }
  }

  getSelectText(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'SelectCameraOperator';
      case (ParticipantType.Reporter): return 'SelectReporter';
      case (ParticipantType.Producer): return 'SelectProducer';
      case (ParticipantType.Other): return 'SelectOther';
      case (ParticipantType.Unknow): return 'Unknown';
    }
  }

}
