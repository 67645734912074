import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-d3-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent implements OnInit {

  @Input() templateRef: TemplateRef<any>;
  protected route: ActivatedRoute;
  protected data: any;
  @Input() context: any;

  ngOnInit(): void {
  }


  constructor(route: ActivatedRoute) {
    this.route = route;
  }
}
