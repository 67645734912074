import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { LocationDto } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetLocationsAction } from 'src/app/store/location/actions';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-club-locations',
  templateUrl: './club-locations.component.html'
})
export class ClubLocationsComponent extends BaseComponent implements OnInit {
  data: Array<LocationDto>;
  isSpinner = true;

  @Output() addLocationClicked = new EventEmitter();
  @Output() editLocationClicked = new EventEmitter();

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(e => e.location)
      .pipe(takeUntil(this.componentDestroyed),
        filter(e => e !== undefined && e.items !== undefined))
      .subscribe(e => {
        this.data = e.items;
        this.isSpinner = false;
      });

    this.store.dispatch(new GetLocationsAction(0));
  }

  onAddClick() {
    this.addLocationClicked.emit({ leagueType: false, clubId: 0 });
  }

  onEditClick(location: LocationDto) {
    this.editLocationClicked.emit({ location, leagueType: false, clubId: null });
  }
}
