import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'commonFilter',
    pure: false
})
export class CommonFilterPipe implements PipeTransform {

    constructor() { }
    transform(items: any[], filter: {property: string, value: any}): any {
        if (!items || !filter.property || filter.value === undefined) {
            return items;
        }
        return items.filter(item => item[filter.property] === filter.value);
    }
}
