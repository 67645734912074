import {MatchExtInfoDto} from '../../services/bpp.webapi/client';

export class MatchesState {
    loading: boolean;
    error: boolean;
    items: MatchExtInfoDto[];

    constructor() {
        this.items = new Array<MatchExtInfoDto>();
    }
}
