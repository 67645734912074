import { User } from '../../../models/user.model';
import { IDashboardItemDto, DashboardItemStatus, DashboardItemType } from '../../../services/bpp.webapi/dashboard.client';
import * as helper from '../../dashboard/constructor/constructor.helper';
import { EventCase } from '../models/event-case.enum';
import { getEventCase } from '../../dashboard/constructor/event-case.logic';
import { IDashboardMessage } from '../models/dashboard-message.interface';
import { ISessionData } from '../models/session-data.interface';
import { Roles } from '../../../models/enums';
import { isNullOrUndefined } from 'util';
import { DashboardColorSelector } from '../../dashboard/constructor/dashboard-color.selector';
import { TranslateService } from '@ngx-translate/core';
import { MomentDatePipe } from '../../../pipes/moment.date.pipe';
import { EventStatus } from 'src/app/services/bpp.webapi/client';

export class SimplifiedMessageConstructor {
  user: User;
  dashboardColorSelector: DashboardColorSelector;
  constructor(user: User, private translateService:TranslateService,private momentPipe:MomentDatePipe ) {
    this.dashboardColorSelector = new DashboardColorSelector();
    this.user = user;
  }

  createMessage(s: IDashboardItemDto): IDashboardMessage {
    const eventCase: EventCase = getEventCase(s);
    let subject,  confirmed;
    let mr:string;
    this.translateService.get('MR').subscribe(value=>mr = value)

    switch (s.dashboardItemType) {
      case DashboardItemType.FixtureEvent:
      case DashboardItemType.InterviewEvent:
      case DashboardItemType.FixtureRequest:
      case DashboardItemType.InterviewEventRequest:
        subject = `${helper.getEventCompany(s)} - ${helper.getLfpIcsTypeText(s)} - ${mr} ${helper.getMrText(s)}`;
        confirmed = this.getConfirmedText(s); 
        break;
      case DashboardItemType.AdditionalContent:
      case DashboardItemType.AdditionalContentRequest:  
      case DashboardItemType.Interview:
      case DashboardItemType.InterviewRequest:
        subject=`${helper.getInterviewDate(s)} ${s.interviewTypeName}`
        confirmed = this.getConfirmedText(s); 
        break;
      default:
        break;
    }

    const clubId = (isNullOrUndefined(s.userCompanyId) || isNaN(Number(s.userCompanyId))) ? undefined : parseInt(s.userCompanyId, 10);
    const result: IDashboardMessage = {
      subject,
      body:'',
      confirmed,
      data: this.getSessionData(s),
      date: s.updatedOn,
      account: helper.getUserAccount(s),
      avatar: helper.getAvatar(clubId),
      clubId,
      color:this.dashboardColorSelector.getDashboardColor (s)
    };

    return result;
  }

  getSessionData(s: IDashboardItemDto): ISessionData {

    const data: ISessionData = {
      title: helper.getMatchInfo(s),
      updatedOn: s.updatedOn,
      arrival: s.arrival,
      start: s.start,
      location: s.location,
      languages: s.languages,
      attendees: s.attendees,
      match: s.matchData,
      eventId: s.eventId,
      isRejected: s.dashboardItemStatus === DashboardItemStatus.Rejected,
      showLeagueNote: s.dashboardItemStatus === DashboardItemStatus.Rejected &&
        (s.dashboardItemType === DashboardItemType.FixtureEvent || s.dashboardItemType === DashboardItemType.TrainingEvent) &&
        s.leagueNote !== undefined && s.leagueNote !== null && s.leagueNote !== '',
      leagueNote: s.leagueNote,
      showBpp: this.user.role === Roles.Admin || this.user.role === Roles.League || this.user.role === Roles.Club,
      userRole: this.user.role,
      interviewee: s.interviewee,
      monthId: s.elementId,
      broadcasterDeadline: s.broadcasterDeadline,
      isNonLiveFixtureEvent: s.isNonLiveFixtureEvent,
      totalRequests:s.totalRequests,
      requests: s.requests,
      isOverDeadline:s.isOverDeadline,
      eventStatus:s.eventStatus,
      dashboardItemStatus: s.dashboardItemStatus,
      dashboardItemType:s.dashboardItemType,
      additionalContentType:s.additionalContentType
    };

    return data;
  }

  getConfirmedText(s: IDashboardItemDto): string {
    if (this.user.role==Roles.Broadcaster || this.user.role==Roles.PLPBroadcaster){
      if (s.eventStatus==EventStatus.Submitted){
        let validationMessage ='';
          this.translateService.get('IsInterviewInValidation').subscribe(value=>validationMessage=value);
        return validationMessage;
      }
      if (s.eventStatus==EventStatus.Reject){
        let rejectionMessage ='';
          this.translateService.get('IsInterviewRejectedByLeague').subscribe(value=>rejectionMessage=value);
        return rejectionMessage;
      }
    }
    if (s.updatedAfterApprovalOnUtc){
      return `Updated by ${helper.getUserAccountAfterApproval(s)} on ${s.updatedAfterApprovalOnUtc.format('DD/MM/YYYY')} 
        at ${this.momentPipe.transform(s.updatedAfterApprovalOnUtc.toDate(),'HH:mm')}`;
    }
    return '';  
    }
} 
