import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-column-page',
  templateUrl: './two-column-page.component.html',
  styleUrls: ['./two-column-page.component.scss']
})
export class TwoColumnPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
