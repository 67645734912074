import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { BaseComponent } from '../base-component/base.component';
import { takeUntil, map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GetInterviewsAction } from '../../store/interviews/actions';
import {EventStatus, IInterviewDto, RequestStatus} from '../../services/bpp.webapi/client';
import { Roles } from 'src/app/models/enums';
import {StatusHelper} from '../../helpers/statuses-helper';
import { ConfigService } from 'src/app/config/config.service';

enum DateRange {
  Upcoming = 0,
  Past = 1
}

@Component({
  selector: 'app-interview',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.scss']
})
export class InterviewsComponent extends BaseComponent implements OnInit {
  items: IInterviewDto[];
  isLoading: boolean;
  Roles = Roles;
  role: Roles;

  upcoming: any = {
    id: DateRange.Upcoming,
    name: 'Upcoming'
  };
  past: any = {
    id: DateRange.Past,
    name: 'Past'
  };
  dateRanges: any = [this.upcoming, this.past];
  selectedDateRange: any = this.upcoming;
  isLfp:boolean;

  constructor(private store: Store<AppState>,private config:ConfigService) {
    super();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
          console.log('role',this.role)
        }
      });

  this.store.select(e => e.interviews)
    .pipe(
      takeUntil(this.componentDestroyed), filter(e => e !== undefined && e.collection !== undefined))
    .subscribe(data => {
      this.isLoading = data.collection.loading;
      this.items = data.collection.items || [];
    });

    this.refreshData();
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }

  getLfpLegendaColor(status:RequestStatus){
    return StatusHelper.getLfpRequestStatusColor(status)
  }

  selectDateRange(dateRange: any) {
    this.selectedDateRange = dateRange;
    this.refreshData();
  }

  refreshData() {
    const isPast = this.selectedDateRange.id === DateRange.Past;
    this.store.dispatch(new GetInterviewsAction(isPast));
    this.store.dispatch(new GetInterviewsAction(isPast));
  }
}
