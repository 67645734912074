import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {IInterviewDetails as IInterviewDetails, IInterviewsResult} from '../store/interviews/state';
import {Observable} from 'rxjs';
import {ConfigService} from '../config/config.service';
import {CreateInterviewsRequestDto, IInterviewsRequestDto, InterviewsClient, InterviewTypeDto, InterviewsRequestClient,} from './bpp.webapi/client';
import {FxRequest} from '../store/fx-request/state';
import {isGuidNotEmpty} from '../helpers/common';
import {AlertService} from './alert.service';
import {InterviewDetailsMapper} from '../mappers/interview-details.mapper';
import {InterviewRequestMapper} from '../mappers/interview-request.mapper';
import {AppInsightClientService} from './app-insight.service';
import {StatusHelper} from '../helpers/statuses-helper';

@Injectable()
export class InterviewService {
    private timeZone: any;
    private detailsMapper: InterviewDetailsMapper;
    private requestMapper: InterviewRequestMapper;

    constructor(config: ConfigService,
        private client: InterviewsClient,
        private requestClient: InterviewsRequestClient,
        private alertSErvice: AlertService,
        private appInsightService: AppInsightClientService) {
        this.timeZone = config.get('defaultTimeZone');
        this.detailsMapper = new InterviewDetailsMapper(this.timeZone, config.getSeasonYear());
        this.requestMapper = new InterviewRequestMapper();
    }

    public getInterviews(includePast: boolean): Observable<IInterviewsResult> {
        return this.client.getAll(includePast).pipe(map(res => {
            return {
              items : res
            };
        }));
    }

    public getInterviewDetails(id: string): Observable<IInterviewDetails> {
        return this.client.getDetails(id).pipe(map(res => {
            if (res && res.isSuccess) {
                const model = this.detailsMapper.mapModel(res.value);
                model.requests = res.value.requests && res.value.requests.length > 0
                    ? res.value.requests.map(r => this.requestMapper.mapModel(r))
                    : [];
                return model;
            } else {
              return this.detailsMapper.getEmptyModel();
            }
        }));
    }

    public createInterview(model: IInterviewDetails) {
        const dto = this.detailsMapper.mapDto(model);
        return this.client.create(dto).pipe(map(res => {
            if (res && res.isSuccess) {
                this.logInterview(model);
                const response = this.detailsMapper.mapModel(res.value);
                response.requests = res.value.requests && res.value.requests.length > 0
                ? res.value.requests.map(r => this.requestMapper.mapModel(r))
                : [];
                return response;
            } else {
                this.logInterview(model, true);
                this.throwError();
            }
        }));
    }

  public updateInterview(model: IInterviewDetails) {
    const dto = this.detailsMapper.mapDto(model);
    return this.client.update(dto).pipe(map(res => {
      if (res && res.isSuccess) {
        this.logInterview(model);
        const response = this.detailsMapper.mapModel(res.value);
        response.requests = res.value.requests && res.value.requests.length > 0
          ? res.value.requests.map(r => this.requestMapper.mapModel(r))
          : [];
        return response;
      } else {
        this.logInterview(model, true);
        this.throwError();
      }
    }));
  }

    public createUpdateInterviewRequest(model: FxRequest, interviewId: string): Observable<FxRequest> {
        if (isGuidNotEmpty(model.id)) {
            const dto = this.requestMapper.mapDto(model);
            return this.requestClient.update(dto).pipe(
                map(res => {
                    if (res.success) {
                        this.logRequest(dto);
                        return this.requestMapper.mapModel(res.value);
                    } else {
                        this.logRequest(dto, true);
                        this.throwError(res.messages);
                    }
                }));
        } else {
            const dto = this.requestMapper.mapDto(model);
            const createDto: CreateInterviewsRequestDto = new CreateInterviewsRequestDto({ ...dto, interviewId });
            return this.requestClient.create(createDto).pipe(
                map(res => {
                    if (res.success) {
                        this.logRequest(dto);
                        return this.requestMapper.mapModel(res.value);
                    } else {
                        this.logRequest(dto, true);
                        this.throwError(res.messages);
                    }
                }));
        }

    }

    private throwError(messages?: any[]) {
        if (messages && messages instanceof Array && messages.length > 0 && messages[0].message) {
            throw new Error(messages[0].message);
        } else {
            throw new Error('Something went wrong');
        }
    }

    private logInterview(model: IInterviewDetails, isError = false) {
        const props = {
            id: model.interviewId,
        };

        if (isError) {
            const message = `Fail to ${StatusHelper.getEventStatusAction(model.status).toLowerCase()} Interview`;
            this.alertSErvice.warning(message);
            this.appInsightService.logEvent(message, props);
        } else {
            const message = `Interview successfully ${StatusHelper.getStatusText(model.status).toLowerCase()}`;
            this.alertSErvice.success(message);
            this.appInsightService.logEvent(message, props);
        }
    }

    private logRequest(model: IInterviewsRequestDto, isError = false) {
        const props = {
            id: model.interviewRequestId
        };

        if (isError) {
            const message = `Fail to ${StatusHelper.getRequestStatusAction(model.status).toLowerCase()} Interview Request`;
            this.alertSErvice.warning(message);
            this.appInsightService.logEvent(message, props);
        } else {
            const message = `Interview Request successfully ${StatusHelper.getRequestStatusTextExt(model.status).toLowerCase()}`;
            this.alertSErvice.success(message);
            this.appInsightService.logEvent(message, props);
        }
    }
}
