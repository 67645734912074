import { PagedListOfTeamMemberDto, TeamMemberDto } from "src/app/services/bpp.webapi/client";

export class TeamMembersState {
	loading: boolean;
	error: boolean;
	data: PagedListOfTeamMemberDto;
	item: TeamMemberDto;

	constructor() {
		this.loading = false;
		this.error = false;
		this.data = new PagedListOfTeamMemberDto();
	}
}