
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'menuFilter'})
export class MenuFilterPipe implements PipeTransform {
  transform(value: any[], filter: Object) {
    if (!value || !filter) {
      return value;
    }

    let result = value;
    // tslint:disable-next-line:forin
    for (const property in filter) {
      result = result.filter((item) => {
        // tslint:disable-next-line:triple-equals
        return item[property] == filter[property];
      });
    }
    return result;
  }
}
