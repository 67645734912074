import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ConfigService } from '../../../../config/config.service';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import {
  UpdateTrainingRequestDto,
  UpdateTrainingDto, ITrainingDetailsDto,
  RequestDetailsDto, ParticipantType,
  NoteStatus, SaveNoteDto, IRequestDetailsDto
} from '../../../../services/bpp.webapi/client';
import { NotesService } from '../../../../services/notes.service';
import { AlertService } from '../../../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { getCameraOptionTextId } from '../../../../helpers/request.helper';
import { BaseComponent } from '../../../base-component/base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-club-training-details-ended',
  templateUrl: './club-training-details-ended.component.html'
})
export class ClubTrainingDetailsEndedComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() training: ITrainingDetailsDto;
  @Output() toggleSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
  userSubscription: Subscription;
  user;
  dataLoaded: boolean;
  newNoteText = '';
  editNote = false;
  isLog = false;
  isLfp: boolean;

  constructor(private config: ConfigService,
    private store: Store<AppState>,
    private alertService: AlertService,
    private router: Router,
    private translationService: TranslateService,
    private notesService: NotesService) {
    super();
    this.isLog = config.isDebug();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.userSubscription = this.store
      .select(e => e.user.currentUser)
      .subscribe(res => {
        this.user = res;
      });
  }

  transformToUpdateModel() {
    const updateModel = new UpdateTrainingDto();
    updateModel.init({
      id: this.training.id,
      date: this.training.arrival,
      arrival: this.training.arrival,
      start: this.training.start,
      locationId: this.training.location.locationId,
      status: this.training.status,
      clubNote: this.training.clubNote,
      leagueNote: this.training.leagueNote,
      requests: this.transformRequests(),
    });
    return updateModel;
  }

  transformRequests() {
    const requests = [];
    if (this.training.requests === undefined || this.training.requests.length === 0) {
      return requests;
    }
    this.training.requests.forEach(x => requests.push(this.transformToUpdateRequestModel(x)));
    return requests;
  }

  transformToUpdateRequestModel(request) {
    const updateModel = new UpdateTrainingRequestDto();
    updateModel.init({
      id: request.id,
      trainingId: request.trainingId,
      status: request.status,
      clubNote: request.clubNote
    });
    return updateModel;
  }

  generateTrainingName(date: moment.Moment) {
    return moment(date).format('MMMM') + ' Training Session';
  }

  updateNote() {
    this.training.clubNote.message = this.newNoteText;
    this.newNoteText = '';
    this.editNote = false;
  }

  openEditNote() {
    this.newNoteText = this.training.clubNote.message;
    this.editNote = true;
  }

  cancelEditNote() {
    this.editNote = false;
  }

  getParticipants(request: RequestDetailsDto, type: ParticipantType) {
    if (request === undefined || request === null || request.participants === undefined) {
      return '';
    } else {
      const participants = request.participants.filter(x => x.type === type);
      if (participants.length > 0) {
        return participants;
      } else {
        return '';
      }
    }
  }

  saveRequestNote(request, status) {
    const note = this.generateNoteData(request, status);
    this.toggleSpinner.emit(true);
    this.notesService.saveClubRequestNote(note)
      .subscribe((resp) => {
        this.toggleSpinner.emit(false);
        if (resp) {
          this.translationService.get('NoteUpdated').subscribe((value) => {
            this.alertService.success(value);
          });
          request.clubNote = resp;
        }
      }, (err) => {
        this.toggleSpinner.emit(false);
        if (this.config.isDebug()) {
          console.log(err);
        }
        this.alertService.success(err);
      });
  }

  generateNoteData(request: RequestDetailsDto, status: NoteStatus): any {
    return SaveNoteDto.fromJS({
      status: status,
      message: request.clubNote.message,
      objectId: request.id
    });
  }

  getCameraOption(request: IRequestDetailsDto) {
    return this.translationService.instant(getCameraOptionTextId(request.cameraOperator));
  }

  goBack() {
    this.router.navigate([`trainings`]);
  }
}
