import { ClubDto } from '../../services/bpp.webapi/client';

export class ClubsState {
    loading: boolean;
    error: boolean;
    items: ClubDto[];

    constructor() {
        this.items = new Array<ClubDto>();
    }
}
