import { Injectable } from '@angular/core';

export class ConfigService {
  config: any;
  private loaded = false;

  constructor(config: any) {
    this.config = config;
    if (config) {
      this.loaded = true;
    }
  }

  isLfp():boolean{
    return this.hasFeature("environment.lfp");
  }

  hasFeature(property: string): boolean {
    const prop = 'features.' + property.trim() + '.enable';
    return <boolean>this.get(prop);
  }

  get(property: string): any {
    const properties = property.split('.');
    if (this.loaded && this.config) {
      let tmp = this.config[properties[0]];
      let index = 1;
      while (tmp != undefined && index < properties.length) {
        if (tmp[properties[index]] !== undefined) {
          tmp = tmp[properties[index]];
          index++;
        } else {
          tmp = null;
        }
      }
      return tmp;
    }
    return null;
  }

  getApiConnString(): string {
    return this.get('bppApiUrl');
  }

  getMulti(properties: string[]): any {
    const result = {};
    if (this.loaded && this.config) {
      for (const property of properties) {
        if (this.config.hasOwnProperty(property)) {
          result[property] = this.config[property];
        }
      }
    }
    return result;
  }

  getCurrentSeason() {
    const currentDate = new Date();
    const competition = new Date(currentDate.getFullYear(), 6, 1, 0, 0, 0, 0);
    const fromDate =
      competition > currentDate
        ? new Date(currentDate.getFullYear() - 1, 6, 1, 0, 0, 0, 0)
        : competition;

    return {
      from: fromDate,
      to: currentDate
    };
  }

  getSeasonYear(): number {
    const season = this.getCurrentSeason();
    return season.from.getFullYear();
  }

  isDebug(): boolean {
    return this.get('enableDebug');
  }

  getBrDeadlineHours() {
    return this.get('broadcasterDeadlineHours');
  }

  getTimezone() {
    return this.get('defaultTimeZone');
  }
}
