import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-team-member-bios-dialog-component',
	templateUrl: './team-member-bios-dialog.component.html'
})
export class TeamMemberBiosDialogComponent {
	@Input()
	bios: string;

	constructor(public activeModal: NgbActiveModal) {
		var i = activeModal;
	}
}