
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { AlertService } from '../../services/alert.service';
import { FilmingService } from '../../services/filming-service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class FilmingEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: FilmingService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  private updateErrorMessage = 'Failed to update Filming Location Details';

  @Effect()
  $GetFilmingFixtures: Observable<Action> = this.action$.pipe(
    ofType<actions.GetLeagueFilmingFixturesAction>(actions.GET_LEAGUE_FILMING_FIXTURES),
    switchMap(action => this.service.getLeagueFilmingFixtures().pipe(
      switchMap(result => of(new actions.GetLeagueFilmingFixturesActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetLeagueFilmingFixturesActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetLeagueFilmingFixturesActionError(error));
    }));

  @Effect()
  $GetFilmingMatches: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubFilmingMatchesAction>(actions.GET_CLUB_FILMING_MATCHES),
    switchMap(action => this.service.getClubFilmingFixtures().pipe(
      switchMap(result => of(new actions.GetClubFilmingMatchesActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubFilmingMatchesActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetClubFilmingMatchesActionError(error));
    }));

  @Effect()
  $GetFilmingLocations: Observable<Action> = this.action$.pipe(
    ofType<actions.GetFilmingLocationsAction>(actions.GET_FILMING_LOCATIONS),
    switchMap(action => this.service.getFilmingLocations().pipe(
      switchMap(result => of(new actions.GetFilmingLocationsActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetFilmingLocationsActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetFilmingLocationsActionError(error));
    }));

  @Effect()
  $UpdateLocationLeague: Observable<Action> = this.action$.pipe(
    ofType<actions.UpdateFilmingLocationLeagueAction>(actions.UPDATE_FILMING_LOCATION_LEAGUE),
    switchMap(action => this.service.updateLocation(action.dto, action.matchDateUtc).pipe(
      switchMap(result => of(new actions.UpdateFilmingLocationLeagueActionSuccess(result, action.matchRound))),
      catchError(error => this.updateLocationError(error)))),
    catchError(error => this.updateLocationError(error)));

  @Effect()
  $UpdateLocationClub: Observable<Action> = this.action$.pipe(
    ofType<actions.UpdateFilmingLocationClubAction>(actions.UPDATE_FILMING_LOCATION_CLUB),
    switchMap(action => this.service.updateLocation(action.dto, action.matchDateUtc).pipe(
      switchMap(result => of(new actions.UpdateFilmingLocationClubActionSuccess(result))),
      catchError(error => this.updateLocationError(error))
    )),
    catchError(error => this.updateLocationError(error)));

  @Effect()
  $UpdateForAllMatches: Observable<Action> = this.action$.pipe(
    ofType<actions.UpdateForAllMatches>(actions.UPDATE_FOR_ALL_MATCHES),
    switchMap(action => this.service.updateForAllmatches(action.payload).pipe(
      switchMap(result => of(new actions.GetClubFilmingMatchesAction())),
      catchError(error => this.updateLocationError(error)))),
    catchError(error => this.updateLocationError(error)));

  updateLocationError(error) {
    this.alertService.warning(this.updateErrorMessage);
    this.alertService.warning(error);
    return of(new actions.UpdateFilmingLocationActionError(error));
  }
}
