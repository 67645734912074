import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import * as moment from 'moment';
import { GetTrainingEventsAction } from '../../../store/training/actions';
import { EventStatus, ITrainingDto} from '../../../services/bpp.webapi/client';
import { ConfigService } from '../../../config/config.service';
import { Subscription } from 'rxjs';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { Router } from '@angular/router';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { BaseComponent } from '../../base-component/base.component';
import { FilterPanelByClubDaterangeComponent } from '../../filter-panel-by-club-date-range/filter-panel-by-club-daterange.component';
import { filterTrainings } from '../../../helpers/trainings-helper';

@Component({
  selector: 'app-trainings-league-table',
  templateUrl: './trainings-league-table.component.html'
})
export class TrainingsLeagueTableComponent extends BaseComponent implements OnInit, OnDestroy {
  user: any;
  private trainingSubscription: Subscription;
  userSubscription: Subscription;
  items: Array<ITrainingDto>;
  trainings: Array<ITrainingDto>;
  date = moment.utc();
  isLog = false;
  isLoading = false;

  private filterPanel: FilterPanelByClubDaterangeComponent;
  @ViewChild('filterPanel', { static: false }) set panel(filterPanel: FilterPanelByClubDaterangeComponent) {
    if (filterPanel) {
      this.filterPanel = filterPanel;
    }
  }

  constructor(private store: Store<AppState>, private config: ConfigService,
    private appInsightsService: AppInsightClientService, private router: Router) {
    super();
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.items = [];
    this.trainings = [];

    this.trainingSubscription = this.store
      .select(e => e.trainings.result)
      .subscribe(res => {
        this.isLoading = res.loading;
        if (res && res.items && res.items instanceof Array) {
          this.items = res.items;
          this.filterData();
        }
      });

    this.userSubscription = this.store.select(store => store.user.currentUser).subscribe(user => {
      if (user && user.role) {
        this.user = user;
        this.store.dispatch(new GetTrainingEventsAction(user.role));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.trainingSubscription) {
      this.trainingSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  navigateToDetail(item: ITrainingDto) {
    if (StatusHelper.isCompeletedEvent(item, moment().utc()) === true) {
      return;
    }
    this.appInsightsService.logEvent('Training details', {
      trainingId: item.id
    });
    this.router.navigate([`trainings/detail/${item.id}`], { queryParams: {club: item.club}});
  }

  getStateColor(item: ITrainingDto) {
    return StatusHelper.getStatusColor(item.status);
  }

  onClubSelected() {
    this.filterData();
  }

  onDateRangeSelected() {
    this.filterData();
  }

  filterData() {
    this.trainings = filterTrainings(this.items, this.filterPanel);
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }
}
