import { Component, OnInit, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { TestContentComponent } from '../../Tests/test-content/test-content.component';
import { Roles } from '../../../models/enums';
import { TrainingsBroadcastersTableComponent } from '../../trainings/trainings-broadcasters-table/trainings-broadcasters-table.component';
import { TrainingsLeagueTableComponent } from '../../trainings/trainings-league-table/trainings-league-table.component';
import { TrainingsClubsTableComponent } from '../../trainings/trainings-clubs-table/trainings-clubs-table.component';
import { Subscription } from 'rxjs';
import { IUserRole } from '../../../models/user.model';
import { IComponetByRole, getComponentByRole } from '../../../helpers/component-by-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ConfigService } from '../../../config/config.service';

@Component({
  selector: 'app-training-wrapper',
  templateUrl: './training-wrapper.component.html',
  styleUrls: ['./training-wrapper.component.scss']
})
export class TrainingWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) trainingRef;
  interval: any;
  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: TrainingsLeagueTableComponent},
    { role: Roles.Admin, component: TrainingsLeagueTableComponent},
    { role: Roles.Broadcaster, component: TrainingsBroadcastersTableComponent},
    { role: Roles.PLPBroadcaster, component: TrainingsBroadcastersTableComponent},
    { role: Roles.Club, component: TrainingsClubsTableComponent}
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>, private config: ConfigService) { }

  ngOnInit() {
    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
          if (this.config.isDebug()) {
            console.log('Loading trainings for role: ', this.role);
          }
        }
      });
  }

  loadComponent(role) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.trainingRef.clear();
    const ref = this.trainingRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userInfo && this.userInfo.closed) {
      this.userInfo.unsubscribe();
    }
  }

}
