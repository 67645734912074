import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-filming-warning-modal',
  templateUrl: './filming-warning-modal.component.html',
  styleUrls: ['./filming-warning-modal.component.scss']
})
export class FilmingWarningModalComponent implements OnInit {

  confirm: EventEmitter<boolean> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal, private translate: TranslateService) { }

  ngOnInit() {
  }

  onCancel() {
    this.confirm.emit(false);
    this.activeModal.close();
  }

  onConfirm() {
    this.confirm.emit(true);
    this.activeModal.close();
  }
}
