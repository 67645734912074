import {IParticipantDto, AdditionalContentType, AdditionalContentFixtureDto} from '../../services/bpp.webapi/client';
import {IStoreData} from '../app.state';

export type AdditionalContentGrouped = { [key in keyof typeof AdditionalContentType]?: AdditionalContentFixtureDto[]; };

export class AdditionalContentState {
  loading: boolean;
  error: boolean;
  saveDisabled:boolean;
  submitDisabled:boolean;
  editDisabled:boolean;
  deleteDisabled:boolean;
  result: IAdditionalContentResult;
  additionalContentDetails: any = {};
  participants: Array<IParticipantDto>;
  createdAdditionalContent: string;

  constructor() {
    this.loading = false;
    this.result = {
      loading: false,
      saveDisabled: false,
      submitDisabled: false,
      editDisabled: false,
      deleteDisabled: false,
      preSeasonItems: [],
      midSeasonItems: [],
      otherClubItems: [],
      trainingGroundItems: []
    };
    this.additionalContentDetails = {};
    this.error = false;
    this.createdAdditionalContent = '';
  }
}

export interface IAdditionalContentResult extends IStoreData {
  saveDisabled:boolean;
  submitDisabled:boolean;
  editDisabled:boolean;
  deleteDisabled:boolean;
  preSeasonItems: Array<AdditionalContentFixtureDto>;
  midSeasonItems: Array<AdditionalContentFixtureDto>;
  otherClubItems: Array<AdditionalContentFixtureDto>;
  trainingGroundItems: Array<AdditionalContentFixtureDto>;
}
