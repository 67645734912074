import { Component, OnInit, Input } from '@angular/core';
import { ISessionData } from '../../models/session-data.interface';
import { Router } from '@angular/router';
import { ConfigService } from '../../../../config/config.service';
import * as playerHelper from '../../../../helpers/player-helper';
import { AttendeeDto } from '../../../../services/bpp.webapi/dashboard.client';
import { Roles } from '../../../../models/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {
  isLog = false;
  isAttendees = false;
  resources: {
    review: string,
    details: string
  };
  @Input() data: ISessionData;

  private isSimplified:boolean; 

  constructor(private router: Router, config: ConfigService, private translate: TranslateService) {
    this.isLog = config.isDebug();
    this.isSimplified = config.hasFeature("dashboard.simplified");
  }

  ngOnInit() {
    // if (this.isLog) { console.log('Message init: ', this.data); }
    this.isAttendees = this.data.attendees && this.data.attendees.length > 0 && this.data.attendees.filter(a => a.fullName !== null).length > 0;

    this.resources = { review: '', details: ''};
    this.translate.get('ReviewBtn').subscribe(value => this.resources.review = value);
    this.translate.get('DetailsBtn').subscribe(value => this.resources.details = value);
  }

  reviewBtnClick(data: ISessionData) {

    if (data.match && data.match.matchId) {
      if (this.isSimplified){
        this.router.navigate([`/club-interviews/detail/${data.match.matchId}`]);
      }
      else{
        this.router.navigate([`/fixtures/detail/${data.match.matchId}/nonLive/${data.isNonLiveFixtureEvent}`]);
      }
    } else if (data.interviewee && data.monthId) {
      this.router.navigate([`/interviews/detail/${data.monthId}`]);
    } else if (data.eventId) {
      this.router.navigate([`/trainings/detail/${data.eventId}`]);
    }

  }

  isManager(player: AttendeeDto) {
    return playerHelper.isManagerForDashboard(player);
  }

  getReviewButtonText() {
    switch (this.data.userRole) {
      case Roles.Admin:
      case Roles.League:
        return this.resources.review;
      case Roles.Club:
      case Roles.Broadcaster:
      case Roles.PLPBroadcaster:
        return this.resources.details;
      default: return this.resources.details;
    }
  }

  isReviewBtnVisible() {
    if ((this.data.userRole === Roles.League || this.data.userRole === Roles.Admin) && this.data.isRejected === true) {
      return false;
    }
    return true;
  }

  getPlayerRole(player: AttendeeDto) {
    return `(${player.kind})`;
  }
}
