import { FixtureState } from './state';
import * as actions from './actions';
import * as moment from 'moment';

export type Action = actions.All;
const defaultState = new FixtureState();

export function FixturesReducer(state = defaultState, action: Action): FixtureState {
    switch (action.type) {

        case actions.GET_CLUB_EVENTS: {
            return {
                ...state,
                loading: true,
                filter: { isUpcoming: action.isUpcoming, isNonLiveInUK: action.nonLiveInUK, matchRound: 1 },
                error: false
            };
        }

        case actions.GET_CLUB_EVENTS_SUCCESS: {
            let clubItems = { upcoming: [], past: [] };
            if (action.isUpcoming) {
                const sorted = action.payload.sort((a, b) => {
                    const date1 = moment(a.matchDate);
                    const date2 = moment(b.matchDate);
                    if(date1 > date2) {
                        return 1;
                    } else if(date1 < date2) {
                        return -1;
                    }
                    return 0;
                });
                clubItems = { ...state.clubItems, upcoming: action.payload }
            } else {
                const sorted = action.payload.sort((a, b) => {
                    const date1 = moment(a.matchDate);
                    const date2 = moment(b.matchDate);
                    if(date1 < date2) {
                        return 1;
                    } else if(date1 > date2) {
                        return -1;
                    }
                    return 0;
                });
                clubItems = { ...state.clubItems, past: action.payload }
            }
            return {
                ...state,
                clubItems,
                loading: false,
                error: false
            };
        }

        case actions.GET_LEAGUE_EVENTS: {
            return {
                ...state,
                filter: { isUpcoming: true, isNonLiveInUK: action.nonLiveInUK, matchRound: action.matchRound },
                loading: true
            };
        }

        case actions.GET_LEAGUE_EVENTS_SUCCESS: {

            return {
                ...state,
                byGameWeek: { ...state.byGameWeek, [action.matchRound]: action.payload },
                loading: false,
                error: false
            };
        }

        case actions.GET_BROADCASTER_EVENTS: {
            return {
                ...state,
                loading: true,
                error: false,
                filter: { isUpcoming: true, isNonLiveInUK: action.nonLiveInUK, matchRound: action.matchRound },
            };
        }

        case actions.GET_BROADCASTER_EVENTS_SUCCESS: {

            return {
                ...state,
                broadcasterByGameWeek: { ...state.broadcasterByGameWeek, [action.matchRound]: action.payload },
                loading: false,
                error: false
            };
        }

        case actions.CREATE_FIXTURE_EVENT: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.CREATE_FIXTURE_EVENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false
            };
        }

        case actions.UPDATE_FIXTURE_EVENT: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_FIXTURE_EVENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false
            };
        }

        case actions.GET_MATCH_INFO: {
            return {
                ...state,
                loading: true,
                selectedMatch: null,
                error: false
            };
        }

        case actions.GET_MATCH_INFO_SUCCESS: {
            return {
                ...state,
                loading: false,
                selectedMatch: action.payload,
                error: false
            };
        }

        case actions.GET_MATCH_INFO_ERROR:
        case actions.GET_CLUB_EVENTS_ERROR:
        case actions.GET_LEAGUE_EVENTS_ERROR:
        case actions.CREATE_FIXTURE_EVENT_ERROR:
        case actions.UPDATE_FIXTURE_EVENT_ERROR:
        case actions.GET_BROADCASTER_EVENTS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
    }

    return state;
}
