
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { ConfigService } from '../../../../config/config.service';
import * as moment from 'moment';
import { UpdateTrainigEventAction } from '../../../../store/training/actions';
import { ITrainingDetailsDto, TrainingDetailsDto, RequestDetailsDto, ParticipantType, CameraOperator, IRequestDetailsDto } from '../../../../services/bpp.webapi/client';
import { TrainingMapper } from '../../../../mappers/training.mapper';
import * as helper from '../../../../helpers/common';
import { StatusHelper } from '../../../../helpers/statuses-helper';
import { DateBinder } from '../../dateBinder';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { Subject } from '../../../../../../node_modules/rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { getCameraOptionTextId } from '../../../../helpers/request.helper';
import { BaseComponent } from '../../../base-component/base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-club-training-details-proceed',
  templateUrl: './club-training-details-proceed.component.html'
})
export class ClubTrainingDetailsProceedComponent extends BaseComponent implements OnInit, OnDestroy {
  trainingCopy: TrainingDetailsDto;
  @Input() training: ITrainingDetailsDto;
  @Output() toggleSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataLoaded: boolean;
  editNote = false;
  editState = false;
  isLog = false;
  lastSaved: string;
  mapper: TrainingMapper;
  dateBinder;
  user: any;
  clubName: string;
  clubLogoImagePath = '';
  minDate: { year: number; month: number; day: number; };
  isLfp: boolean;

  constructor(private config: ConfigService,
    private appInsightsService: AppInsightClientService,
    private store: Store<AppState>,
    private router: Router,
    private translate: TranslateService) {
    super();
    this.isLog = config.isDebug();
    this.mapper = new TrainingMapper();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
          this.clubName = state.currentUser.companyName;
          this.clubLogoImagePath = '../../../../assets/teams/48/t' + this.training.clubId + '.png';
        }
      });

    if (!this.training.clubNote) {
      this.training.clubNote = this.mapper.getClubNote();
    }
    this.lastSaved = helper.getLastSavedText(this.training.updatedOn, this.training.updatedByName, StatusHelper.getStatusText(this.training.status));
    const now = moment().utc();
    this.minDate = { year: now.year(), month: now.month() + 1, day: now.date() };
  }

  ngOnDestroy(): void {
  }

  editEvent() {
    this.copyTraining();
    if (this.trainingCopy !== undefined) {
      this.dateBinder = new DateBinder(this.trainingCopy.start, this.trainingCopy.arrival, this.config.get('defaultTimeZone'));
    }
    this.editState = true;
  }

  goBack() {
    this.router.navigate([`trainings`]);
  }

  copyTraining() {
    this.trainingCopy = new TrainingDetailsDto();
    this.trainingCopy.init(this.training);
  }

  cancelEditEvent() {
    this.editState = false;
  }

  dateChanged(e) {
    this.dateBinder.dateChanged(e);
  }

  saveEvent() {
    this.appInsightsService.logEvent('Update training (club)', {
      trainingId: this.training.id,
      status: StatusHelper.getStatusText(this.trainingCopy.status),
      userRole: this.user.role,
      userEmail: this.user.email,
      companyId: this.user.companyId,
      companyName: this.user.companyName
    });
    this.dateBinder.bindToTraining(this.trainingCopy);
    this.trainingCopy.status = 2;
    this.dataLoaded = false;
    const model = this.mapper.update(this.trainingCopy);
    this.toggleSpinner.emit(true);
    this.store.dispatch(new UpdateTrainigEventAction(model));
    this.editState = false;
  }

  getParticipants(request: RequestDetailsDto, type: ParticipantType) {
    if (request === undefined || request === null || request.participants === undefined) {
      return '';
    } else {
      const participants = request.participants.filter(x => x.type === type);
      if (participants.length > 0) {
        return participants;
      } else {
        return '';
      }
    }
  }

  getCameraOption(request: IRequestDetailsDto) {
    return this.translate.instant(getCameraOptionTextId(request.cameraOperator));
  }
}
