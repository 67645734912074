
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { BootstrapAlertService } from '../../widgets/ng-bootstrap-alert/bootstrap-alert.service';
import { ForgeService } from '../../services/forge.service';
import { DashboardService } from '../../services/dashboard.service';
import { BootstrapAlert } from '../../widgets/ng-bootstrap-alert/bootstrap-alert';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class NewsEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: ForgeService,
    protected rssService: DashboardService,
    protected action$: Actions,
    protected alertService: BootstrapAlertService
  ) { }

  @Effect()
  GetNews$: Observable<Action> = this.action$
    .pipe(ofType<actions.GetNewsAction>(actions.GET_NEWS),
      switchMap(action => this.service.getStories().pipe(
        switchMap((result) => {
          return of(new actions.GetNewsSuccessAction(result));
        }),
        catchError(error => {
          this.alertService.alert(new BootstrapAlert('News error', 'alert-danger'));
          return of(new actions.GetNewsErrorAction(error));
        }))));


  @Effect()
  GetNewsById$: Observable<Action> = this.action$
    .pipe(ofType<actions.GetNewsByIdAction>(actions.GET_NEWS_BY_ID),
      switchMap(action => this.service.getStoryById(action.id).pipe(
        switchMap((result) => {
          return of(new actions.GetNewsByIdSuccessAction(result));
        }),
        catchError(error => of(new actions.GetNewsErrorAction(error))))));
 
}
