import { ClubsState } from './state';
import * as actions from './actions';

export type Action = actions.All;

export function ClubsReducer(state = new ClubsState(), action: Action): ClubsState {
    switch (action.type) {

        case actions.GET_CLUBS_LIST: {

            return {
                ...state,
                loading: true,
                error: false
            };
        }
        case actions.GET_CLUBS_LIST_SUCCESS: {
            const items = action.payload;

            return {
                ...state,
                loading: false,
                error: false,
                items
            };
        }
        case actions.GET_CLUBS_LIST_ERROR: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
    }
    return state;
}
