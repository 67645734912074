import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import {
  FilmingLocationsClient,
  FilmingLocationDto,
  MatchFilmingLocationDetailsDto,
  MatchFilmingLocationDetailsItemDto,
  IMatchFilmingLocationDetailsItemDto,
  MatchFilmingLocationsDto,
  MatchFilmingLocationOffsetItemDto
} from './bpp.webapi/client';
import { GroupByPipe } from '../pipes/groupby.pipe';
import { IFilmingFixturesResult, IFilmingWeekDto, IFilmingMatchDto, IFilmingClubMatchesResult, IFilmingLocation, IFilmingLocationItem } from '../store/filming/state';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { toNumber } from 'lodash';
import { isFutureMatch, isPastMatch } from '../helpers/fixture-helper';
import { AlertService } from './alert.service';
import { ConfigService } from '../config/config.service';
import { ITimePicker } from '../components/common/combo-picker/combo-picker.component';
import { map } from 'rxjs/operators';

@Injectable()
export class FilmingService {
  timeZone: any;
  constructor(protected authService: AuthService,
    protected filmingClient: FilmingLocationsClient,
    private alertService: AlertService,
    private groupBy: GroupByPipe,
    config: ConfigService) {
    this.timeZone = config.get('defaultTimeZone');
  }

  getClubFilmingFixtures(): Observable<IFilmingClubMatchesResult> {
    return this.getFilmingMatches().pipe(
      map(res => {
        const upcomingMatches = res.filter(match => isFutureMatch(match.date));
        const pastMatches = res.filter(match => isPastMatch(match.date));
        const result: IFilmingClubMatchesResult = {
          upcoming: upcomingMatches,
          past: pastMatches
        };
        return result;
      }));
  }

  getLeagueFilmingFixtures(): Observable<IFilmingFixturesResult> {
    return this.getFilmingMatches().pipe(
      map(res => {
        const upcomingMatches = res.filter(match => isFutureMatch(match.date));
        const pastMatches = res.filter(match => isPastMatch(match.date));
        const upcomingRounds = this.getGrouped(upcomingMatches);
        const pastRounds = this.getGrouped(pastMatches);

        const result: IFilmingFixturesResult = {
          upcoming: upcomingRounds.sort((a, b) => a.weekNumber >= b.weekNumber ? 1 : -1),
          past: pastRounds.sort((a, b) => a.weekNumber < b.weekNumber ? 1 : -1),
        };
        return result;
      }));
  }

  getFilmingLocations(): Observable<{ [key: string]: FilmingLocationDto[] }> {
    return this.filmingClient.getAll().pipe(map(res => {
      const grouped = this.groupBy.transform(res.locations, 'clubId');
      return grouped;
    }));
  }

  updateLocation(dto: IFilmingLocation, matchDateUtc: moment.Moment): Observable<IFilmingLocation> {
    const model: MatchFilmingLocationDetailsDto = new MatchFilmingLocationDetailsDto();
    const locations: IMatchFilmingLocationDetailsItemDto[] = dto.items
      .map(item => ({
        locationId: item.locationId,
        startTime: moment.tz(matchDateUtc, this.timeZone).hour(item.startTime.hour).minute(item.startTime.minute).utc(),
        endTime: moment.tz(matchDateUtc, this.timeZone).hour(item.endTime.hour).minute(item.endTime.minute).utc()
      }));

    model.init({
      matchId: dto.matchId,
      locations,
      note: dto.note,
    });

    return this.filmingClient.update(model).pipe(map(res => {
      if (res.isSuccess && res.value) {
        const titles = dto.items.reduce((acc, item) => {
          acc[item.locationId] = item.title;
          return acc;
        }, {});
        this.alertService.success('Filming Location Details updated successfuly');
        return this.mapFilmingLocation({ source: res.value, titles });
      }
      throw Error;
    }));
  }

  updateForAllmatches(model: IFilmingLocation) {
    const items = model.items.map(item => {
      const offsetItem = new MatchFilmingLocationOffsetItemDto();
      offsetItem.init({
        locationId: item.locationId,
        startHours: item.startTime.hour,
        startMinutes: item.startTime.minute,
        endHours: item.endTime.hour,
        endMinutes: item.endTime.minute
      });
      return offsetItem;
    });
    const dto = new MatchFilmingLocationsDto();
    dto.init({ items, note: model.note });
    return this.filmingClient.updateForMatches(dto).pipe(map(res => true));
  }

  // ---------------------------------------------------------------

  private getFilmingMatches(): Observable<IFilmingMatchDto[]> {
    return this.filmingClient.getAll().pipe(map(res => {
      const titles: { [id: number]: string } = res.locations
        .reduce((acc, item) => {
          acc[item.locationId] = item.locationName;
          return acc;
        }, {});
      const remapped = res.matchesInfo.reduce((acc, prev) => {
        const fl = prev.matchFilmingLocation.value;
        const item: IFilmingMatchDto = {
          ...prev.matchInfo,
          filmingLocation: this.mapFilmingLocation({ source: fl, titles })
        };
        acc.push(item);
        return acc;
      }, new Array<IFilmingMatchDto>());
      return remapped;
    }));
  }

  private getGrouped(source: IFilmingMatchDto[]): IFilmingWeekDto[] {
    const grouped = this.groupBy.transform(source, 'matchDay');
    const rounds = Object.keys(grouped).map(key => {
      const round: IFilmingWeekDto = {
        weekNumber: toNumber(key),
        matches: grouped[key]
      };
      return round;
    });
    return rounds;
  }

  private mapFilmingLocation({ source, titles }: { source: MatchFilmingLocationDetailsDto; titles: { [id: number]: string } }): IFilmingLocation {
    const result: IFilmingLocation = {
      items: source.locations.map(x => this.mapFilmingLocationItem(x, titles[x.locationId])),
      matchId: source.matchId,
      note: source.note
    };
    return result;
  }

  private mapFilmingLocationItem(source: MatchFilmingLocationDetailsItemDto, title: string): IFilmingLocationItem {
    const result: IFilmingLocationItem = {
      title,
      startTime: this.getHoursMinutes(source.startTime),
      endTime: this.getHoursMinutes(source.endTime),
      locationId: source.locationId
    };
    return result;
  }

  private getHoursMinutes(source: moment.Moment): ITimePicker {
    if (source) {
      return {
        hour: moment.utc(source).tz(this.timeZone).hour(),
        minute: moment.utc(source).tz(this.timeZone).minute()
      };
    } else {
      return {
        hour: 0,
        minute: 0
      };
    }
  }
}
