import { ReportState } from './state';
import * as actions from './actions';

export type Action = actions.All;
const defaultState = new ReportState();

export function ReportReducer(state = defaultState, action: Action): ReportState {
    switch (action.type) {
        case actions.GET_PERSONS_REPORT: {
            const newState = { ...state };
            newState.loading = true;
            return newState;
        }

        case actions.GET_PERSONS_REPORT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.items = action.payload;
            return newState;
        }

        case actions.GET_PERSONS_REPORT_ERROR: {
            const newState = { ...state };
            return newState;
        }

        case actions.GET_CONTENT_SESSION_TYPE_REPORT: {
            const newState = { ...state };
            newState.loading = true;
            return newState;
        }

        case actions.GET_CONTENT_SESSION_TYPE_REPORT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.contentSessionTypeItems = action.payload;
            return newState;
        }

        case actions.GET_CONTENT_SESSION_TYPE_REPORT_ERROR: {
            const newState = { ...state };
            return newState;
        }
        case actions.GET_BROADCASTER_REQUESTS_REPORT: {
            const newState = { ...state };
            newState.loading = true;
            return newState;
        }

        case actions.GET_BROADCASTER_REQUESTS_REPORT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.broadcasterRequestsItems = action.payload;
            return newState;
        }

        case actions.GET_BROADCASTER_REQUESTS_REPORT_ERROR: {
            const newState = { ...state };
            return newState;
        }

        case actions.GET_INTERVIEWS_SUMMARY_REPORT: {
            const newState = { ...state };
            newState.loading = true;

            return newState;
        }

        case actions.GET_INTERVIEWS_SUMMARY_REPORT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.interviewsSummaryItems = action.payload;
            return newState;
        }

        case actions.GET_INTERVIEWS_SUMMARY_REPORT_ERROR: {
            const newState = { ...state };
            return newState;
        }
    }
    return state;
}
