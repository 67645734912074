import { Pipe, PipeTransform } from '@angular/core';
import { ITimePicker } from '../components/common/combo-picker/combo-picker.component';
import { ConfigService } from '../config/config.service';

@Pipe({
    name: 'timePicker',
    pure: false
})
export class TimePickerPipe implements PipeTransform {

    constructor(private config: ConfigService) {
    }
    
    transform(value: ITimePicker): string {
        if (!value) { return ''; }
        const hour = value.hour < 10 ? '0' + value.hour : value.hour;
        const minute = value.minute < 10 ? '0' + value.minute : value.minute;
        if (this.config.isLfp()){
            return `${hour}:${minute} CET`;    
        }
        return `${hour}:${minute}`;
    }
}
