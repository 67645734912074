import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';
import {ClubDto, LocationDto} from '../../../services/bpp.webapi/client';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {filter, takeUntil} from 'rxjs/operators';
import {GetInterviewLocationsAction, GetLocationsAction} from '../../../store/location/actions';
import {GetClubsListAction} from '../../../store/clubs/actions';

@Component({
  selector: 'app-league-locations',
  templateUrl: './league-locations.component.html'
})
export class LeagueLocationsComponent extends BaseComponent implements OnInit {
  isSpinner = true;
  locations: Array<LocationDto>;
  clubs: ClubDto[];
  leagueClub = new ClubDto({
    id: 0,
    name: 'League'
  });

  selectedClub: ClubDto = this.leagueClub;

  @Output() addLocationClicked = new EventEmitter();
  @Output() editLocationClicked = new EventEmitter();

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(e => e.clubs)
      .pipe(takeUntil(this.componentDestroyed),
        filter(e => e !== undefined && e.items !== undefined && e.items.length > 0))
      .subscribe(e => {
        this.clubs = [this.selectedClub, ...e.items];

        if (this.clubs.length > 0) {
          this.selectClub(this.clubs[0]);
        }
      });

    this.store.select(e => e.location)
      .pipe(takeUntil(this.componentDestroyed),
        filter(e => e !== undefined && e.items !== undefined))
      .subscribe(e => {
        this.locations = e.items;
        this.isSpinner = false;
      });

    this.store.dispatch(new GetClubsListAction());
  }

  selectClub(club: ClubDto) {
    this.selectedClub = club;
    if (club.id === 0) {
      // league club
      this.store.dispatch(new GetInterviewLocationsAction());
    } else {
      this.store.dispatch(new GetLocationsAction(this.selectedClub.id));
    }
  }

  onAddClick() {
    this.addLocationClicked.emit({ leagueType: this.selectedClub.id === 0, clubId: this.selectedClub.id  });
  }

  onEditClick(location: LocationDto) {
    this.editLocationClicked.emit({ location, leagueType: this.selectedClub.id === 0, clubId: this.selectedClub.id  })
  }
}
