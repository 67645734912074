import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { isGuidNotEmpty } from 'src/app/helpers/common';
import * as helper from '../../../helpers/common';
import { getCameraOptionTextId } from 'src/app/helpers/request.helper';
import { IClubInterviewIdentity } from 'src/app/models/club-interview-accordion-item';
import { Roles } from 'src/app/models/enums';
import { IParticipantDto, CameraOperator, NoteDto, NoteStatus, RequestStatus, CreateParticipantDto, ParticipantDto, ParticipantType, RequestDetailsDto, InterviewEventRequestSlotDto, InterviewEventDetailsDto, ContentSessionTypeClient, EventStatus } from 'src/app/services/bpp.webapi/client';
import { ValidationService } from 'src/app/services/validation.service';
import { AppState } from 'src/app/store/app.state';
import { FxRequestGetParticipantsAction, FxRequestGetParticipantsByCompanyAction } from 'src/app/store/fx-request/actions';
import { IRequestSection } from 'src/app/store/fx-request/state';
import { AddContactComponent } from '../../common/add-contact/add-contact.component';
import { SECTIONS_LIST } from '../models/sections';
import * as moment from 'moment';
import { ApproveRejectConfirmComponentLfp } from '../../common/reject-confirm/lfp/approve-reject-confirm.component';

@Component({
  selector: 'app-club-interview-request-body',
  templateUrl: './club-interview-request-body.component.html',
  styleUrls: ['./club-interview-request-body.component.scss']
})
export class ClubInterviewRequestBodyComponent implements OnInit, OnChanges {

  isLog = false;
  modalSubscription: Subscription;
  private componentDestroyed: Subject<void> = new Subject();

  @Input() request: RequestDetailsDto;
  @Input() isPastEvent = false;
  @Input() identity: IClubInterviewIdentity;
  @Input() slots: InterviewEventRequestSlotDto[] = [];
  @Input() interview: InterviewEventDetailsDto;
  @Output() submit: EventEmitter<{ request: RequestDetailsDto, slotId: string }> = new EventEmitter<{ request: RequestDetailsDto, slotId: string }>();
  @Output() save: EventEmitter<{ request: RequestDetailsDto, slotId: string }> = new EventEmitter<{ request: RequestDetailsDto, slotId: string }>();
  @Output() withdraw: EventEmitter<{ request: RequestDetailsDto, slotId: string }> = new EventEmitter<{ request: RequestDetailsDto, slotId: string }>();
  @Output() update: EventEmitter<{ request: RequestDetailsDto, slotId: string }> = new EventEmitter<{ request: RequestDetailsDto, slotId: string }>();
  isEditMode: boolean;
  isLeagueEditMode:boolean;
  participantsByType: { [type: number]: IParticipantDto[] } = {};
  isLeagueMode: boolean;
  isBroadcasterMode: boolean;
  containerMode: Roles;
  CameraOperator = CameraOperator;
  hasCameraOptionPlp = true;
  hasCameraOptionBroadcaster = true;
  hasCameraOptionAudio = true;
  sections = SECTIONS_LIST;
  isReadOnly: boolean = false;
  requestSource: RequestDetailsDto = new RequestDetailsDto();
  slotId: string;
  user:any;
  isInterviewInValidationOrRejected:boolean;

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    private config: ConfigService,
    private validator: ValidationService,
    private translate: TranslateService) {
    this.isLog = config.isDebug();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.request && changes.request.currentValue) {
      this.requestSource.init(changes.request.currentValue);
      if (isGuidNotEmpty(changes.request.currentValue.id)) {
        this.isReadOnly = true;
      }
    }
    if (changes && changes.slots && changes.slots.currentValue && this.request) {
      const slot = changes.slots.currentValue.find(x => x.submittedRequests.includes(this.request.id))
      if (slot) {
        this.slotId = slot.id;
      }
    }
  }

  ngOnInit() {
    this.isInterviewInValidationOrRejected = this.interview.status===EventStatus.Submitted || this.interview.status === EventStatus.Reject;

    if (this.isLog) {
      console.log('request component: ', this.request, this.identity);
    }

    this.store.select(state => state.user.currentUser).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(user => {
        this.user = user;
        this.containerMode = user.role;
        console.log('request',this.request)
        if (user.role === Roles.League || user.role === Roles.Admin) {
          this.slotId = this.getSlotIdForLeague(this.interview,this.request.id);
          this.isLeagueMode = true;
          this.request.leagueNote = 
          this.request.leagueNote!= null 
          ? this.request.leagueNote 
          : new NoteDto({ date: undefined, id: undefined, status: undefined, message: '', user: this.user.id, userName: `${this.user.givenName} ${this.user.surname}` }),
          this.store.dispatch(new FxRequestGetParticipantsByCompanyAction(this.request.companyId));
          this.store.select(state => state.fxRequests.participantsByCompany).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(p => {
              if (p && p[this.request.companyId] && p[this.request.companyId].length > 0) {
                this.setParticipantsByType(p[this.request.companyId]);
              }
            });
        } else {
          if (user.role === Roles.Broadcaster || user.role === Roles.PLPBroadcaster) {
            this.isBroadcasterMode = true;
          // if (!helper.isGuidNotEmpty(this.request.id) &&  this.interview.status ==EventStatus.Reject){
            //   this.request.cameraOperator = undefined;
            // }
            this.isEditMode = !this.isPastEvent && !this.isInterviewInValidationOrRejected && helper.isEmptyGuid(this.request.id);
            this.request.broadcasterNote = 
              this.request.broadcasterNote!= null 
              ? this.request.broadcasterNote
              : new NoteDto({ date: undefined, id: undefined, status: undefined, message: '', user: this.user.id, userName: `${this.user.givenName} ${this.user.surname}` })
          }
          this.store.dispatch(new FxRequestGetParticipantsAction());
          this.store.select(state => state.fxRequests.participants).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(p => {
              this.setParticipantsByType(p);
            });
        }
      });
    this.hasCameraOptionPlp = this.config.hasFeature('requestCameraOptions.plp');
    this.hasCameraOptionBroadcaster = this.config.hasFeature('requestCameraOptions.broadcaster');
    this.hasCameraOptionAudio = this.config.hasFeature('requestCameraOptions.audioOnly');
    console.log('status',this.request.status)  

  }

  isRequestEmpty(guid):boolean{
    console.log(guid);
    return !isGuidNotEmpty(guid);
  }


  getSlotIdForLeague(interview: InterviewEventDetailsDto, requestId: string): string {
    if (interview.slots){
      const slot = interview.slots.find(x => x.submittedRequests ? x.submittedRequests.includes(requestId) : false);
      if (slot){
        return slot.id;
      }
    }
    return "";
  }

  setParticipantsByType(p: IParticipantDto[]) {

    if (p && p.length > 0) {
      this.participantsByType = p.reduce((rv, x) => {
        (rv[x['type']] = rv[x['type']] || []).push(x);
        return rv;
      }, {});

      if (this.isLog) { console.log('participants', p); }
    }

  }

  isNotEmptyNotes(notes: NoteDto) {
    return notes && notes.message && notes.message.length > 0;
  }

  isSubmittedNotes(notes: NoteDto) {
    return notes && notes.status === NoteStatus.Submitted;
  }

  isCanChange() {
    return this.request.status !== RequestStatus.Withdrawn 
            && this.request.status !== RequestStatus.Reject 
            && this.isPastEvent !== true 
            && this.isInterviewInValidationOrRejected!==true;
  }

  onSave() {
    if (!this.validator.isClubInterviewRequestValid(this.request, this.slotId, this.interview.interviewType)) {
      return;
    }
    if (this.isLeagueMode){
      this.isLeagueEditMode= false;
    }
    else  {
      this.isEditMode = false;
    }
    this.save.emit({ request: this.request, slotId: this.slotId });
  }

  onWithdraw() {
    if (this.isLeagueMode){
      this.isLeagueEditMode= false;
    }
    else  {
      this.isEditMode = false;
    }
    this.withdraw.emit({ request: this.request, slotId: this.slotId });
  }

  onSubmit() {
    if (!this.validator.isClubInterviewRequestValid(this.request, this.slotId, this.interview.interviewType)) {
      return;
    }
    if (this.isLeagueMode){
      this.isLeagueEditMode= false;
    }
    else  {
      this.isEditMode = false;
    }
    this.submit.emit({ request: this.request, slotId: this.slotId });
  }

  onUpdate() {
    if (!this.validator.isClubInterviewRequestValid(this.request, this.slotId, this.interview.interviewType)) {
      return;
    }
    this.isEditMode = false;
    this.update.emit({ request: this.request, slotId: this.slotId });
  }

  onEdit() {
    if (this.isLeagueMode){
      this.isLeagueEditMode= true;
    }
    else  {
      this.isEditMode = true;
      this.isReadOnly = false;
    }
  }

  onCancel() {
    if (this.isLeagueMode){
      this.isLeagueEditMode= false;
    }
    else  {
      this.isEditMode = false;
    }
    this.isReadOnly = true;
    this.request.init(this.requestSource);
  }

  onReject() {
    if (this.isLeagueMode){
      this.showConfirmRequest(this.request,this.slotId,true)
    }
    else  {
      this.isEditMode = false;
      this.request.status = RequestStatus.Reject;
      this.submit.emit({ request: this.request, slotId: this.slotId });
    }
  }

  onUnReject() {
    if (this.isLeagueMode){
      this.isLeagueEditMode= true;
    }
    else  {
      this.isEditMode = true;
    }
    this.request.status = RequestStatus.Submitted;
    this.submit.emit({ request: this.request, slotId: this.slotId });
  }

  onApprove() {
    if (!this.validator.isClubInterviewRequestValid(this.request, this.slotId, this.interview.interviewType)) {
      return;
    }
    if (this.isLeagueMode){
      this.showConfirmRequest(this.request,this.slotId,false)
    }
    else  {
      this.isEditMode = false;
      this.request.status = RequestStatus.Approved;
      this.submit.emit({ request: this.request, slotId: this.slotId });
    }
  }


  isSectionEnabled(section) {
    if (section.type === ParticipantType.Camera) {
      return this.request.cameraOperator === CameraOperator.BroadcasterCamera;
    }
    return this.request[section.property];
  }

  onSectionEnable(value, section) {
    console.log('section-change');
    if (section.type === ParticipantType.Camera) {
      this.request.cameraOperator = value;
      if (value !== CameraOperator.BroadcasterCamera){
       this.removeParticipantByType(ParticipantType.Camera)
      }
      return;
    }
    this.request[section.property] = value;
  }

  newParticipant(model: CreateParticipantDto) {
    if (this.isLog) { console.log('New Participant', model); }
    const participant = ParticipantDto.fromJS({
      id: '00000000-0000-0000-0000-000000000000',
      name: model.name,
      phone: model.phone,
      email: model.email,
      type: model.type
    });
    this.request.participants.push(participant);
  }

  popupNewParticipant(section: any) {
    const modalRef = this.modalService.open(AddContactComponent, { centered: true });
    const instance = modalRef.componentInstance as AddContactComponent;
    instance.type = section.type;
    this.modalSubscription = instance.save.pipe(takeUntil(this.componentDestroyed))
      .subscribe(contact => this.newParticipant(contact));
  }

  removeParticipant(model: IParticipantDto) {
    this.request.participants = this.request.participants.filter(x => x != model);
  }

  removeParticipantByType(type: ParticipantType) {
    this.request.participants = this.request.participants.filter(x => x.type != type);  
    this.participantsByType[type]= [];
  }

  selectParticipant(id, type: ParticipantType) {
    const item = this.participantsByType[type].find(x => x.id === id);
    if (item && !this.request.participants.find(x => x.id === id)) {
      const participant = new ParticipantDto();
      participant.init(item);
      this.request.participants.push(participant);
    }
  }

  getSelectText(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'SelectCameraOperator';
      case (ParticipantType.Reporter): return 'SelectReporter';
      case (ParticipantType.Producer): return 'SelectProducer';
      case (ParticipantType.Other): return 'SelectOther';
      case (ParticipantType.Unknow): return 'Unknown';
    }
  }

  getCameraOption(section: any) {
    if (section.type === ParticipantType.Camera && this.request.cameraOperator === CameraOperator.BroadcasterCamera)
      return this.translate.instant('InPerson');

    return this.translate.instant(getCameraOptionTextId(this.request.cameraOperator));
  }

  showParticipantSelector(section: IRequestSection) {
    return section.cameraOperator === CameraOperator.BroadcasterCamera;
  }

  hasParticipants(type) {
    return this.request.participants.filter(x => x.type === type).length > 0;
  }

  getSlotText(id) {
    const slotIndex = this.slots.findIndex(x => x.id === id);
    return slotIndex > -1 ? `Slot ${slotIndex + 1}` : 'Select slot';
  }

  onChangeSlot(item) {
    this.slotId = item.id;
  }

  showConfirmRequest(request: RequestDetailsDto, slotId: string ,isReject:boolean){
    const modalRef = this.modalService.open(ApproveRejectConfirmComponentLfp);
    const instance = modalRef.componentInstance as ApproveRejectConfirmComponentLfp;
    instance.note = request.leagueNote ? request.leagueNote.message : "";
    instance.isReject = isReject;
    return instance.submit
        .subscribe((result: boolean) => {
            if (result) {
              request.leagueNote.message = instance.note;
              request.status = isReject ? RequestStatus.Reject : RequestStatus.Approved;
              this.submit.emit({ request: request, slotId });
              this.isLeagueEditMode= false;
              return;
          }
        });
  }
}
