import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TrainingService } from '../../services/training.service';
import * as actions from './actions';
import { StatusHelper } from '../../helpers/statuses-helper';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class TrainingEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: TrainingService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetTrainingEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetTrainingEventsAction>(actions.GET_TRAINING_EVENTS),
    switchMap(action => this.service.getTrainingEvents(action.userRole).pipe(
      switchMap((result) => of(new actions.GetTrainingEventsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetTrainingEventsErrorAction(error));
      }))));

  @Effect()
  CreateTrainigEvent$ = this.action$.pipe(
    ofType<actions.CreateTrainigEventAction>(actions.CREATE_TRAINING_EVENT),
    switchMap(action => this.service.createEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Training created. Status: ${StatusHelper.getStatusText(result.status)}`;
        this.alertService.success(message);
        return of(new actions.CreateTrainigEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateTrainigEventErrorAction(error));
      }))));

  @Effect()
  GetTrainingEventById$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetTrainingEventByIdAction>(actions.GET_TRAINING_EVENT_BY_ID),
    switchMap(action => this.service.getEventById(action.id).pipe(
      switchMap((result) => of(new actions.GetTrainingEventByIdSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetTrainingEventsErrorAction(error));
      }))));

  @Effect()
  UpdateTrainigEvent$ = this.action$.pipe(
    ofType<actions.UpdateTrainigEventAction>(actions.UPDATE_TRAINING_EVENT),
    switchMap(action => this.service.updateEvent(action.payload).pipe(
      switchMap(result => {
        const message = `Training status updated.`;
        this.alertService.success(message);
        return of(new actions.UpdateTrainigEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateTrainigEventErrorAction(error));
      })
    )));

  @Effect()
  GetTrainingParticipants$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetTrainingParticipantsAction>(actions.GET_TRAINING_PARTICIPANTS),
    switchMap(action => this.service.getParticipants().pipe(
      switchMap((result) => of(new actions.GetTrainingParticipantsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetTrainingParticipantsErrorAction(error));
      }))));

  @Effect()
  GetTrainingParticipantsByCompany$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetTrainingParticipantByCompanyAction>(actions.GET_TRAINING_PARTICIPANTS_BY_COMPANY),
    switchMap(action => this.service.getParticipantsByCompany(action.payload).pipe(
      switchMap((result) => of(new actions.GetTrainingParticipantsSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetTrainingParticipantsErrorAction(error));
      }))));

  @Effect()
  CreateTrainingParticipant$ = this.action$.pipe(
    ofType<actions.CreateTrainingParticipantAction>(actions.CREATE_TRAINING_PARTICIPANT),
    switchMap(action => this.service.createParticipant(action.payload).pipe(
      switchMap((result) => {
        return of(new actions.CreateTrainingParticipantSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateTrainingParticipantErrorAction(error));
      }))));

  @Effect()
  CreateTrainingRequest$ = this.action$.pipe(
    ofType<actions.CreateTrainingRequestAction>(actions.CREATE_TRAINING_REQUEST),
    switchMap(action => this.service.createRequest(action.payload).pipe(
      switchMap(result => {
        const message = `Request created`;
        this.alertService.success(message);
        return of(new actions.CreateTrainingRequestSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateTrainingRequestErrorAction(error));
      }))));

  @Effect()
  UpdateTrainingRequest$ = this.action$.pipe(
    ofType<actions.UpdateTrainingRequestAction>(actions.UPDATE_TRAINING_REQUEST),
    switchMap(action => this.service.updateRequest(action.payload).pipe(
      switchMap(result => {
        const message = `Request updated`;
        this.alertService.success(message);
        return of(new actions.UpdateTrainingRequestSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateTrainingRequestErrorAction(error));
      })
    )));
}
