import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { TestRouteComponent } from './components/Tests/test-route/test-route.component';
import { RouterModule } from '@angular/router';
import { router } from './routers/app.router';
import { LoggedInGuard } from './authentication/logged-in.guard';
import { MainLayoutPageComponent } from './components/main-layout-page/main-layout-page.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { TestContentComponent } from './components/Tests/test-content/test-content.component';
import { AuthService } from './authentication/auth.service';
import { ConfigService } from './config/config.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { UserInfoService } from './authentication/userinfo.service';
import { StateControlComponent } from './components/common/state-control/state-control.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/app.state';
import { MarkdownModule } from 'ngx-markdown';
import { UserEffects } from './store/user/effects';
import { TrainingEffects } from './store/training/effects';
import { TrainingService } from './services/training.service';
import { FixturesLeagueTableComponent } from './components/fixtures/fixtures-league-table/fixtures-league-table.component';
import { FixturesClubsTableComponent } from './components/fixtures/fixtures-clubs-table/fixtures-clubs-table.component';
import { FixturesBroadcastersTableComponent } from './components/fixtures/fixtures-broadcasters-table/fixtures-broadcasters-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './components/menu/menu.component';
import { TitlebarComponent } from './components/titlebar/titlebar.component';
import { FixturesWrapperComponent } from './components/fixtures/fixtures-wrapper/fixtures-wrapper.component';
import { TrainingWrapperComponent } from './components/training/training-wrapper/training-wrapper.component';
import { DashboardCommonComponent } from './components/dashboard/dashboard-common/dashboard-common.component';
import { AboutComponent } from './components/about/about.component';
import { SearchControlComponent } from './components/common/search-control/search-control.component';
import { TrainingsBroadcastersTableComponent } from './components/trainings/trainings-broadcasters-table/trainings-broadcasters-table.component';
import { TrainingsClubsTableComponent } from './components/trainings/trainings-clubs-table/trainings-clubs-table.component';
import { TrainingsLeagueTableComponent } from './components/trainings/trainings-league-table/trainings-league-table.component';
import { ClubFixtureDetailsComponent } from './components/fixture-details/club-fixture-details/club-fixture-details.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { WidgetsModule } from './widgets/widgets.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardMessageComponent } from './components/dashboard/messages/dashboard-message/dashboard-message.component';
import { ClubTrainingDetailsComponent } from './components/training-details/clubs-training-details/club-training-details.component';
import { CreateClubTrainingComponent } from './components/training-details/create-club-training/create-club-training.component';
import { TrainingDetailsWrapperComponent } from './components/training-details/training-details-wrapper.component';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './components/common/ngb-datepicker-formatter/ngb-datepicker-custom-formatter';
import { BppService } from './services/bpp.service';
import { BppEffects } from './store/bpp/effects';
import {
  ApiVersionClient,
  TrainingClient,
  LocationClient,
  LocationTypeClient,
  FixtureClient,
  UserClient,
  PeopleClient,
  AuthenticationClient,
  LanguageClient,
  TrainingRequestClient,
  TrainingParticipantClient,
  FixtureParticipantClient,
  FixtureRequestClient,
  ReportClient,
  ClubsClient,
  NoteClient,
  LoggerClient,
  FilmingLocationsClient,
  InterviewsClient,
  InterviewsRequestClient,
  TeamMembersClient, MatchClient, ClubContentSessionRequestClient, ClubContentSessionParticipantClient, InterviewTypesClient, InterviewEventClient
} from './services/bpp.webapi/client';
import { BroadcastersTrainingDetailsComponent } from './components/training-details/broadcasters-training-details/broadcasters-training-details.component';
import { LeagueTrainingDetailsComponent } from './components/training-details/league-training-details/league-training-details.component';
import { SpinnerModule } from 'spinner-angular';
import { AddLocationComponent } from './components/common/add-location/add-location.component';
import { FixtureService } from './services/fixture.service';
import { FixtureEffects } from './store/fixture/effects';
import { LocationService } from './services/location.service';
import { MessageInfoComponent } from './components/dashboard/messages/message-info/message-info.component';
import { LocationEffects } from './store/location/effects';
import { LocationTypeEffects } from './store/location-types/effects';
import { ClientBase } from './services/bpp.webapi/client-base';
import { DatePickerWrapperComponent } from './components/common/date-picker-wrapper/date-picker-wrapper.component';
import { FixtureDetailsWrapperComponent } from './components/fixture-details/fixture-details-wrapper/fixture-details-wrapper.component';
import { TimePickerWrapperComponent } from './components/common/time-picker-wrapper/time-picker-wrapper.component';
import { PlayerInfoCardComponent } from './components/fixture-details/player-info-card/player-info-card.component';
import { PlayerPickedCardComponent } from './components/fixture-details/player-picked-card/player-picked-card.component';
import { ClubTrainingDetailsDraftComponent } from './components/training-details/clubs-training-details/club-training-details-draft/club-training-details-draft.component';
import { ClubTrainingDetailsEndedComponent } from './components/training-details/clubs-training-details/club-training-details-ended/club-training-details-ended.component';
import { ClubTrainingDetailsProceedComponent } from './components/training-details/clubs-training-details/club-training-details-proceed/club-training-details-proceed.component';
import { LeagueFixtureDetailComponent } from './components/fixture-details/league-fixture-detail/league-fixture-detail.component';
import { EventFilterPipe } from './pipes/filterEventsPipe';
import { CommonFilterPipe } from './pipes/commonFiterPipe';
import { OrderPipe } from './pipes/orderPipe';
import { LogoutComponent } from './components/logout/logout.component';
import { LanguagesPickerComponent } from './components/common/languages-picker/languages-picker.component';
import { SomethingWrongComponent } from './components/error-handler/something-wrong/something-wrong.component';
import { AddLanguagesComponent } from './components/common/add-languages/add-languages.component';
import { FixtureBodyDetailsComponent } from './components/fixture-details/fixture-body-details/fixture-body-details.component';
import { LocationsPickerComponent } from './components/common/locations-picker/locations-picker.component';
import { UnderConstructComponent } from './components/under-construct/under-construct.component';
import { BroadcasterFixtureDetailsComponent } from './components/fixture-details/broadcaster-fixture-details/broadcaster-fixture-details.component';
import { AddContactComponent } from './components/common/add-contact/add-contact.component';
import { FixtureRequestEffects } from './store/fx-request/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LeagueEditTrainingRequestComponent } from './components/training-details/league-training-details/league-edit-training-request-details/league-edit-training-request.component';
import { RequestBodyComponent } from './components/requests/request-body/request-body.component';
import { FixtureHeaderComponent } from './components/fixture-details/fixture-header/fixture-header.component';
import { ClubRequestComponent } from './components/requests/club-request/club-request.component';
import { FixturesTableComponent } from './components/fixtures/fixtures-table/fixtures-table.component';
import { DashboardService } from './services/dashboard.service';
import { DashboardClient } from './services/bpp.webapi/dashboard.client';
import { DashboardEffects } from './store/dashboard/effects';
import { NgxPaginationModule } from 'ngx-pagination';
import { PersonsReportWrapperComponent } from './components/reports/persons-report/persons-report-wrapper/persons-report-wrapper.component';
import { ClubPersonsReportComponent } from './components/reports/persons-report/club-persons-report/club-persons-report.component';
import { ReportService } from './services/report.service';
import { ReportEffects } from './store/report/effects';
import { ReportTableComponent } from './components/common/report-table/report-table.component';
import { LeaguePersonsReportComponent } from './components/reports/persons-report/league-persons-report/league-persons-report.component';
import { ClubsService } from './services/clubs.service';
import { ClubsEffects } from './store/clubs/effects';
import { AlertService } from './services/alert.service';
import { MomentDatePipe } from './pipes/moment.date.pipe';
import { ValidationService } from './services/validation.service';
import { NotAuthorizedComponent } from './components/error-handler/not-authorized/not-authorized.component';
import { NotesService } from './services/notes.service';
import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {
  ApplicationInsightsModule,
  AppInsightsService
} from '@markpieszak/ng-application-insights';
import { ConfirmWithdrawPopupComponent } from './components/common/withdraw-request/confirm-withdraw-popup.component';
import { AppInsightClientService } from './services/app-insight.service';
import { EventsImportComponent } from './components/events-import/events-import.component';
import { ImportService } from './services/import.service';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { FirstLoginGuard } from './authentication/firstLogin.guard';
import { ContentSessionTypesReportWrapperComponent } from './components/reports/content-session-types-report/content-session-types-report-wrapper/content-session-types-report-wrapper.component';
import { LeagueContentSessionTypesReportComponent } from './components/reports/content-session-types-report/league-content-session-types-report/league-content-session-types-report.component';
import { ClubContentSessionTypesReportComponent } from './components/reports/content-session-types-report/club-content-session-types-report/club-content-session-types-report.component';
import { ContentSessionTypesReportComponent } from './components/reports/content-session-types-report/content-session-types-report-component/content-session-types-report.component';
import { TextService } from './services/text.service';
import { RejectConfirmComponent } from './components/common/reject-confirm/reject-confirm.component';
import { FilmingLocationComponent } from './components/filming-location/filming-location.component';
import { FilmingEffects } from './store/filming/effects';
import { FilmingService } from './services/filming-service';
import { LeagueFilmingComponent } from './components/filming-location/league-filming/league-filming.component';
import { ClubFilmingComponent } from './components/filming-location/club-filming/club-filming.component';
import { FilmingMatchComponent } from './components/filming-location/filming-match/filming-match.component';
import { FilmingRoundComponent } from './components/filming-location/filming-round/filming-round.component';
import { FilmingRoundsComponent } from './components/filming-location/filming-rounds/filming-rounds.component';
import { FilmingModalComponent } from './components/common/filming-modal/filming-modal.component';
import { MonitoringErrorHandler } from './services/monitoring-error-handler';
import { ComboPickerComponent } from './components/common/combo-picker/combo-picker.component';
import { InterviewsComponent } from './components/interviews/interviews.component';
import { InterviewDetailsComponent } from './components/interview-details/interview-details.component';
import { InterviewsEffects } from './store/interviews/effects';
import { InterviewService } from './services/interviews.service';
import { InterviewsTableComponent } from './components/interviews/interviews-table/interviews-table.component';
import { TimePickerPipe } from './pipes/time-picker.pipe';
import { StringJoinPipe } from './pipes/string-join.pipe';
import { RequestStatusPipe } from './pipes/request-status.pipe';
import { GroupByPipe } from './pipes/groupby.pipe';
import { InterviewRequestComponent } from './components/interview-details/interview-request/interview-request.component';
import { InterviewRequestReadComponent } from './components/interview-details/interview-request-read/interview-request-read.component';
import { ContactsService } from './services/contacts.service';
import { FilmingFormComponent } from './components/filming-location/filming-form/filming-form.component';
import { FilmingFormItemComponent } from './components/filming-location/filming-form/filming-form-item/filming-form-item.component';
import { OrderTimePipe } from './pipes/orderTimePipe';
import { FilmingGlobalModalComponent } from './components/common/filming-global-modal/filming-global-modal.component';
import { FilmingWarningModalComponent } from './components/common/filming-warning-modal/filming-warning-modal.component';
import { ContactCardComponent } from './components/about/contact-card/contact-card.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { ClubTeamMembersComponent } from './components/team-members/club-team-members/club-team-members.component';
import { TeamMembersService } from './services/team-members.service';
import { TeamMembersEffects } from './store/team-members/effects';
import { TeamMemberDetailsComponent } from './components/team-member-details/team-member-details.component';
import { TeamMembersListComponent } from './components/team-members/team-members-list/team-members-list.component';
import { LeagueTeamMemberComponent } from './components/team-members/league-team-members/league-team-members.component';
import { TeamMembersForClubsComponent } from './components/team-members/team-members-for-clubs/team-members-for-clubs.component';
import { BroadcasterTeamMemberComponent } from './components/team-members/broadcaster-team-members/broadcaster-team-members.component';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe';
import { TeamMemberBiosDialogComponent } from './components/team-members/team-member-bios-dialog/team-member-bios-dialog.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatchesConfigurationComponent } from './components/matches-configuration/matches-configuration.component';
import { LeagueMatchesComponent } from './components/matches-configuration/league-matches/league-matches.component';
import { MatchesListComponent } from './components/matches-configuration/matches-list/matches-list.component';
import { MatchesService } from './services/matches.service';
import { MatchesEffects } from './store/matches/effects';
import { WeekViewComponent } from './components/fixtures/week-view/week-view.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ClubLocationsComponent } from './components/locations/club-locations/club-locations.component';
import { LeagueLocationsComponent } from './components/locations/league-locations/league-locations.component';
import { LocationsListComponent } from './components/locations/locations-list/locations-list.component';
import { LocationTypesService } from './services/location-types.service';
import { DefaultsService } from './services/defaults.service';
import { FilterPanelByClubDaterangeComponent } from './components/filter-panel-by-club-date-range/filter-panel-by-club-daterange.component';
import { AdditionalContentWrapperComponent } from './components/additional-content/additional-content-wrapper.component';
import { AdditionalContentClubComponent } from './components/additional-content/additional-content-club/additional-content-club.component';
import { AdditionalContentListComponent } from './components/additional-content/additional-content-list/additional-content-list.component';
import { AdditionalContentDetailsWrapperComponent } from './components/additional-content-details/additional-content-details-wrapper.component';
import { AdditionalContentDetailsClubComponent } from './components/additional-content-details/additional-content-details-club/additional-content-details-club.component';
import { CreateClubAdditionalContentComponent } from './components/additional-content-details/additional-content-create-club/create-club-additional-content.component';
import { AdditionalContentDetailsBodyComponent } from './components/additional-content-details/additional-content-details-body/additional-content-details-body.component';
import { AdditionalContentEffects } from './store/additional-content/effects';
import { AdditionalContentService } from './services/additional-content.service';
import { AdditionalContentClient } from './services/bpp.webapi/client';
import { AdditionalContentDetailsHeaderComponent } from './components/additional-content-details/additional-content-header/additional-content-details-header.component';
import { AdditionalContentLeagueComponent } from './components/additional-content/additional-content-league/additional-content-league.component';
import { AdditionalContentDetailsLeagueComponent } from './components/additional-content-details/additional-content-details-league/additional-content-details-league.component';
import { AdditionalContentBroadcasterComponent } from './components/additional-content/additional-content-broadcaster/additional-content-broadcaster.component';
import { AdditionalContentDetailsBroadcasterComponent } from './components/additional-content-details/additional-content-details-broadcaster/additional-content-details-broadcaster.component';
import { ClubContentSessionRequestBroadcasterComponent } from './components/additional-content-details/additional-content-details-broadcaster/club-content-session-request-broadcaster/club-content-session-request-broadcaster-component';
import { AdditionalContentGroupComponent } from './components/additional-content/additional-content-list/additional-content-group/additional-content-group.component';
import { ClubContentSessionRequestsLeagueComponent } from './components/additional-content-details/additional-content-details-league/club-content-session-requests-league/club-content-session-requests-league.component';
import { InterviewsTableGroupComponent } from './components/interviews/interviews-table/interviews-table-group/interviews-table-group.component';
import { InterviewCreateLeagueComponent } from './components/interview-details/interview-create-league/interview-create-league.component';
import { InterviewDetailsLeagueComponent } from './components/interview-details/interview-details-league/interview-details-league.component';
import { InterviewDetailsBroadcasterComponent } from './components/interview-details/interview-details-broadcaster/interview-details-broadcaster.component';
import { InterviewDetailsWrapperComponent } from './components/interview-details/interview-details-wrapper.component';
import { InterviewDetailsClubComponent } from './components/interview-details/interview-details-club/interview-details-club.component';
import { BroadcasterRequestsReportComponent } from './components/reports/broadcaster-requests-report/league-broadcaster-requests-report/league-broadcaster-requests-report.component';
import { InterviewsSummaryReportWarpperComponent } from './components/reports/interviews-summary-report/interviews-summary-report-warpper/interviews-summary-report-warpper.component';
import { LeagueInterviewsSummaryReportComponent } from './components/reports/interviews-summary-report/league-interviews-summary-report/league-interviews-summary-report.component';
import { InterviewsSummaryReportComponent } from './components/reports/interviews-summary-report/interviews-summary-report-component/interviews-summary-report.component';
import { ClubInterviewsSummaryReportComponent } from './components/reports/interviews-summary-report/club-interviews-summary-report/club-interviews-summary-report.component';
import { InterviewTypesEffects } from './store/interview-types/effects';
import { InterviewTypesService } from './services/interview-types.service';
import { MenuService } from './services/menu.service';
import { ClubInterviewsBroadcasterComponent } from './components/club-interviews/club-interviews-broadcaster/club-interviews-broadcaster.component';
import { ClubInterviewsClubComponent } from './components/club-interviews/club-interviews-club/club-interviews-club.component';
import { ClubInterviewsLeagueComponent } from './components/club-interviews/club-interviews-league/club-interviews-league.component';
import { ClubInterviewsWrapperComponent } from './components/club-interviews/club-interviews-wrapper/club-interviews-wrapper.component';
import { ClubInterviewWrapperComponent } from './components/club-interview-details/club-interview-wrapper/club-interview-wrapper.component';
import { ClubInterviewBroadcasterComponent } from './components/club-interview-details/club-interview-broadcaster/club-interview-broadcaster.component';
import { ClubInterviewClubComponent } from './components/club-interview-details/club-interview-club/club-interview-club.component';
import { ClubInterviewLeagueComponent } from './components/club-interview-details/club-interview-league/club-interview-league.component';
import { ClubInterviewBodyComponent } from './components/club-interview-details/club-interview-body/club-interview-body.component';
import { ClubInterviewsEffects } from './store/club-interview/effects';
import { ClubInterviewService } from './services/club-interview.service';
import { ClubInterviewWeekViewComponent } from './components/club-interviews/club-interview-week-view/club-interview-week-view.component';
import { ClubInterviewHeaderComponent } from './components/club-interview-details/club-interview-header/club-interview-header.component';
import { ClubInterviewRequestBodyComponent } from './components/club-interview-details/request-body/club-interview-request-body.component';
import { RequestsViewClubComponent } from './components/club-interview-details/requests-view-club/requests-view-club.component';
import { ItemsPickerComponent } from './components/common/items-picker/items-picker.component';
import { AddItemComponent } from './components/common/add-item/add-item.component';
import { ModelJoinPipe } from './pipes/model-join.pipe';
import { NewsComponent } from './components/news/news/news.component';
import { NewsDetailComponent } from './components/news/details/news-detail/news-detail.component';
import { NewsDetailRpComponent } from './components/news/details/news-detail-rp/news-detail-rp.component';
import { NewsEffects } from './store/news/effects';
import { ForgeService } from './services/forge.service';
import { ButtonNewsComponent } from './components/button-news/button-news.component';
import { AdditionalContentDetailsCreateWrapperComponent } from './components/additional-content-details/additional-content-details-create-wrapper.component';
import { CreateLeagueAdditionalContentComponent } from './components/additional-content-details/additional-content-create-league/create-league-additional-content.component';
import { ClubContentSessionRequestsClubComponent } from './components/additional-content-details/additional-content-details-club/club-content-session-requests-club/club-content-session-requests-club.component';
import { DashboardItemComponent } from './components/new-dashboard/messages/dashboard-item/dashboard-item.component';
import { DashboardInfoComponent } from './components/new-dashboard/messages/message-info/dashboard-info.component';
import { DashboardNewCommonComponent } from './components/new-dashboard/dashboard-new-common.component';
import { Steps } from './components/new-dashboard/stepper/another-stepper';
import { DashboardCommonWrapperComponent } from './components/dashboard/dashboard-common-wrapper.component';
import { ApproveRejectConfirmComponentLfp } from './components/common/reject-confirm/lfp/approve-reject-confirm.component';
import { DashboardLfpInfoComponent } from './components/new-dashboard/messages/message-lfp-info/dashboard-lfp-info.component';


let hasRouterError = false;

export function routerErrorHandler(error: any) {
  console.log('RouterErrorHandler: ' + error);
  hasRouterError = true;
  throw error;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

export function configServiceFactory() {
  return new ConfigService(window['tempConfigStorage']);
}

const routes: ModuleWithProviders = RouterModule.forRoot(router, {
  initialNavigation: false,
  errorHandler: routerErrorHandler,
  enableTracing: false
});

@NgModule({
  declarations: [
    AppComponent,
    TestRouteComponent,
    MainLayoutPageComponent,
    SideBarComponent,
    TestContentComponent,
    StateControlComponent,
    MenuComponent,
    TitlebarComponent,
    FixturesWrapperComponent,
    FixturesLeagueTableComponent,
    FixturesBroadcastersTableComponent,
    FixturesClubsTableComponent,
    TrainingWrapperComponent,
    TrainingsBroadcastersTableComponent,
    TrainingsClubsTableComponent,
    TrainingsLeagueTableComponent,
    DashboardCommonComponent,
    AboutComponent,
    SearchControlComponent,
    ClubFixtureDetailsComponent,
    AccessDeniedComponent,
    DashboardMessageComponent,
    ClubTrainingDetailsComponent,
    CreateClubTrainingComponent,
    TrainingDetailsWrapperComponent,
    TrainingDetailsWrapperComponent,
    BroadcastersTrainingDetailsComponent,
    LeagueTrainingDetailsComponent,
    AddLocationComponent,
    MessageInfoComponent,
    DatePickerWrapperComponent,
    FixtureDetailsWrapperComponent,
    TimePickerWrapperComponent,
    PlayerInfoCardComponent,
    PlayerPickedCardComponent,
    ClubTrainingDetailsDraftComponent,
    ClubTrainingDetailsEndedComponent,
    ClubTrainingDetailsProceedComponent,
    LeagueFixtureDetailComponent,
    EventFilterPipe,
    CommonFilterPipe,
    OrderPipe,
    OrderTimePipe,
    TimePickerPipe,
    StringJoinPipe,
    ModelJoinPipe,
    RequestStatusPipe,
    GroupByPipe,
    SafeHtmlPipe,
    LogoutComponent,
    LanguagesPickerComponent,
    SomethingWrongComponent,
    AddLanguagesComponent,
    FixtureBodyDetailsComponent,
    LocationsPickerComponent,
    UnderConstructComponent,
    BroadcasterFixtureDetailsComponent,
    AddContactComponent,
    LeagueEditTrainingRequestComponent,
    RequestBodyComponent,
    FixtureHeaderComponent,
    ClubRequestComponent,
    FixturesTableComponent,
    PersonsReportWrapperComponent,
    ClubPersonsReportComponent,
    ReportTableComponent,
    LeaguePersonsReportComponent,
    ContentSessionTypesReportWrapperComponent,
    InterviewsSummaryReportWarpperComponent,
    LeagueInterviewsSummaryReportComponent,
    ClubInterviewsSummaryReportComponent,
    InterviewsSummaryReportComponent,
    BroadcasterRequestsReportComponent,
    LeagueContentSessionTypesReportComponent,
    ClubContentSessionTypesReportComponent,
    ContentSessionTypesReportComponent,
    MomentDatePipe,
    NotAuthorizedComponent,
    TopNavbarComponent,
    UserProfileComponent,
    ConfirmWithdrawPopupComponent,
    EventsImportComponent,
    RejectConfirmComponent,
    FilmingLocationComponent,
    TeamMembersComponent,
    TeamMemberDetailsComponent,
    ClubTeamMembersComponent,
    LeagueTeamMemberComponent,
    BroadcasterTeamMemberComponent,
    TeamMemberBiosDialogComponent,
    TeamMembersForClubsComponent,
    TeamMembersListComponent,
    LeagueFilmingComponent,
    ClubFilmingComponent,
    FilmingMatchComponent,
    FilmingRoundComponent,
    FilmingRoundsComponent,
    FilmingModalComponent,
    ComboPickerComponent,
    InterviewsComponent,
    InterviewDetailsComponent,
    InterviewsTableComponent,
    InterviewRequestComponent,
    InterviewRequestReadComponent,
    FilmingFormComponent,
    FilmingFormItemComponent,
    FilmingGlobalModalComponent,
    FilmingWarningModalComponent,
    ContactCardComponent,
    PaginatorComponent,
    MatchesConfigurationComponent,
    LeagueMatchesComponent,
    MatchesListComponent,
    WeekViewComponent,
    LocationsComponent,
    LocationsListComponent,
    ClubLocationsComponent,
    LeagueLocationsComponent,
    FilterPanelByClubDaterangeComponent,
    AdditionalContentWrapperComponent,
    AdditionalContentClubComponent,
    AdditionalContentLeagueComponent,
    AdditionalContentBroadcasterComponent,
    AdditionalContentListComponent,
    AdditionalContentDetailsWrapperComponent,
    CreateClubAdditionalContentComponent,
    AdditionalContentDetailsClubComponent,
    AdditionalContentDetailsLeagueComponent,
    ClubContentSessionRequestsLeagueComponent,
    AdditionalContentDetailsBroadcasterComponent,
    ClubContentSessionRequestBroadcasterComponent,
    AdditionalContentDetailsBodyComponent,
    AdditionalContentDetailsHeaderComponent,
    AdditionalContentGroupComponent,
    InterviewsTableGroupComponent,
    InterviewCreateLeagueComponent,
    InterviewDetailsLeagueComponent,
    InterviewDetailsBroadcasterComponent,
    InterviewDetailsWrapperComponent,
    InterviewDetailsClubComponent,
    ClubInterviewsBroadcasterComponent,
    ClubInterviewsClubComponent,
    ClubInterviewsLeagueComponent,
    ClubInterviewsWrapperComponent,
    ClubInterviewWrapperComponent,
    ClubInterviewBroadcasterComponent,
    ClubInterviewClubComponent,
    ClubInterviewLeagueComponent,
    ClubInterviewBodyComponent,
    ClubInterviewWeekViewComponent,
    ClubInterviewHeaderComponent,
    ClubInterviewRequestBodyComponent,
    RequestsViewClubComponent,
    ItemsPickerComponent,
    AddItemComponent,
    NewsComponent,
    NewsDetailComponent,
    NewsDetailRpComponent,
    ButtonNewsComponent,
    AdditionalContentDetailsCreateWrapperComponent,
    CreateLeagueAdditionalContentComponent,
    ClubContentSessionRequestsClubComponent,
    DashboardItemComponent,
    DashboardInfoComponent,
    DashboardLfpInfoComponent,
    DashboardNewCommonComponent,
    Steps,
    DashboardCommonWrapperComponent,
    ApproveRejectConfirmComponentLfp
  ],
  imports: [
    BrowserModule,
    WidgetsModule,
    routes,
    MarkdownModule.forRoot(),
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true
    }),
    HttpClientModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 5
    }),
    EffectsModule.forRoot([
      UserEffects,
      BppEffects,
      TrainingEffects,
      FixtureEffects,
      LocationEffects,
      LocationTypeEffects,
      FixtureRequestEffects,
      DashboardEffects,
      ClubsEffects,
      ReportEffects,
      FilmingEffects,
      InterviewsEffects,
      TeamMembersEffects,
      MatchesEffects,
      AdditionalContentEffects,
      InterviewTypesEffects,
      ClubInterviewsEffects,
      NewsEffects
    ]),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SpinnerModule.forRoot({}),
    NgxPaginationModule,
    AngularEditorModule
  ],
  providers: [
    { provide: ConfigService, useFactory: configServiceFactory },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandler
    },
    AuthService,
    AppInsightsService,
    LoggedInGuard,
    FirstLoginGuard,
    ClientBase,
    UserClient,
    ApiVersionClient,
    TrainingClient,
    LocationClient,
    LocationTypeClient,
    FixtureClient,
    FixtureParticipantClient,
    FixtureRequestClient,
    PeopleClient,
    AuthenticationClient,
    LanguageClient,
    TrainingRequestClient,
    TrainingParticipantClient,
    ClubsClient,
    UserInfoService,
    TrainingService,
    FixtureService,
    LocationService,
    LocationTypesService,
    BppService,
    DashboardService,
    ClubsService,
    ReportService,
    ReportClient,
    DashboardClient,
    AlertService,
    ValidationService,
    NotesService,
    NoteClient,
    LoggerClient,
    AppInsightClientService,
    ImportService,
    TextService,
    FilmingService,
    FilmingLocationsClient,
    InterviewService,
    InterviewsClient,
    GroupByPipe,
    SafeHtmlPipe,
    InterviewsRequestClient,
    ContactsService,
    TeamMembersService,
    TeamMembersClient,
    MatchesService,
    MatchClient,
    DefaultsService,
    AdditionalContentService,
    AdditionalContentClient,
    ClubContentSessionRequestClient,
    ClubContentSessionParticipantClient,
    InterviewTypesClient,
    InterviewTypesService,
    MenuService,
    ClubInterviewService,
    InterviewEventClient,
    ForgeService      
  ],
  entryComponents: [
    TrainingsClubsTableComponent,
    TrainingsLeagueTableComponent,
    TrainingsBroadcastersTableComponent,
    FixturesLeagueTableComponent,
    FixturesBroadcastersTableComponent,
    FixturesClubsTableComponent,
    TestContentComponent,
    ClubTrainingDetailsComponent,
    BroadcastersTrainingDetailsComponent,
    LeagueTrainingDetailsComponent,
    AddLocationComponent,
    MessageInfoComponent,
    ClubFixtureDetailsComponent,
    LeagueFixtureDetailComponent,
    AddLanguagesComponent,
    BroadcasterFixtureDetailsComponent,
    AddContactComponent,
    LeagueEditTrainingRequestComponent,
    PersonsReportWrapperComponent,
    ContentSessionTypesReportWrapperComponent,
    InterviewsSummaryReportWarpperComponent,
    LeagueInterviewsSummaryReportComponent,
    ClubInterviewsSummaryReportComponent,
    BroadcasterRequestsReportComponent,
    LeagueContentSessionTypesReportComponent,
    ClubContentSessionTypesReportComponent,
    UnderConstructComponent,
    ClubPersonsReportComponent,
    LeaguePersonsReportComponent,
    ConfirmWithdrawPopupComponent,
    RejectConfirmComponent,
    FilmingModalComponent,
    FilmingGlobalModalComponent,
    FilmingWarningModalComponent,
    TeamMemberBiosDialogComponent,
    ClubInterviewsBroadcasterComponent,
    ClubInterviewsLeagueComponent,
    ClubInterviewsClubComponent,
    ClubInterviewClubComponent,
    ClubInterviewBroadcasterComponent,
    ClubInterviewLeagueComponent,
    AddItemComponent,
    ButtonNewsComponent,
    DashboardInfoComponent,
    DashboardLfpInfoComponent,
    ApproveRejectConfirmComponentLfp
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
