
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { Router } from '@angular/router';
import { UserModel } from '../../widgets/login/user.model';
import { environment } from '../../../environments/environment';
import { MenuService } from 'src/app/services/menu.service';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit, OnDestroy {

  protected appName: any;
  private componetDestroyed: Subject<void> = new Subject();
  protected userSubscription: Subscription;
  protected webApiSubscription: Subscription;

  protected menu: Array<any> = [];
  protected loggedUser: UserModel;
  protected userId: string;
  protected menuVerticale: any;
  protected apiVersion: string;
  protected isProduction: boolean;

  constructor(
    private store: Store<AppState>,
    protected authService: AuthService,
    protected router: Router,
    private menuService: MenuService,
    private configService: ConfigService
  ) { }

  ngOnInit() {

    this.loggedUser = new UserModel();
    this.menuVerticale = {
      show: false,
      Bitwise: 0,
      Items: [],
    };

    if (environment.production) {
      this.isProduction = true;
    }

    const appNameConfig = this.configService.get("app");
    this.appName = {
      desc: appNameConfig.name,
      subDesc1: appNameConfig.description[0],
      subDesc2: appNameConfig.description[1],
      subDesc3: appNameConfig.description[2],
      link: '',
      icon: 'bigLion'
    };

    this.userSubscription = this.store
      .select(state => state.user).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {

        if (state && state.currentUser && !state.loading) {
          this.loggedUser.firstName = state.currentUser.givenName ? state.currentUser.givenName : '';
          this.loggedUser.surname = state.currentUser.surname ? state.currentUser.surname : '';
          this.loggedUser.email = state.currentUser.email;
          this.loggedUser.thumbnail = state.currentUser.photo;
          this.loggedUser.companyId = state.currentUser.companyId;
          this.loggedUser.companyName = state.currentUser.companyName;
          this.loggedUser.role = state.currentUser.role;
          this.menuVerticale = state.userMenu;
        }

      });

    this.webApiSubscription = this.store
      .select(state => state.bpp.apiVersion)
      .subscribe(r => {
        if (r) {
          this.apiVersion = r;
          console.log('ICS WebApi Version: ', r);
        }
      }
      );
  }

  logout(evt): void {
    this.authService.logout();
  }

  edit(evt): void {
    this.router.navigateByUrl('/profile');
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

}
