import { IDashboardItemDto, DashboardItemStatus, DashboardItemType } from '../../../services/bpp.webapi/dashboard.client';
import { EventCase } from '../models/event-case.enum';
import { getCurrentOperatorRole } from './constructor.helper';
import { Roles } from '../../../models/enums';

export function getEventCase(source: IDashboardItemDto): EventCase {
    const owner = getCurrentOperatorRole(source);
    switch (owner) {
        case Roles.Club:
            switch (source.dashboardItemStatus) {
                case DashboardItemStatus.Submitted:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureEvent:
                        case DashboardItemType.InterviewEvent:
                             return EventCase.ClubSubmitFixture;
                        case DashboardItemType.TrainingEvent: return EventCase.ClubSubmitTraining;
                    }
                    break;
                case DashboardItemStatus.Updated:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureEvent: 
                        case DashboardItemType.InterviewEvent:
                            return EventCase.ClubEditApprovedFixture;
                        case DashboardItemType.TrainingEvent: return EventCase.ClubEditApprovedTraining;
                    }
                    break;
            }
            break;
        case Roles.League:
            switch (source.dashboardItemStatus) {
                case DashboardItemStatus.Approved:
                    if (source.wasApproved) {
                        switch (source.dashboardItemType) {
                            case DashboardItemType.FixtureEvent: 
                            case DashboardItemType.InterviewEvent:
                                return EventCase.LeagueApproveFixtureChanges;
                            case DashboardItemType.TrainingEvent: return EventCase.LeagueApproveTrainingChanges;
                            case DashboardItemType.FixtureRequest:
                            case DashboardItemType.InterviewEventRequest: 
                                return EventCase.LeagueApproveFixtureRequest;
                            case DashboardItemType.TrainingRequest: return EventCase.LeagueApproveTrainingRequest;
                            // TODO: what with approving request changes?
                        }
                    } else {
                        switch (source.dashboardItemType) {
                            case DashboardItemType.FixtureEvent: 
                            case DashboardItemType.InterviewEvent:
                                return EventCase.LeagueApproveFixture;
                            case DashboardItemType.TrainingEvent: return EventCase.LeagueApproveTraining;
                            case DashboardItemType.FixtureRequest:
                            case DashboardItemType.InterviewEventRequest: 
                                return EventCase.LeagueApproveFixtureRequest;
                            case DashboardItemType.TrainingRequest: return EventCase.LeagueApproveTrainingRequest;
                            case DashboardItemType.InterviewRequest: return EventCase.LeagueApproveInterviewRequest;
                        }
                    }
                    break;
                case DashboardItemStatus.Rejected:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureEvent: 
                        case DashboardItemType.InterviewEvent:
                            return EventCase.LeagueRejectFixture;
                        case DashboardItemType.TrainingEvent: return EventCase.LeagueRejectTraining;
                        case DashboardItemType.FixtureRequest:
                        case DashboardItemType.InterviewEventRequest: 
                            return EventCase.LeagueRejectFixtureRequest;
                        case DashboardItemType.TrainingRequest: return EventCase.LeagueRejectTrainingRequest;
                        case DashboardItemType.InterviewRequest: return EventCase.LeagueRejectInterviewRequest;
                    }
                    break;
                case DashboardItemStatus.Submitted:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.Interview: return EventCase.LeagueCreateInterview;
                        case DashboardItemType.FixtureRequest :
                        case DashboardItemType.InterviewEventRequest: 
                            return EventCase.LeagueUnRejectFixtureRequest;
                        case DashboardItemType.TrainingRequest: return EventCase.LeagueUnRejectTrainingRequest;
                        case DashboardItemType.InterviewRequest: return EventCase.LeagueUnRejectInterviewRequest;
                    }
                    break;
                case DashboardItemStatus.Updated:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.Interview: return EventCase.LeagueUpdateInterview;
                        case DashboardItemType.FixtureEvent: 
                        case DashboardItemType.InterviewEvent:
                            return EventCase.LeagueUpdateFixture;
                    }
                    break;
            }
            break;
        case Roles.Broadcaster:
            switch (source.dashboardItemStatus) {
                case DashboardItemStatus.Submitted:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureRequest:
                        case DashboardItemType.InterviewEventRequest: 
                            return EventCase.BroadcasterRequestFixture;
                        case DashboardItemType.TrainingRequest: return EventCase.BroadcasterRequestTraining;
                        case DashboardItemType.InterviewRequest: return EventCase.BroadcasterRequestInterview;
                    }
                    break;
                case DashboardItemStatus.Updated:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureRequest:
                        case DashboardItemType.InterviewEventRequest: 
                             return EventCase.BroadcasterEditApprovedFixture;
                        case DashboardItemType.TrainingRequest: return EventCase.BroadcasterEditApprovedTraining;
                        case DashboardItemType.InterviewRequest: return EventCase.BroadcasterEditApprovedInterview;
                    }
                    break;
                case DashboardItemStatus.RequestWithdrawn:
                    switch (source.dashboardItemType) {
                        case DashboardItemType.FixtureRequest:
                        case DashboardItemType.InterviewEventRequest: 
                             return EventCase.BroadcasterWithdrawnFixture;
                        case DashboardItemType.TrainingRequest: return EventCase.BroadcasterWithdrawnTraining;
                        case DashboardItemType.InterviewRequest: return EventCase.BroadcasterWithdrawnInterview;
                    }
                    break;
            }
            break;
    }
}
