import { IMatchDto, FilmingLocationDto, IFilmingLocationDto, IMatchFilmingLocationDetailsItemDto } from '../../services/bpp.webapi/client';
import { IStoreData } from '../app.state';
import { Moment } from 'moment';
import { ITimePicker } from '../../components/common/combo-picker/combo-picker.component';


export class FilmingState {
    fixtures: IFilmingFixturesStore;
    clubMatches: IFilmingClubMatchesStore;
    locations: IFilmingLocationsStore;
    updateStatus: {
        loading: boolean;
        matchId?: number;
    };

    constructor() {
        this.fixtures = {
            items: {
                upcoming: [],
                past: []
            },
            loading: false
        };
        this.clubMatches = {
            items: {
                upcoming: [],
                past: []
            },
            loading: false
        };
        this.locations = {
            byClubId: {},
            loading: false
        };
        this.updateStatus = {
            loading: false
        };
    }
}

export interface IFilmingFixturesStore extends IStoreData {
    items: IFilmingFixturesResult;
}

export interface IFilmingFixturesResult {
    upcoming: IFilmingWeekDto[];
    past: IFilmingWeekDto[];
}

export interface IFilmingWeekDto {
    weekNumber: number;
    matches: IFilmingMatchDto[];
}

export interface IFilmingMatchDto extends IMatchDto {
    filmingLocation: IFilmingLocation;
    locations?: IFilmingLocationDto[];
}

export interface IFilmingLocation {
    matchId?: number;
    items: IFilmingLocationItem[];
    note?: string;
}

export interface IFilmingLocationItem {
  title: string;
  locationId: number;
  startTime: ITimePicker;
  endTime: ITimePicker;
  invalid?: boolean;
}

export interface IFilmingClubMatchesStore extends IStoreData {
    items: IFilmingClubMatchesResult;
}

export interface IFilmingClubMatchesResult {
    upcoming: IFilmingMatchDto[];
    past: IFilmingMatchDto[];
}

export interface IFilmingLocationsStore extends IStoreData {
    byClubId: {
        [key: string]: FilmingLocationDto[]
    };
}
