import { AdditionalContentState } from './state';
import * as actions from './actions';
import {CREATE_ADDITIONAL_CONTENT_EVENT_SUCCESS} from './actions';

export type Action = actions.All;
const defaultState = new AdditionalContentState();

export function AdditionalContentReducer(state = defaultState, action: Action): AdditionalContentState {
    switch (action.type) {

      case actions.GET_ADDITIONAL_CONTENT_EVENTS: {
        return {
          ...state,
          result: {
            editDisabled:false,
            deleteDisabled:false,
            saveDisabled:false,
            submitDisabled:false,
            loading: true,
            error: false,
            preSeasonItems: [...state.result.preSeasonItems],
            midSeasonItems: [...state.result.midSeasonItems],
            otherClubItems: [...state.result.otherClubItems],
            trainingGroundItems: [...state.result.trainingGroundItems],
          }
        };
      }

      case actions.GET_ADDITIONAL_CONTENT_EVENTS_SUCCESS: {
        const sortedPreSeason = action.payload.PreSeason.sort((a, b) => a.arrivalUtc.valueOf() > b.arrivalUtc.valueOf() ? 1 : -1);
        const sortedMidSeason = action.payload.MidSeason.sort((a, b) => a.arrivalUtc.valueOf() > b.arrivalUtc.valueOf() ? 1 : -1);
        const sortedClub = action.payload.Club.sort((a, b) => a.arrivalUtc.valueOf() > b.arrivalUtc.valueOf() ? 1 : -1);
        const sortedGround = action.payload.TrainingGround.sort((a, b) => a.arrivalUtc.valueOf() > b.arrivalUtc.valueOf() ? 1 : -1);
        return {
          ...state,
          result: {
            editDisabled:false,
            deleteDisabled:false,
            saveDisabled:false,
            submitDisabled:false,
            loading: false,
            preSeasonItems: sortedPreSeason,
            midSeasonItems: sortedMidSeason,
            otherClubItems: sortedClub,
            trainingGroundItems: sortedGround,
            error: false
          }
        };
      }

      case actions.GET_ADDITIONAL_CONTENT_EVENTS_ERROR: {
        return {
          ...state,
          result: {
            editDisabled:false,
            deleteDisabled:false,
            saveDisabled:false,
            submitDisabled:false,
            loading: false,
            error: true,
            preSeasonItems: [],
            midSeasonItems: [],
            otherClubItems: [],
            trainingGroundItems: []
          }
        };
      }

      case actions.GET_ADDITIONAL_CONTENT_EVENT_BY_ID: {
        const newState = { ...state };
        newState.loading = true;
        newState.error = false;
        newState.additionalContentDetails[action.id] = null;
        return newState;
      }

      case actions.GET_ADDITIONAL_CONTENT_EVENT_BY_ID_SUCCESS: {
        const newState = { ...state };
        newState.loading = false;
        newState.error = false;
        newState.additionalContentDetails[action.payload.eventId] = action.payload;
        return newState;
      }

      case actions.CREATE_ADDITIONAL_CONTENT_EVENT: {
        const newState = { ...state };
        newState.loading = true;
        newState.error = false;
        newState.createdAdditionalContent = '';
        return newState;
      }

      case actions.CREATE_ADDITIONAL_CONTENT_EVENT_SUCCESS: {
        const newState = { ...state };
        newState.loading = false;
        newState.error = false;
        newState.additionalContentDetails[action.payload.eventId] = action.payload;
        newState.createdAdditionalContent = action.payload.eventId;
        return newState;
      }

      case actions.UPDATE_ADDITIONAL_CONTENT_EVENT: {
        const newState = { ...state };
        newState.loading = true;
        newState.error = false;
        newState.additionalContentDetails[action.payload.eventId] = null;
        return newState;
      }

      case actions.UPDATE_ADDITIONAL_CONTENT_EVENT_SUCCESS: {
        const newState = { ...state };
        newState.loading = false;
        newState.error = false;
        newState.additionalContentDetails[action.payload.eventId] = action.payload;
        return newState;
      }

      case actions.CREATE_CLUB_CONTENT_SESSION_REQUEST:
      case actions.UPDATE_CLUB_CONTENT_SESSION_REQUEST: {
        const newState = { ...state };
        newState.loading = true;
        newState.error = false;
        return newState;
      }

      case actions.CREATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS:
      case actions.UPDATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS: {
        const newState = { ...state };
        newState.loading = false;
        newState.error = false;
        newState.additionalContentDetails[action.payload.eventId] = action.payload;

        return newState;
      }

      case actions.GET_ADDITIONAL_CONTENT_EVENT_BY_ID_ERROR:
      case actions.CREATE_ADDITIONAL_CONTENT_EVENT_ERROR:
      case actions.UPDATE_ADDITIONAL_CONTENT_EVENT_ERROR:
      case actions.CREATE_CLUB_CONTENT_SESSION_REQUEST_ERROR:
      case actions.UPDATE_CLUB_CONTENT_SESSION_REQUEST_ERROR: {
        const newState = { ...state };
        newState.saveDisabled=false;
        newState.submitDisabled=false;
        newState.editDisabled=false;
        newState.deleteDisabled=false;
        newState.loading = false;
        newState.error = true;
        return newState;
      }

      default: {
        return state;
      }
    }
}
