import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NoteClient, SaveNoteDto, NoteDto } from './bpp.webapi/client';

@Injectable()
export class NotesService {
    constructor(protected apiClient: NoteClient) {
    }

    saveClubRequestNote(note: SaveNoteDto): Observable<NoteDto> {
        return this.apiClient.saveClubRequestNote(note);
    }

    saveLeagueAdminBPPNote(note: SaveNoteDto): Observable<NoteDto> {
        return this.apiClient.saveLeagueAdminNote(note);
    }
}
