import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-construct',
  templateUrl: './under-construct.component.html',
  styleUrls: ['./under-construct.component.scss']
})
export class UnderConstructComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
