import { LocationState } from './state';
import * as actions from './actions';

export type Action = actions.All;
const defaultState = new LocationState();

export function LocationReducer(state = defaultState, action: Action): LocationState {
    switch (action.type) {
        case actions.GET_LOCATIONS: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.GET_LOCATIONS_SUCCESS: {

            return {
                ...state,
                loading: false,
                items: action.payload
            };
        }

        case actions.GET_INTERVIEW_LOCATIONS: {
            return {
                ...state,
                items: [],
                loading: true
            };
        }

        case actions.GET_INTERVIEW_LOCATIONS_SUCCESS: {
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        }

        case actions.CREATE_LOCATION: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.CREATE_LOCATION_SUCCESS: {
            const newState = { ...state };
            newState.items.push(action.payload);
            newState.loading = false;
            return newState;
        }

        case actions.UPDATE_LOCATION: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_LOCATION_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }

        case actions.DELETE_LOCATION: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.DELETE_LOCATION_SUCCESS: {
          return {
            items: [...state.items.filter(x => x.locationId !== action.payload)],
            loading: false
          };
        }

        case actions.CREATE_LOCATION_ERROR:
        case actions.UPDATE_LOCATION_ERROR:
        case actions.DELETE_LOCATION_ERROR:
        case actions.GET_INTERVIEW_LOCATIONS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
    }

    return state;
}
