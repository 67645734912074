import { OnDestroy, OnInit, ComponentFactoryResolver, Component, ViewContainerRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { ConfigService } from '../../../../config/config.service';
import { IComponetByRole, getComponentByRole } from '../../../../helpers/component-by-role';
import { Roles } from '../../../../models/enums';
import { LeagueContentSessionTypesReportComponent } from '../league-content-session-types-report/league-content-session-types-report.component';
import { Subscription } from 'rxjs';
import { ClubContentSessionTypesReportComponent } from '../club-content-session-types-report/club-content-session-types-report.component';

@Component({
  selector: 'app-content-session-types-report-wrapper',
  templateUrl: 'content-session-types-report-wrapper.component.html'
})
export class ContentSessionTypesReportWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) trainingRef;

  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: LeagueContentSessionTypesReportComponent },
    { role: Roles.Admin, component: LeagueContentSessionTypesReportComponent },
    { role: Roles.Club, component: ClubContentSessionTypesReportComponent }
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>, private config: ConfigService) { }


  ngOnInit(): void {
    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
          if (this.config.isDebug()) {
            console.log('Loading trainings for role: ', this.role);
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userInfo && this.userInfo.closed) {
      this.userInfo.unsubscribe();
    }
  }

  loadComponent(role) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.trainingRef.clear();
    const ref = this.trainingRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
