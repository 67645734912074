
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';
import { ClubInterviewService } from 'src/app/services/club-interview.service';
import { GetInterviewDetailsAction } from '../interviews/actions';

@Injectable()
export class ClubInterviewsEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: ClubInterviewService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetClubEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubEventsForClubAction>(actions.GET_CLUB_INTERVIEW_CLUB_EVENTS),
    switchMap(action => this.service.getClubEvents().pipe(
      switchMap((result) => of(new actions.GetClubEventsForClubSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubEventsForClubErrorAction(error));
      }))));

  @Effect()
  GetLeagueEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubInterviewLeagueEventsAction>(actions.GET_CLUB_INTERVIEW_LEAGUE_EVENTS),
    switchMap(action => this.service.getByMatchRound(action.matchRound).pipe(
      switchMap((result) => of(new actions.GetClubInterviewLeagueEventsSuccessAction(action.matchRound, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubInterviewLeagueEventsErrorAction(error));
      }))));

  @Effect()
  GetBroadcasterEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubInterviewBroadcasterEventsAction>(actions.GET_CLUB_INTERVIEW_BROADCASTER_EVENTS),
    switchMap(action => this.service.getByMatchRound(action.matchRound).pipe(
      switchMap((result) => of(new actions.GetClubInterviewBroadcasterEventsSuccessAction(action.matchRound, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubInterviewBroadcasterEventsErrorAction(error));
      }))));

  @Effect()
  CreateFixtureEvent$ = this.action$.pipe(
    ofType<actions.CreateClubInterviewEventAction>(actions.CREATE_CLUB_INTERVIEW_EVENT),
    switchMap(action => this.service.createEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Club interview event created`;
        this.alertService.success(message);
        return of(new actions.CreateClubInterviewEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateClubInterviewEventErrorAction(error));
      }))));

  @Effect()
  UpdateFixtureEvent$ = this.action$.pipe(
    ofType<actions.UpdateClubInterviewEventAction>(actions.UPDATE_CLUB_INTERVIEW_EVENT),
    switchMap(action => this.service.updateEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Club interview status updated.`;
        this.alertService.success(message);
        return of(new actions.UpdateClubInterviewEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateClubInterviewEventErrorAction(error));
      }))));

  @Effect()
  GetMatchInfo$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetMatchInfoAction>(actions.GET_CLUB_INTERVIEW_MATCH_INFO),
    switchMap(action => this.service.getMatchInfo(action.matchId).pipe(
      switchMap((result) => of(new actions.GetMatchInfoActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetMatchInfoActionError(error));
      }))));

  @Effect()
  GetClubInterviewEventDetailsForClub$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubInterviewDetailsForClubAction>(actions.GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB),
    switchMap(action => this.service.getEventForClubByMatchId(action.matchId).pipe(
      switchMap((result) => of(new actions.GetClubInterviewDetailsForClubSuccessAction(action.matchId, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubInterviewDetailsForClubErrorAction(error));
      }))));

  @Effect()
  GetClubInterviewEventDetailsForLeagueBroadcaster$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubInterviewDetailsForLeagueBroadcasterAction>(actions.GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER),
    switchMap(action => this.service.getEventById(action.matchId).pipe(
      switchMap((result) => of(new actions.GetClubInterviewDetailsForLeagueBroadcasterSuccessAction(action.matchId, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubInterviewDetailsForLeagueBroadcasterErrorAction(error));
      }))));

  @Effect()
  CreateClubInterviewRequest$ = this.action$.pipe(
    ofType<actions.CreateClubInterviewRequestAction>(actions.CREATE_CLUB_INTERVIEW_REQUEST),
    switchMap(action => this.service.createRequest(action.payload, action.slotId).pipe(
      switchMap((result) => {
        const message = `Club interview request created`;
        this.alertService.success(message);
        this.store.dispatch(new actions.GetMatchInfoAction(action.matchId));
        return of(new actions.CreateClubInterviewRequestSuccessAction(result, action.slotId));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateClubInterviewRequestErrorAction(error));
      }))));

  @Effect()
  UpdateClubInterviewRequest$ = this.action$.pipe(
    ofType<actions.UpdateClubInterviewRequestAction>(actions.UPDATE_CLUB_INTERVIEW_REQUEST),
    switchMap(action => this.service.updateRequest(action.payload, action.slotId).pipe(
      switchMap((result) => {
        const message = `Club interview request status updated.`;
        this.alertService.success(message);
        return this.service.getEventById(action.matchId).pipe(switchMap((match) => {
          return of(new actions.UpdateClubInterviewRequestSuccessAction(match, action.slotId))
        }));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateClubInterviewRequestErrorAction(error));
      }))));

  @Effect()
  UpdateClubInterviewRequestLeague$ = this.action$.pipe(
    ofType<actions.UpdateClubInterviewRequestLeagueAction>(actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE),
    switchMap(action => this.service.updateRequestLeague(action.payload, action.slotId).pipe(
      switchMap((result) => {
        const message = `Club interview request status updated.`;
        this.alertService.success(message);
        return this.service.getEventById(action.matchId).pipe(switchMap((match) => {
          return of(new actions.UpdateClubInterviewRequestLeagueSuccessAction(match, action.slotId))
        }));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateClubInterviewRequestLeagueErrorAction(error));
      }))));
  
  @Effect()
    UpdateClubInterviewRequestLeagueNote$ = this.action$.pipe(
      ofType<actions.UpdateClubInterviewRequestLeagueNoteAction>(actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE),
      switchMap(action => this.service.updateRequestLeagueNote(action.payload).pipe(
        switchMap((result) => {
          const message = `Club interview request note updated.`;
          this.alertService.success(message);
          return this.service.getEventById(action.matchId).pipe(switchMap((match) => {
            return of(new actions.UpdateClubInterviewRequestLeagueNoteSuccessAction(match))
          }));
        }),
        catchError(error => {
          this.alertService.error(error);
          return of(new actions.UpdateClubInterviewRequestLeagueNoteErrorAction(error));
        }))));
}
