import { Pipe, PipeTransform } from '@angular/core';
import { RequestStatus } from '../services/bpp.webapi/client';
import { StatusHelper } from '../helpers/statuses-helper';

@Pipe({
    name: 'requestStatus',
    pure: false
})
export class RequestStatusPipe implements PipeTransform {

    constructor() { }
    transform(value: RequestStatus): string {
        if (!value) { return ''; }

        return StatusHelper.getRequestStatusText(value);
    }
}
