import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { Roles } from 'src/app/models/enums';
import { User } from 'src/app/models/user.model';
import { ClubMatchInterviewEventDto, MatchInterviewEventDto } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetClubInterviewLeagueEventsAction } from 'src/app/store/club-interview/actions';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-club-interviews-league',
  templateUrl: './club-interviews-league.component.html'
})
export class ClubInterviewsLeagueComponent implements OnInit, OnDestroy {

  private componetDestroyed: Subject<any> = new Subject();
  private isLog = false;
  byGameWeek: { [key: string]: ClubMatchInterviewEventDto[] } = {};
  dataLoaded = false;
  rounds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  round: number = 1;
  user: User;
  isBroadcaster: boolean;
  isSpinner: boolean;

  get matches() {
    if (!this.round || !this.byGameWeek[this.round]) {
      return [];
    }
   // console.log('matches',this.byGameWeek[this.round])
    return this.byGameWeek[this.round];
  }

  constructor(private store: Store<AppState>,private router: Router, private config: ConfigService) { }

  ngOnInit() {
    this.isLog = this.config.isDebug();

    this.store
      .select(state => state.clubInterviews).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Club interviews league:', state.byGameWeek); }
          if (state.byGameWeek) {
            this.dataLoaded = true;
            this.isSpinner = false;
            this.byGameWeek = state.byGameWeek;
          }
        }
      });

    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
          this.isBroadcaster = state.currentUser.role === Roles.Broadcaster || state.currentUser.role === Roles.PLPBroadcaster;
        }
      });


    this.store.select(c=>c.bpp.matchRoundNumbers).subscribe(c=>{
      this.rounds=c;
    })

    this.store.select(x => x.clubInterviews.filter).pipe(take(1)).subscribe(x => {
      this.round = x.matchRound;
      if (this.config.isLfp()){
        this.selectMatchRound(x.matchRound);
      }
      else {
        this.store.dispatch(new GetClubInterviewLeagueEventsAction(this.round));
      }
    });
  }

  selectionChange(item: any) {
    this.round = item;
    this.store.dispatch(new GetClubInterviewLeagueEventsAction(this.round));
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }
  
  selectMatchRound(matchRound: number) {
    this.store.select(c=>c.bpp.currentMatchRoundNumber).subscribe(c => {
      if (matchRound === 1 && c!==matchRound){
        this.round = c;
        this.store.dispatch(new GetClubInterviewLeagueEventsAction(this.round));
      }
      else{
        this.store.dispatch(new GetClubInterviewLeagueEventsAction(this.round));
      }
    });
  }
  
  navigate(item: MatchInterviewEventDto) {
    if (item.awayEvent !== undefined || item.homeEvent !== undefined) {
      if (this.isLog) { console.log('Navigated item:', item); }
      this.router.navigate([`club-interviews/detail/${item.matchId}`]);
    } else {
      if (this.isLog) { console.log('Match has no ICS Events'); }
    }
  }

  getLegendaColor(status) {
    return StatusHelper.getLfpStatusColor(status);
  }

  getRequestsNumber(item: MatchInterviewEventDto) {
    let requestCount = 0;
    if (item.awayEvent && item.awayEvent.requestTotal){
      requestCount+=item.awayEvent.requestTotal.requested;
    }
    if (item.homeEvent && item.homeEvent.requestTotal){
      requestCount+=item.homeEvent.requestTotal.requested;
    }
    return requestCount;
  }
 
  getPeoplesString(item: MatchInterviewEventDto) {
    //peoples: PlayerDto[]
    const list = new Array<string>();
    if (item.awayEvent){
      item.awayEvent.peoples.forEach(p => {
        if (p) {
          if (this.isManager(p)) {
            list.push(p.officialName + ' ' + p.officialSurname);
          } else {
            list.push(p.shortName);
          }
        }
      });  
    }
    
    if (item.homeEvent){
      item.homeEvent.peoples.forEach(p => {
        if (p) {
          if (this.isManager(p)) {
            list.push(p.officialName + ' ' + p.officialSurname);
          } else {
            list.push(p.shortName);
          }
        }
      });
    }
    return list.join(', ');
  }

  getLanguagesString(item: MatchInterviewEventDto) {
    const list = new Array<string>();
    if (item.awayEvent && item.awayEvent.languages){
      item.awayEvent.languages.forEach(p => {
        if (p) {
          list.push(p.name);
        }
      });  
    }
    
    if (item.homeEvent && item.homeEvent.languages){
      item.homeEvent.languages.forEach(p => {
        if (p) {
          list.push(p.name);
        }
      });  
    }
    return list.join(', ');
  }

  hasEvent(item: MatchInterviewEventDto) {
    return item.awayEvent || item.homeEvent;
  }
  
  hasTime(time:moment.Moment){
    return !(time.hours()===0 && time.minutes()===0 && time.seconds()===0);
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }
}

