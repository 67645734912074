import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { AppInsightClientService } from 'src/app/services/app-insight.service';
import { PlayerDto, ClubMatchInterviewEventDto, EventStatus } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetClubEventsForClubAction } from 'src/app/store/club-interview/actions';
import * as helper from '../../../helpers/common';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import * as moment from 'moment';

@Component({
  selector: 'app-club-interviews-club',
  templateUrl: './club-interviews-club.component.html',
  styleUrls: ['./club-interviews-club.component.scss']
})
export class ClubInterviewsClubComponent implements OnInit {
  private componetDestroyed: Subject<any> = new Subject();
  dataLoaded = false;
  isSpinner: boolean;
  isLog = false;
  user: any;
  clubItems: { upcoming: ClubMatchInterviewEventDto[], past: ClubMatchInterviewEventDto[] } = { upcoming: [], past: [] };
  isUpcoming: boolean = true;

  get matches() {
    return this.isUpcoming ? this.clubItems.upcoming : this.clubItems.past;
  }

  constructor(private store: Store<AppState>, private router: Router, private appInsightsService: AppInsightClientService, private config: ConfigService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
        }
      });
    this.store
      .select(state => state.clubInterviews).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Club interviews: ', state.clubItems); }
          this.clubItems = state.clubItems;
          this.dataLoaded = true;
          this.isSpinner = false;
        }
      });
    this.store.dispatch(new GetClubEventsForClubAction());
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

  getClubInterviewEventType(item: ClubMatchInterviewEventDto) {
    if (item.status === 0) { return '-'; }
    return fixtureHelper.getClubInterviewEventType(item.type);
  }

  navigate(item: ClubMatchInterviewEventDto) {
    if (item.fixtureDateUtc.valueOf() >= moment().utc().valueOf()) {
      this.appInsightsService.logEvent('Club interview details', {
        matchId: item.matchId.toFixed()
      });
      this.router.navigate([`club-interviews/detail/${item.matchId}`]);
    } else if (!!item.status) {
      this.appInsightsService.logEvent('club interview details', {
        matchId: item.matchId.toFixed()
      });
      this.router.navigate([`club-interviews/detail/${item.matchId}`]);
    }
  }

  getStatusColor(item: ClubMatchInterviewEventDto) {
    if (!item.status){
      item.status=EventStatus.Unknown;
    }
    return StatusHelper.getLfpStatusColor(item.status);
  }

  getLegendaColor(status) {
    return StatusHelper.getLfpStatusColor(status);
  }

  getPeoplesString(peoples: PlayerDto[]) {
    const list = new Array<string>();
    peoples.forEach(p => {
      if (p) {
        if (this.isManager(p)) {
          list.push(p.officialName + ' ' + p.officialSurname);
        } else {
          list.push(p.shortName);
        }
      }
    });
    return list.join(', ');
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }

}
