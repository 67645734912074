import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, takeUntil } from "rxjs/operators";
import { ClubDto, PagedListOfTeamMemberDto } from "src/app/services/bpp.webapi/client";
import { AppState } from "src/app/store/app.state";
import { GetClubsListAction } from "src/app/store/clubs/actions";
import { GetTeamMembersByClubIdAction, ResetTeamMemberAction } from "src/app/store/team-members/actions";
import { BaseComponent } from "../../base-component/base.component";

@Component({
	selector: 'app-team-members-for-clubs',
	templateUrl: './team-members-for-clubs.component.html'
})
export class TeamMembersForClubsComponent extends BaseComponent implements OnInit {
	@Input() editAllowed: boolean = false;
	isSpinner: boolean = true;
	data: PagedListOfTeamMemberDto;
	clubs: ClubDto[];
	selectedClub: ClubDto = new ClubDto({
		id: 0,
		name: ''
	});
	skip: number = 0;
	take: number = 10;

	constructor(private store: Store<AppState>) {
		super();
	}

	ngOnInit(): void {
		this.store.dispatch(new ResetTeamMemberAction());
		this.store.select(e => e.clubs)
			.pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined))
			.subscribe(e => {
				this.clubs = e.items;

				if (this.clubs.length > 0)
					this.selectClub(this.clubs[0]);
			});
		this.store.select(e => e.teamMembers.loading)
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(e => {
				this.isSpinner = !!e;
			})
		this.store.select(e => e.teamMembers)
			.pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined && e.data !== undefined && e.data.data !== undefined))
			.subscribe(e => {
				this.data = e.data;
			})

		this.store.dispatch(new GetClubsListAction());
	}

	selectClub(club: ClubDto) {
		this.selectedClub = club;

		this.store.dispatch(new GetTeamMembersByClubIdAction(this.selectedClub.id, this.skip, this.take));
	}

	updateData(skip: number, take: number) {
		this.skip = skip;
		this.take = take;
		this.store.dispatch(new GetTeamMembersByClubIdAction(this.selectedClub.id, skip, take));
	}
}
