import {
  IFixtureEventDetailsDto, CreateFixtureEventDto, UpdateFixtureEventDto,
  IMatchInfoDto, FixtureEventDetailsDto, NoteDto, PlayerListDto, EventStatus, LocationDto, PeopleDto, MemberTypeDto, PlayerDto, FixtureEventType
} from '../services/bpp.webapi/client';
import * as moment from 'moment-timezone';
import * as playerHelper from '../helpers/player-helper';
import { ConfigService } from '../config/config.service';

export class FixtureMapper {
  timeZone: any;
  constructor(private config: ConfigService) {
    this.timeZone = config.getTimezone();
  }

  private toUtc(input: moment.Moment) {
    const result = moment(input, 'YYYY-MM-DD HH:mm');
    return result;
  }

  concatDate(date: moment.Moment, time: moment.Moment) {
    const result = moment(date).hour(time.hour()).minute(time.minute());
    return result;
  }

  create(fixture: IFixtureEventDetailsDto): CreateFixtureEventDto {
    const model = new CreateFixtureEventDto();
    model.matchId = fixture.matchId;
    model.peoples = this.mapPlayers(fixture.peoples.players);
    model.locationId = fixture.location.locationId;
    model.clubNote = fixture.clubNote;
    model.arrival = this.toUtc(fixture.arrival);
    model.start = this.toUtc(fixture.start);
    model.languages = fixture.languages;
    model.status = EventStatus.Draft;
    model.type = fixture.type;
    model.isNonLiveInUK = fixture.isNonLiveInUK;
    return model;
  }

  update(fixture: IFixtureEventDetailsDto): UpdateFixtureEventDto {
    const model = new UpdateFixtureEventDto();
    model.id = fixture.eventId;
    model.matchId = fixture.matchId;
    model.arrival = this.toUtc(fixture.arrival);
    model.start = this.toUtc(fixture.start);
    model.peoples = this.mapPlayers(fixture.peoples.players);
    model.locationId = fixture.location.locationId;
    model.languages = fixture.languages;
    model.type = fixture.type;
    model.clubNote = this.updateNote(fixture.clubNote);
    model.isNonLiveInUK = fixture.isNonLiveInUK;
    return model;
  }

  init(match: IMatchInfoDto): FixtureEventDetailsDto {
    const model = new FixtureEventDetailsDto();
    const clubNote = this.getNewNote();

    model.init(
      {
        matchId: match.matchId,
        homeTeam: match.homeTeam,
        awayTeam: match.awayTeam,
        eventId: '',
        peoples: new PlayerListDto({
          players: []
        }),
        clubNote,
        status: EventStatus.Unknown,
        type: FixtureEventType.Standard,
        seasonId: '0',
        location: new LocationDto(),
        createOn: moment().utc(),
        updatedOn: moment().utc(),
        languages: [],
        start: match.date,
        arrival: match.date,
        matchDate: match.date
      } as IFixtureEventDetailsDto);

    // model.start = moment(match.date).tz(this.timeZone); // moment.tz(match.date, this.timeZone);
    // model.arrival = moment(match.date).tz(this.timeZone); // moment.tz(match.date, this.timeZone);
    // model.matchDate = moment(match.date).tz(this.timeZone); // moment.tz(match.date, this.timeZone);

    return model;
  }

  mapPlayers(players: PlayerDto[]): PeopleDto[] {
    return players.map(player => {
      const memberType: MemberTypeDto = playerHelper.isManager(player) ? MemberTypeDto.Staff : MemberTypeDto.Player;
      return new PeopleDto({
        personId: player.id,
        memberType
      });
    }
    );
  }

  updateNote(note: NoteDto): NoteDto | undefined {
    const newNote = this.getNewNote();
    newNote.init({
      message: note && note.message !== undefined ? note.message : '',
      id: note.id
    });
    return newNote;
  }

  getNewNote(): NoteDto {
    const note = new NoteDto();
    note.init({
      date: moment().utc(),
      message: ''
    });
    return note;
  }
}
