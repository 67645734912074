import { Component, OnInit, Input } from '@angular/core';
import { IFilmingMatchDto } from '../../../store/filming/state';

@Component({
  selector: 'app-filming-round',
  templateUrl: './filming-round.component.html',
  styleUrls: ['./filming-round.component.scss']
})
export class FilmingRoundComponent implements OnInit {

  @Input() matches: IFilmingMatchDto[];
  constructor() { }

  ngOnInit() {

  }
}
