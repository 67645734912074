import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { MatchClient, UserClient, UserDetails, UserNotificationType } from '../services/bpp.webapi/client';
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import { Roles } from '../models/enums';
import { MenuService } from '../services/menu.service';

@Injectable()
export class UserInfoService {
  private authService: AuthService;
  constructor(protected oAuthService: AuthService, private userClient: UserClient, private matchClient: MatchClient, private menuService: MenuService) {
    this.authService = oAuthService;
  }

  getUser() {
    return this.authService.getUser();
  }

  getUserMenu() {
    return this.userClient.getUserMenu();
  }

  getUserWithDetails() {
    return forkJoin([this.authService.getUser(), this.userClient.getDetails()]).pipe(mergeMap(([user, details]) => {
      user.companyName = details.company;
      user.companyId = details.id ? details.id.toString() : '';
      if (user.role !== Roles.Broadcaster && user.role !== Roles.PLPBroadcaster) {
        return this.menuService.getMenu(user.role).then(x => {
          return { user, details, userMenu: x };
        });
      }
      if (!details.id || details.id.length === 0) {
        return this.menuService.getMenu(user.role).then(x => {
          return { user, details, userMenu: x };
        })
      }
      return forkJoin([this.matchClient.isCompanyEnabledForNonLiveInUK(details.id), from(this.menuService.getMenu(user.role))]).pipe(map(result => {
        user.isCompanyEnabledForNonLiveInUK = result[0];
        return { user, details, userMenu: result[1] };
      }))
    }));
  }

  getUserDetails(role: Roles) {
    return this.userClient.getDetails().pipe(mergeMap((x: UserDetails) => {
      if (role !== Roles.Broadcaster && role !== Roles.PLPBroadcaster) {
        return of(x);
      }
      if (!x.id || x.id.length === 0) {
        return of(x);
      }
      return this.matchClient.isCompanyEnabledForNonLiveInUK(x.id).pipe(map(result => {
        return { ...x, isCompanyEnabledForNonLiveInUK: result };
      }))
    }));
  }

  updateUserNotificationTypes(types: UserNotificationType[]) {
    return this.userClient.updateNotificationTypes(types);
  }
}
