
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './actions';

import { DashboardService } from '../../services/dashboard.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class DashboardEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: DashboardService,
    protected action$: Actions,
    protected errorService: AlertService
  ) { }

  @Effect()
  GetDashboardItems$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetDashboardListAction>(actions.GET_DASHBOARD_LIST),
    switchMap(action => this.service.getItems(action.pgNum, action.pgSize).pipe(
      switchMap((result) => of(new actions.GetDashboardListActionSuccess(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetDashboardListActionSuccess(error));
      }))));
}
