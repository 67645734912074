import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    FixtureClient, IUpdateFixtureEventDto, UpdateFixtureEventDto, IWeekDto,
    FixtureRequestClient, TrainingParticipantClient, IMatchFixtureEventDto
} from './bpp.webapi/client';
import { AuthService } from '../authentication/auth.service';
import { GroupByPipe } from '../pipes/groupby.pipe';

import { isFutureMatch, isPastMatch } from '../helpers/fixture-helper';

export const unauthorisedCallback = () => { throw new Error('unauthorised'); };

@Injectable()
export class FixtureService {
    constructor(protected authService: AuthService,
        protected eventClient: FixtureClient,
        protected requestClient: FixtureRequestClient,
        protected participantClient: TrainingParticipantClient,
        protected groupby: GroupByPipe) {
    }

    getLeagueEvents(matchRound, nonLiveInUK): Observable<IWeekDto[]> {
        return this.eventClient.getAllForLeague(matchRound, nonLiveInUK);
    }

    getBroadcasterEvents(matchRound, nonLiveInUK) {
        return this.eventClient.getAllForBroadcaster(matchRound, nonLiveInUK);
    }

    getClubEvents(upcomingMatches, nonLiveInUK): Observable<Array<IMatchFixtureEventDto>> {
        return this.eventClient.getAll(nonLiveInUK, upcomingMatches);
    }

    getEventById(id) {
        return this.eventClient.get(id);
    }

    createEvent(model: any) {
        return this.eventClient.post(model);
    }

    updateEvent(model: IUpdateFixtureEventDto) {

        return this.eventClient.put(model as UpdateFixtureEventDto);
    }

    getEventsByMatchId(matchId, nonLiveInUK) {
        return this.eventClient.getMatchEvents(matchId, nonLiveInUK);
    }

    getRequestById(id) {
        return this.requestClient.get(id);
    }

    createRequest(model) {
        return this.requestClient.post(model);
    }

    updateRequest(model) {
        return this.requestClient.put(model);
    }

    getParticipants() {

        return this.participantClient.getAll();
    }

    createParticipant(model) {
        return this.participantClient.post(model);
    }

    getParticipantsByCompany(companyId: string) {

        return this.participantClient.getByCompany(companyId);
    }

    getMatchInfo(matchId) {
        return this.eventClient.getMatchInfo(matchId);
    }

    getUpcomingPastSorted(payload: IWeekDto[]) {

        const upcomingFixtures = payload.reduce((acc, item) => {
            const matches = item.matches.filter(match => isFutureMatch(match.date));
            if (matches.length > 0) {
                const updated: IWeekDto = { ...item, matches };
                acc.push(updated);
            }
            return acc;
        }, []) as IWeekDto[];

        const pastFixtures = payload.reduce((acc, item) => {
            const matches = item.matches.filter(match => isPastMatch(match.date));
            if (matches.length > 0) {
                const updated: IWeekDto = { ...item, matches };
                acc.push(updated);
            }
            return acc;
        }, []) as IWeekDto[];

        const byGameWeek = {
            upcoming: upcomingFixtures.sort((a, b) => a.weekNumber >= b.weekNumber ? 1 : -1),
            past: pastFixtures.sort((a, b) => a.weekNumber < b.weekNumber ? 1 : -1),
        };

        return byGameWeek;
    }
}
