import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../config/config.service';

@Component({
  selector: 'app-button-news',
  templateUrl: './button-news.component.html',
  styleUrls: ['./button-news.component.scss']
})
export class ButtonNewsComponent implements OnInit {

  url: String;
  news;
  writeNew;
  newsLink: string;

  constructor(public configService: ConfigService) { }

  ngOnInit() {
    this.news = this.configService.get('features.news');
    this.newsLink = this.configService.get('newsLink');
    this.writeNew = this.configService.get('features.news.writeNewText');
  }

}
