import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { GetNewsByIdAction } from '../../../../store/news/actions';
import { ConfigService } from '../../../../config/config.service';
import { AuthService } from '../../../../authentication/auth.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {

  private newsSubscription: Subscription;

  oneNews;
  slug;
  imageUrl: String = '';
  settings;

  constructor(private router: Router, private route: ActivatedRoute, protected store: Store<AppState>,
    private configService: ConfigService, private userService: AuthService) { }

  ngOnInit() {
    this.settings = this.configService.get('features.news');

    this.route.params.subscribe((params) => {
      this.slug = params['id'];
      this.store.dispatch(new GetNewsByIdAction(this.slug));
    });

    this.newsSubscription = this.store
      .select(state => state.news)
      .subscribe(result => {
        if (result && result.getLoading === false && result.detailNews) {
          this.oneNews = result.detailNews;
        }
      });
  }

  getUrl(url) {
    return url.replace('{formatName}', '_detail');
  }

  loadFile(item) {
    fetch(item.downloadUrl, {
      headers: new Headers({
        Origin: location.origin
      }),
      mode: 'cors'
    })
      .then(response => response.blob())
      .then(blob => {
        const a = document.createElement('a');
        a.download = item.originalFileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      })
      .catch(e => console.error(e));
  }

  backClick() {
    const prevUrl = this.userService.getPreviousUrl();
    prevUrl.indexOf('detail') !== -1 ? this.router.navigate([`/news`]) : this.router.navigate([prevUrl]);
  }

  ngOnDestroy(): void {
    if (this.newsSubscription) {
      this.newsSubscription.unsubscribe();
    }
  }

  getColumns(row) {
    const result = [];
    if (row === undefined) return result;

    const allKeys = Object.keys(row);
    for (const index in allKeys) {
      result.push(allKeys[index]);
    }

    return result;
  }

  getRowValues(row) {
    const result = [];

    const allKeys = Object.keys(row);
    for (const index in allKeys) {
      result.push(row[allKeys[index]]);
    }

    return result;
  }
}
