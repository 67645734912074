import { Action } from '@ngrx/store';
import { IUpdateFixtureEventDto, IWeekDto, IMatchFixtureEventDto, IMatchInfoDto } from '../../services/bpp.webapi/client';

export const CREATE_FIXTURE_EVENT = 'create_fixture_event';
export const CREATE_FIXTURE_EVENT_SUCCESS = 'create_fixture_event_success';
export const CREATE_FIXTURE_EVENT_ERROR = 'create_fixture_event_error';

export const UPDATE_FIXTURE_EVENT = 'update_fixture_event';
export const UPDATE_FIXTURE_EVENT_SUCCESS = 'update_fixture_event_success';
export const UPDATE_FIXTURE_EVENT_ERROR = 'update_fixture_event_error';

export const GET_CLUB_EVENTS = 'get_club_events';
export const GET_CLUB_EVENTS_SUCCESS = 'get_club_events_success';
export const GET_CLUB_EVENTS_ERROR = 'get_club_events_error';

export const GET_LEAGUE_EVENTS = 'get_league_events';
export const GET_LEAGUE_EVENTS_SUCCESS = 'get_league_events_success';
export const GET_LEAGUE_EVENTS_ERROR = 'get_league_events_error';

export const GET_BROADCASTER_EVENTS = 'get_broadcaster_events';
export const GET_BROADCASTER_EVENTS_SUCCESS = 'get_broadcaster_events_success';
export const GET_BROADCASTER_EVENTS_ERROR = 'get_broadcaster_events_error';

export const GET_REQUEST_BYID = 'get_request_byid';
export const GET_REQUEST_BYID_SUCCESS = 'get_request_byid_success';
export const GET_REQUEST_BYID_ERROR = 'get_request_byid_error';

export const GET_MATCH_INFO = 'get_match_info';
export const GET_MATCH_INFO_SUCCESS = 'get_match_info_success';
export const GET_MATCH_INFO_ERROR = 'get_match_info_error';

export class GetMatchInfoAction implements Action {
    readonly type = GET_MATCH_INFO;
    constructor(public matchId: string) { }
}

export class GetMatchInfoActionSuccess implements Action {
    readonly type = GET_MATCH_INFO_SUCCESS;
    constructor(public payload: IMatchInfoDto) { }
}

export class GetMatchInfoActionError implements Action {
    readonly type = GET_MATCH_INFO_ERROR;
    constructor(public payload: any) { }
}

export class CreateFixtureEventAction implements Action {
    readonly type = CREATE_FIXTURE_EVENT;
    constructor(public payload: any) {
    }
}

export class CreateFixtureEventSuccessAction implements Action {
    readonly type = CREATE_FIXTURE_EVENT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class CreateFixtureEventErrorAction implements Action {
    readonly type = CREATE_FIXTURE_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateFixtureEventAction implements Action {
    readonly type = UPDATE_FIXTURE_EVENT;
    constructor(public payload: IUpdateFixtureEventDto) {
    }
}

export class UpdateFixtureEventSuccessAction implements Action {
    readonly type = UPDATE_FIXTURE_EVENT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class UpdateFixtureEventErrorAction implements Action {
    readonly type = UPDATE_FIXTURE_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class GetClubEventsAction implements Action {
    readonly type = GET_CLUB_EVENTS;
    constructor(public isUpcoming: boolean, public nonLiveInUK: boolean) { }
}

export class GetClubEventsSuccessAction implements Action {
    readonly type = GET_CLUB_EVENTS_SUCCESS;
    constructor(public isUpcoming: boolean, public payload: Array<IMatchFixtureEventDto>) {
    }
}

export class GetClubEventsErrorAction implements Action {
    readonly type = GET_CLUB_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetLeagueEventsAction implements Action {
    readonly type = GET_LEAGUE_EVENTS;
    constructor(public matchRound: number, public nonLiveInUK: boolean) { }
}

export class GetLeagueEventsSuccessAction implements Action {
    readonly type = GET_LEAGUE_EVENTS_SUCCESS;
    constructor(public matchRound: number, public payload: IWeekDto) {
    }
}

export class GetLeagueEventsErrorAction implements Action {
    readonly type = GET_LEAGUE_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetBroadcasterEventsAction implements Action {
    readonly type = GET_BROADCASTER_EVENTS;
    constructor(public matchRound: number, public nonLiveInUK: boolean) { }
}

export class GetBroadcasterEventsSuccessAction implements Action {
    readonly type = GET_BROADCASTER_EVENTS_SUCCESS;
    constructor(public matchRound: number, public payload: IWeekDto) {
    }
}

export class GetBroadcasterEventsErrorAction implements Action {
    readonly type = GET_BROADCASTER_EVENTS_ERROR;
    constructor(public payload: any) { }
}


export type All = GetClubEventsAction
    | GetClubEventsSuccessAction
    | CreateFixtureEventAction
    | CreateFixtureEventSuccessAction
    | CreateFixtureEventErrorAction
    | GetClubEventsErrorAction
    | UpdateFixtureEventAction
    | UpdateFixtureEventSuccessAction
    | UpdateFixtureEventErrorAction
    | GetLeagueEventsAction
    | GetLeagueEventsSuccessAction
    | GetLeagueEventsErrorAction
    | GetBroadcasterEventsAction
    | GetBroadcasterEventsSuccessAction
    | GetBroadcasterEventsErrorAction
    | GetMatchInfoAction
    | GetMatchInfoActionSuccess
    | GetMatchInfoActionError
    ;
