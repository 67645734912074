import { IPlayerListDto, ILanguageDto, PlayerDto } from '../../services/bpp.webapi/client';
import { AppState } from '../app.state';
import { createSelector } from '@ngrx/store';

export class BppState {
    loading: boolean;
    error: boolean;
    apiVersion: string;
    playersByTeam: { [teamIs: string]: PlayerDto[] };
    clubPlayers: IPlayerListDto;
    languagesList: Array<ILanguageDto>;
    presentationUrl: string;
    currentMatchRoundNumber:number;
    matchRoundNumbers:number[];
    constructor() {
        this.playersByTeam = {};
        this.playersByTeam = {};
        this.languagesList = [];
    }
}

const getLanguagesResult = (state: AppState) => state.bpp.languagesList;
const getState = (state: AppState) => state.bpp;

export const selectLanguagesByIds = (ids: string[]) =>
    createSelector(
        getLanguagesResult,
        result => {
            if (result && result.length > 0) {
                return result.filter(item => ids.includes(item.languageId));
            }
        }
    );

export const selectLanguagesLoading = () =>
    createSelector(
        getState,
        result => result.loading
    );
