import { Component, OnInit, OnDestroy, TemplateRef, ComponentFactoryResolver, Input, ViewContainerRef, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';

import { Subscription } from 'rxjs';
import { ConfigService } from '../../config/config.service';
import { Roles } from '../../models/enums';
import { TITLEBAR_ICONS_REPLACEMENTS } from '../menu/icon-replacements';
import { MenuService } from 'src/app/services/menu.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']

})
export class TitlebarComponent implements OnInit, OnDestroy {
  templateRef: TemplateRef<any>;
  protected route: ActivatedRoute;
  protected data: any;
  context: any;
  ctx: any;
  protected userSubscription: Subscription;

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) elementRef;
  @Input() rightElement: any = '';
  @Input() rightElementProps: any = {};

  isHtml: Boolean = false;
  dataHTML;

  findNode(node: any, route: string): any {
    let result = { icon: null, desc: null };

    if (node.route === route) {
      result.icon = node.icon;
      result.desc = node.titlebar || node.desc;
    } else {
      if (node.Items && node.Items.length > 0) {
        for (const item of node.Items) {
          if (result.desc == null) {
            result = this.findNode(item, route);
          }
        }
      }
    }

    return result;
  }

  constructor(route: ActivatedRoute, 
    protected store: Store<AppState>, private menuService: MenuService,
    protected config: ConfigService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sanitizer: DomSanitizer) {
    this.route = route;
    this.ctx = {
      node: {
        icon: '',
        desc: ''
      }
    };
  }

  ngOnInit(): void {
    this.checkComponent();
    this.userSubscription = this.store.select(state => state.user).subscribe(state => {
      if (state && !state.loading) {
        let node = state.userMenu;

        this.route.parent.parent.url.subscribe(segments => {
          let url = '/' + segments.join('/');

          let index = url.indexOf('detail');
          if (index !== -1) {
            url = url.substr(0, index + 6);
          }
          index = url.indexOf('create');
          if (index !== -1) {
            url = url.substr(0, index + 6);
          }

          this.ctx.node = this.findNode(node, url);
          if (this.ctx.node.icon) {
            const found = TITLEBAR_ICONS_REPLACEMENTS.find(item => item.original === this.ctx.node.icon);
            if (found) {
              this.ctx.node.icon = found.replacement;
            }
          }
        });
      }
    });

  }

  checkComponent() {
    if (typeof this.rightElement === 'string') {
      this.dataHTML = this.sanitizer.bypassSecurityTrustHtml(this.rightElement);
      this.isHtml = true;
    } else {
      this.loadComponent();
    }
  }

  loadComponent() {
    this.elementRef.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.rightElement);
    const ref = this.elementRef.createComponent(factory);
    if (this.rightElementProps) {
      Object.assign(ref.instance, this.rightElementProps);
    }
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
