import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ITimePicker } from '../components/common/combo-picker/combo-picker.component';

// MonthId started from 1 - Jan...
export function getMinDateByMonth(monthId: number) {
    const t = moment().month(monthId - 1);
    return getMinDate(t);
}
export function getMaxDateByMonth(monthId: number) {
    const t = moment().month(monthId - 1);
    return getMaxDate(t);
}

export function getMinDate(date: moment.Moment) {
    const draft = moment(date).startOf('month');
    const minDate = {
        year: draft.year(),
        month: draft.month() + 1,
        day: draft.date()
    };
    return minDate;
}

export function getMaxDate(date: moment.Moment) {
    const draft = moment(date).endOf('month');
    const maxDate = {
        year: draft.year(),
        month: draft.month() + 1,
        day: draft.date()
    };
    return maxDate;
}

export function getLfpMaxDate(date: moment.Moment) {
    const maxDate = {
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
    };
    return maxDate;
}


export function getMonthName(monthId): string {
    return moment().month(monthId - 1).format('MMMM');
}

export function getHoursMinutes(source: moment.Moment, timeZone?: string): ITimePicker {
    if (source && timeZone) {
        return {
            hour: momentTz.utc(source).tz(timeZone).hour(),
            minute: momentTz.utc(source).tz(timeZone).minute()
        };
    } else if (source) {
        return {
            hour: moment.utc(source).hour(),
            minute: moment.utc(source).minute()
        };
    } else {
        return {
            hour: 0,
            minute: 0
        };
    }
}


export function getTimeString(duration: moment.Duration): string {
    const hours = duration.hours() > 9 ? duration.hours().toString() : '0' + duration.hours().toString();
    const minutes = duration.minutes() > 9 ? duration.minutes().toString() : '0' + duration.minutes().toString();
    return `${hours} : ${minutes}`;
}

export function getTimeStringForTimePicker(duration: ITimePicker): string {
    const hours = duration.hour > 9 ? duration.hour.toString() : '0' + duration.hour.toString();
    const minutes = duration.minute > 9 ? duration.minute.toString() : '0' + duration.minute.toString();
    return `${hours} : ${minutes}`;
}
