import { Action } from '@ngrx/store';
import { Section } from './section';
import { ITrainingDetailsDto, RequestStatus, IParticipantDto, ParticipantDto, NoteDto } from '../../../../services/bpp.webapi/client';
import * as moment from 'moment-timezone';
import { StatusHelper } from '../../../../helpers/statuses-helper';

export class ViewState {
  public isNew = false;
  public lastSavedOn = '';
  public lastSavedBy = '';
  public date: moment;
  public arrival: moment;
  public startTime: moment;
  public location = '';
  public locationData = '';
  public trainingId = '';
  public showDeleteButton = false;
  public showStatus = true;
  public clubName = '';
  public sessionMonth = '';
  public imagePath = '';
  public note = '';
  public clubNote: NoteDto;
  public leagueNote: NoteDto;

  public sections = new Array<Section>();
  public reporters = Section.reporters;
  public cameras = Section.cameras;
  public producers = Section.producers;
  public others = Section.others;
  public participants = new Array<IParticipantDto>();
  public editState = false;

  setTraining(training: ITrainingDetailsDto) {
    this.lastSavedOn = moment.utc(training.updatedOn).local().format('DD/MM/YYYY | HH:mm');
    this.lastSavedBy = training.updatedByName;
    this.date = moment.utc(training.start);
    this.arrival = moment.utc(training.arrival);
    this.startTime = moment.utc(training.start);
    this.location = training.location.title;
    this.locationData = training.location.address1 + (training.location.address2 ? training.location.address2 : '');
    this.trainingId = training.id;
    this.clubName = training.club;
    this.clubNote = training.clubNote;
    this.leagueNote = training.leagueNote;
    this.imagePath = '../../../../assets/teams/48/t' + training.clubId + '.png';

    this.sessionMonth = moment.utc(training.arrival).format('MMMM');

    this.sections.push(this.reporters);
    this.cameras.cameraOperator = training.requests && training.requests[0] && training.requests[0].cameraOperator;
    this.sections.push(this.cameras);
    this.sections.push(this.producers);
    this.sections.push(this.others);
  }

  public getAction(status: RequestStatus): Action {
    return undefined;
  }

  getParticipants(): Array<ParticipantDto> {
    const result = new Array<ParticipantDto>();

    for (const section of this.sections) {
      for (const item of section.participants) {
        result.push(new ParticipantDto(item));
      }
    }

    return result;
  }

  isReadOnly() {
    return true;
  }

  isCanChange() {
    return !StatusHelper.isDatePassed(this.startTime);
  }

  toggleState() {
    if (this.isCanChange() && this.editState === false) {
      this.editState = true;
    } else {
      this.editState = false;
    }
  }
}
