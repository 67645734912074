import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../base-component/base.component';
import {AddLocationComponent} from '../common/add-location/add-location.component';
import {ClubDto, CreateLocationDto, LocationDetailsDto, LocationDto, LocationTypeDto} from '../../services/bpp.webapi/client';
import {CreateLocationAction, DeleteLocationAction, GetInterviewLocationsAction, GetLocationsAction, UpdateLocationAction} from '../../store/location/actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GetAvailableLocationTypesAction, GetLocationTypesAction} from '../../store/location-types/actions';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: []
})
export class LocationsComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;

  constructor(private store: Store<AppState>, private modalService: NgbModal) {
    super();
  }

  modalSubscription;
  modalDelSubscription;

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });

    this.store.dispatch(new GetLocationTypesAction());
  }

  // Modal Location
  openAddLocation(leagueMode: boolean, clubId: number) {
    this.store.dispatch(new GetAvailableLocationTypesAction(clubId));

    const modalRef = this.modalService.open(AddLocationComponent);
    modalRef.componentInstance.isLeague = leagueMode;
    this.modalSubscription = modalRef.componentInstance.save.subscribe(location => this.saveLocation(location, leagueMode, clubId));
  }

  openUpdateLocation(item: LocationDto, clubId: number) {
    const modalRef = this.modalService.open(AddLocationComponent);
    modalRef.componentInstance.id = item.locationId;
    this.modalSubscription = modalRef.componentInstance.update.subscribe(location => this.updateLocation(location));
    this.modalDelSubscription = modalRef.componentInstance.delete.subscribe(location => this.deleteLocation(location, clubId));
  }

  saveLocation(location: CreateLocationDto, leagueMode: boolean, clubId: number) {
    location.leagueType = leagueMode;
    location.clubId = clubId;
    this.store.dispatch(new CreateLocationAction(location));
    this.modalSubscription.unsubscribe();
  }

  updateLocation(location: LocationDetailsDto) {
    this.store.dispatch(new UpdateLocationAction(location));

    this.modalSubscription.unsubscribe();
    this.modalDelSubscription.unsubscribe();
  }

  deleteLocation(location: LocationDetailsDto, clubId: number) {
    this.store.dispatch(new DeleteLocationAction(location.locationId, clubId));

    this.modalSubscription.unsubscribe();
    this.modalDelSubscription.unsubscribe();
  }

  handleAddLocation(e: ILocationEventArgs) {
    this.openAddLocation(e.leagueType, e.clubId);
  }

  handleEditLocation(e: ILocationEventArgs) {
    this.openUpdateLocation(e.location, e.clubId);
  }
}

export interface ILocationEventArgs {
  location: LocationDto;
  leagueType: boolean;
  clubId: number;
}
