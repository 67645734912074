import { IStoreData, AppState } from '../app.state';
import { Moment } from 'moment';
import { createSelector } from '@ngrx/store';
import {
  NoteDto,
  EventStatus,
  IInterviewDto,
  ILocationDto,
  ILanguageDto,
  InterviewsRequestsInfoDto,
  InterviewTypeDto
} from '../../services/bpp.webapi/client';
import { ITimePicker } from '../../components/common/combo-picker/combo-picker.component';
import { FxRequest } from '../fx-request/state';
import { ILastSaved } from '../../models/last-saved.interface';
import * as moment from 'moment';

export class InterviewsState {
    created: ICreatedInterview;
    collection: IInterviews;
    details: IDetailsById;
    past: number[];

    constructor() {
        this.collection = {
            items: [],
            loading: false
        };

        this.details = {
            byId: {},
            loading: false
        };
        this.past = [];
        this.created = {
          interviewId: null,
          loading: false,
        };
    }
}

export interface ICreatedInterview extends IStoreData {
  interviewId: string;
}

export interface IInterviews extends IInterviewsResult, IStoreData {

}

export interface IInterviewsResult {
  items: Array<IInterviewDto>;
}

export interface IDetailsById extends IStoreData {
    byId: {
        [key: string]: IInterviewDetails
    };
}

export interface IInterviewDetails extends ILastSaved {
    interviewId: string;
    arrivalDate: Moment;
    startDate: Moment;
    arrivalTime: ITimePicker;
    startTime: ITimePicker;
    location: ILocationDto;
    languages: ILanguageDto[];
    leagueNote: NoteDto;
    interviewee: string;
    status: EventStatus;
    interviewType: InterviewTypeDto;
    requests?: FxRequest[];
    requestsInfo?: InterviewsRequestsInfoDto;
    requestsInfoStr: string;
    isPast?: boolean;
    duration?: moment.Duration;
}

const getDetailsResult = (state: AppState) => state.interviews.details.byId;
const getDetailsState = (state: AppState) => state.interviews.details;

export const selectInterviewDetailsById = (interviewId: number) =>
    createSelector(
        getDetailsResult,
        result => {
            if (result[interviewId]) {
                return result[interviewId];
            }
        }
    );

export const selectInterviewDetailsLoading = () =>
    createSelector(
        getDetailsState,
        result => result.loading
    );
