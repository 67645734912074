import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IPagination } from "src/app/models/paginator.interface";
import { PagedListOfTeamMemberDto, TeamMemberDto } from "src/app/services/bpp.webapi/client";
import { BaseComponent } from "../../base-component/base.component";
import { TeamMemberBiosDialogComponent } from "../team-member-bios-dialog/team-member-bios-dialog.component";

@Component({
	selector: 'app-team-members-list',
	templateUrl: './team-members-list.component.html',
	styleUrls: ['./team-members-list.component.scss']
})
export class TeamMembersListComponent extends BaseComponent implements OnInit, OnChanges {
	@Input()
	data: PagedListOfTeamMemberDto;
	@Input()
	editAllowed: boolean = false;

	page: IPagination = {
		pageNumber: 1,
		pageSize: 10,
		totalCount: 0
	};


	@Input('onUpdateData')
	onUpdateData: (skip: number, take: number) => {};

	constructor(private router: Router, private modalService: NgbModal) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data && changes.data.currentValue) {
			this.page.totalCount = changes.data.currentValue.totalCount;
		}
	}

	ngOnInit(): void {
	}

	rowClick(item: TeamMemberDto) {
		if (!this.editAllowed) return;

		this.router.navigate([`team-member/details/${item.id}`]);
	}

	hasBios(item: TeamMemberDto) {
		return item.bios !== undefined && item.bios !== null && item.bios !== '';
	}

	showContent(item: TeamMemberDto) {
		let modal = this.modalService.open(TeamMemberBiosDialogComponent, { ariaLabelledBy: 'modal-title' });

		modal.componentInstance.bios = item.bios;
		modal.result.then((result) => { });
	}

	onPageChanged(page: IPagination) {
		const skip = (page.pageNumber - 1)* page.pageSize;
		this.onUpdateData(skip, page.pageSize);
	}
}
