import { PersonReportDataDto, ContentSessionTypeDataDto, InterviewsSummaryDataDto, BroadcasterRequestsDataDto } from '../../services/bpp.webapi/client';

export class ReportState {
    loading: boolean;
    items: Array<PersonReportDataDto>;
    contentSessionTypeItems: Array<ContentSessionTypeDataDto>;
    broadcasterRequestsItems: Array<BroadcasterRequestsDataDto>;
    interviewsSummaryItems: Array<InterviewsSummaryDataDto>;
    constructor() {
        this.loading = false;
        this.items = [];
        this.contentSessionTypeItems = [];
        this.broadcasterRequestsItems = [];
    }
}
