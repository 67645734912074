
import { takeUntil, filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { VgAPI } from 'videogular2/core';
import { getCookie, setCookie } from 'tiny-cookie';
import { Router } from '@angular/router';
import { ConfigService } from '../../config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Subject } from 'rxjs';
import { Roles } from '../../models/enums';
import { TextService } from '../../services/text.service';
import { IAboutPageInterface } from '../../models/about.interface';
import { ContactsService, IContactsInfo, IPerson } from '../../services/contacts.service';
import { GetPresentationUrlAction } from '../../store/bpp/actions';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  @ViewChild('fullscreen', { static: false })
  fullscreen: ElementRef;
  @ViewChild('media', { static: false })
  media: ElementRef;

  singleVideo: any;
  public isFullscreen = false;
  private vgApi: VgAPI;
  public videoSource = '';
  public isBroadcaster: boolean;
  content: IAboutPageInterface;
  isLoading: boolean;
  cookieName;
  contacts: IPerson[];
  sourceIsReady = false;

  constructor(
    protected router: Router,
    protected configService: ConfigService,
    protected store: Store<AppState>,
    private textService: TextService,
    private contactsService: ContactsService,
  ) {
    this.cookieName = this.configService.get('firstLoginCookieName');
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
  ngOnInit() {
    this.store.dispatch(new GetPresentationUrlAction());
    this.store.select(s => s.bpp.presentationUrl)
      .pipe(takeUntil(this.componentDestroyed), filter(r => r !== undefined))
      .subscribe(res => {
        this.videoSource = res;
        this.sourceIsReady = true;
      });

    this.contactsService.get().subscribe(res => this.contacts = res ? res.persons : []);

    this.store
      .select(state => state.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.textService.getAboutPageTexts(state.currentUser.role)
            .subscribe(responce => {
              if (responce && responce.texts && responce.texts.length > 0) {
                this.content = responce;
              }
            });
        }
      });

    if (this.media && this.media.nativeElement) {
      this.media.nativeElement.autoplay = false;
    }
    const cookie = getCookie(this.cookieName) === 'true';
    if (!cookie) {
      if (this.media && this.media.nativeElement) {
        this.media.nativeElement.autoplay = true;
      }
    }
  }

  goFullscreen() {
    this.vgApi.fsAPI.toggleFullscreen();
  }

  onPlayerReady(api: VgAPI) {
    this.vgApi = api;
    const cookie = getCookie(this.cookieName) === 'true';
    if (!cookie) {
      this.vgApi.getDefaultMedia().subscriptions.ended.subscribe(() => {
        this.isFullscreen = !this.isFullscreen;
      });
      this.vgApi.getDefaultMedia().subscriptions.canPlay.subscribe(() => {
        const promise = this.media.nativeElement.play();
        if (promise !== undefined) {
          promise
            .then(() => {
              console.log('autoplay started!');
              // setCookie(this.cookieName, true, { expires: '100Y' });
            })
            .catch(err => {
              alert(
                'Your browser prevented this video to autoplay, please start it manually'
              );
            });
        }
      });
      setTimeout(() => {
        this.isFullscreen = !this.isFullscreen;
        setTimeout(() => {
          setCookie(this.cookieName, true, { expires: '100Y' });
        }, 2000);
      });
      if (this.fullscreen && this.fullscreen.nativeElement) {
        if (!this.isFullscreen) {
          this.vgApi.fsAPI.init(this.fullscreen.nativeElement, null);
        }
      }
    }
  }
}
