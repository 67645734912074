
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './actions';

import { MatchesService } from '../../services/matches.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class MatchesEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: MatchesService,
    protected action$: Actions,
    private errorService: AlertService
  ) { }

  @Effect()
  GetAllMatches$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetMatchesListAction>(actions.GET_MATCHES_LIST),
    switchMap(action => this.service.getAllMatches().pipe(
      switchMap((result) => of(new actions.GetMatchesListActionSuccess(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetMatchesListActionError(error));
      }))));

  @Effect()
  UpdateMatch$: Observable<Action> = this.action$.pipe(
    ofType<actions.UpdateMatchAction>(actions.UPDATE_MATCH),
    switchMap(action =>
      this.service.updateMatch(action.item).pipe(
        switchMap(result => of(new actions.UpdateMatchSuccessAction())),
        catchError(error => {
          this.errorService.error(error);

          return of(new actions.UpdateMatchErrorAction(error));
        })))
  );
}
