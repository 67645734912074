import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { ButtonNewsComponent } from '../../button-news/button-news.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-test-route',
  templateUrl: './test-route.component.html',
  styleUrls: ['./test-route.component.css']
})
export class TestRouteComponent implements OnInit {

  protected adalConfig: any;
  private unsubscribe: Subject<boolean> = new Subject();
  role: Roles;

  // isProgramme: Boolean = false;
  rightElement: any = '';
  rightElementProps: any = {};

  constructor(
    protected router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.store
      .select(state => state.user.currentUser).pipe(
      takeUntil(this.unsubscribe))
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
        }
      });

    if (this.router.url.indexOf('/news') !== -1 && this.role === Roles.League) {
      this.rightElement = ButtonNewsComponent;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

}
