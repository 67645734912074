
import { takeUntil, mergeMap, filter, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { selectInterviewDetailsById, selectInterviewDetailsLoading, IInterviewDetails } from '../../../store/interviews/state';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetInterviewDetailsAction } from '../../../store/interviews/actions';
import { ITimePicker } from '../../common/combo-picker/combo-picker.component';
import { ConfigService } from '../../../config/config.service';
import { selectCurrentUserRole, selectUserIsLoading } from '../../../store/user/state';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { BaseComponent } from '../../base-component/base.component';
import { GetInterviewLocationsAction } from '../../../store/location/actions';
import { selectLanguagesByIds, selectLanguagesLoading } from '../../../store/bpp/state';
import { selectLocationById, selectLocationLoading } from '../../../store/location/state';
import { EventStatus, InterviewTypeDto } from '../../../services/bpp.webapi/client';
import { FxRequest } from '../../../store/fx-request/state';
import { getLastSavedText, isGuidNotEmpty } from '../../../helpers/common';
import { getMinDate, getMaxDate } from '../../../helpers/date-helper';
import { GetInterviewTypesAction } from 'src/app/store/interview-types/actions';

export enum EInterviewView {
  Undefined = 0,
  ClubRead,
}

@Component({
  selector: 'app-interview-details-club',
  templateUrl: './interview-details-club.component.html'
})
export class InterviewDetailsClubComponent extends BaseComponent implements OnInit {
  details$: Observable<IInterviewDetails>;
  isLoading$: Observable<boolean>;
  data: IInterviewDetails;
  broadcasterRequest: FxRequest;
  original: IInterviewDetails;
  lastSaved: string;
  minStart: Subject<ITimePicker> = new Subject();
  maxStart: Subject<ITimePicker> = new Subject();
  minEnd: Subject<ITimePicker> = new Subject();
  maxEnd: Subject<ITimePicker> = new Subject();
  isDebug: boolean;
  editMode = false;
  EInterviewView = EInterviewView;
  interviewView: EInterviewView;
  isPastEvent = false;
  minDate: { year: number; month: number; day: number; };
  maxDate: { year: number; month: number; day: number; };
  timeZone: string;
  isLfp: boolean;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public config: ConfigService,) {
    super();
    this.isLfp = config.isLfp();
    this.isDebug = config.isDebug();
    this.timeZone = config.getTimezone();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        const interviewId = params['id'];
        this.store.dispatch(new GetInterviewDetailsAction(interviewId));
        this.details$ = this.store.select(selectInterviewDetailsById(interviewId)).pipe(filter(x => x !== undefined));
      }
    });

    const userRole$ = this.store.select(selectCurrentUserRole());
    const combined$ = this.details$.pipe(
      mergeMap((details) => {
        const languages$ = this.store.select(selectLanguagesByIds(details.languages.map(l => l.languageId)));
        const location$ = this.store.select(selectLocationById(details.location.locationId));
        return combineLatest([of(details), languages$, location$, userRole$]);
      }));

    combined$.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(([details, languages, location, role]) => {
        this.isPastEvent = details.isPast;
        this.minDate = getMinDate(details.arrivalDate);
        this.maxDate = getMaxDate(details.arrivalDate);
        this.data = cloneDeep(details);
        this.data.languages = languages || [];
        this.data.location = location ? cloneDeep(location) : details.location;
        this.original = cloneDeep(this.data);
        this.interviewView = EInterviewView.ClubRead;
        this.lastSaved = this.getLastSaved();
      });

    const dataLoading$ = this.store.select(selectInterviewDetailsLoading());
    const userLoading$ = this.store.select(selectUserIsLoading());
    const locationLoading$ = this.store.select(selectLocationLoading());
    const languagesLoading$ = this.store.select(selectLanguagesLoading());
    this.isLoading$ = combineLatest([dataLoading$, userLoading$, locationLoading$, languagesLoading$]).pipe(
      takeUntil(this.componentDestroyed),
      map(([data, usr, loc, lang]) => data || usr || loc || lang));

    this.store.dispatch(new GetInterviewLocationsAction());
  }

  getLastSaved() {
    if (isGuidNotEmpty(this.data.interviewId)) {
      return getLastSavedText(this.data.updatedOnUtc, this.data.updatedBy, EventStatus[this.data.status]);
    } else {
      return 'To be saved';
    }
  }

  goBack() {
    this.router.navigate([`interviews`]);
  }

  dateChanged(event: moment.Moment) {
    this.data.arrivalDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.arrivalTime.hour)
      .minutes(this.data.arrivalTime.minute);
    this.data.startDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.startTime.hour)
      .minutes(this.data.startTime.minute);
  }

  onArrivalTimeChanged(event: ITimePicker) {
    this.minEnd.next({
      hour: event.hour,
      minute: event.minute
    });
    this.data.arrivalDate = moment.utc(this.data.arrivalDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }
}
