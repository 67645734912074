import { APPROVED_COLOR, COMPLETED_COLOR, DRAFT_COLOR, REJECTED_COLOR, REQUESTS_COLOR, SUBMITTED_COLOR, WITHDRAWN_COLOR } from "src/app/models/constants";

export class DashboardColorUtil{
    public static toString(color: DashboardColors) {
        return DashboardColors[color];
    }
    public static toHexString(color: DashboardColors) {
        switch (color) {
            case DashboardColors.ClubNotifications:
                return "#FF9900"
            case DashboardColors.LeagueNotifications:
                return "#FF0000"
            case DashboardColors.Requests:
                return "#0000FF"
            case DashboardColors.Validations:
                return "#BBFF00"
            default:
                return "#FF2882"
        }
    }
    
    public static toLfpHexString(color: DashboardColors) {
        switch (color) {
            case DashboardColors.Draft:
                return DRAFT_COLOR
            case DashboardColors.Submitted:
                return SUBMITTED_COLOR
            case DashboardColors.Approved:
                return APPROVED_COLOR
            case DashboardColors.Rejected:
                return REJECTED_COLOR
            case DashboardColors.RequestsPending:
                return REQUESTS_COLOR
            case DashboardColors.Withdrawn:
                return WITHDRAWN_COLOR
            case DashboardColors.Completed:
                return COMPLETED_COLOR
            default:
                return "#FF2882"
        }
    }
}

export enum DashboardColors {
    LeagueNotifications = "leagueNotifications",
    ClubNotifications = "clubNotifications",
    Validations = "validations",
    Requests= "requests",
    Base= "base",
    Draft = "draft",
    Submitted = "submitted",
    Rejected = "rejected",
    Approved = "approved",
    RequestsPending = "requestsPending",
    Withdrawn = "withdrawn",
    Completed = "completed",

}
