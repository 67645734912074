import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reject-confirm',
  templateUrl: './reject-confirm.component.html',
  styleUrls: ['./reject-confirm.component.scss']
})
export class RejectConfirmComponent implements OnInit {

  @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onSubmit() {
    this.submit.emit(true);
    this.activeModal.close();
  }

  onCancel() {
    this.submit.emit(false);
    this.activeModal.close();
  }
}
