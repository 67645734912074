import * as actions from './actions';
import { BppState } from './state';
import { PlayerDto } from '../../services/bpp.webapi/client';
import * as playerHelper from '../../helpers/player-helper';

type Action = actions.All;
const defaultState = new BppState();

export function BppReducer(state: BppState = defaultState, action: Action): BppState {
    switch (action.type) {

        case actions.GET_API_VERSION_SUCCESS: {
            return {
                ...state,
                apiVersion: action.payload
            };
        }

        case actions.GET_PLAYER_LIST: {
            return {
                ...state,
                clubPlayers: null,
                loading: true,
                error: false
            };
        }

        case actions.GET_PLAYER_LIST_SUCCESS: {

            // const sorted = sortPlayers(action.payload.players);
            return {
                ...state,
                loading: false,
                clubPlayers: {
                    team: action.payload.team,
                    players: action.payload.players
                }
            };
        }

        case actions.GET_LANGUAGE_LIST: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.GET_LANGUAGE_LIST_SUCCESS: {
            const sorted = action.payload; // .sort((a, b) => a.name > b.name ? 1 : -1)
            return {
                ...state,
                loading: false,
                error: false,
                languagesList: sorted
            };
        }

        case actions.GET_PLAYER_LIST_BY_TEAM: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.GET_PLAYER_LIST_BY_TEAM_SUCCESS: {
            const updated = { ...state.playersByTeam };

            action.payload.forEach(item => {
                updated[item.team.id] = sortPlayers(item.players);
            });

            return {
                ...state,
                loading: false,
                error: false,
                playersByTeam: updated
            };
        }

        case actions.BPP_API_ERROR_ACTION:
        case actions.GET_PLAYER_LIST_BY_TEAM_ERROR:
        case actions.GET_PRESENTATION_URL_ERROR: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }

        case actions.GET_PRESENTATION_URL_SUCCESS: {
            return {
                ...state,
                presentationUrl: action.payload
            };
        }

        case actions.GET_MATCH_ROUND_NUMBERS_SUCCESS: {
            return {
                ...state,
                currentMatchRoundNumber: action.payload.currentMatchRoundNumber,
                matchRoundNumbers:action.payload.matchRoundNumbers
            };
        }
    }
    return state;
}

function sortPlayers(array: PlayerDto[]) {
    if (!array) { return []; }
    const sorted = array
        .filter(p => !playerHelper.isAssistantManager(p)) // Exclude Assistant manager from the list
        .sort((a, b) => a.officialName >= b.officialName ? 1 : -1);
    const managers = sorted.filter(p => playerHelper.isManager(p));
    const players = sorted.filter(p => !playerHelper.isManager(p));
    return managers.concat(players);
}
