import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as userActions from '../../../store/user/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';

@Component({
  selector: 'app-test-content',
  templateUrl: './test-content.component.html',
  styleUrls: ['./test-content.component.scss']
})
export class TestContentComponent implements OnInit, OnDestroy {

  private userSubscription;
  currentRoute;
  userName;
  company;
  userRoles;
  userEmail;

  constructor(protected router: Router, private store: Store<AppState>) { }

  ngOnInit() {
    this.currentRoute = this.router.url;

    // this.userInfo = this.adalService.userInfo;

    this.userSubscription = this.store
      .select(state => state.user.currentUser)
      .subscribe(state => {
        if (state) {

          this.company = state.companyName;
          this.userRoles = state.role;
          this.userName = state.givenName;
          this.userEmail = state.email;
        }
      });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
