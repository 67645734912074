import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ILanguageDto } from '../../../services/bpp.webapi/client';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLanguagesComponent } from '../add-languages/add-languages.component';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-languages-picker',
  templateUrl: './languages-picker.component.html',
  styleUrls: ['./languages-picker.component.scss']
})
export class LanguagesPickerComponent implements OnInit, OnDestroy {

  // @Input() pickedLanguages: Array<LanguageDto>;
  @Output() changeLanguages = new EventEmitter();

  private bppSubscription: Subscription;
  private subscription;

  constLanguages: Array<ILanguageDto> = [];
  saveUsername = false;

  @Input() pickedLanguages: Array<ILanguageDto>;
  @Output() change = new EventEmitter();

  languages: Array<ILanguageDto> = [];

  constructor(private store: Store<AppState>, private modalService: NgbModal, public config: ConfigService) { }

  ngOnInit() {

    this.bppSubscription = this.store
      .select(state => state.bpp)
      .subscribe(state => {
        if (state.loading) {
        } else {
          this.constLanguages = state.languagesList;
        }
      });

    this.pickedLanguages.forEach(language => {
      this.languages.push(language);
    });
  }

  openAddLocation() {
    const modalRef = this.modalService.open(AddLanguagesComponent);
    modalRef.componentInstance.constLanguages = this.constLanguages;
    modalRef.componentInstance.pickedLanguages = this.pickedLanguages;
    this.subscription = modalRef.componentInstance.save.subscribe(languages => this.saveLanguages(languages));
  }

  saveLanguages(languages: Array<ILanguageDto>) {

    this.languages = languages;
    this.pickedLanguages = this.languages;
    this.changeLanguages.emit(languages);
  }

  deleteLanguage(sender: ILanguageDto) {
    const index = this.findWithAttr(this.languages, 'languageId', sender.languageId);

    if (index !== -1) {
      this.languages.splice(index, 1);
      this.changeLanguages.emit(this.languages);
    }

  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  ngOnDestroy(): void {

    if (this.bppSubscription) {
      this.bppSubscription.unsubscribe();
    }

  }

}
