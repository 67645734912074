import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import { AppState } from 'src/app/store/app.state';
import {AdditionalContentFixtureDetailsDto, AdditionalContentType, ClubDto, EventStatus, PlayerDto} from '../../../services/bpp.webapi/client';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {DateBinder} from '../../additional-content-details/dateBinder';
import {ConfigService} from '../../../config/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {AppInsightClientService} from '../../../services/app-insight.service';
import {Subject, Subscription} from 'rxjs';
import {User} from '../../../models/user.model';
import {AdditionalContentMapper} from '../../../mappers/additional-content.mapper';
import * as moment from 'moment-timezone';
import {CreateAdditionalContentEventAction} from '../../../store/additional-content/actions';
import {GetPlayerListAction} from '../../../store/bpp/actions';
import {mapAdditionalContentType, mapAdditionalContentTypeUrl} from '../../../helpers/common';

@Component({
  selector: 'app-create-club-additional-content',
  templateUrl: './create-club-additional-content.component.html'
})
export class CreateClubAdditionalContentComponent implements OnInit, OnDestroy {
  dateBinder: DateBinder;
  private componentDestroyed: Subject<void> = new Subject();
  user: User;
  userSubscription: Subscription;
  dataLoaded: boolean;
  isLog = false;
  isSpinner = false;
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  dates: { date: moment.Moment, start: moment.Moment, arrival: moment.Moment };
  additionalContent: AdditionalContentFixtureDetailsDto;
  club: ClubDto;
  lastSaved: string;
  mapper: AdditionalContentMapper;

  isReadOnly = false;
  players = new Array<PlayerDto>();
  features: any;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
              private config: ConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private translationService: TranslateService,
              private appInsightsService: AppInsightClientService) {
    this.isLog = this.config.isDebug();
    this.mapper = new AdditionalContentMapper();
    this.features = this.config.get("features.additionalContent");
    this.dateBinder = new DateBinder(moment(), moment(), this.config.get('defaultTimeZone'));
    if (this.config.hasFeature('environment.lfp')){
      this.isLfp= true;
    }
  }

  ngOnInit() {
    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

    this.activatedRoute.params.subscribe(
      params => {
        const eventType = mapAdditionalContentType(params['type'].toLowerCase(), !!(this.features && this.features.alternativeNames));
        if (eventType === AdditionalContentType.PreSeason || eventType === AdditionalContentType.MidSeason) {
          this.store.dispatch(new GetPlayerListAction());
        }

        const now = moment().utc();
        this.minDate = { year: now.year(), month: now.month() + 1, day: now.date() };

        this.store
          .select(e => e.user).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(state => {
            if (state.loading) {
            } else if (state.currentUser) {
              this.user = state.currentUser;
              this.club = new ClubDto({ id: parseInt(state.currentUser.companyId, 10), name: state.currentUser.companyName });
              this.additionalContent = this.mapper.init(now, this.club.id, eventType);
              this.dateBinder = new DateBinder(this.additionalContent.start, this.additionalContent.arrival, this.config.get('defaultTimeZone'));
            }
          });
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  goBack() {
    this.router.navigate([`additional-content`]);
  }


  saveOrSubmitEvent(status) {
    this.dateBinder.bindToDetailsModel(this.additionalContent);
    if (!this.validateModel()) {
      return;
    }
    this.isSpinner = true;
    this.appInsightsService.logEvent('Create additional content (club)', {
      eventDate: this.additionalContent.start.format('YYYY/MM/dd HH:mm'),
      status: StatusHelper.getStatusText(status)
    });
    this.additionalContent.status = status;
    const model = this.mapper.create(this.additionalContent);
    model.status = status;
    this.store.dispatch(new CreateAdditionalContentEventAction(model));
    this.store.select(e => e.additionalContent).pipe(
      takeUntil(this.componentDestroyed), filter(r => r !== undefined))
      .subscribe(res => {
        if (res.loading) {
        } else {
          this.isSpinner = false;
          if (res.createdAdditionalContent !== '') {
            const id = res.createdAdditionalContent;
            res.createdAdditionalContent = '';
            this.router.navigate([`additional-content/${mapAdditionalContentTypeUrl(this.additionalContent.type, !!(this.features && this.features.alternativeNames))}/detail/${id}`]);
          }
        }
      });
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }

  private validateModel(): boolean {
    if (this.additionalContent.location === undefined || this.additionalContent.location.locationId === undefined) {
      this.translationService.get('invalidAdditionalContentLocation').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }

    if (this.isLfp && (this.additionalContent.duration === undefined || this.additionalContent.duration===moment.duration(0,'milliseconds'))){
      this.translationService.get('invalidDuration').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    
    return true;
  }
}
