import * as actions from './actions';
import { InterviewsState } from './state';

export type Action = actions.All;
const defaultState = new InterviewsState();

export function InterviewsReducer(state = defaultState, action: Action): InterviewsState {

    switch (action.type) {
        case actions.GET_INTERVIEWS_ACTION: {
            return {
                ...state,
                collection: {
                    ...state.collection,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.GET_INTERVIEWS_ACTION_SUCCESS: {
            return {
                ...state,
                collection: {
                    items: action.payload.items,
                    loading: false,
                    error: false
                },
                // past: action.payload.past.map(p => p.monthId)
            };
        }
        case actions.GET_INTERVIEWS_ACTION_ERROR: {
            return {
                ...state,
                collection: {
                    ...state.collection,
                    loading: false,
                    error: true
                }
            };
        }
        case actions.GET_INTERVIEW_DETAILS_ACTION: {
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.GET_INTERVIEW_DETAILS_ACTION_SUCCESS: {
            const byId = { ...state.details.byId };
            byId[action.payload.interviewId] = action.payload;
            return {
                ...state,
                details: {
                    byId,
                    loading: false,
                    error: false
                }
            };
        }
        case actions.GET_INTERVIEW_DETAILS_ACTION_ERROR: {
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: false,
                    error: true
                }
            };
        }
        case actions.CREATE_INTERVIEW_ACTION: {
          return {
            ...state,
            created: {
              interviewId: null,
              loading: true,
              error: false
            }
          };
        }
        case actions.UPDATE_INTERVIEW_ACTION: {
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.CREATE_INTERVIEW_ACTION_SUCCESS: {
          const byId = { ...state.details.byId };
          byId[action.model.interviewId] = action.model;
          return {
            ...state,
            created: {
              interviewId: action.model.interviewId,
              loading: false,
              error: false,
            },
            details: {
              ...state.details,
              byId,
            }
          };
        }
        case actions.UPDATE_INTERVIEW_ACTION_SUCCESS: {
            const byId = { ...state.details.byId };
            byId[action.model.interviewId] = action.model;
            return {
                ...state,
                details: {
                    ...state.details,
                    byId,
                    loading: false,
                    error: false,

                }
            };
        }
        case actions.CREATE_UPDATE_INTERVIEW_REQUEST: {
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: true,
                    error: false
                }
            };
        }
        case actions.CREATE_UPDATE_INTERVIEW_REQUEST_SUCCESS: {
            const byId = { ...state.details.byId };
            const interview = byId[action.monthId];
            const index = (interview && interview.requests) ? interview.requests.findIndex(r => r.id === action.payload.id) : undefined;
            if (index > -1) {
                interview.requests[index] = action.payload;
            }
            return {
                ...state,
                details: {
                    ...state.details,
                    byId,
                    loading: false,
                    error: false
                }
            };
        }
        case actions.CREATE_UPDATEA_INTERVIEW_REQUEST_ERROR: {
            return {
                ...state,
                details: {
                    ...state.details,
                    loading: false,
                    error: true
                }
            };
        }
    }

    return state;
}
