import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log('PROD');
  enableProdMode();
} else {
  console.log('DEV');
}

function bootstrapFailed(val) {
  console.error('bootstrap-fail', val);
}
const success = res => res.ok ? res.json() : Promise.resolve({});
const fetchConfig$ = fetch('assets/configuration.json?antiCache=' + new Date().getTime()).then(success);
const fetchFeatures$ = fetch('assets/features.json?antiCache=' + new Date().getTime()).then(success);

Promise.all([fetchConfig$, fetchFeatures$])
  .then(([config, features]) => {

    if (!config || !features) {
      bootstrapFailed(config);
      return;
    }
    window['tempConfigStorage'] = { ...config, ...features };

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(bootstrapFailed);

  })
  .catch(err => {
    console.error(err);
    this.bootstrapFailed();
  });
