
import {filter} from 'rxjs/operators';
import { Injectable, PLATFORM_ID, Inject, Injector } from '@angular/core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { isPlatformBrowser } from '@angular/common';
import { User } from '../models/user.model';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';

export interface IError {
    message: string;
    response?: any;
}
@Injectable()
export class AppInsightClientService {
    isBrowser: boolean;
    user: User;
    userId: string;

    constructor(private appInsightsService: AppInsightsService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    init(iKey: string, user: User) {
        this.appInsightsService.config = {
            instrumentationKey: iKey
        };
        this.userId = `${user.email} [${user.role}] (${user.companyName})`;
        this.user = user;
        this.appInsightsService.init();
        this.appInsightsService.setAuthenticatedUserContext(this.userId);

        this.router.events.pipe(
            filter(event => event instanceof ResolveEnd))
            .subscribe((event: ResolveEnd) => {
                const activatedComponent = this.getActivatedComponent(event.state.root);
                if (activatedComponent) {
                    this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
                }
            });
    }

    public logPageView(
        name: string,
        url?: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number },
        duration?: number) {
        this.appInsightsService.trackPageView(name, url, this.appendUserInfo(properties), measurements, duration);
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.appInsightsService.trackEvent(name, this.appendUserInfo(properties), measurements);
    }

    public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        this.appInsightsService.trackException(error, null, this.addGlobalProperties(properties), measurements);
    }

    private appendUserInfo(properties: { [key: string]: string }) {
        if (!properties) {
            properties = {};
        }
        properties.userRole = this.user.role;
        properties.userEmail = this.user.email;
        properties.companyId = this.user.companyId;
        properties.companyName = this.user.companyName;
        return properties;
    }

    private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }
        return properties;
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}
