
import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Router } from '@angular/router';
import { GetClubEventsAction } from '../../../store/fixture/actions';
import { IFixtureEventDto, IMatchFixtureEventDto, PlayerDto } from '../../../services/bpp.webapi/client';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { Subject } from 'rxjs';
import { ConfigService } from '../../../config/config.service';
import * as helper from '../../../helpers/common';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { config } from 'process';

@Component({
  selector: 'app-fixtures-clubs-table',
  templateUrl: './fixtures-clubs-table.component.html',
  styleUrls: ['./fixtures-clubs-table.component.scss']
})
export class FixturesClubsTableComponent implements OnInit, OnDestroy {
  private componetDestroyed: Subject<any> = new Subject();
  dataLoaded = false;
  isSpinner: boolean;
  isLog = false;
  user: any;
  clubItems: { upcoming: IMatchFixtureEventDto[], past: IMatchFixtureEventDto[] } = { upcoming: [], past: [] };
  isUpcoming: boolean = true;
  nonLiveInUK: boolean = false;
  isMatchFilterEnabled: boolean=false;

  get matches() {
    return this.isUpcoming ? this.clubItems.upcoming : this.clubItems.past;
  }

  constructor(private store: Store<AppState>, private router: Router, private appInsightsService: AppInsightClientService, private config: ConfigService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
        }
      });
    this.store
      .select(state => state.fixtures).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Club fixtures: ', state.clubItems); }
          this.clubItems = state.clubItems;
          this.dataLoaded = true;
          this.isSpinner = false;
          this.isMatchFilterEnabled=this.config.hasFeature("matchFilter.clubs");
        }
      });

      this.store.select(x => x.fixtures.filter).pipe(take(1)).subscribe(x => {
        this.isUpcoming = !!x.isUpcoming;
        this.nonLiveInUK = !!x.isNonLiveInUK;
        this.store.dispatch(new GetClubEventsAction(this.isUpcoming, this.nonLiveInUK));
      });
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

  getFixtureType(item: IMatchFixtureEventDto) {
    if (item.status === 0) { return '-'; }
    return fixtureHelper.getFixtureType(item.type);
  }

  navigate(item: IMatchFixtureEventDto) {
    if (fixtureHelper.isFutureFixture(item)) {
      this.appInsightsService.logEvent('Fixture details', {
        matchId: item.matchId.toFixed()
      });
      this.router.navigate([`fixtures/detail/${item.matchId}/nonLive/${this.nonLiveInUK}`]);
    } else if (helper.isGuidNotEmpty(item.fixtureEventId)) {
      this.appInsightsService.logEvent('Fixture details', {
        matchId: item.matchId.toFixed()
      });
      this.router.navigate([`fixtures/detail/${item.matchId}/nonLive/${this.nonLiveInUK}`]);
    }
  }

  getStatusColor(item: IFixtureEventDto) {
    return StatusHelper.getStatusColor(item.status);
  }

  getLegendaColor(status) {
    return StatusHelper.getStatusColor(status);
  }

  getPeoplesString(peoples: PlayerDto[]) {
    const list = new Array<string>();
    peoples.forEach(p => {
      if (p) {
        if (this.isManager(p)) {
          list.push(p.officialName + ' ' + p.officialSurname);
        } else {
          list.push(p.shortName);
        }
      }
    });
    return list.join(', ');
  }

  selectionChange() {
    this.store.dispatch(new GetClubEventsAction(this.isUpcoming, this.nonLiveInUK));
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }
}
