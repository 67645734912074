
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../config/config.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard-common-wrapper',
  templateUrl: './dashboard-common-wrapper.component.html'
  
})

export class DashboardCommonWrapperComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isSpinner: boolean;
  isLog = false;
  isLfp:boolean;
  

  constructor( config: ConfigService) {
    this.isLog = config.isDebug();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
