import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Route, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ConfigService } from '../config/config.service';
import { Roles } from '../models/enums';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private authService: AuthService, private config: ConfigService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      const expectedFeature = route.data ? route.data.expectedFeature : undefined;
      if (expectedFeature) {
        return this.config.hasFeature(expectedFeature);
      }
      if(this.isDeprecatedFeature(route)) {
        this.router.navigate(['dashboard']);
      }
      return true;
    } else {
      this.authService.login();
      return false;
    }
  }

  private isDeprecatedFeature(route: ActivatedRouteSnapshot) {
    const role = this.authService.getUserRole();
    const delrecatedFeatures = this.config.get("deprecatedFeatures");
    if (!delrecatedFeatures) {
      return false;
    }
    let roleDeprecatedFeatures = [];
    switch (role) {
      case Roles.Admin:
      case Roles.League:
        roleDeprecatedFeatures = delrecatedFeatures.admin; break;
      case Roles.Club:
        roleDeprecatedFeatures = delrecatedFeatures.club; break;
      case Roles.Broadcaster:
        roleDeprecatedFeatures = delrecatedFeatures.broadcaster; break;
    }
    if(!roleDeprecatedFeatures) {
      return false;
    }
    return !!roleDeprecatedFeatures.find(x => route.url.toString().startsWith(x));
  }
}
