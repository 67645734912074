import { User } from '../../../models/user.model';
import { IDashboardItemDto, DashboardItemStatus, DashboardItemType } from '../../../services/bpp.webapi/dashboard.client';
import * as helper from './constructor.helper';
import { EventCase } from '../models/event-case.enum';
import { getEventCase } from './event-case.logic';
import { IDashboardMessage } from '../models/dashboard-message.interface';
import { ISessionData } from '../models/session-data.interface';
import { Roles } from '../../../models/enums';
import { isNullOrUndefined } from 'util';
import { DashboardColorSelector } from './dashboard-color.selector';
import { TranslateService } from '@ngx-translate/core';

export class SimplifiedMessageConstructor {
  user: User;
  dashboardColorSelector: DashboardColorSelector;
  constructor(user: User, private translateService:TranslateService ) {
    this.dashboardColorSelector = new DashboardColorSelector();
    this.user = user;
  }

  createMessage(s: IDashboardItemDto): IDashboardMessage {
    const eventCase: EventCase = getEventCase(s);
    let subject,  confirmed;
    let mr:string;
    this.translateService.get('MR').subscribe(value=>mr = value)

    switch (eventCase) {
      case EventCase.ClubSubmitFixture:
        subject = `${helper.getCompany(s)} submitted new ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)}`;
        confirmed = `Submitted by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.ClubEditApprovedFixture:
        subject = `${helper.getCompany(s)} updated ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)}`;
        confirmed = `Updated by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueApproveFixture:
        subject = `${helper.getEventCompany(s)} - ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} confirmed`;
        confirmed = `Confirmed by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueRejectFixture:
        subject = `${helper.getEventCompany(s)}'s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} Rejected`;
        confirmed = `Rejected by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueApproveFixtureChanges:
        subject = `${helper.getEventCompany(s)}’s updated ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} confirmed`;
        confirmed = `Confirmed by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.BroadcasterRequestFixture:
        subject = `${helper.getCompany(s)} request for ${helper.getEventCompany(s)}’s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} submitted`;
        confirmed = ``;
        break;
      case EventCase.LeagueApproveFixtureRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getEventCompany(s)}’s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} approved`;
        confirmed = ``;
        break;
      case EventCase.BroadcasterEditApprovedFixture:
        subject = `${helper.getCompany(s)} updated request for ${helper.getEventCompany(s)}’s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} submitted`;
        confirmed = ``;
        break;
      case EventCase.LeagueRejectFixtureRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getEventCompany(s)}’s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} rejected`;
        confirmed = ``;
        break;
      case EventCase.LeagueCreateInterview:
        subject = `${helper.getCompany(s)} submitted new ${helper.getInterviewDate(s)} ${s.interviewTypeName}`;
        confirmed = `Submitted by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueUpdateInterview:
        subject = `${helper.getCompany(s)} updated  ${helper.getInterviewDate(s)} ${s.interviewTypeName}`;
        confirmed = `Submitted by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.BroadcasterRequestInterview:
        subject = `${helper.getCompany(s)} request for ${helper.getInterviewDate(s)} ${s.interviewTypeName} submitted `;
        confirmed = ``;
        break;
      case EventCase.BroadcasterEditApprovedInterview:
        subject = `${helper.getCompany(s)} updated request for ${helper.getInterviewDate(s)} ${s.interviewTypeName}`;
        confirmed = ``;
        break;
      case EventCase.LeagueApproveInterviewRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getInterviewDate(s)} ${s.interviewTypeName} approved`;
        confirmed = ``;
        break;
      case EventCase.LeagueRejectInterviewRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getInterviewDate(s)} ${s.interviewTypeName} rejected`;
        confirmed = ``;
        break;
      case EventCase.BroadcasterWithdrawnFixture:
        subject = `${helper.getCompany(s)} request for ${helper.getEventCompany(s)}'s ${helper.getIcsTypeText(s)} withdrawn`;
        confirmed = ``;
        break;
      case EventCase.BroadcasterWithdrawnInterview:
        subject = `${helper.getCompany(s)} request for ${helper.getInterviewDate(s)} ${s.interviewTypeName}  withdrawn`;
        confirmed = ``;
        break;
      case EventCase.LeagueUnRejectFixtureRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getEventCompany(s)}’s ${helper.getIcsTypeText(s)} for ${mr} ${helper.getMrText(s)} un-rejected`;
        confirmed = `Un-rejected by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueUnRejectInterviewRequest:
        subject = `${helper.getOwnerCompany(s)} request for ${helper.getInterviewDate(s)} ${s.interviewTypeName} un-rejected`;
        confirmed = `Un-rejected by ${helper.getUserAccount(s)}`;
        break;
      case EventCase.LeagueUpdateFixture:
        subject = `${helper.getEventCompany(s)}'s ${helper.getIcsTypeText(s)} for ${mr}${helper.getMrText(s)} updated`;
        confirmed = `Updated by ${helper.getUserAccountWithoutBrackets(s)}`;
        break;
      default: return undefined;
    }

    const clubId = (isNullOrUndefined(s.userCompanyId) || isNaN(Number(s.userCompanyId))) ? undefined : parseInt(s.userCompanyId, 10);
    const result: IDashboardMessage = {
      subject,
      body:'',
      confirmed,
      data: this.getSessionData(s),
      date: s.updatedOn,
      account: helper.getUserAccount(s),
      avatar: helper.getAvatar(clubId),
      clubId,
      color:this.dashboardColorSelector.getDashboardColor (s)
    };

    return result;
  }

  getSessionData(s: IDashboardItemDto): ISessionData {

    const data: ISessionData = {
      title: helper.getMatchInfo(s),
      updatedOn: s.updatedOn,
      arrival: s.arrival,
      start: s.start,
      location: s.location,
      languages: s.languages,
      attendees: s.attendees,
      match: s.matchData,
      eventId: s.eventId,
      isRejected: s.dashboardItemStatus === DashboardItemStatus.Rejected,
      showLeagueNote: s.dashboardItemStatus === DashboardItemStatus.Rejected &&
        (s.dashboardItemType === DashboardItemType.FixtureEvent || s.dashboardItemType === DashboardItemType.TrainingEvent) &&
        s.leagueNote !== undefined && s.leagueNote !== null && s.leagueNote !== '',
      leagueNote: s.leagueNote,
      showBpp: this.user.role === Roles.Admin || this.user.role === Roles.League || this.user.role === Roles.Club,
      userRole: this.user.role,
      interviewee: s.interviewee,
      monthId: s.elementId,
      broadcasterDeadline: s.broadcasterDeadline,
      isNonLiveFixtureEvent: s.isNonLiveFixtureEvent,
     
    };

    return data;
  }
}