import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface IPerson {
  id: number;
  name: string;
  role: string;
  phone: string;
  email: string;
  imageUrl: string;
}

export interface IContactsInfo {
  persons: IPerson[];
}

@Injectable()
export class ContactsService {
  constructor(private httpClient: HttpClient) {}

  get(): Observable<IContactsInfo> {
    const headers = { "content-type": "application/json" };
    return this.httpClient
      .get(`assets/contacts.json?antiCache=${new Date().getTime()}`, {
        headers,
      })
      .pipe(
        map((res) => {
          if (res) {
            return res as IContactsInfo;
          }
        })
      );
  }
}
