import { Action } from '@ngrx/store';
import { ViewState } from './view-state';
import { IAdditionalContentFixtureDetailsDto, RequestStatus, CreateAdditionalContentSessionRequestDto } from '../../../../../services/bpp.webapi/client';
import { CreateClubContentSessionRequestAction } from '../../../../../store/additional-content/actions';
import { StatusHelper } from '../../../../../helpers/statuses-helper';

export class AddState extends ViewState {

  constructor(additionalContent: IAdditionalContentFixtureDetailsDto) {
    super();
    this.showStatus = false;
    this.isNew = true;
    this.setAdditionalContent(additionalContent);
  }

  public getAction(status: RequestStatus): Action {
    const request = new CreateAdditionalContentSessionRequestDto();
    request.status = status;
    request.eventId = this.eventId;
    request.participants = this.getParticipants();
    request.cameraOperator = this.cameras.cameraOperator;
    request.isOthersEnabled = this.others.require;
    request.isProducersEnabled = this.producers.require;
    request.isReportersEnabled = this.reporters.require;
    request.note = this.note;
    request.additionalContentType = this.additionalContentType;

    return new CreateClubContentSessionRequestAction(request);
  }

  isReadOnly() {
    return false;
  }

  isCanChange() {
    return !StatusHelper.isDatePassed(this.startTime);
  }

  toggleState() {
  }

}
