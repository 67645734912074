import { Action } from '@ngrx/store';
import { LocationTypeDto } from '../../services/bpp.webapi/client';

export const GET_LOCATION_TYPES = 'get_location_types_action';
export const GET_LOCATION_TYPES_SUCCESS = 'get_location_types_success';
export const GET_LOCATION_TYPES_ERROR = 'get_location_types_error';

export const GET_AVAILABLE_LOCATION_TYPES = 'get_available_location_types_action';
export const GET_AVAILABLE_LOCATION_TYPES_SUCCESS = 'get_available_location_types_success';
export const GET_AVAILABLE_LOCATION_TYPES_ERROR = 'get_available_location_types_error';

export class GetLocationTypesAction implements Action {
    readonly type = GET_LOCATION_TYPES;
    constructor() { }
}

export class GetLocationTypesSuccessAction implements Action {
    readonly type = GET_LOCATION_TYPES_SUCCESS;
    constructor(public payload: Array<LocationTypeDto>) {
    }
}

export class GetLocationTypesErrorAction implements Action {
    readonly type = GET_LOCATION_TYPES_ERROR;
    constructor(public payload: string) { }
}

export class GetAvailableLocationTypesAction implements Action {
  readonly type = GET_AVAILABLE_LOCATION_TYPES;
  constructor(public clubId: number) { }
}

export class GetAvailableLocationTypesSuccessAction implements Action {
  readonly type = GET_AVAILABLE_LOCATION_TYPES_SUCCESS;
  constructor(public payload: Array<LocationTypeDto>) {
  }
}

export class GetAvailableLocationTypesErrorAction implements Action {
  readonly type = GET_AVAILABLE_LOCATION_TYPES_ERROR;
  constructor(public payload: string) { }
}

export type All = GetLocationTypesErrorAction
    | GetLocationTypesAction
    | GetLocationTypesSuccessAction
    | GetAvailableLocationTypesErrorAction
    | GetAvailableLocationTypesAction
    | GetAvailableLocationTypesSuccessAction;

