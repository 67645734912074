import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as actions from './actions';
import { LocationTypesService } from '../../services/location-types.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class LocationTypeEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: LocationTypesService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetLocationTypes$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetLocationTypesAction>(actions.GET_LOCATION_TYPES),
    switchMap(action => this.service.getLocationTypes().pipe(
      switchMap((result) => of(new actions.GetLocationTypesSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetLocationTypesErrorAction(error));
      }))));

  @Effect()
  GetAvailableLocationTypes$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetAvailableLocationTypesAction>(actions.GET_AVAILABLE_LOCATION_TYPES),
    switchMap(action => this.service.getAvailableLocationTypes(action.clubId).pipe(
      switchMap((result) => of(new actions.GetAvailableLocationTypesSuccessAction(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetAvailableLocationTypesErrorAction(error));
      }))));
}
