
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { BaseComponent } from '../../base-component/base.component';

export enum EMinutesStep {
  Five = 5,
  Ten = 10,
  Default = 1
}

export interface ILabelValue {
  label: string;
  value: number;
}
export interface ITimePicker {
  hour: number;
  minute: number;
}

@Component({
  selector: 'app-combo-picker',
  templateUrl: 'combo-picker.component.html',
  styleUrls: ['combo-picker.component.scss']
})

export class ComboPickerComponent extends BaseComponent implements OnInit {
  @Input() minTime$: Observable<ITimePicker>;
  @Input() maxTime$: Observable<ITimePicker>;
  @Input() model: ITimePicker;
  @Input() step = 5;
  @Input() labelId: string;
  @Input() readOnly = false;
  @Output() modelChanged: EventEmitter<ITimePicker> = new EventEmitter();
  @Output() isInitialized: EventEmitter<ITimePicker> = new EventEmitter();
  hours: ILabelValue[];
  minutes: ILabelValue[];
  selectedHour$: Subject<number> = new Subject();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.model) {
      switch (this.step) {
        case EMinutesStep.Five:
          this.model.minute = Math.ceil(this.model.minute / 5) * 5;
          break;
        case EMinutesStep.Ten:
          this.model.minute = Math.ceil(this.model.minute / 10) * 10;
          break;
        default: this.step = 1;
      }
    }

    const minTime$ = this.minTime$.pipe(
      takeUntil(this.componentDestroyed),
      filter(res => res !== undefined));

    const maxTime$ = this.maxTime$.pipe(
      takeUntil(this.componentDestroyed),
      filter(res => res !== undefined));

    this.maxTime$.subscribe(res => {
      console.log(res);
    });

    combineLatest([minTime$, maxTime$, this.selectedHour$])
      .subscribe(([min, max, hour]) => {
        const minHour = min.hour !== undefined ? min.hour : 0;
        const minMinute = min.minute !== undefined ? min.minute : 0;
        const maxHour = max.hour !== undefined ? max.hour : 23;
        const maxMinute = max.minute !== undefined ? max.minute : 59;
        this.hours = new Array<ILabelValue>();
        for (let i = minHour; i <= maxHour; i++) {
          this.hours.push({
            label: this.getLabel(i),
            value: i
          });
        }
        this.checkHoursLimit(this.hours);
        this.minutes = this.getMinutes(this.model.hour, min, max, minMinute, maxMinute);
      });

    this.selectedHour$.next(this.model.hour);
    this.isInitialized.emit(this.model);
  }

  getLabel(i: number) {
    return i > 9 ? `${i}` : `0${i}`;
  }

  onChangeHours(item: ILabelValue) {
    this.model.hour = item.value;
    this.selectedHour$.next(item.value);
    this.modelChanged.emit(this.model);
  }

  onChangeMinutes(item: ILabelValue) {
    this.model.minute = item.value;
    this.modelChanged.emit(this.model);
  }

  isValid(): boolean {
    return true;
  }

  getMinutes(hour: number, minTime: ITimePicker, maxTime: ITimePicker, minMinute: number, maxMinute: number): ILabelValue[] {
    let result = [];
    if (hour !== undefined) {
      if (minTime && minTime.hour !== undefined && hour === minTime.hour) {
        for (let i = minMinute; i <= 59; i += this.step) {
          result.push({
            label: this.getLabel(i),
            value: i
          });
        }
      } else if (maxTime && maxTime.hour !== undefined && hour === maxTime.hour) {
        for (let i = 0; i <= maxMinute; i += this.step) {
          result.push({
            label: this.getLabel(i),
            value: i
          });
        }
      }
    }
    if (result.length === 0) {
      result = this.getDefaultMinutes();
    }
    this.checkMinutesLimit(result);
    return result;
  }

  getDefaultMinutes(): ILabelValue[] {
    const result = new Array<ILabelValue>();
    for (let i = 0; i <= 59; i += this.step) {
      result.push({
        label: this.getLabel(i),
        value: i
      });
    }
    return result;
  }

  checkMinutesLimit(range: ILabelValue[]) {
    if (range && range.length > 0) {
      const min = range[0].value;
      const max = range[range.length - 1].value;
      if (this.model.minute < min) {
        this.model.minute = min;
      } else if (this.model.minute > max) {
        this.model.minute = max;
      }
    }
  }

  checkHoursLimit(range: ILabelValue[]) {
    if (range && range.length > 0) {
      const min = range[0].value;
      const max = range[range.length - 1].value;
      if (this.model.hour < min) {
        this.model.hour = min;
      } else if (this.model.hour > max) {
        this.model.hour = max;
      }
    }
  }
}
