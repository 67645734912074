
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { ConfigService } from '../../config/config.service';
import { Subject ,  Observable } from 'rxjs';
import { UpdateUserNotificationTypesAction } from '../../store/user/actions';
import { UserNotificationType, NotificationType } from '../../services/bpp.webapi/client';
import { StatusHelper } from '../../helpers/statuses-helper';
import { selectUserIsLoading } from '../../store/user/state';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  componentDestroyed: Subject<void> = new Subject();
  user: User;
  isSpinner$: Observable<boolean>;
  options: UserNotificationType[];
  constructor(private store: Store<AppState>,
    private config: ConfigService) { }

  ngOnInit() {
    this.isSpinner$ = this.store.select(selectUserIsLoading());
    this.store.select(state => state.user).pipe(takeUntil(this.componentDestroyed))
      .subscribe(u => {
        if (!u.loading && u.details && u.details.userNotifications) {
          this.user = u.currentUser;
          this.options = this.filterOptions(u.details.userNotifications);
        }
      });
  }

  filterOptions(source: UserNotificationType[]): UserNotificationType[] {

    if (this.config.hasFeature('interviews')) {
      return source;
    } else {
      return source.filter(item =>
        item.notificationType !== NotificationType.InterviewSubmitted
        && item.notificationType !== NotificationType.InterviewRequestSubmitted
        && item.notificationType !== NotificationType.InterviewRequestApproved
        && item.notificationType !== NotificationType.InterviewRequestRejected);
    }
  }

  getNotificationTypeName(type, role) {
    return StatusHelper.getNotificationTypeNameId(type, role);
  }

  savePreferences() {
    this.store.dispatch(new UpdateUserNotificationTypesAction(this.options));
  }
}
