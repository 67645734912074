import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { MatchExtInfoDto } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import {GetMatchesListAction, UpdateMatchAction} from '../../../store/matches/actions';
import {MatchItem} from '../matches-list/match-item';

export interface IRound {
  name: string;
  value: number;
}

@Component({
  selector: 'app-league-matches-configuration',
  templateUrl: './league-matches.component.html'
})
export class LeagueMatchesComponent extends BaseComponent implements OnInit {
  @Input() editAllowed = false;
  isSpinner = true;
  rounds: IRound[];
  matches: MatchExtInfoDto[];
  data: MatchItem[];
  selectedRound: IRound = {
    name: 'Match Round 1',
    value: 1
  };

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.select(e => e.matches)
      .pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined && e.items !== undefined))
      .subscribe(e => {
        this.matches = e.items;
        const uniqueMatchDays = [... new Set(this.matches.map(m => m.matchDay))];
        this.rounds = uniqueMatchDays.map(m => {
          return { name: `Match Round ${m}`, value: m};
        });
        if (this.rounds.length > 0) {
          this.selectRound(this.rounds[0]);
        }
        this.isSpinner = false;
      });

    this.store.dispatch(new GetMatchesListAction());
  }

  selectRound(round: IRound) {
    this.selectedRound = round;
    this.data = this.matches
      .filter(m => m.matchDay === round.value)
      .map(m => {
        return new MatchItem(m);
      });
  }

  saveItem(item: MatchItem) {
    this.store.dispatch(new UpdateMatchAction(item.item));
    item.edit = false;
  }
}
