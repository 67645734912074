import { Injectable } from '@angular/core';
import { DashboardClient } from './bpp.webapi/dashboard.client';

@Injectable()
export class DashboardService {
    constructor(private client: DashboardClient) {

    }

    getItems(pgNum: number, pgSize: number) {
        // console.log('Dashboard service get');
        return this.client.get(pgNum, pgSize);
    }
}
