import { DashboardState } from './state';
import * as actions from './actions';

export type Action = actions.All;

export function DashboardReducer(state = new DashboardState(), action: Action): DashboardState {
    switch (action.type) {

        case actions.GET_DASHBOARD_LIST: {

            return {
                ...state,
                loading: true,
                error: false
            };
        }
        case actions.GET_DASHBOARD_LIST_SUCCESS: {
            const items = action.payload.data;
            const list = action.payload;

            return {
                ...state,
                loading: false,
                error: false,
                items,
                list
            };
        }
        case actions.GET_DASHBOARD_LIST_ERROR: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
    }
    return state;
}
