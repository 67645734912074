import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetNewsAction } from '../../../store/news/actions';
import { ConfigService } from '../../../config/config.service';
import { Roles } from '../../../models/enums';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  private newsSubscription: Subscription;
  private userSubscription: Subscription;

  news;
  settings;
  title;
  preview;
  isLeagueRole = false;
  forgeUrl: string;
  urgentFeature: boolean;

  constructor(private router: Router, protected store: Store<AppState>, private configService: ConfigService) { }

  ngOnInit() {
    this.settings = this.configService.get('features.documents');
    this.title = this.configService.get('features.news.title');
    this.preview = this.configService.get('features.news.preview');
    this.forgeUrl = this.configService.get('forgeUrl');
    this.urgentFeature = this.configService.get('features.news.urgentNotifications');

    this.store.dispatch(new GetNewsAction());

    this.userSubscription = this.store
    .select(state => state.user.currentUser)
    .subscribe(u => {
      if (u.role) {
        this.isLeagueRole = u.role === Roles.League;
      }
    });

    this.newsSubscription = this.store
      .select(state => state.news)
      .subscribe(result => {
        if (result && result.getLoading === false && result.allNews) {
          this.news = result.allNews.items;
          // console.log('NEWS - ', this.news);
        }
      });
  }

  readClick(item) {
    this.router.navigate([`/news/detail/${item.slug}`]);
  }

  editClick(item) {
    console.log('EDIT');
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.newsSubscription) {
      this.newsSubscription.unsubscribe();
    }
  }

}
