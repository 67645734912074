import { ITrainingDto, ITrainingDetailsDto, IParticipantDto, ParticipantDto } from '../../services/bpp.webapi/client';
import { IStoreData } from '../app.state';

export class TrainingState {
    loading: boolean;
    error: boolean;
    result: ITrainingsResult;
    trainingDetails: any = {};
    participants: Array<IParticipantDto>;
    createdTraining: string;
    constructor() {
        this.loading = false;
        this.result = {
            loading: false,
            items: []
        };
        this.trainingDetails = {};
        this.error = false;
        this.createdTraining = '';
    }
}

export interface ITrainingsResult extends IStoreData {
    items: Array<ITrainingDto>;
}
