import { IDashboardItemDto } from "src/app/services/bpp.webapi/dashboard.client";
import { EventCase } from "../models/event-case.enum";
import { getEventCase } from "./event-case.logic";
import {DashboardColors} from "../models/dashboard-colors"

export class DashboardColorSelector {
    
    getDashboardColor(item: IDashboardItemDto): string {

        const eventCase: EventCase = getEventCase(item);
        switch (eventCase) {
            case EventCase.ClubEditApprovedFixture:
            case EventCase.ClubSubmitFixture:
                return DashboardColors.ClubNotifications
            case EventCase.BroadcasterRequestFixture :
            case EventCase.BroadcasterRequestInterview:
            case EventCase.BroadcasterEditApprovedFixture:
            case EventCase.BroadcasterEditApprovedInterview:
            case EventCase.BroadcasterWithdrawnFixture:
            case EventCase.BroadcasterWithdrawnInterview:
                return DashboardColors.Requests 
            case EventCase.LeagueApproveFixtureChanges:
            case EventCase.LeagueApproveFixtureRequest:
            case EventCase.LeagueApproveFixture:
            case EventCase.LeagueApproveInterviewRequest:
            case EventCase.LeagueRejectFixture:
            case EventCase.LeagueRejectFixtureRequest:
            case EventCase.LeagueRejectInterviewRequest:
                return DashboardColors.Validations
            case EventCase.LeagueCreateInterview:
            case EventCase.LeagueUnRejectFixtureRequest:
            case EventCase.LeagueUnRejectTrainingRequest:
            case EventCase.LeagueUnRejectInterviewRequest:
            case EventCase.LeagueUpdateInterview:
            case EventCase.LeagueUpdateFixture:
                return DashboardColors.LeagueNotifications
            default: 
            console.log('DashboardColors',eventCase)
                return DashboardColors.Base
        }
    }
}