import { LocationTypeDto } from '../../services/bpp.webapi/client';

export class LocationTypesState {
    loading: boolean;
    error?: boolean;
    items: Array<LocationTypeDto>;
    availableItems: Array<LocationTypeDto>;
    constructor() {
        this.loading = false;
        this.items = [];
        this.availableItems = [];
    }
}
