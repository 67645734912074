import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../authentication/auth.service";
import { isFutureMatch, isPastMatch } from "../helpers/fixture-helper";
import { GroupByPipe } from "../pipes/groupby.pipe";
import { ApproveRequestToSlotDto, ClubMatchInterviewEventDto, FixtureClient, FixtureRequestClient, IInterviewEventDetailsDto, InterviewEventClient, InterviewEventDetailsDto, IUpdateFixtureRequestDto, IWeekDto, MatchInterviewEventDto, RequestDetailsDto, RequestStatus, RequestToSlotDto, TrainingParticipantClient, UpdateFixtureRequestDto } from "./bpp.webapi/client";

@Injectable()
export class ClubInterviewService {
    constructor(protected authService: AuthService,
        protected eventClient: InterviewEventClient,
        protected requestClient: FixtureRequestClient,
        protected fixturesClient: FixtureClient,
        protected participantClient: TrainingParticipantClient,
        protected groupby: GroupByPipe) {
    }

    getByMatchRound(matchRound): Observable<MatchInterviewEventDto[]> {
        return this.eventClient.get(matchRound);
    }

    getClubEvents(): Observable<{ upcoming: ClubMatchInterviewEventDto[], past: ClubMatchInterviewEventDto[] }> {
        return this.eventClient.getMatchesForClub().pipe(
            map(result => {
                const upcomingClubInterviews = result.reduce((acc, item) => {
                    const isFuture = isFutureMatch(item.fixtureDateUtc);
                    if (isFuture) {
                        acc.push(item);
                    }
                    return acc;
                }, []) as ClubMatchInterviewEventDto[];

                const pastClubInterviews = result.reduce((acc, item) => {
                    const isFuture = isPastMatch(item.fixtureDateUtc);
                    if (isFuture) {
                        acc.push(item);
                    }
                    return acc;
                }, []) as ClubMatchInterviewEventDto[];

                const byGameWeek = {
                    upcoming: upcomingClubInterviews.sort((a, b) => a.matchRound >= b.matchRound ? 1 : -1),
                    past: pastClubInterviews.sort((a, b) => a.matchRound < b.matchRound ? 1 : -1),
                };
                return byGameWeek;
            })
        );
    }

    getEventById(id) {
        return this.eventClient.getForMatch(id);
    }

    setSlot(id) {
        return this.eventClient.submitRequestForSlot(new RequestToSlotDto({ slotId: id }));
    }

    getEventForClubByMatchId(id) {
        return this.eventClient.getDetailsForClub(id);
    }

    createEvent(model: any) {
        return this.eventClient.create(model);
    }

    updateEvent(model: IInterviewEventDetailsDto) {

        return this.eventClient.update(model as InterviewEventDetailsDto);
    }

    getRequestById(id) {
        return this.requestClient.get(id);
    }

    createRequest(model, slotId = undefined) {
        return this.requestClient.post(model).pipe(switchMap((result) => {
            if (slotId) {
                return this.eventClient.submitRequestForSlot(new RequestToSlotDto({ slotId })).pipe(
                    switchMap(r => of(result))
                )
            }
            return of(result);
        }
        ));
    }

    updateRequest(model, slotId: string) {
        return this.requestClient.put(model).pipe(switchMap((result) => {
            if (slotId) {
                return this.eventClient.submitRequestForSlot(new RequestToSlotDto({ slotId })).pipe(
                    switchMap(r => of(result))
                )
            }
            return of(result);
        }
        ));
    }

    updateRequestLeagueNote(model){
        return this.requestClient.put(model);
    }

    updateRequestLeague(model, slotId: string) {
        return this.requestClient.put(model).pipe(switchMap((result) => {
            if (slotId && model.status === RequestStatus.Approved) {
                return this.eventClient.approveRequestForSlot(new ApproveRequestToSlotDto({ slotId, requestId: model.fixtureRequestId })).pipe(
                    switchMap(r => of(result))
                );
            } else if (slotId) {
                return this.eventClient.removeRequestFromSlot(model.fixtureRequestId).pipe(switchMap(r => of(result)));
            }
            return of(result);
        }
        ));
    }

    getParticipants() {

        return this.participantClient.getAll();
    }

    createParticipant(model) {
        return this.participantClient.post(model);
    }

    getParticipantsByCompany(companyId: string) {

        return this.participantClient.getByCompany(companyId);
    }

    getMatchInfo(matchId) {
        return this.fixturesClient.getMatchInfo(matchId);
    }
}