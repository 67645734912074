import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateLocationDto, LocationDetailsDto, LocationTypeDto, LocationTypes } from '../../../services/bpp.webapi/client';
import { LocationService } from '../../../services/location.service';
import { ValidationService } from '../../../services/validation.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit, OnDestroy {
  protected componentDestroyed: Subject<void> = new Subject();

  location: CreateLocationDto | LocationDetailsDto;
  @Input() id: string;
  @Input() isLeague: boolean;

  @Output() delete: EventEmitter<CreateLocationDto | LocationDetailsDto> = new EventEmitter<CreateLocationDto | LocationDetailsDto>();
  @Output() save: EventEmitter<CreateLocationDto | LocationDetailsDto> = new EventEmitter<CreateLocationDto | LocationDetailsDto>();
  @Output() update: EventEmitter<CreateLocationDto | LocationDetailsDto> = new EventEmitter<CreateLocationDto | LocationDetailsDto>();

  isEdit = false;
  locationTypes: Array<LocationTypeDto>;
  selectedLocationType: LocationTypeDto;

  constructor(public activeModal: NgbActiveModal,
              private locationService: LocationService,
              protected config: ConfigService,
              private validator: ValidationService,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    if (!this.id) {
      this.location = new CreateLocationDto();
      const defaultLocationType = this.isLeague ? LocationTypes.League : LocationTypes.Offsite;
      this.location.type = defaultLocationType;

      this.store.select(e => e.locationTypes)
        .pipe(takeUntil(this.componentDestroyed),
          filter(e => e !== undefined && e.availableItems !== undefined && e.availableItems.length > 0))
        .subscribe(e => {
          this.locationTypes = e.availableItems;
        });
    } else {
      this.locationService.getLocationById(this.id).subscribe(result => {
        this.location = result;
        this.isEdit = true;

        this.store.select(e => e.locationTypes)
          .pipe(takeUntil(this.componentDestroyed),
            filter(e => e !== undefined && e.items !== undefined && e.items.length > 0))
          .subscribe(e => {
            this.locationTypes = e.items;
          });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.componentDestroyed) {
      this.componentDestroyed.next();
      this.componentDestroyed.unsubscribe();
    }
  }

  onSave() {
    if (this.validator.isLocationValid(this.location)) {
      this.save.emit(this.location);
      this.activeModal.close('Close click');
    }
  }

  onUpdate() {
    if (this.validator.isLocationValid(this.location)) {
      this.update.emit(<LocationDetailsDto>this.location);
      this.activeModal.close('Close click');
    }
  }

  onDelete() {
    this.delete.emit(this.location);
    this.activeModal.close('Close click');
  }

  onLocationTypeChange(locationType: LocationTypeDto) {
    if (!this.isEdit) {
      this.location.type = locationType.id;
    }
  }

  getLocationTypeName(type: number) {
    if (this.locationTypes && this.locationTypes.length > 0) {
      const locationType = this.locationTypes.find(x => x.id === type);
      return locationType ? locationType.name : '';
    }
    return '';
  }
}

