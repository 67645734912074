import { Action } from '@ngrx/store';
import {
    IMatchInfoDto,
    IInterviewEventDetailsDto,
    ClubMatchInterviewEventDto,
    MatchInterviewEventDto,
    ClubMatchInterviewEventDetailsDto,
    MatchInterviewEventDetailsDto,
    ICreateFixtureRequestDto,
    IUpdateFixtureRequestDto,
    FixtureEventDetailsDto,
    MatchInfoDto,
    InterviewEventDetailsDto
} from '../../services/bpp.webapi/client';

export const CREATE_CLUB_INTERVIEW_EVENT = 'create_club_interview_event';
export const CREATE_CLUB_INTERVIEW_EVENT_SUCCESS = 'create_club_interview_event_success';
export const CREATE_CLUB_INTERVIEW_EVENT_ERROR = 'create_club_interview_event_error';

export const UPDATE_CLUB_INTERVIEW_EVENT = 'update_club_interview_event';
export const UPDATE_CLUB_INTERVIEW_EVENT_SUCCESS = 'update_club_interview_event_success';
export const UPDATE_CLUB_INTERVIEW_EVENT_ERROR = 'update_club_interview_event_error';

export const GET_CLUB_INTERVIEW_CLUB_EVENTS = 'get_club_interview_club_events';
export const GET_CLUB_INTERVIEW_CLUB_EVENTS_SUCCESS = 'get_club_interview_club_events_success';
export const GET_CLUB_INTERVIEW_CLUB_EVENTS_ERROR = 'get_club_interview_club_events_error';

export const GET_CLUB_INTERVIEW_LEAGUE_EVENTS = 'get_club_interview_league_events';
export const GET_CLUB_INTERVIEW_LEAGUE_EVENTS_SUCCESS = 'get_club_interview_league_events_success';
export const GET_CLUB_INTERVIEW_LEAGUE_EVENTS_ERROR = 'get_club_interview_league_events_error';

export const GET_CLUB_INTERVIEW_BROADCASTER_EVENTS = 'get_club_interview_broadcaster_events';
export const GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_SUCCESS = 'get_club_interview_broadcaster_events_success';
export const GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_ERROR = 'get_club_interview_broadcaster_events_error';

export const GET_CLUB_INTERVIEW_REQUEST_BYID = 'get_club_interview_request_byid';
export const GET_CLUB_INTERVIEW_REQUEST_BYID_SUCCESS = 'get_club_interview_request_byid_success';
export const GET_CLUB_INTERVIEW_REQUEST_BYID_ERROR = 'get_club_interview_request_byid_error';

export const GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB = 'get_club_interview_details_for_club';
export const GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_SUCCESS = 'get_club_interview_details_for_club_success';
export const GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_ERROR = 'get_club_interview_details_for_club_error';

export const GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER = 'get_club_interview_details_for_league_broadcaster';
export const GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_SUCCESS = 'get_club_interview_details_for_league_broadcaster_success';
export const GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_ERROR = 'get_club_interview_details_for_league_broadcaster_error';

export const GET_CLUB_INTERVIEW_MATCH_INFO = 'get_club_interview_match_info';
export const GET_CLUB_INTERVIEW_MATCH_INFO_SUCCESS = 'get_club_interview_match_info_success';
export const GET_CLUB_INTERVIEW_MATCH_INFO_ERROR = 'get_club_interview_match_info_error';

export const CREATE_CLUB_INTERVIEW_REQUEST = 'create_club_interview_request';
export const CREATE_CLUB_INTERVIEW_REQUEST_SUCCESS = 'create_club_interview_request_success';
export const CREATE_CLUB_INTERVIEW_REQUEST_ERROR = 'create_club_interview_request_error';

export const UPDATE_CLUB_INTERVIEW_REQUEST = 'update_club_interview_request';
export const UPDATE_CLUB_INTERVIEW_REQUEST_SUCCESS = 'update_club_interview_request_success';
export const UPDATE_CLUB_INTERVIEW_REQUEST_ERROR = 'update_club_interview_request_error';

export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE = 'update_club_interview_request_league';
export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_SUCCESS = 'update_club_interview_request_league_success';
export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_ERROR = 'update_club_interview_request_league_error';

export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE = 'update_club_interview_request_league_note';
export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_SUCCESS = 'update_club_interview_request_league_note_success';
export const UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_ERROR = 'update_club_interview_request_league_note_error';

export class GetMatchInfoAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_MATCH_INFO;
    constructor(public matchId: string) { }
}

export class GetMatchInfoActionSuccess implements Action {
    readonly type = GET_CLUB_INTERVIEW_MATCH_INFO_SUCCESS;
    constructor(public payload: IMatchInfoDto) { }
}

export class GetMatchInfoActionError implements Action {
    readonly type = GET_CLUB_INTERVIEW_MATCH_INFO_ERROR;
    constructor(public payload: any) { }
}

export class CreateClubInterviewEventAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_EVENT;
    constructor(public payload: any) {
    }
}

export class CreateClubInterviewEventSuccessAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_EVENT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class CreateClubInterviewEventErrorAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateClubInterviewEventAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_EVENT;
    constructor(public payload: IInterviewEventDetailsDto) {
    }
}

export class UpdateClubInterviewEventSuccessAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_EVENT_SUCCESS;
    constructor(public payload: InterviewEventDetailsDto) {
    }
}

export class UpdateClubInterviewEventErrorAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class GetClubEventsForClubAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_CLUB_EVENTS;
    constructor() { }
}

export class GetClubEventsForClubSuccessAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_CLUB_EVENTS_SUCCESS;
    constructor(public payload: { upcoming: ClubMatchInterviewEventDto[], past: ClubMatchInterviewEventDto[] }) {
    }
}

export class GetClubEventsForClubErrorAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_CLUB_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetClubInterviewLeagueEventsAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_LEAGUE_EVENTS;
    constructor(public matchRound: number) { }
}

export class GetClubInterviewLeagueEventsSuccessAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_LEAGUE_EVENTS_SUCCESS;
    constructor(public matchRound: number, public payload: MatchInterviewEventDto[]) {
    }
}

export class GetClubInterviewLeagueEventsErrorAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_LEAGUE_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetClubInterviewBroadcasterEventsAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_BROADCASTER_EVENTS;
    constructor(public matchRound: number) { }
}

export class GetClubInterviewBroadcasterEventsSuccessAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_SUCCESS;
    constructor(public matchRound: number, public payload: MatchInterviewEventDto[]) {
    }
}

export class GetClubInterviewBroadcasterEventsErrorAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetClubInterviewDetailsForClubAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB;
    constructor(public matchId: number) { }
}

export class GetClubInterviewDetailsForClubSuccessAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_SUCCESS;
    constructor(public matchId: number, public payload: ClubMatchInterviewEventDetailsDto) {
    }
}

export class GetClubInterviewDetailsForClubErrorAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_ERROR;
    constructor(public payload: any) { }
}

export class GetClubInterviewDetailsForLeagueBroadcasterAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER;
    constructor(public matchId: number) { }
}

export class GetClubInterviewDetailsForLeagueBroadcasterSuccessAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_SUCCESS;
    constructor(public matchId: number, public payload: MatchInterviewEventDetailsDto) {
    }
}

export class GetClubInterviewDetailsForLeagueBroadcasterErrorAction implements Action {
    readonly type = GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_ERROR;
    constructor(public payload: any) { }
}

export class CreateClubInterviewRequestAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_REQUEST;
    constructor(public payload: ICreateFixtureRequestDto, public slotId: string, public matchId: string) { }
}

export class CreateClubInterviewRequestSuccessAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_REQUEST_SUCCESS;
    constructor(public payload: FixtureEventDetailsDto, public slotId: string) { }
}

export class CreateClubInterviewRequestErrorAction implements Action {
    readonly type = CREATE_CLUB_INTERVIEW_REQUEST_ERROR;
    constructor(public payload: any) { }
}

export class UpdateClubInterviewRequestAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST;
    constructor(public payload: IUpdateFixtureRequestDto, public slotId: string, public matchId: string) { }
}

export class UpdateClubInterviewRequestSuccessAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_SUCCESS;
    constructor(public payload: MatchInterviewEventDetailsDto, public slotId: string) { }
}

export class UpdateClubInterviewRequestErrorAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_ERROR;
    constructor(public payload: any) { }
}

export class UpdateClubInterviewRequestLeagueAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE;
    constructor(public payload: IUpdateFixtureRequestDto, public slotId: string, public matchId: string) { }
}

export class UpdateClubInterviewRequestLeagueSuccessAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_SUCCESS;
    constructor(public payload: MatchInterviewEventDetailsDto, public slotId: string) { }
}

export class UpdateClubInterviewRequestLeagueErrorAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_ERROR;
    constructor(public payload: any) { }
}

export class UpdateClubInterviewRequestLeagueNoteAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE;
    constructor(public payload: IUpdateFixtureRequestDto, public matchId: string) { }
}

export class UpdateClubInterviewRequestLeagueNoteSuccessAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_SUCCESS;
    constructor(public payload: MatchInterviewEventDetailsDto) { }
}

export class UpdateClubInterviewRequestLeagueNoteErrorAction implements Action {
    readonly type = UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_ERROR;
    constructor(public payload: any) { }
}

export type All = GetClubEventsForClubAction
    | GetClubEventsForClubSuccessAction
    | CreateClubInterviewEventAction
    | CreateClubInterviewEventSuccessAction
    | CreateClubInterviewEventErrorAction
    | GetClubEventsForClubErrorAction
    | UpdateClubInterviewEventAction
    | UpdateClubInterviewEventSuccessAction
    | UpdateClubInterviewEventErrorAction
    | GetClubInterviewLeagueEventsAction
    | GetClubInterviewLeagueEventsSuccessAction
    | GetClubInterviewLeagueEventsErrorAction
    | GetClubInterviewBroadcasterEventsAction
    | GetClubInterviewBroadcasterEventsSuccessAction
    | GetClubInterviewBroadcasterEventsErrorAction
    | GetMatchInfoAction
    | GetMatchInfoActionSuccess
    | GetMatchInfoActionError
    | GetClubInterviewDetailsForClubAction
    | GetClubInterviewDetailsForClubSuccessAction
    | GetClubInterviewDetailsForClubErrorAction
    | GetClubInterviewDetailsForLeagueBroadcasterAction
    | GetClubInterviewDetailsForLeagueBroadcasterSuccessAction
    | GetClubInterviewDetailsForLeagueBroadcasterErrorAction
    | CreateClubInterviewRequestAction
    | CreateClubInterviewRequestErrorAction
    | CreateClubInterviewRequestSuccessAction
    | UpdateClubInterviewRequestAction
    | UpdateClubInterviewRequestErrorAction
    | UpdateClubInterviewRequestSuccessAction
    | UpdateClubInterviewRequestLeagueAction
    | UpdateClubInterviewRequestLeagueErrorAction
    | UpdateClubInterviewRequestLeagueSuccessAction
    | UpdateClubInterviewRequestLeagueNoteAction
    | UpdateClubInterviewRequestLeagueNoteErrorAction
    | UpdateClubInterviewRequestLeagueNoteSuccessAction
    ;
