import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ConfigService } from "src/app/config/config.service";
import { getComponentByRole, IComponetByRole } from "src/app/helpers/component-by-role";
import { Roles } from "src/app/models/enums";
import { AppState } from "src/app/store/app.state";
import { ClubInterviewsSummaryReportComponent } from "../club-interviews-summary-report/club-interviews-summary-report.component";
import { LeagueInterviewsSummaryReportComponent } from "../league-interviews-summary-report/league-interviews-summary-report.component";

@Component({
	selector: 'app-interviews-summary-report-warpper',
	templateUrl: 'interviews-summary-report-warpper.component.html'
})
export class InterviewsSummaryReportWarpperComponent implements OnInit, OnDestroy {

	@ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) interviewsRef;

	userInfo: Subscription;
	role: Roles;
	private components: IComponetByRole[] = [
		{ role: Roles.League, component: LeagueInterviewsSummaryReportComponent },
		{ role: Roles.Club, component: ClubInterviewsSummaryReportComponent }
	];

	constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>, private config: ConfigService) { }

	ngOnInit(): void {
		this.userInfo = this.store
			.select(state => state.user.currentUser)
			.subscribe(u => {
				if (u.role) {
					this.role = u.role;
					this.loadComponent(this.role)

					if (this.config.isDebug) {
						console.log('Loading interviews for role: ', this.role);
					}
				}
			});
	}

	ngOnDestroy(): void {
		if (this.userInfo && this.userInfo.closed)
			this.userInfo.unsubscribe();
	}

	loadComponent(role: Roles) {
		const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
		this.interviewsRef.clear();
		const ref = this.interviewsRef.createComponent(factory);
		ref.changeDetectorRef.detectChanges();
	}
}