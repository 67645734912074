
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FxRequest, IDentity, IRequestSection, ERequestType } from '../../../store/fx-request/state';
import { AppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { AddContactComponent } from '../../common/add-contact/add-contact.component';
import { Subscription, Subject } from 'rxjs';
import {
  CreateParticipantDto, IParticipantDto,
  ParticipantType, RequestStatus,
  NoteDto, NoteStatus, ParticipantDto, CameraOperator
} from '../../../services/bpp.webapi/client';
import { ConfigService } from '../../../config/config.service';
import {
  FxRequestEnableSectionAction,
  FxRequestRemoveParticipantAction,
  FxRequestSelectParticipantAction,
  FxRequestSetPropAction,
  FxRequestGetParticipantsAction,
  FxRequestGetParticipantsByCompanyAction
} from '../../../store/fx-request/actions';
import { Roles } from '../../../models/enums';
import { ValidationService } from '../../../services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getCameraOptionTextId } from '../../../helpers/request.helper';

@Component({
  selector: 'app-request-body',
  templateUrl: './request-body.component.html',
  styleUrls: ['./request-body.component.scss']
})
export class RequestBodyComponent implements OnInit {

  isLog = false;
  modalSubscription: Subscription;
  // request: FxRequest;
  private componentDestroyed: Subject<void> = new Subject();

  @Input() request: FxRequest;
  @Input() isPastEvent = false;
  @Input() identity: IDentity;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() withdraw: EventEmitter<any> = new EventEmitter<any>();
  @Output() update: EventEmitter<{ identity: IDentity, request: FxRequest }> = new EventEmitter<{ identity: IDentity, request: FxRequest }>();
  isEditMode: boolean;
  participants: IParticipantDto[];
  participantsByType: { [type: number]: IParticipantDto[] } = {};
  isLeagueMode: boolean;
  isBroadcasterMode: boolean;
  containerMode: Roles;
  CameraOperator = CameraOperator;
  hasCameraOptionPlp = true;
  hasCameraOptionBroadcaster = true;
  hasCameraOptionAudio = true;

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    private config: ConfigService,
    private validator: ValidationService,
    private translate: TranslateService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    if (this.isLog) {
      console.log('request component: ', this.request, this.identity);
      console.log('request component: isReadOnly? ', this.request.isReadOnly);
    }

    this.store.select(state => state.user.currentUser).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(user => {
        this.containerMode = user.role;
        if (user.role === Roles.League || user.role === Roles.Admin) {
          this.isLeagueMode = true;
          this.request.isReadOnly = true;
          this.request.note = this.request.leagueNote ? this.request.leagueNote.message : '';
          this.store.dispatch(new FxRequestGetParticipantsByCompanyAction(this.request.companyId));
          this.store.select(state => state.fxRequests.participantsByCompany).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(p => {
              if (p && p[this.request.companyId] && p[this.request.companyId].length > 0) {
                this.setParticipantsByType(p[this.request.companyId]);
              }
            });
        } else {
          if (user.role === Roles.Broadcaster || user.role === Roles.PLPBroadcaster) {
            this.request.note = this.request.broadcasterNote ? this.request.broadcasterNote.message : '';
            this.isBroadcasterMode = true;
            this.isEditMode = !this.request.isReadOnly && !this.isPastEvent;
          }
          if (user.role === Roles.Club) {
            this.request.note = this.request.clubNote ? this.request.clubNote.message : '';
            this.request.isReadOnly = true;
          }
          this.store.dispatch(new FxRequestGetParticipantsAction());
          this.store.select(state => state.fxRequests.participants).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(p => {
              this.setParticipantsByType(p);
            });
        }
      });
    this.hasCameraOptionPlp = this.config.hasFeature('requestCameraOptions.plp');
    this.hasCameraOptionBroadcaster = this.config.hasFeature('requestCameraOptions.broadcaster');
    this.hasCameraOptionAudio = this.config.hasFeature('requestCameraOptions.audioOnly');
  }

  getYesOption(section: IRequestSection) {
    return 'Yes';
  }

  getNoOption(section: IRequestSection) {
    return 'No';
  }


  setParticipantsByType(p: IParticipantDto[]) {

    if (p && p.length > 0) {
      this.participants = p;
      this.participantsByType = p.reduce((rv, x) => {
        (rv[x['type']] = rv[x['type']] || []).push(x);
        return rv;
      }, {});

      if (this.isLog) { console.log('participants', this.participants); }
    }

  }

  isNotEmptyNotes(notes: NoteDto) {
    return notes && notes.message && notes.message.length > 0;
  }

  isSubmittedNotes(notes: NoteDto) {
    return notes && notes.status === NoteStatus.Submitted;
  }

  getIdentity() {
    return { ...this.identity, companyId: this.request.companyId };
  }

  isCanChange() {
    return this.request.status !== RequestStatus.Withdrawn && this.request.status !== RequestStatus.Reject && this.isPastEvent !== true;
  }

  onSave() {
    if(!this.validator.isRequestValid(this.request)) {
      return;
    }
    this.isEditMode = false;
    this.save.emit({ identity: this.getIdentity(), request: this.request });
  }

  onWithdraw() {
    this.isEditMode = false;
    this.withdraw.emit({ identity: this.getIdentity(), request: this.request });
  }

  onSubmit() {
    if(!this.validator.isRequestValid(this.request)) {
      return;
    }
    this.isEditMode = false;
    this.submit.emit({ identity: this.getIdentity(), request: this.request });
  }

  onEdit() {
    this.isEditMode = true;
    if (this.isBroadcasterMode) {
      this.store.dispatch(new FxRequestSetPropAction(this.getIdentity(), 'isReadOnly', false));
    }
  }

  onCancel() {
    this.isEditMode = false;
    if (this.isBroadcasterMode) {
      this.store.dispatch(new FxRequestSetPropAction(this.getIdentity(), 'isReadOnly', true));
    }
  }

  onReject() {
    this.isEditMode = false;
    this.request.status = RequestStatus.Reject;
    this.update.emit({ identity: this.getIdentity(), request: this.request });
  }

  onUnReject() {
    this.isEditMode = true;
    this.request.status = RequestStatus.Submitted;
    this.update.emit({ identity: this.getIdentity(), request: this.request });
  }

  onApprove() {
    this.isEditMode = false;
    this.request.status = RequestStatus.Approved;
    this.update.emit({ identity: this.getIdentity(), request: this.request });
  }


  onSectionEnable(value: string, section: IRequestSection) {
    if (this.isLog) { console.log('onSectionEnable'); }
    const require = value === 'Yes';

    const identity = { ...this.getIdentity(), sectionKey: section.key };
    if (this.isLog) { console.log('onSectionEnable', identity); }
    this.store.dispatch(new FxRequestEnableSectionAction(identity, require));
    if (!require) {
      section.items.forEach(item => this.store.dispatch(new FxRequestRemoveParticipantAction(identity, item)));
    }
  }

  onCameraSection(option: CameraOperator, section: IRequestSection) {
    const require = option === CameraOperator.BroadcasterCamera;
    section.cameraOperator = option;
    section.require = require;

    const identity = { ...this.getIdentity(), sectionKey: section.key };
    if (this.isLog) { console.log('onSectionEnable', identity); }
    this.store.dispatch(new FxRequestEnableSectionAction(identity, require));
    if (!require) {
      section.items.forEach(item => this.store.dispatch(new FxRequestRemoveParticipantAction(identity, item)));
    }
  }

  newParticipant(model: CreateParticipantDto, identity: IDentity) {
    if (this.isLog) { console.log('New Participant', model); }
    const participant = ParticipantDto.fromJS({
      id: '00000000-0000-0000-0000-000000000000',
      name: model.name,
      phone: model.phone,
      email: model.email,
      type: model.type
    });
    const section = this.request.sections.find(s => s.sectionType === participant.type);
    section.items.push(participant);
    // this.store.dispatch(new FxRequestCreateParticipantAction(identity, model));
  }

  popupNewParticipant(section: IRequestSection) {
    const identity = { ...this.getIdentity(), sectionKey: section.key };

    const modalRef = this.modalService.open(AddContactComponent, { centered: true });
    const instance = modalRef.componentInstance as AddContactComponent;
    instance.type = section.sectionType;
    this.modalSubscription = instance.save.pipe(takeUntil(this.componentDestroyed))
      .subscribe(contact => this.newParticipant(contact, identity));
  }

  removeParticipant(model: IParticipantDto, section: IRequestSection,) {
    const identity = { ...this.getIdentity(), sectionKey: section.key };
    this.store.dispatch(new FxRequestRemoveParticipantAction(identity, model));
  }

  selectParticipant(id, section: IRequestSection) {
    const item = this.participantsByType[section.sectionType].find(x => x.id === id);
    if (item) {
      const identity = {
        ...this.getIdentity(),
        sectionKey: ParticipantType[item.type]
      };
      if (this.isLog) { console.log('selected participant', item); }
      this.store.dispatch(new FxRequestSelectParticipantAction(identity, item));
    }
  }

  getSelectText(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'SelectCameraOperator';
      case (ParticipantType.Reporter): return 'SelectReporter';
      case (ParticipantType.Producer): return 'SelectProducer';
      case (ParticipantType.Other): return 'SelectOther';
      case (ParticipantType.Unknow): return 'Unknown';
    }
  }

  getCameraOption(section: IRequestSection) {
    if (section.isCameraSection && section.value)
      return this.translate.instant('InPerson');

    return this.translate.instant(getCameraOptionTextId(section.cameraOperator));
  }

  showParticipantSelector(section: IRequestSection) {
    return section.cameraOperator === CameraOperator.BroadcasterCamera;
  }
}
