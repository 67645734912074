import { Action } from '@ngrx/store';
import {
  AdditionalContentFixtureDetailsDto,
  AdditionalContentType,
  CreateAdditionalContentFixtureDto,
  IAdditionalContentFixtureDetailsDto,
  ICreateAdditionalContentSessionRequestDto,
  ICreateParticipantDto,
  IParticipantDto,
  IUpdateAdditionalContentSessionRequestDto,
  UpdateAdditionalContentFixtureDto,

} from '../../services/bpp.webapi/client';
import { AdditionalContentGrouped} from './state';


export const GET_ADDITIONAL_CONTENT_EVENTS = 'get_additional_content_events';
export const GET_ADDITIONAL_CONTENT_EVENTS_SUCCESS = 'get_additional_content_events_success';
export const GET_ADDITIONAL_CONTENT_EVENTS_ERROR = 'get_additional_content_events_error';

export const GET_ADDITIONAL_CONTENT_EVENT_BY_ID = 'get_additional_content_event_by_id';
export const GET_ADDITIONAL_CONTENT_EVENT_BY_ID_SUCCESS = 'get_additional_content_event_by_id_success';
export const GET_ADDITIONAL_CONTENT_EVENT_BY_ID_ERROR = 'get_additional_content_event_by_id_error';

export const CREATE_ADDITIONAL_CONTENT_EVENT = 'create_additional_content_event';
export const CREATE_ADDITIONAL_CONTENT_EVENT_SUCCESS = 'create_additional_content_event_success';
export const CREATE_ADDITIONAL_CONTENT_EVENT_ERROR = 'create_additional_content_event_error';

export const UPDATE_ADDITIONAL_CONTENT_EVENT = 'update_additional_content_event';
export const UPDATE_ADDITIONAL_CONTENT_EVENT_SUCCESS = 'update_additional_content_event_success';
export const UPDATE_ADDITIONAL_CONTENT_EVENT_ERROR = 'update_additional_content_event_error';

export const CREATE_CLUB_CONTENT_SESSION_REQUEST = 'create_club_content_session_request';
export const CREATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS = 'create_club_content_session_request_success';
export const CREATE_CLUB_CONTENT_SESSION_REQUEST_ERROR = 'create_club_content_session_request_error';

export const UPDATE_CLUB_CONTENT_SESSION_REQUEST = 'update_club_content_session_request';
export const UPDATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS = 'update_club_content_session_request_success';
export const UPDATE_CLUB_CONTENT_SESSION_REQUEST_ERROR = 'update_club_content_session_request_error';

export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS = 'get_club_content_session_participants';
export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS_SUCCESS = 'get_club_content_session_participants_success';
export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS_ERROR = 'get_club_content_session_participants_error';

export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS_BY_COMPANY = 'get_club_content_session_participants_by_company';
export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS_BY_COMPANY_SUCCESS = 'get_club_content_session_participants_by_company_success';
export const GET_CLUB_CONTENT_SESSION_PARTICIPANTS_BY_COMPANY_ERROR = 'get_club_content_session_participants_by_company_error';

export const CREATE_CLUB_CONTENT_SESSION_PARTICIPANT = 'create_club_content_session_participant';
export const CREATE_CLUB_CONTENT_SESSION_PARTICIPANT_SUCCESS = 'create_club_content_session_participant_success';
export const CREATE_CLUB_CONTENT_SESSION_PARTICIPANT_ERROR = 'create_club_content_session_participant_error';

export class GetAdditionalContentEventsAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENTS;
  constructor(public isPast: boolean, public clubId?: number) { }
}

export class GetAdditionalContentEventsSuccessAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENTS_SUCCESS;
  constructor(public payload: AdditionalContentGrouped) {
  }
}

export class GetAdditionalContentEventsErrorAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENTS_ERROR;
  constructor(public payload: any) { }
}

export class GetAdditionalContentEventByIdAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENT_BY_ID;
  constructor(public id: string, public contentType: AdditionalContentType) { }
}

export class GetAdditionalContentEventByIdSuccessAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENT_BY_ID_SUCCESS;
  constructor(public payload: IAdditionalContentFixtureDetailsDto) {
  }
}

export class GetAdditionalContentEventByIdErrorAction implements Action {
  readonly type = GET_ADDITIONAL_CONTENT_EVENT_BY_ID_ERROR;
  constructor(public payload: any) {
  }
}

export class CreateAdditionalContentEventAction implements Action {
  readonly type = CREATE_ADDITIONAL_CONTENT_EVENT;
  constructor(public payload: CreateAdditionalContentFixtureDto) {
  }
}

export class CreateAdditionalContentEventSuccessAction implements Action {
  readonly type = CREATE_ADDITIONAL_CONTENT_EVENT_SUCCESS;
  constructor(public payload: AdditionalContentFixtureDetailsDto) {
  }
}

export class CreateAdditionalContentEventErrorAction implements Action {
  readonly type = CREATE_ADDITIONAL_CONTENT_EVENT_ERROR;
  constructor(public payload: any) {
  }
}

export class UpdateAdditionalContentEventAction implements Action {
  readonly type = UPDATE_ADDITIONAL_CONTENT_EVENT;
  constructor(public payload: UpdateAdditionalContentFixtureDto) {
  }
}

export class UpdateAdditionalContentEventSuccessAction implements Action {
  readonly type = UPDATE_ADDITIONAL_CONTENT_EVENT_SUCCESS;
  constructor(public payload: AdditionalContentFixtureDetailsDto) {
  }
}

export class UpdateAdditionalContentEventErrorAction implements Action {
  readonly type = UPDATE_ADDITIONAL_CONTENT_EVENT_ERROR;
  constructor(public payload: any) {
  }
}

export class CreateClubContentSessionRequestAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_REQUEST;

  constructor(public payload: ICreateAdditionalContentSessionRequestDto) {
  }
}

export class CreateClubContentSessionRequestSuccessAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS;
  constructor(public payload: IAdditionalContentFixtureDetailsDto) {
  }
}

export class CreateClubContentSessionRequestErrorAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_REQUEST_ERROR;
  constructor(public payload: any) {
  }
}

export class UpdateClubContentSessionRequestAction implements Action {
  readonly type = UPDATE_CLUB_CONTENT_SESSION_REQUEST;
  constructor(public payload: IUpdateAdditionalContentSessionRequestDto) {
  }
}

export class UpdateClubContentSessionRequestSuccessAction implements Action {
  readonly type = UPDATE_CLUB_CONTENT_SESSION_REQUEST_SUCCESS;
  constructor(public payload: IAdditionalContentFixtureDetailsDto) {
  }
}

export class UpdateClubContentSessionRequestErrorAction implements Action {
  readonly type = UPDATE_CLUB_CONTENT_SESSION_REQUEST_ERROR;
  constructor(public payload: any) {
  }
}

export class GetClubContentSessionParticipantsAction implements Action {
  readonly type = GET_CLUB_CONTENT_SESSION_PARTICIPANTS;
  constructor() {
  }
}

export class GetClubContentSessionParticipantsSuccessAction implements Action {
  readonly type = GET_CLUB_CONTENT_SESSION_PARTICIPANTS_SUCCESS;
  constructor(public payload: Array<IParticipantDto>) {
  }
}

export class GetClubContentSessionParticipantsErrorAction implements Action {
  readonly type = GET_CLUB_CONTENT_SESSION_PARTICIPANTS_ERROR;
  constructor(public payload: any) {
  }
}

export class GetClubContentSessionParticipantByCompanyAction implements Action {
  readonly type = GET_CLUB_CONTENT_SESSION_PARTICIPANTS_BY_COMPANY;
  constructor(public payload: string) {
  }
}

export class CreateClubContentSessionParticipantAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_PARTICIPANT;
  constructor(public payload: ICreateParticipantDto) {
  }
}

export class CreateClubContentSessionParticipantSuccessAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_PARTICIPANT_SUCCESS;
  constructor(public payload: IParticipantDto) {
  }
}

export class CreateClubContentSessionParticipantErrorAction implements Action {
  readonly type = CREATE_CLUB_CONTENT_SESSION_PARTICIPANT_ERROR;
  constructor(public payload: any) {
  }
}

export type All = GetAdditionalContentEventsAction
    | GetAdditionalContentEventsSuccessAction
    | GetAdditionalContentEventsErrorAction
    | GetAdditionalContentEventByIdAction
    | GetAdditionalContentEventByIdSuccessAction
    | GetAdditionalContentEventByIdErrorAction
    | CreateAdditionalContentEventAction
    | CreateAdditionalContentEventSuccessAction
    | CreateAdditionalContentEventErrorAction
    | UpdateAdditionalContentEventAction
    | UpdateAdditionalContentEventSuccessAction
    | UpdateAdditionalContentEventErrorAction
    | CreateClubContentSessionRequestAction
    | CreateClubContentSessionRequestSuccessAction
    | CreateClubContentSessionRequestErrorAction
    | UpdateClubContentSessionRequestAction
    | UpdateClubContentSessionRequestSuccessAction
    | UpdateClubContentSessionRequestErrorAction
    | GetClubContentSessionParticipantsAction
    | GetClubContentSessionParticipantsSuccessAction
    | GetClubContentSessionParticipantsErrorAction
    | GetClubContentSessionParticipantByCompanyAction
    | CreateClubContentSessionParticipantAction
    | CreateClubContentSessionParticipantSuccessAction
    | CreateClubContentSessionParticipantErrorAction
    ;
