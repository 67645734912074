import { ClubMatchInterviewEventDetailsDto, ClubMatchInterviewEventDto, IMatchInfoDto, MatchInterviewEventDetailsDto, MatchInterviewEventDto } from "src/app/services/bpp.webapi/client";

export interface IClubInterviewsGrouped {
    upcoming: IClubInterviewByWeek[];
    past: IClubInterviewByWeek[];
}

export interface IClubInterviewByMatch {
    key: string;
    value: MatchInterviewEventDto[];
}

export interface IClubInterviewByWeek {
    key: string;
    value: IClubInterviewByMatch[];
}

export class ClubInterviewState {
    loading: boolean;
    updateLoading: boolean;
    error: boolean;
    filter: { matchRound: number; };

    clubItems: {
        upcoming: Array<ClubMatchInterviewEventDto>;
        past: Array<ClubMatchInterviewEventDto>;
    };

    detailsForClub: {[matchId: string]: ClubMatchInterviewEventDetailsDto };
    detailsForLeagueBroadcaster: {[matchId: string]: MatchInterviewEventDetailsDto };

    byGameWeek: { [key: string]: any };

    broadcasterByGameWeek: { [key: string]: any };

    selectedMatch: IMatchInfoDto;

    constructor() {
        this.loading = false;
        this.error = false;
        this.clubItems = { upcoming: [], past: [] };
        this.detailsForClub = {};
        this.detailsForLeagueBroadcaster = {};
        this.byGameWeek = {};
        this.broadcasterByGameWeek = {};
        this.filter = {
            matchRound: 1
        };
    }
}
