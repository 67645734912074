import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'eventFilter',
    pure: false
})
export class EventFilterPipe implements PipeTransform {

    constructor() { }
    transform(items: any[], filter: {date: moment.Moment, completed: boolean}): any {
        if (!items || !filter.date || filter.completed === undefined) {
            return items;
        }
        return items.filter(item => this.isCompeletedEvent(item, filter.date) === filter.completed);
    }

    isCompeletedEvent(event, date: moment.Moment) {
        if (event.eventDate.year() > 1) {
            if (date.isAfter(event.eventDate) || date.isSame(event.eventDate)) {
                return true;
            } else {
                return false;
            }
        }
        const temp = moment(event.date).add(1, 'months').date(0);
        if (date.isAfter(temp) || date.isSame(temp)) {
            return true;
        } else {
            return false;
        }
    }
}


