import { Type } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {
  @Input() title: string;
  @Input() initAction: any;
  @Input() isSingle: boolean = false;
  @Input() idProperty: string;
  @Input() nameProperty: string;
  @Input() constItems: Array<any>;
  @Input() pickedItems: Array<any>;
  @Output() save: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  items: Array<any> = [];
  checkBox: Array<any> = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

    this.checkBox = this.constItems.map(item => {
      const index = this.findWithAttr(this.pickedItems, this.idProperty, item[this.idProperty]);
      return { checked: index !== -1, id: item[this.idProperty], name: item[this.nameProperty] };
    });

  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  changeCheckbox(e) {
    e.checked = !e.checked;
    if(this.isSingle) {
      this.checkBox.forEach(item => {
        if (item !== e) {
          item.checked = false;
        }
      });
    }
  }

  onSave() {
    this.checkBox.forEach(item => {
      if (item.checked) {
        const model = this.constItems.find(x => x[this.idProperty] === item.id);
        this.items.push(this.initAction(model));
      }
    });

    this.save.emit(this.items);
    this.activeModal.close();
  }

}
