
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ITrainingDetailsDto } from '../../../../services/bpp.webapi/client';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { ConfigService } from '../../../../config/config.service';
import * as moment from 'moment';
import { UpdateTrainigEventAction } from '../../../../store/training/actions';
import { TrainingMapper } from '../../../../mappers/training.mapper';
import * as helper from '../../../../helpers/common';
import { StatusHelper } from '../../../../helpers/statuses-helper';
import { DateBinder } from '../../dateBinder';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { BaseComponent } from '../../../base-component/base.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-club-training-details-draft',
  templateUrl: './club-training-details-draft.component.html'
})
export class ClubTrainingDetailsDraftComponent extends BaseComponent implements OnInit, OnDestroy {
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  @Input() training: ITrainingDetailsDto;
  @Output() toggleSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataLoaded: boolean;
  subscription;
  isLog = false;
  lastSaved: string;
  mapper: TrainingMapper;
  dateBinder;
  user: any;
  clubName: string;
  clubLogoImagePath = '';
  isLfp: boolean;

  constructor(private store: Store<AppState>,
    private appInsightsService: AppInsightClientService,
    private router: Router,
    private config: ConfigService) {
    super();
    this.isLfp = config.isLfp();
    this.isLog = this.config.isDebug();
    this.mapper = new TrainingMapper();
    if (this.training !== undefined) {
      this.dateBinder = new DateBinder(this.training.start, this.training.arrival, this.config.get('defaultTimeZone'));
    }
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
          this.clubName = state.currentUser.companyName;
          this.clubLogoImagePath = '../../../../assets/teams/48/t' + this.training.clubId + '.png';
        }
      });
    if (this.training !== undefined) {
      this.dateBinder = new DateBinder(this.training.start, this.training.arrival, this.config.get('defaultTimeZone'));
    }
    if (this.isLog) {
      console.log('Training draft init:', this.training);
    }

    const now = moment().utc();
    this.minDate = { year: now.year(), month: now.month() + 1, day: now.date() };
    this.lastSaved = helper.getLastSavedText(this.training.updatedOn, this.training.updatedByName, StatusHelper.getStatusText(this.training.status));
    if (!this.training.clubNote) {
      this.training.clubNote = this.mapper.getClubNote();
    }
  }

  dateChanged(e) {
    this.dateBinder.dateChanged(e);
  }

  goBack() {
    this.router.navigate([`trainings`]);
  }

  saveEvent(status) {
    this.training.status = status;
    this.appInsightsService.logEvent('Update training (club)', {
      trainingId: this.training.id,
      status: StatusHelper.getStatusText(status),
      userRole: this.user.role,
      userEmail: this.user.email,
      companyId: this.user.companyId,
      companyName: this.user.companyName
    });
    this.dateBinder.bindToTraining(this.training);
    const model = this.mapper.update(this.training);
    model.status = status;
    this.toggleSpinner.emit(true);
    this.store.dispatch(new UpdateTrainigEventAction(model));
  }
}
