import { Component, OnInit, Input } from '@angular/core';
import { FxRequest, IRequestSection } from '../../../store/fx-request/state';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NotesService } from '../../../services/notes.service';
import { SaveNoteDto, NoteStatus, CameraOperator } from '../../../services/bpp.webapi/client';
import { ConfigService } from '../../../config/config.service';
import { getCameraOptionTextId } from 'src/app/helpers/request.helper';

@Component({
  selector: 'app-club-request',
  templateUrl: './club-request.component.html',
  styleUrls: ['./club-request.component.scss']
})
export class ClubRequestComponent implements OnInit {
  @Input() requests: FxRequest[];
  @Input() isReadOnly: boolean;
  @Input() isPastFixture: boolean;
  isLog = false;
  isEditMode = false;
  isEditMessage = false;
  CameraOperator = CameraOperator;

  constructor(private config: ConfigService,
    private alertService: AlertService,
    private translationService: TranslateService,
    private notesService: NotesService,
    private translate: TranslateService) {
    this.isLog = this.config.isDebug();
  }

  ngOnInit() {
    if (this.isLog) {
      console.log('this.requests', this.requests);
      console.log('isPastFixture', this.isPastFixture);
    }
  }

  saveRequestNote(request, status) {
    const note = this.generateNoteData(request, status);
    this.notesService.saveClubRequestNote(note)
      .subscribe((resp) => {
        if (resp) {
          this.translationService.get('NoteUpdated').subscribe((value) => {
            this.alertService.success(value);
          });
          request.clubNote = resp;
        }
      }, (err) => {
        if (this.config.isDebug()) {
          console.log(err);
        }
        this.alertService.success(err);
      });
  }

  generateNoteData(request: FxRequest, status: NoteStatus): any {
    return SaveNoteDto.fromJS({
      status: status,
      message: request.clubNote.message,
      objectId: request.id
    });
  }

  getCameraOption(section: IRequestSection) {
    return this.translate.instant(getCameraOptionTextId(section.cameraOperator));
  }
}
