import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfigService } from "src/app/config/config.service";

import { AppInsightClientService } from "src/app/services/app-insight.service";
import { ReportService } from "src/app/services/report.service";
import { AppState } from "src/app/store/app.state";
import { GetInterviewsSummaryReportAction } from "src/app/store/report/actions";

@Component({
	selector: 'app-interviews-summary-report',
	templateUrl: './interviews-summary-report.component.html'
})
export class InterviewsSummaryReportComponent implements OnInit, OnDestroy {
	isSpinner: boolean;
	private componentDestroyed: Subject<void> = new Subject();
	reportTableModel = {
		items: [],
		totalCount: 0,
		orderBy: undefined,
		isAsc: true
	};
	
	constructor(private store: Store<AppState>, private appInsightsService: AppInsightClientService,
		 private reportService: ReportService,
		 private config:ConfigService) { }

	ngOnInit(): void {
		this.store.select(state => state.report)
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(state => {
				if (state.loading || !state.interviewsSummaryItems) {
					return;
				}

				this.isSpinner = false;
				this.reportTableModel = {
					orderBy: '',
					isAsc: false,
					totalCount: state.interviewsSummaryItems.length,
					items: state.interviewsSummaryItems
				}
			});

		this.isSpinner = true;
		this.store.dispatch(new GetInterviewsSummaryReportAction());
	}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
		this.componentDestroyed.unsubscribe();
	}

	order(name) {
		if (this.reportTableModel === undefined || this.reportTableModel.totalCount === 0) {
			return;
		}
		if (this.reportTableModel.orderBy === name) {
			this.reportTableModel.isAsc = !this.reportTableModel.isAsc;
		} else {
			this.reportTableModel.isAsc = true;
		}
		this.reportTableModel.orderBy = name;
		this.reportTableModel.items = this.reportTableModel.items.sort((a, b) => {
			if (this.reportTableModel.isAsc) {
				return a[name] > b[name] ? 1 : (a[name] < b[name] ? -1 : 0);
			} else {
				return a[name] < b[name] ? 1 : (a[name] > b[name] ? -1 : 0);
			}
		});
	}

	getExcel() {
		this.isSpinner = true;
		this.appInsightsService.logEvent('Get Interviews Summary Report', {});

		this.reportService.getInterviewsSummaryExcelReport()
			.subscribe(resp => {
				this.isSpinner = false;

				if (resp) {
					const url = window.URL.createObjectURL(resp.data);
					const link = document.createElement('a');
					link.download =this.config.isLfp() ? 'InterviewsSummaryReport.xlsx' :'ICSInterviewsSummaryReport.xlsx';
					link.href = url;
					document.body.appendChild(link);

					link.click();
					document.body.removeChild(link);
				}
			}, err => {
				this.isSpinner = false;
			});
	}

	getPdf() {
		this.isSpinner = true;
		this.appInsightsService.logEvent('Get Interviews Summary Pdf Report', {});

		this.reportService.getInterviewsSummaryPdfReport()
			.subscribe(resp => {
				this.isSpinner = false;

				if (resp) {
					const url = window.URL.createObjectURL(resp.data);
					const link = document.createElement('a');
					link.download =this.config.isLfp()  ? 'ICSInterviewsSummaryReport.pdf' : 'ICSInterviewsSummaryReport.pdf';
					link.href = url;
					document.body.appendChild(link);

					link.click();
					document.body.removeChild(link);
				}
			}, err => {
				this.isSpinner = false;
			});
	}
}