import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringJoin',
    pure: false
})
export class StringJoinPipe implements PipeTransform {

    constructor() { }
    transform(value: string[] | undefined, maxChars = 0) {
        if (!value || 0 === value.length) {
            return value;
        }
        const joined = value.join(',');
        if (maxChars > 0 && joined.length > maxChars) {
            return joined.substr(0, maxChars) + '...';
        } else {
            return joined;
        }
    }
}
