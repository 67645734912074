import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/config/config.service';
import { clubInterviewEventTypes, playersTypes } from 'src/app/helpers/fixture-helper';
import { Roles } from 'src/app/models/enums';
import { LocationTypeDto, PlayerDto, LocationDto, CreateLocationDto, IInterviewEventDetailsDto, InterviewEventType, InterviewEventRequestSlotDto, Players } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetLocationTypesAction } from 'src/app/store/location-types/actions';
import { GetLocationsAction, CreateLocationAction } from 'src/app/store/location/actions';
import { AddLocationComponent } from '../../common/add-location/add-location.component';
import * as helper from '../../../helpers/date-helper';
import { getEmptyGuid } from 'src/app/helpers/common';

@Component({
  selector: 'app-club-interview-body',
  templateUrl: './club-interview-body.component.html',
  styleUrls: ['./club-interview-body.component.scss']
})
export class ClubInterviewBodyComponent implements OnInit, OnDestroy, OnChanges {

  locationSubscription: Subscription;
  eventModel: IInterviewEventDetailsDto = null;
  locationTypesSubscription: Subscription;
  locationTypes: LocationTypeDto[];
  isLeagueMode: boolean;

  minDate = {
    year: moment().utc().year(),
    month: moment().utc().month(),
    day: moment().utc().date()
  };
  maxDate = {
    year: moment().utc().year(),
    month: moment().utc().month(),
    day: moment().utc().date()
  };

  startDate: moment.Moment;
  arrivalTime: moment.Moment;
  duration: moment.Moment;
  startTime: moment.Moment;

  @Output() modelChange: EventEmitter<IInterviewEventDetailsDto> = new EventEmitter<IInterviewEventDetailsDto>();
  @Input() isReadOnly: boolean;
  @Input() players: PlayerDto[];
  @Input() isPastInterview: boolean;
  @Input() teamId: number;
  @Input() matchDate:moment.Moment;
  interviewEventTypes = clubInterviewEventTypes;
  pTypes = playersTypes;


  @Input()
  get clubInterviewEvent(): IInterviewEventDetailsDto {
    return this.eventModel;
  }
  set clubInterviewEvent(value) {
    this.eventModel = value;
    this.startDate = moment(this.eventModel.startUtc);
    this.startTime = moment(this.eventModel.startUtc);
    this.arrivalTime = moment(this.eventModel.arrivalUtc);
    if (this.eventModel.duration){
      this.eventModel.duration = moment.duration(this.eventModel.duration.asMinutes() - momentTz.tz(this.timezone).utcOffset(),'minutes');
    }
    this.duration = moment().utc().startOf('day').add(this.eventModel.duration);
    this.modelChange.emit(this.eventModel);
  }
  @Input() userRole: Roles;

  selectedLocation: LocationDto;
  locations: LocationDto[];
  modalSubscription;
  isLog = false;
  timezone :string;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    config: ConfigService) {
   this.timezone = config.get('defaultTimeZone');
    this.isLog = config.isDebug();
    this.isLfp = config.isLfp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.clubInterviewEvent && changes.clubInterviewEvent.currentValue) {
      const evt = changes.clubInterviewEvent.currentValue;
      evt.slots.forEach(x => {
        const duration = moment.duration(x.endTime.diff(x.startTime, 'milliseconds'), 'milliseconds');
        x.duration = {
          hour: duration.hours(),
          minute: duration.minutes()
        } 
      });
    }
  }

  ngOnInit() {
    if (this.isLog) { console.log('Club-Interview-body init:', this.eventModel); }

    this.minDate = helper.getMinDate(this.eventModel.arrivalUtc);
    this.maxDate = this.isLfp ? helper.getLfpMaxDate(this.matchDate) : helper.getMaxDate(this.eventModel.arrivalUtc);

    this.isLeagueMode = this.userRole === Roles.League || this.userRole === Roles.Admin;

    this.locationSubscription = this.store
      .select(e => e.location.items)
      .subscribe(res => {
        if (res && res.length > 0) {
          this.locations = res;
          this.selectedLocation = this.locations.find(x => x.locationId === this.clubInterviewEvent.location.locationId);
          this.store.dispatch(new GetLocationTypesAction());
        }
      });
    this.store.dispatch(new GetLocationsAction(this.teamId));
    this.store.dispatch(new GetLocationTypesAction());
  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  initNewPlayer(model) {
    const player = new PlayerDto();
    player.init(model);
    return player;
  }

  concatDate(date: moment.Moment, time: moment.Moment) {
    const h = time.hour();
    const m = time.minute();
    const result = moment(date, 'YYYY-MM-DD').hour(time.hour()).minute(time.minute());
    const str = result.format('YYYY-MM-DD HH:mm');
    return result;
  }

  printLog(what) {
    if (this.isLog) {
      console.log(`${what} changed`);
      console.log(`[arrival]:`, this.eventModel.arrivalUtc.toISOString());
      console.log(`[start]:`, this.eventModel.startUtc.toISOString());
      console.log(`[duration]:`, this.eventModel.duration.toISOString());
    }
  }

  dateChanged(e: moment.Moment) {
    this.startDate = e;
    this.arrivalTime = this.concatDate(e, this.arrivalTime);
    this.startTime = this.concatDate(e, this.startTime);
    this.eventModel.arrivalUtc = this.concatDate(e, this.arrivalTime);
    this.eventModel.startUtc = this.concatDate(e, this.startTime);
    this.printLog('Interview Date');
  }

  arrivalTimeChanged(time: moment.Moment) {
    this.arrivalTime = this.concatDate(this.startDate, time);
    this.eventModel.arrivalUtc = this.concatDate(this.startDate, time);
    this.printLog('Interview Arrival');
  }

  startTimeChanged(time: moment.Moment) {
    this.startTime = this.concatDate(this.startDate, time);
    this.eventModel.startUtc = this.concatDate(this.startDate, time);
    this.printLog('Interview Start');
  }

  durationChanged(time: moment.Moment) {
    this.duration = time;
    this.eventModel.duration = moment.duration(this.duration.diff(moment().utc().startOf('day'), 'milliseconds'), 'milliseconds');
    this.printLog('Interview Duration');
  }

  openAddLocation() {
    const modalRef = this.modalService.open(AddLocationComponent);
    this.modalSubscription = modalRef.componentInstance.save.subscribe(location => this.saveLocation(location));
  }

  saveLocation(location: CreateLocationDto) {
    this.store.dispatch(new CreateLocationAction(location));
    this.store.dispatch(new GetLocationsAction(location.clubId));
    this.modalSubscription.unsubscribe();
  }

  changeLanguages(languages) {
    this.eventModel.languages = languages;
  }

  changeSlotLanguages(languages, slot) {
    slot.languages = languages;
  }

  addSlot() {
    const startTime = moment(this.eventModel.slots.length > 0 ? this.eventModel.slots[this.eventModel.slots.length - 1].endTime : this.eventModel.startUtc).utc();
    const endTime = moment(startTime).add(10, 'minutes').utc();
    this.eventModel.slots.push(new InterviewEventRequestSlotDto({
      id: getEmptyGuid(),
      requestId: null,
      startTime: startTime,
      endTime: endTime,
      languages: [],
      peoples: []
    }));
  }

  firstSlotStartChanged(time: moment.Moment) {
    this.slotChanged(time, 0, 'startTime');
  }

  slotChanged(time: moment.Moment, index: number, prop: string) {
    this.eventModel.slots[index][prop] = time;
    (this.eventModel.slots[index] as any).duration = moment().utc().startOf('day').add(moment.duration(this.eventModel.slots[index].endTime.diff(this.eventModel.slots[index].startTime, 'milliseconds'), 'milliseconds'));
  }

  removeSlot(i:number) {
     this.eventModel.slots.splice(i,1);
  }


  getInterviewICSSettingTypeText(val: number) {
    if (val !== undefined && this.locationTypes) {
      return this.locationTypes.find(x => x.id === val).name;
    }
    return '';
  }

  getInterviewEventTypeText(val: number) {
    if (val !== undefined) {
      return this.interviewEventTypes.find(x => x.key === val).name;
    }
    return '';
  }

  getPlayersText(val: Players) {
    if (val !== undefined) {
      return this.pTypes.find(x => x.key === val).name;
    }
    return '';
  }

  onChangeInterviewType(item: { key: number; name: string }) {
    this.eventModel.interviewType = item.key;
    this.eventModel.duration = moment.duration(0 - momentTz.tz(this.timezone).utcOffset(),'minutes')
    this.eventModel.slots = [];
    if (this.eventModel.interviewType ===1) {
      this.addSlot();
    }
    this.duration = moment().utc().startOf('day').add(this.eventModel.duration);
  }

  onChangeEventPlayers(items) {
    this.eventModel.peoples = items;
  }

  onChangePlayers(items, model) {
    model.peoples = items;
  }

  onChangePlayersType(item) {
    this.eventModel.players = item.key;
    this.eventModel.slots.forEach(x => {
      x.peoples = [];
      x.languages = [];      
    })
  }

  getTimeWithInterval(time: moment.Moment) {
    return moment(time).add(5, 'minutes');
  }

  getDurationText(slot: InterviewEventRequestSlotDto) {
    return helper.getTimeStringForTimePicker((slot as any).duration);
  }


  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
