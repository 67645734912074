import { Component, OnInit } from '@angular/core';
import { AppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../authentication/auth.service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  errorMessages: string[];

  constructor(private store: Store<AppState>, private translate: TranslateService, private authService: AuthService) { }

  ngOnInit() {
    this.store.select(state => state.user).subscribe(user => {
      if (user.error) {
        this.errorMessages = user.errorMessages;
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }
}
