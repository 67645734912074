import { Action } from '@ngrx/store';
import { PersonReportDataDto, ContentSessionTypeDataDto, InterviewsSummaryDataDto, BroadcasterRequestsDataDto } from '../../services/bpp.webapi/client';

export const GET_PERSONS_REPORT = 'get_persons_report_action';
export const GET_PERSONS_REPORT_SUCCESS = 'get_persons_report_action_success';
export const GET_PERSONS_REPORT_ERROR = 'get_persons_report_action_error';

export const GET_CONTENT_SESSION_TYPE_REPORT = 'get_content_session_type_report_action';
export const GET_CONTENT_SESSION_TYPE_REPORT_SUCCESS = 'get_content_session_type_report_action_success';
export const GET_CONTENT_SESSION_TYPE_REPORT_ERROR = 'get_content_session_type_report_action_error';

export const GET_BROADCASTER_REQUESTS_REPORT = 'get_broadcaster_requests_report_action';
export const GET_BROADCASTER_REQUESTS_REPORT_SUCCESS = 'get_broadcaster_requests_report_action_success';
export const GET_BROADCASTER_REQUESTS_REPORT_ERROR = 'get_broadcaster_requests_report_action_error';

export const GET_INTERVIEWS_SUMMARY_REPORT = 'get_interviews_summary_report_action';
export const GET_INTERVIEWS_SUMMARY_REPORT_SUCCESS = 'get_interviews_summary_report_action_success';
export const GET_INTERVIEWS_SUMMARY_REPORT_ERROR = 'get_interviews_summary_report_action_error';

export class GetPersonsReportAction implements Action {
    readonly type = GET_PERSONS_REPORT;
    constructor(public payload: number) { }
}

export class GetPersonsReportSuccessAction implements Action {
    readonly type = GET_PERSONS_REPORT_SUCCESS;
    constructor(public payload: Array<PersonReportDataDto>) {
    }
}

export class GetPersonsReportErrorAction implements Action {
    readonly type = GET_PERSONS_REPORT_ERROR;
    constructor(public payload: any) {
    }
}

export class GetContentSessionTypeReportAction implements Action {
    readonly type = GET_CONTENT_SESSION_TYPE_REPORT;
    constructor() { }
}

export class GetContentSessionTypeReportSuccessAction implements Action {
    readonly type = GET_CONTENT_SESSION_TYPE_REPORT_SUCCESS;
    constructor(public payload: Array<ContentSessionTypeDataDto>) { }
}

export class GetContentSessionTypeReportErrorAction implements Action {
    readonly type = GET_CONTENT_SESSION_TYPE_REPORT_ERROR;
    constructor(public payload: any) { }
}

export class GetBroadcasterRequestsReportAction implements Action {
    readonly type = GET_BROADCASTER_REQUESTS_REPORT;
    constructor(public payload: number) { }
}

export class GetBroadcasterRequestsReportSuccessAction implements Action {
    readonly type = GET_BROADCASTER_REQUESTS_REPORT_SUCCESS;
    constructor(public payload: Array<BroadcasterRequestsDataDto>) { }
}

export class GetBroadcasterRequestsReportErrorAction implements Action {
    readonly type = GET_BROADCASTER_REQUESTS_REPORT_ERROR;
    constructor(public payload: any) { }
}

export class GetInterviewsSummaryReportAction implements Action {
    readonly type = GET_INTERVIEWS_SUMMARY_REPORT;
    constructor() { }
}

export class GetInterviewsSummaryReportSuccessAction implements Action {
    readonly type = GET_INTERVIEWS_SUMMARY_REPORT_SUCCESS;
    constructor(public payload: Array<InterviewsSummaryDataDto>) { }
}

export class GetInterviewsSummaryReportErrorAction implements Action {
    readonly type = GET_INTERVIEWS_SUMMARY_REPORT_ERROR;
    constructor(public payload: any) { }
}


export type All = GetPersonsReportAction
    | GetPersonsReportErrorAction
    | GetPersonsReportSuccessAction
    | GetContentSessionTypeReportAction
    | GetContentSessionTypeReportSuccessAction
    | GetContentSessionTypeReportErrorAction
    | GetBroadcasterRequestsReportAction
    | GetBroadcasterRequestsReportSuccessAction
    | GetBroadcasterRequestsReportErrorAction
    | GetInterviewsSummaryReportAction
    | GetInterviewsSummaryReportSuccessAction
    | GetInterviewsSummaryReportErrorAction;
