import { Roles } from "src/app/models/enums";
import { EventStatus, RequestStatus } from "src/app/services/bpp.webapi/client";
import { ISessionData } from "../new-dashboard/models/session-data.interface";
import { DashboardItemStatus, DashboardItemType } from "src/app/services/bpp.webapi/dashboard.client";
import { APPROVED_COLOR, COMPLETED_COLOR, DRAFT_COLOR, REJECTED_COLOR, REQUESTS_COLOR, SUBMITTED_COLOR, WITHDRAWN_COLOR } from "src/app/models/constants";

export function getStepsStatus(s: ISessionData,userId:string):any {
    switch (s.userRole) {
        case Roles.Admin:
        case Roles.League:
            return getStepsStatusForLeague(s);
        case Roles.Broadcaster:
        case Roles.PLPBroadcaster:
            return getStepsStatusForBroadcaster(s,userId);
        case Roles.Club:
            return getStepsStatusForClub(s);
        default:
            break;
    }
  }

function getStepsStatusForLeague(s: ISessionData) {
    let items = [];
    let content = null;
    const stepStatus = getStepsStatusFromEvent(s,Roles.League,'',s.isOverDeadline);
    if (stepStatus==StepsStatus.LeagueCompleted || stepStatus == StepsStatus.Requests){
        content =s.requests ? s.requests.length : s.totalRequests;
    }
    items=getSteps(stepStatus,content,s.requests.filter(c=>isNotDraft(c.status)))
    return items;
}

function getStepsStatusForBroadcaster(s: ISessionData,userId:string) {
    let items = [];
    const stepStatus = getStepsStatusFromEvent(s,Roles.Broadcaster,userId);
    items=getSteps(stepStatus,null,null)
    return items;
}


function getStepsStatusForClub(s: ISessionData) {
    let items = [];
    const stepStatus = getStepsStatusFromEvent(s,Roles.Club);
    items=getSteps(stepStatus,null,null)
    return items;
}

function getStepsStatusFromEvent (s:ISessionData,role:Roles,userId?:string,isCompleted?:boolean):StepsStatus {
    if (role==Roles.Club){
        if (s.eventStatus===EventStatus.Reject){
            return StepsStatus.Rejected;
        }
        if (s.eventStatus!=EventStatus.Submitted && s.requests && s.requests.length>0 && s.requests.some(c=>isNotDraft(c.status))) {
            return StepsStatus.Approved;
        }
        switch (s.eventStatus) {
            case EventStatus.Approved:
                return StepsStatus.Approved;
            case EventStatus.Submitted:
                return StepsStatus.Submitted;
        }
    }
    if (role===Roles.League) {
        if (s.eventStatus!=EventStatus.Submitted && s.requests && s.requests.length>0 && s.requests.some(c=>isNotDraft(c.status))) {
            if (s.eventStatus==EventStatus.Reject){
                return StepsStatus.Rejected;
            }
          
            return StepsStatus.Requests;
        }
        switch (s.eventStatus) {
            case EventStatus.Submitted:
                return StepsStatus.Submitted;
            case EventStatus.Approved:
                return StepsStatus.Approved;
            case EventStatus.Reject:
                return StepsStatus.Rejected;
        }
    }
    if (role===Roles.Broadcaster || role===Roles.PLPBroadcaster){
        if (!s.requests.find(c=>c.companyId==userId) ){
            return StepsStatus.Unknown;
        }
        if (s.requests && s.requests.length>0 &&
            (s.dashboardItemType==DashboardItemType.InterviewRequest 
                || s.dashboardItemType==DashboardItemType.Interview
                || s.dashboardItemType==DashboardItemType.InterviewEvent
                || s.dashboardItemType==DashboardItemType.InterviewEventRequest
                || s.dashboardItemType==DashboardItemType.AdditionalContent
                || s.dashboardItemType==DashboardItemType.AdditionalContentRequest
            )
        )
        if (s.requests.filter(c=>c.companyId==userId).every(c=>c.status==RequestStatus.Draft || c.status ==RequestStatus.Unknown))
            return StepsStatus.Draft;
        if (s.requests.filter(c=>c.companyId==userId).every(c=>c.status==RequestStatus.Submitted))
            return StepsStatus.Submitted;
        if (s.requests.filter(c=>c.companyId==userId).every(c=>c.status==RequestStatus.Approved))
            return StepsStatus.Approved;
        if (s.requests.filter(c=>c.companyId==userId).every(c=>c.status==RequestStatus.Reject))
            return StepsStatus.Rejected;
        if (s.requests.filter(c=>c.companyId==userId).every(c=>c.status==RequestStatus.Withdrawn))
            return StepsStatus.Withdrawn;
    }
    
    switch (s.dashboardItemStatus) {
        case DashboardItemStatus.NotSubmitted:
            return StepsStatus.Draft;
        case DashboardItemStatus.Approved:
            return StepsStatus.Approved;
        case DashboardItemStatus.Rejected:
            return StepsStatus.Rejected;
        case DashboardItemStatus.RequestWithdrawn: 
            return StepsStatus.Withdrawn;
        case DashboardItemStatus.Submitted: 
            return StepsStatus.Submitted;
        default:
            break;
    }
}

function getSteps(step:StepsStatus,content?:string,extendedData?:any){
    if (content){
        content = "'"+content+"'";
    }
    let items: IItem[]=[];
    switch (step) {
        case StepsStatus.Unknown:
            return items;
        case StepsStatus.Draft:
            return [{label:getStepperStatusText(StepsStatus.Draft),color:DRAFT_COLOR  }]
        case StepsStatus.Submitted:
            items= [{label:getStepperStatusText(StepsStatus.Submitted),color:SUBMITTED_COLOR  }]
            return items;
        case StepsStatus.Approved:
            items = [
                {label:getStepperStatusText(StepsStatus.Submitted),color:SUBMITTED_COLOR  },
                {label:getStepperStatusText(StepsStatus.Approved),color:APPROVED_COLOR  }
            ]
            return items;
            
        case StepsStatus.Rejected:
            items = [
                {label:getStepperStatusText(StepsStatus.Submitted),color:SUBMITTED_COLOR  },
                {label:getStepperStatusText(StepsStatus.Rejected),color:REJECTED_COLOR  }
            ]
            return items;
        case StepsStatus.Withdrawn:
            items = [
                {label:getStepperStatusText(StepsStatus.Submitted),color:SUBMITTED_COLOR  },
                {label:getStepperStatusText(StepsStatus.Withdrawn),color:WITHDRAWN_COLOR  }
            ]
            return items;
        case StepsStatus.Requests:
            items = 
            [
                {label:getStepperStatusText(StepsStatus.Submitted),color:SUBMITTED_COLOR  },
                {label:getStepperStatusText(StepsStatus.Approved),color:APPROVED_COLOR  },
                {label:getStepperStatusText(StepsStatus.Requests),color:REQUESTS_COLOR,content,extendedData  }
            ]
            return items;
        default:
            break;
    }
}

function getStepperStatusText(status: StepsStatus) {
    switch (status) {
      case StepsStatus.Approved: return 'Approved';
      case StepsStatus.Draft: return 'Draft';
      case StepsStatus.Rejected: return 'Rejected';
      case StepsStatus.Submitted: return 'Submitted';
      case StepsStatus.Withdrawn: return 'Withdrawn';
      case StepsStatus.Requests: return 'Requests';
      case StepsStatus.LeagueCompleted: return 'Completed';
      case StepsStatus.Unknown: return '---';
      default: return '---';
    }
  }
  
export enum StepsStatus {
    Unknown,
    Draft,
    Submitted,
    Approved,
    Rejected,
    Withdrawn,
    Requests,
    LeagueCompleted
}

interface IItem {
    label:string,
    color:string,
    content?:string | undefined,
    extendedData?:any,
    isCompleted?:boolean
}

function isNotDraft(status: RequestStatus) {
  return status!==RequestStatus.Draft && status!==RequestStatus.Unknown;
}
