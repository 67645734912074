import * as moment from 'moment-timezone';
import {IAdditionalContentFixtureDetailsDto} from '../../services/bpp.webapi/client';

export class DateBinder {
    timeZone: string;
    public date: moment.Moment;
    public arrival: moment.Moment;
    public start: moment.Moment;

    constructor(start: moment.Moment, arrival: moment.Moment, timeZone: string) {
        this.date = moment.utc(start);
        this.start = moment.utc(start);
        this.arrival = moment.utc(arrival);
        this.timeZone = timeZone;
    }

    bindToDetailsModel(content: IAdditionalContentFixtureDetailsDto) {
      content.start = moment.utc(this.date)
            .hour(this.start.hour())
            .minute(this.start.minute()).utc();
      content.arrival = moment.utc(this.date)
            .hour(this.arrival.hour())
            .minute(this.arrival.minute()).utc();
    }

    dateChanged(e) {
        this.date = e;
        this.start = moment(e, 'YYYY-MM-DD').hour(this.start.hour()).minute(this.start.minute());
        this.arrival = moment(e, 'YYYY-MM-DD').hour(this.arrival.hour()).minute(this.arrival.minute());
    }
}
