
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { IPersonReportDataDto, ClubDto } from '../../../../services/bpp.webapi/client';
import { Subject } from 'rxjs';
import { GetPersonsReportAction } from '../../../../store/report/actions';
import { ReportTableModel } from '../../../common/report-table/report-table-model/model';
import { ReportService } from '../../../../services/report.service';
import { GetClubsListAction } from '../../../../store/clubs/actions';
import * as moment from 'moment';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-league-persons-report',
  templateUrl: './league-persons-report.component.html'
})
export class LeaguePersonsReportComponent implements OnInit, OnDestroy {
  reportTableModel: ReportTableModel;
  private pageSize = 20;
  private componentDestroyed: Subject<void> = new Subject();
  @ViewChild('imageColumn', { static: true }) imageColumn;
  isSpinner: boolean;
  items: Array<IPersonReportDataDto>;
  clubs: Array<ClubDto> = [];
  allClubs: ClubDto = ClubDto.fromJS({ name: 'All Clubs', id: undefined });
  selectedClub: ClubDto = ClubDto.fromJS({ name: 'All Clubs', id: undefined });
  currentDate = moment().utc();

  columns: any[];
  user: any;

  constructor(private store: Store<AppState>,
    private appInsightsService: AppInsightClientService,
    private reportService: ReportService,
    private config: ConfigService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.store
      .select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });
    this.columns = [
      {
        rowClass: 'text-right',
        headerClass: 'text-right white-bg sticky-header',
        template: this.imageColumn,
        property: 'clubId',
        title: '',
      },
      {
        rowClass: 'text-left',
        headerClass: 'text-left white-bg sticky-header',
        property: 'clubName',
        title: 'Club',
      },
      {
        rowClass: 'text-center',
        headerClass: 'text-center white-bg sticky-header',
        property: 'fullName',
        title: 'Name',
      },
      {
        rowClass: 'text-center',
        headerClass: 'text-center white-bg sticky-header',
        property: 'appCount',
        title: 'Appearances',
      },
      {
        rowClass: 'text-center',
        headerClass: 'text-center white-bg sticky-header',
        property: 'bppCount',
        title: this.translateService.instant('ICSs'),
      }
    ];
    this.store.select(state => state.report).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.isSpinner = false;
          this.items = state.items;
          this.reportTableModel = {
            orderBy: '',
            isAsc: false,
            totalCount: this.items.length,
            takeBy: this.pageSize,
            items: this.items.slice(0, this.pageSize)
          };
        }
      });
    this.isSpinner = true;
    this.store.dispatch(new GetPersonsReportAction(this.selectedClub.id));
    this.store.select(state => state.clubs).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.clubs = state.items;
        }
      });
    this.store.dispatch(new GetClubsListAction());
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  selectClub(club: ClubDto) {
    this.selectedClub = club;
    this.isSpinner = true;
    this.store.dispatch(new GetPersonsReportAction(this.selectedClub.id));
  }

  loadMore(evt: ReportTableModel) {
    this.reportTableModel.items = this.items.slice(0, this.reportTableModel.items.length + this.reportTableModel.takeBy);
  }

  order(evt: ReportTableModel) {
    this.items = this.items.sort((i1, i2) => {
      if (i1[evt.orderBy] > i2[evt.orderBy]) {
        return evt.isAsc ? 1 : -1;
      }
      if (i1[evt.orderBy] < i2[evt.orderBy]) {
        return evt.isAsc ? -1 : 1;
      }
      return 0;
    });
    this.reportTableModel.items = this.items.slice(0, this.reportTableModel.takeBy);
  }

  getExcel() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Peoples Report (League)', {
      clubId: this.selectedClub.id ? this.selectedClub.id.toFixed() : '',
      clubName: this.selectedClub.name
    });
    this.reportService.getPersonsExcelReport(this.selectedClub.id)
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = 'ClubSummaryReport.xlsx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  print() {
    this.reportTableModel.items = this.items;
    setTimeout(() => { window.print(); }, 500);
  }
}
