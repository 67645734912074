import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAboutPageInterface } from '../models/about.interface';
import { HttpClient } from '@angular/common/http';
import { Roles } from '../models/enums';

@Injectable()
export class TextService {

    constructor(private httpClient: HttpClient) { }

    getAboutPageTexts(userRole: Roles): Observable<IAboutPageInterface> {
        let filename;
        switch (userRole) {
            case Roles.Admin:
            case Roles.League:
            case Roles.Club: filename = 'about-club.json'; break;
            case Roles.PLPBroadcaster:
            case Roles.Broadcaster: filename = 'about-br.json'; break;
        }

        if (filename) {
            const headers = { 'content-type': 'application/json' };
            return this.httpClient.get<IAboutPageInterface>(`assets/${filename}?cb=${new Date().getTime()}`, { headers });
        } else {
            return of();
        }
    }
}
