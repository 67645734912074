import { Action } from '@ngrx/store';
import { ViewState } from './view-state';
import {
  RequestDetailsDto,
  ParticipantType,
  RequestStatus,
  NoteDto,
  CameraOperator,
  IAdditionalContentFixtureDetailsDto,
  UpdateAdditionalContentSessionRequestDto
} from '../../../../../services/bpp.webapi/client';
import { UpdateClubContentSessionRequestAction } from '../../../../../store/additional-content/actions';
import { StatusHelper } from '../../../../../helpers/statuses-helper';
import * as moment from 'moment';

export class UpdateState extends ViewState {
  public requestId: string;
  private requestStatus: RequestStatus;
  noteDate: moment.Moment;

  constructor(additionalContent: IAdditionalContentFixtureDetailsDto, request: RequestDetailsDto) {
    super();

    this.setAdditionalContent(additionalContent);
    this.requestId = request.id;
    this.requestStatus = request.status;
    this.showDeleteButton = true;
    this.isNew = false;
    this.additionalContentType = additionalContent.type;
    if (request.broadcasterNote !== undefined) {
      this.note = request.broadcasterNote.message;
      this.noteDate = request.broadcasterNote.date;
    }

    for (const item of request.participants) {
      if (item.type === ParticipantType.Reporter) {
        this.reporters.participants.push(item);
      } else if (item.type === ParticipantType.Camera) {
        this.cameras.participants.push(item);
      } else if (item.type === ParticipantType.Producer) {
        this.producers.participants.push(item);
      } else if (item.type === ParticipantType.Other) {
        this.others.participants.push(item);
      }
    }
    this.others.require = request.isOthersEnabled;
    this.producers.require = request.isProducersEnabled;
    this.cameras.cameraOperator = request.cameraOperator;
    this.cameras.require = request.cameraOperator === CameraOperator.BroadcasterCamera;
    this.reporters.require = request.isReportersEnabled;
  }

  public getAction(status: RequestStatus): Action {
    const request = new UpdateAdditionalContentSessionRequestDto();
    request.status = status;
    request.eventId = this.eventId;
    request.participants = this.getParticipants();
    request.id = this.requestId;
    request.broadcasterNote = new NoteDto();
    request.cameraOperator = this.cameras.cameraOperator;
    request.isOthersEnabled = this.others.require;
    request.isProducersEnabled = this.producers.require;
    request.isReportersEnabled = this.reporters.require;
    request.broadcasterNote.message = this.note;
    request.additionalContentType = this.additionalContentType;

    return new UpdateClubContentSessionRequestAction(request);
  }

  isReadOnly() {
    return this.editState === false;
  }

  isCanChange() {
    return !(StatusHelper.isDatePassed(this.startTime) || this.requestStatus === RequestStatus.Reject || this.requestStatus === RequestStatus.Withdrawn);
  }

  toggleState() {
    if (this.isCanChange() && this.editState === false) {
      this.editState = true;
    } else {
      this.editState = false;
    }
  }
}
