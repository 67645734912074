import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/config/config.service';
import { getCameraOptionTextId } from 'src/app/helpers/request.helper';
import { AlertService } from 'src/app/services/alert.service';
import { CameraOperator, InterviewEventRequestSlotDto, NoteStatus, RequestDetailsDto, RequestStatus, SaveNoteDto } from 'src/app/services/bpp.webapi/client';
import { NotesService } from 'src/app/services/notes.service';
import { SECTIONS_LIST } from '../models/sections';

@Component({
  selector: 'app-requests-view-club',
  templateUrl: './requests-view-club.component.html',
  styleUrls: ['./requests-view-club.component.scss']
})
export class RequestsViewClubComponent implements OnInit {
  @Input() requests: RequestDetailsDto[];
  @Input() slots: InterviewEventRequestSlotDto[];
  @Input() isReadOnly: boolean;
  @Input() isPastClubIntreview: boolean;
  isLog = false;
  isEditMode = false;
  isEditMessage = false;
  CameraOperator = CameraOperator;
  sections = SECTIONS_LIST;

  constructor(private config: ConfigService,
    private alertService: AlertService,
    private translationService: TranslateService,
    private notesService: NotesService,
    private translate: TranslateService) {
    this.isLog = this.config.isDebug();
  }

  ngOnInit() {
  }

  saveRequestNote(request, status) {
    const note = this.generateNoteData(request, status);
    this.notesService.saveClubRequestNote(note)
      .subscribe((resp) => {
        if (resp) {
          this.translationService.get('NoteUpdated').subscribe((value) => {
            this.alertService.success(value);
          });
          request.clubNote = resp;
        }
      }, (err) => {
        if (this.config.isDebug()) {
          console.log(err);
        }
        this.alertService.success(err);
      });
  }

  generateNoteData(request: RequestDetailsDto, status: NoteStatus): any {
    return SaveNoteDto.fromJS({
      status: status,
      message: request.clubNote.message,
      objectId: request.id
    });
  }

  getCameraOption(cameraOperator :CameraOperator) {
    return this.translate.instant(getCameraOptionTextId(cameraOperator));
  }

  getSlotText(requestId) {
    if(!this.slots || this.slots.length === 0) {
      return '';
    }
    let slotIndex;
    const slot = this.slots.find(x => x.submittedRequests ? x.submittedRequests.includes(requestId) : false);
    if (slot){
      slotIndex = this.slots.findIndex(x => x.id === slot.id);
    }
    
    return slotIndex > -1 ? `Slot ${slotIndex + 1}` : '';
  }

  isAssignedToSlot(requestId) {
    
    if(!this.slots || this.slots.length === 0) {
      return false;
    }
    const slot = this.slots.find(x => x.submittedRequests ? x.submittedRequests.includes(requestId) : false);
    return !!slot;
  }

  hasParticipants(request, type) {
    return request.participants.filter(x => x.type === type).length > 0;
  }
  
  isApproved(request:RequestDetailsDto){
    return request.status==RequestStatus.Approved;
  }
}
