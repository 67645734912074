import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {AppState} from 'src/app/store/app.state';
import {AdditionalContentFixtureDetailsDto, AdditionalContentType, EventStatus, PlayerDto} from '../../../services/bpp.webapi/client';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {DateBinder} from '../../additional-content-details/dateBinder';
import {ConfigService} from '../../../config/config.service';
import {ActivatedRoute} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {User} from '../../../models/user.model';
import {AdditionalContentMapper} from '../../../mappers/additional-content.mapper';
import * as momentTz from 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-additional-content-details-body',
  templateUrl: './additional-content-details-body.component.html'
})
export class AdditionalContentDetailsBodyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() additionalContent: AdditionalContentFixtureDetailsDto;
  @Input() players = new Array<PlayerDto>();
  @Input() isReadOnly: boolean;
  @Input() dateBinder: DateBinder;

  private componentDestroyed: Subject<void> = new Subject();
  user: User;
  userSubscription: Subscription;
  dataLoaded: boolean;
  isLog = false;
  isSpinner = false;
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  mapper: AdditionalContentMapper;
  selectedPlayers = new Array<PlayerDto>();

  isShowPlayers: boolean;
  isShowParticipants: boolean;
  timezone:string;

  durationEnabled = false;
  _duration: moment.Moment ;
  _startOfDay: moment.Moment ;
  get duration(): moment.Moment {
    return this._duration;
  }

  set duration(value: moment.Moment) {
    this._duration = value;
    if(!this.additionalContent) {
      return;
    }
    this.additionalContent.duration =moment.duration(momentTz(value).tz(this.timezone).diff(momentTz().tz(this.timezone).startOf('day'), 'milliseconds'), 'milliseconds');
  }

  constructor(private store: Store<AppState>,
              private config: ConfigService,
              private activatedRoute: ActivatedRoute,
              ) {
    this.isLog = this.config.isDebug();
    this.mapper = new AdditionalContentMapper();
    const features = this.config.get("features.additionalContent");
    this.timezone = config.get('defaultTimeZone');
    this._startOfDay= momentTz().tz(this.timezone).startOf('day');
    this._duration = this._startOfDay;
    if(features && features.duration) {
      this.durationEnabled = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.additionalContent && changes.additionalContent.currentValue && changes.additionalContent.currentValue.duration) {
      const dur = changes.additionalContent.currentValue.duration as moment.Duration;
      this._duration = this._startOfDay.add(dur.asMilliseconds(), 'milliseconds');
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        const mindate = momentTz().utc();
        this.minDate = { year: mindate.year(), month: mindate.month() + 1, day: mindate.date() };

        this.isShowPlayers = this.additionalContent.type === AdditionalContentType.PreSeason || this.additionalContent.type === AdditionalContentType.MidSeason;
        this.isShowParticipants = this.additionalContent.type === AdditionalContentType.Club || this.additionalContent.type === AdditionalContentType.TrainingGround;

        this.store
          .select(e => e.user).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(state => {
            if (state.loading) {
            } else if (state.currentUser) {
              this.user = state.currentUser;
              this.initPlayers();
            }
          });
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  dateChanged(e) {
    this.dateBinder.dateChanged(e);
  }

  changeLanguages(languages) {
    this.additionalContent.languages = languages;
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  initPlayers() {
    this.additionalContent.peoples.forEach(p => {
      const player = this.players.find(pl => pl.id === p.personId);
      if (player) {
        this.selectedPlayers.push(player);
      }
    });
  }

  selectPlayer(id) {
    if (this.additionalContent.peoples && this.additionalContent.peoples.findIndex(x => x.personId.toString() === id.toString()) === -1) {
      const player = this.players.find(pl => pl.id === id);
      const newPlayer: PlayerDto = new PlayerDto({...player});
      const newPeople = this.mapper.mapPlayer(newPlayer);

      this.selectedPlayers.push(newPlayer);
      this.additionalContent.peoples.push(newPeople);
    }
  }

  deletePlayer(sender) {
    let index = this.findWithAttr(this.additionalContent.peoples, 'personId', sender.player.id);
    if (index !== -1) {
      this.additionalContent.peoples.splice(index, 1);
    }

    index = this.findWithAttr(this.selectedPlayers, 'id', sender.player.id);
    if (index !== -1) {
      this.selectedPlayers.splice(index, 1);
    }
  }
}
