import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import * as playerHelper from '../../../helpers/player-helper';

@Component({
  selector: 'app-player-picked-card',
  templateUrl: './player-picked-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PlayerPickedCardComponent implements OnInit {

  @Input() player;
  @Input() isReadOnly: boolean;
  @Input() teamId: number;
  @Output() deletePlayer = new EventEmitter();
  isManager = false;

  constructor() { }

  ngOnInit() {
    if (this.player) {
      this.isManager = playerHelper.isManager(this.player);
    }
  }

  close() {
    this.deletePlayer.emit(this);
  }

}
