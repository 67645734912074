import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfigService } from "src/app/config/config.service";
import { AppInsightClientService } from "src/app/services/app-insight.service";
import { ClubDto } from "src/app/services/bpp.webapi/client";
import { ReportService } from "src/app/services/report.service";
import { AppState } from "src/app/store/app.state";
import { GetClubsListAction } from "src/app/store/clubs/actions";
import { GetBroadcasterRequestsReportAction } from "src/app/store/report/actions";

@Component({
	selector: 'app-broadcaster-requests-report',
	templateUrl: './league-broadcaster-requests-report.component.html'
})
export class BroadcasterRequestsReportComponent implements OnInit, OnDestroy {
	isSpinner: boolean;
	isTrainingEnabled: boolean;
	isICSNonLiveEnabled: boolean;
	isTotalEnabled: boolean;
	isClubFilterEnabled:boolean;
	clubs: Array<ClubDto> = [];
  	allClubs: ClubDto = ClubDto.fromJS({ name: 'All Clubs', id: undefined });
  	selectedClub: ClubDto = ClubDto.fromJS({ name: 'All Clubs', id: undefined });
	private componentDestroyed: Subject<void> = new Subject();
	isLfp:boolean;
	reportTableModel = {
		items: [],
		totalCount: 0,
		takeBy: 10,
		orderBy: undefined,
		isAsc: true
	};
	constructor(private store: Store<AppState>, private appInsightsService: AppInsightClientService,
		 private reportService: ReportService,   public configService: ConfigService) {
			if (this.configService.hasFeature('environment.lfp')){
				this.isLfp= true;
			  }
		  }

	ngOnDestroy(): void {
		this.componentDestroyed.next();
		this.componentDestroyed.unsubscribe();
	}

	ngOnInit(): void {
		this.store.select(state => state.report).pipe(
			takeUntil(this.componentDestroyed))
			.subscribe(state => {
				if (state.loading || !state.broadcasterRequestsItems) {
					return;
				}
				this.isICSNonLiveEnabled=this.configService.hasFeature("reports.broadcasterRequests.icsNonLive");
				this.isTotalEnabled=this.configService.hasFeature("reports.broadcasterRequests.total");
				this.isTrainingEnabled=this.configService.hasFeature("reports.broadcasterRequests.training");
				this.isSpinner = false;
				this.isClubFilterEnabled=this.configService.hasFeature("reports.broadcasterRequests.clubsFilter");
				this.reportTableModel = {
					orderBy: '',
					isAsc: false,
					totalCount: state.broadcasterRequestsItems.length,
					takeBy: state.broadcasterRequestsItems.length,
					items: state.broadcasterRequestsItems
				};
			});

		this.isSpinner = true;
		this.store.dispatch(new GetBroadcasterRequestsReportAction(this.selectedClub.id));
		this.store.select(state => state.clubs).pipe(
			takeUntil(this.componentDestroyed))
			.subscribe(state => {
			  if (!state.loading) {
				this.clubs = state.items;
			  }
			});
		this.store.dispatch(new GetClubsListAction());
	}

	selectClub(club: ClubDto) {
		this.selectedClub = club;
		this.isSpinner = true;
		this.store.dispatch(new GetBroadcasterRequestsReportAction(this.selectedClub.id));
	  }

	order(name) {
		if (this.reportTableModel === undefined || this.reportTableModel.totalCount === 0) {
			return;
		  }
		  if (this.reportTableModel.orderBy === name) {
			this.reportTableModel.isAsc = !this.reportTableModel.isAsc;
		  } else {
			this.reportTableModel.isAsc = true;
		  }
		  this.reportTableModel.orderBy = name;
		  this.reportTableModel.items = this.reportTableModel.items.sort((a, b) => {
			if(this.reportTableModel.isAsc) {
				return a[name] > b[name] ? 1 : (a[name] < b[name] ? -1 : 0);  
			} else {
				return a[name] < b[name] ? 1 : (a[name] > b[name] ? -1 : 0);  
			}
		  });
	}

	getExcel() {
		this.isSpinner = true;
		this.appInsightsService.logEvent('Get Content Session Types Report (League)', {});

		this.reportService.getBroadcastersRequestsExcelReport(this.selectedClub.id)
			.subscribe(resp => {
				this.isSpinner = false;

				if (resp) {
					const url = window.URL.createObjectURL(resp.data);
					const link = document.createElement('a');
					link.download = 'ICSBroadcasterRequestsReport.xlsx';
					link.href = url;
					document.body.appendChild(link);

					link.click();
					document.body.removeChild(link);
				}
			}, err => {
				this.isSpinner = false;
			});
	}

	getPdf() {
		this.isSpinner = true;
		this.appInsightsService.logEvent('Get Broadcaster Request Report (League)', {});

		this.reportService.getBroadcastersRequestsPdfReport(this.selectedClub.id)
			.subscribe(resp => {
				this.isSpinner = false;

				if (resp) {
					const url = window.URL.createObjectURL(resp.data);
					const link = document.createElement('a');
					link.download = 'ICSBroadcasterRequestsReport.pdf';
					link.href = url;
					document.body.appendChild(link);

					link.click();
					document.body.removeChild(link);
				}
			}, err => {
				this.isSpinner = false;
			});
	}

	calculateTotalApproved(item) {
		return item.icsLive.approved+item.plInterview.approved+item.clubContent.approved;
	}
	calculateTotalRejected(item) {
		return item.icsLive.rejected+item.plInterview.rejected+item.clubContent.rejected;
	}
	getTooltip(item) {
		return `${item.exclusive}/${item.icsLive.approved} Extended`;
	}
}