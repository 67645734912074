import { MatchesState } from './state';
import * as actions from './actions';

export type Action = actions.All;

export function MatchesReducer(state = new MatchesState(), action: Action): MatchesState {
    switch (action.type) {

        case actions.GET_MATCHES_LIST: {

            return {
                ...state,
                loading: true,
                error: false
            };
        }
        case actions.GET_MATCHES_LIST_SUCCESS: {
            const items = action.payload;

            return {
                ...state,
                loading: false,
                error: false,
                items
            };
        }
        case actions.GET_MATCHES_LIST_ERROR: {
            return {
                ...state,
                error: true,
                loading: false
            };
        }
    }
    return state;
}
