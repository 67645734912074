// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-menu-base',
//   templateUrl: './menu-base.component.html',
//   styleUrls: ['./menu-base.component.scss']
// })
// export class MenuBaseComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component, OnInit, ViewEncapsulation, Input, AfterContentInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-menu-base',
  templateUrl: './menu-base.component.html',
  styleUrls: ['./menu-base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuBaseComponent implements OnInit, AfterContentInit {
  @Input() data: any;
  @Input() vertical: boolean;
  @Input() showName: boolean;
  @Input() templateRef: TemplateRef<any>;
  @Input() location: string;

  @Input() isFirstLevel = true;

  ctx: any = {
    node: null
  };

  constructor() {
  }

  ngOnInit() {
    this.ctx.node = this.data;
  }

  ngAfterContentInit(): void {
  }

  checkLocation(location): boolean {
    let result = false;

    if (this.data && this.data.route && location === this.data.route) {
      result = true;
    }
    return result;
  }
}
