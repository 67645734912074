import { Component, Input, OnInit } from '@angular/core';
import { IFixtureHeader } from '../../fixture-details/fixture-header/fixture-header.component';
import * as moment from 'moment';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-club-interview-header',
  templateUrl: './club-interview-header.component.html',
  styleUrls: ['./club-interview-header.component.scss']
})
export class ClubInterviewHeaderComponent implements OnInit {
  @Input() header: IFixtureHeader;
  @Input() lastSaved: string;
  
  isLfp: boolean;

  constructor(public config: ConfigService) { 
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    // console.log('this.header = ', this.header);
  }

  hasTime(time:moment.Moment){
    return !(time.hours()===0 && time.minutes()===0 && time.seconds()===0);
  }
}
