import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderTime',
  pure: false
})
export class OrderTimePipe implements PipeTransform {
  transform(array: Array<any>, filter: { arg: string, first: string, second: string }): Array<string> {
    if (!array) {
      return array;
    }
    array.sort((a: any, b: any) => {
      if (a[filter.arg].hour > b[filter.arg].hour) {
        return 1;
      } else if (a[filter.arg].hour === b[filter.arg].hour) {
        if (a[filter.arg].minute > b[filter.arg].minute) {
          return 1;
        } else if (a[filter.arg].minute < b[filter.arg].minute) {
          return -1;
        } else {
          return 0;
        }
      } else {
        return -1;
      }
    });
    return array;
  }
}
