import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { GetTrainingEventByIdAction } from '../../../store/training/actions';
import { ITrainingDetailsDto, NoteDto } from '../../../services/bpp.webapi/client';
import { StatusHelper } from '../../../helpers/statuses-helper';

@Component({
  selector: 'app-club-training-details',
  templateUrl: './club-training-details.component.html'
})
export class ClubTrainingDetailsComponent implements OnInit, OnDestroy {
  dataLoaded: boolean;
  isLog = false;
  trainingSubscription: Subscription;
  isSpinner = false;

  training: ITrainingDetailsDto;

  constructor(private store: Store<AppState>,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.isLog = true; // this.config.isDebug();
    this.activatedRoute.params.subscribe(
      params => {
        this.trainingSubscription = this.store
          .select(e => e.trainings.trainingDetails[params['id']])
          .subscribe(res => {
            if (res) {
              this.isSpinner = false;
              if (this.isLog) { console.log('GetTrainingEventDetails: ', res); }
              if (res.requests !== undefined) {
                res.requests.forEach(x => {
                  if (x.clubNote === undefined || x.clubNote === null) {
                    x.clubNote = new NoteDto();
                  }
                });
              }
              if (!res.clubNote) {
                res.clubNote = new NoteDto();
              }
              this.training = res;
              this.dataLoaded = true;
            }
          });
        this.isSpinner = true;
        this.store.dispatch(new GetTrainingEventByIdAction(params['id']));
      });
  }

  ngOnDestroy(): void {
    if (this.trainingSubscription) {
      this.trainingSubscription.unsubscribe();
    }
  }

  isCompletedEvent(training) {
    const result = StatusHelper.isCompeletedTrainingDetails(training, moment().utc());
    return result;
  }

  toggleSpinner(value) {
    this.isSpinner = value;
  }
}
