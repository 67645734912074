import { Component, OnInit, Input } from '@angular/core';
import { IPerson } from '../../../services/contacts.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {

  @Input() person: IPerson;
  emailSymbol = 'E.';
  phoneSymbol = 'T.';
  mobileSymbol = 'M.';
  constructor() { }

  ngOnInit() {
  }

}
