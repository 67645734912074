import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { IInterviewDto, RequestStatus} from 'src/app/services/bpp.webapi/client';

@Component({
  selector: 'app-interviews-table-group',
  templateUrl: './interviews-table-group.component.html',
  styleUrls: ['./interviews-table-group.component.scss']
})
export class InterviewsTableGroupComponent implements OnInit {
  @Input() data: IInterviewDto[];
  @Input() showStatus: boolean;
  @Input() showRequestsCount: boolean;
  @Output() itemClick: EventEmitter<IInterviewDto> = new EventEmitter();

  isLfp:boolean;
  RequestStatuses = RequestStatus;

  constructor(private config:ConfigService) {
    this.isLfp = config.isLfp();
   }

  ngOnInit() {
    console.log('InterviewsTableGroupComponent ngOnInit', this.data);
  }

  getStateColor(item: IInterviewDto) {
    return StatusHelper.getRequestStatusColor(item.requestStatus);
  }

  getLfpStatusColor(status:RequestStatus){
    return StatusHelper.getLfpRequestStatusColor(status)
  }

  navigateToDetail(item: IInterviewDto) {
    this.itemClick.emit(item);
  }

}
