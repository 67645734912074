import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AdditionalContentClient,
  AdditionalContentType,
  AdditionalContentFixtureDto,
  ICreateAdditionalContentFixtureDto,
  CreateAdditionalContentFixtureDto,
  UpdateAdditionalContentFixtureDto,
  CreateAdditionalContentSessionRequestDto,
  UpdateAdditionalContentSessionRequestDto,
  ClubContentSessionRequestClient,
  ICreateAdditionalContentSessionRequestDto,
  AdditionalContentFixtureDetailsDto,
  IUpdateAdditionalContentSessionRequestDto,
  IParticipantDto,
  ICreateParticipantDto,
  ParticipantDto,
  CreateParticipantDto,
  ClubContentSessionParticipantClient, ClubContentSessionRequestDto
} from './bpp.webapi/client';
import { AuthService } from '../authentication/auth.service';
import { GroupByPipe } from '../pipes/groupby.pipe';


@Injectable()
export class AdditionalContentService {
    constructor(protected authService: AuthService,
        protected eventClient: AdditionalContentClient,
        protected requestClient: ClubContentSessionRequestClient,
        protected participantsClient: ClubContentSessionParticipantClient,
        protected groupby: GroupByPipe) {
    }

    public getAll(isPast: boolean, clubId?: number) {
      return this.eventClient.getAll(clubId, isPast);
    }

  public getAllByType(type: AdditionalContentType) {
    return this.eventClient.get(type);
  }

  public getById(id: string, type: AdditionalContentType) {
      return this.eventClient.get2(id, type);
  }

  public createEvent(model: CreateAdditionalContentFixtureDto) {
    return this.eventClient.create(model);
  }

  public updateEvent(model: UpdateAdditionalContentFixtureDto) {
      return this.eventClient.update(model);
  }

  public createRequest(model: ICreateAdditionalContentSessionRequestDto): Observable<AdditionalContentFixtureDetailsDto> {
    return this.requestClient.create(model as CreateAdditionalContentSessionRequestDto);
  }

  public updateRequest(model: IUpdateAdditionalContentSessionRequestDto): Observable<AdditionalContentFixtureDetailsDto> {
    return this.requestClient.update(model as UpdateAdditionalContentSessionRequestDto);
  }

  getParticipants(): Observable<Array<IParticipantDto>> {
    return this.participantsClient.getAll();
  }

  getParticipantsByCompany(companyId: string): Observable<Array<IParticipantDto>> {
    return this.participantsClient.getByCompany(companyId);
  }

  createParticipant(model: ICreateParticipantDto): Observable<ParticipantDto> {
    return this.participantsClient.post(model as CreateParticipantDto);
  }
}
