
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TrainingDetailsDto } from '../../../services/bpp.webapi/client';
import { Subscription ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ConfigService } from '../../../config/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { User } from '../../../models/user.model';
import { TrainingMapper } from '../../../mappers/training.mapper';
import { CreateTrainigEventAction } from '../../../store/training/actions';
import { DateBinder } from '../dateBinder';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { StatusHelper } from '../../../helpers/statuses-helper';

@Component({
  selector: 'app-create-club-training',
  templateUrl: './create-club-training.component.html'
})
export class CreateClubTrainingComponent implements OnInit, OnDestroy {
  dateBinder: DateBinder;
  private componentDestroyed: Subject<void> = new Subject();
  user: User;
  userSubscription: Subscription;
  dataLoaded: boolean;
  isLog = false;
  isSpinner = false;
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  dates: { date: moment.Moment, start: moment.Moment, arrival: moment.Moment };
  training: TrainingDetailsDto;
  clubId: string;
  clubName: string;
  clubLogoImagePath = '';
  mapper: TrainingMapper;
  isLfp: boolean;

  constructor(private store: Store<AppState>,
    private config: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private translationService: TranslateService,
    private appInsightsService: AppInsightClientService) {
    this.isLog = this.config.isDebug();
    this.mapper = new TrainingMapper();
    this.dateBinder = new DateBinder(moment(), moment(), this.config.get('defaultTimeZone'));
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        const date = moment.unix(params['time']).utc();
        const mindate = moment().utc();
        this.minDate = { year: mindate.year(), month: mindate.month() + 1, day: mindate.date() };

        this.store
          .select(e => e.user).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(state => {
            if (state.loading) {
            } else if (state.currentUser) {
              this.user = state.currentUser;
              this.clubId = state.currentUser.companyId;
              this.clubName = state.currentUser.companyName;
              this.training = this.mapper.init(date, +this.clubId);
              this.clubLogoImagePath = '../../../../assets/teams/48/t' + this.clubId + '.png';
              this.dateBinder = new DateBinder(this.training.start, this.training.arrival, this.config.get('defaultTimeZone'));
            }
          });
        this.store.select(e => e.trainings).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(res => {
            if (res.loading) {
            } else {
              this.isSpinner = false;
              if (this.isLog) { console.log('Create-club-training: saveEvent: onSubscribe: ', res); }
              if (res.createdTraining !== '') {
                const id = res.createdTraining;
                res.createdTraining = '';
                this.router.navigate([`trainings/detail/` + id]);
              }
            }
          });
      });

  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  dateChanged(e) {
    this.dateBinder.dateChanged(e);
  }

  cancelBtnClicked() {
    this.router.navigate([`trainings`]);
  }

  saveEvent(status) {
    this.dateBinder.bindToTraining(this.training);
    if (!this.validateTraining()) {
      return;
    }
    this.isSpinner = true;
    this.appInsightsService.logEvent('Create training (club)', {
      trainingDate: this.training.start.format('YYYY/MM/dd HH:mm'),
      status: StatusHelper.getStatusText(status)
    });
    this.training.status = status;
    const model = this.mapper.create(this.training);
    model.status = status;
    this.store.dispatch(new CreateTrainigEventAction(model));
  }

  generateTrainingName(date: moment.Moment) {
    return moment(date).format('MMMM') + ' Training Session';
  }

  private validateTraining(): boolean {
    if (this.training.location === undefined || this.training.location.locationId === undefined) {
      this.translationService.get('invalidTrainingLocation').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    return true;
  }
}
