import { Component, OnInit, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { TestContentComponent } from '../Tests/test-content/test-content.component';
import { Roles } from '../../models/enums';
import { ClubTrainingDetailsComponent } from './clubs-training-details/club-training-details.component';
import { Subscription } from 'rxjs';
import { IUserRole } from '../../models/user.model';
import { IComponetByRole, getComponentByRole } from '../../helpers/component-by-role';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { LeagueTrainingDetailsComponent } from './league-training-details/league-training-details.component';
import { BroadcastersTrainingDetailsComponent } from './broadcasters-training-details/broadcasters-training-details.component';

@Component({
  selector: 'app-training-details-wrapper',
  templateUrl: './training-details-wrapper.component.html',
  styleUrls: ['./training-details-wrapper.component.scss']
})
export class TrainingDetailsWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) trainingRef;
  interval: any;
  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: LeagueTrainingDetailsComponent},
    { role: Roles.Admin, component: LeagueTrainingDetailsComponent},
    { role: Roles.Broadcaster, component: BroadcastersTrainingDetailsComponent},
    { role: Roles.PLPBroadcaster, component: BroadcastersTrainingDetailsComponent},
    { role: Roles.Club, component: ClubTrainingDetailsComponent}
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>) { }

  ngOnInit() {
    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
        }
      });
  }

  loadComponent(role) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.trainingRef.clear();
    const ref = this.trainingRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userInfo && this.userInfo.closed) {
      this.role = undefined;
      this.userInfo.unsubscribe();
    }
  }

}
