import { Injectable } from '@angular/core';
import { BootstrapAlert } from './bootstrap-alert';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BootstrapAlertService {

    public _bootstrapAlert: BehaviorSubject<BootstrapAlert>;

    constructor() {
        this._bootstrapAlert = new BehaviorSubject<BootstrapAlert>( null );
    }

    public alert(alert: BootstrapAlert) {
        this._bootstrapAlert.next(alert);
    }

}
