import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-d3-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

}

@Component({
  selector: 'app-sidebar-header',
  template: '<ng-content></ng-content>',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarHeaderComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

}

@Component({
  selector: 'app-sidebar-body',
  template: '<ng-content></ng-content>',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarBodyComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

}

@Component({
  selector: 'app-sidebar-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarFooterComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

}
