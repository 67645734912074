import { Component } from '@angular/core';
import { AuthService } from './authentication/auth.service';
import { ConfigService } from './config/config.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { TranslateService } from '@ngx-translate/core';
import { GetLoggedUserAction } from './store/user/actions';
import { GetApiVersionAction, GetMatchRoundNumbersAction, GetLanguageListAction, GetPlayerListAction } from './store/bpp/actions';
import { LoggerClient } from './services/bpp.webapi/client';
import { AppInsightClientService } from './services/app-insight.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'ICS Application';
  loadingApp = true;
  constructor(
    private router: Router,
    private configService: ConfigService,
    private authService: AuthService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private appInsightClient: AppInsightClientService,
    private loggerClient: LoggerClient,
    private titleService:Title

  ) {
    this.translate.setDefaultLang('en');
    translate.get("AppTitle").subscribe((value)=>{
      this.titleService.setTitle(value)
    })
    this.authService.getUser().subscribe(user => {
      this.router.initialNavigation();
      if (user && this.authService.isAuthenticated()) {
        this.loggerClient.getInstrumentationKey()
          .subscribe(iKey => {
            this.appInsightClient.init(iKey, user);
          });

        this.store.dispatch(new GetLoggedUserAction());
        this.store.dispatch(new GetLanguageListAction());
        this.store.dispatch(new GetPlayerListAction());
        this.store.dispatch(new GetApiVersionAction());
        this.store.dispatch(new GetMatchRoundNumbersAction());

        console.log('App initialization: ');
        console.log(`- webApi: `, this.configService.getApiConnString());

      } else {
        console.error('Not autorized', user);
      }
    });

    this.store.select(s => s.user.applicationIsLoading).subscribe(res => this.loadingApp = res);
  }
}
