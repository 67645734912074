import { IParticipantDto, ParticipantType, ICreateParticipantDto, IFixtureEventDetailsDto, IUpdateFixtureRequestDto, ICreateFixtureRequestDto } from '../../services/bpp.webapi/client';
import { Action } from '@ngrx/store';
import { IDentity } from './state';

export const FXRQ_ADD_NOTES = 'fixture_request_add_notes';
export const FXRQ_ENABLE_SECTION = 'fixture_request_enable_section';

export const FXRQ_GET_REQUEST_BYEVENT_SUCCESS = 'fixture_request_get_byid_success';
export const CREATE_FIXTURE_REQUEST = 'create_fixture_request';
export const CREATE_FIXTURE_REQUEST_SUCCESS = 'create_fixture_request_success';
export const CREATE_FIXTURE_REQUEST_ERROR = 'create_fixture_request_error';
export const UPDATE_FIXTURE_REQUEST = 'update_fixture_request';
export const UPDATE_FIXTURE_REQUEST_SUCCESS = 'update_fixture_request_success';
export const UPDATE_FIXTURE_REQUEST_ERROR = 'update_fixture_request_error';

export const FXRQ_NEW_PARTICIPANT = 'fixture_request_new_participant';
export const FXRQ_NEW_PARTICIPANT_SUCCESS = 'fixture_request_new_participant_success';
export const FXRQ_NEW_PARTICIPANT_ERROR = 'fixture_request_new_participant_error';
export const FXRQ_REMOVE_PARTICIPANT = 'fixture_request_remove_participant';
export const FXRQ_SELECT_PARTICIPANT = 'fixture_request_select_participant';
export const FXRQ_GET_PARTICIPANTS = 'fixture_request_get_participants';
export const FXRQ_GET_PARTICIPANTS_SUCCESS = 'fixture_request_get_participants_success';
export const FXRQ_GET_PARTICIPANTS_ERROR = 'fixture_request_get_participants_error';
export const FXRQ_GET_PARTICIPANTS_BY_COMPANY = 'fixture_request_get_participants_bycompany';
export const FXRQ_GET_PARTICIPANTS_BY_COMPANY_SUCCESS = 'fixture_request_get_participants_bycompany_success';
export const FXRQ_GET_PARTICIPANTS_BY_COMPANY_ERROR = 'fixture_request_get_participants_bycompany_error';

export const GET_FIXTURE_EVENTS_BYMATCH = 'get_fixture_bymatch_events';
export const GET_FIXTURE_EVENTS_BYMATCH_SUCCESS = 'get_fixture_bymatch_events_success';
export const GET_FIXTURE_EVENTS_BYMATCH_ERROR = 'get_fixture_bymatch_events_error';
export const FXRQ_SET_PROPERTY = 'fixture_request_set_property';

export class FxRequestEnableSectionAction implements Action {
    readonly type = FXRQ_ENABLE_SECTION;
    constructor(public identity: IDentity, public enable: boolean) { }
}

export class FxRequestAddNotesAction implements Action {
    readonly type = FXRQ_ADD_NOTES;
    constructor(public identity: IDentity, public notes: string) { }
}

// -------------- Participants -----------------------
export class FxRequestCreateParticipantAction implements Action {
    readonly type = FXRQ_NEW_PARTICIPANT;
    constructor(public identity: IDentity, public item: ICreateParticipantDto) { }
}

export class FxRequestCreateParticipantActionSuccess implements Action {
    readonly type = FXRQ_NEW_PARTICIPANT_SUCCESS;
    constructor(public identity: IDentity, public item: IParticipantDto) { }
}

export class FxRequestCreateParticipantActionError implements Action {
    readonly type = FXRQ_NEW_PARTICIPANT_ERROR;
    constructor(public payload: any) { }
}

export class FxRequestRemoveParticipantAction implements Action {
    readonly type = FXRQ_REMOVE_PARTICIPANT;
    constructor(public identity: IDentity, public item: IParticipantDto) { }
}

export class FxRequestSelectParticipantAction implements Action {
    readonly type = FXRQ_SELECT_PARTICIPANT;
    constructor(public identity: IDentity, public item: IParticipantDto) { }
}

export class FxRequestGetParticipantsAction implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS;
    constructor() { }
}

export class FxRequestGetParticipantsActionSuccess implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS_SUCCESS;
    constructor(public items: IParticipantDto[]) { }
}

export class FxRequestGetParticipantsActionError implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS_ERROR;
    constructor(public payload: any) { }
}

export class FxRequestGetParticipantsByCompanyAction implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS_BY_COMPANY;
    constructor(public companyId: string) { }
}

export class FxRequestGetParticipantsByCompanyActionSuccess implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS_BY_COMPANY_SUCCESS;
    constructor(public companyId: string, public items: IParticipantDto[]) { }
}

export class FxRequestGetParticipantsByCompanyActionError implements Action {
    readonly type = FXRQ_GET_PARTICIPANTS_BY_COMPANY_ERROR;
    constructor(public payload: any) { }
}

// ----------------- Requests ------------------
export class FxRequestGetRequestByEventIdSuccess implements Action {
    readonly type = FXRQ_GET_REQUEST_BYEVENT_SUCCESS;
    constructor(public payload: IFixtureEventDetailsDto) { }
}

export class CreateFixtureRequestAction implements Action {
    readonly type = CREATE_FIXTURE_REQUEST;
    constructor(public payload: ICreateFixtureRequestDto) {
    }
}

export class CreateFixtureRequestSuccessAction implements Action {
    readonly type = CREATE_FIXTURE_REQUEST_SUCCESS;
    constructor(public payload: any) {
    }
}

export class CreateFixtureRequestErrorAction implements Action {
    readonly type = CREATE_FIXTURE_REQUEST_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateFixtureRequestAction implements Action {
    readonly type = UPDATE_FIXTURE_REQUEST;
    constructor(public payload: IUpdateFixtureRequestDto) {
    }
}

export class UpdateFixtureRequestSuccessAction implements Action {
    readonly type = UPDATE_FIXTURE_REQUEST_SUCCESS;
    constructor(public payload: any) {
    }
}

export class UpdateFixtureRequestErrorAction implements Action {
    readonly type = UPDATE_FIXTURE_REQUEST_ERROR;
    constructor(public payload: any) {
    }
}

// ---------- Events & Requests by match ---------
export class GetEventsByMatchAction implements Action {
    readonly type = GET_FIXTURE_EVENTS_BYMATCH;
    constructor(public identity: IDentity) { }
}

export class GetEventsByMatchActionSuccess implements Action {
    readonly type = GET_FIXTURE_EVENTS_BYMATCH_SUCCESS;
    constructor(public payload: { identity: IDentity, events: IFixtureEventDetailsDto[] }) { }
}

export class GetEventsByMatchActionError implements Action {
    readonly type = GET_FIXTURE_EVENTS_BYMATCH_ERROR;
    constructor(public payload: any) { }
}

export class FxRequestSetPropAction implements Action {
    readonly type = FXRQ_SET_PROPERTY;
    constructor(public identity: IDentity, public prop: string, public value: any) { }
}


export type All = FxRequestAddNotesAction
    | FxRequestEnableSectionAction
    | FxRequestCreateParticipantAction
    | FxRequestCreateParticipantActionSuccess
    | FxRequestCreateParticipantActionError
    | FxRequestSelectParticipantAction
    | FxRequestRemoveParticipantAction
    | FxRequestGetParticipantsAction
    | FxRequestGetParticipantsActionSuccess
    | FxRequestGetParticipantsActionError
    | FxRequestGetParticipantsByCompanyAction
    | FxRequestGetParticipantsByCompanyActionSuccess
    | FxRequestGetParticipantsByCompanyActionError
    | FxRequestGetRequestByEventIdSuccess
    | CreateFixtureRequestAction
    | CreateFixtureRequestAction
    | CreateFixtureRequestSuccessAction
    | CreateFixtureRequestErrorAction
    | UpdateFixtureRequestAction
    | UpdateFixtureRequestSuccessAction
    | UpdateFixtureRequestErrorAction
    | GetEventsByMatchAction
    | GetEventsByMatchActionSuccess
    | GetEventsByMatchActionError
    | FxRequestSetPropAction;
