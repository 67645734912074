import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocationClient, CreateLocationDto, LocationDto, ICreateLocationDto, ILocationDetailsDto, LocationDetailsDto } from './bpp.webapi/client';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class LocationService {
    constructor(protected apiClient: LocationClient) {
    }

    getLocations(clubId: number): Observable<Array<LocationDto>> {
        return this.apiClient.getAll(clubId);
    }

    getForLeague() {
        return this.apiClient.get();
    }

    createLocation(model: ICreateLocationDto) {
        return this.apiClient.create(model as CreateLocationDto);
    }

    updateLocation(model: ILocationDetailsDto) {
        return this.apiClient.update(model as LocationDetailsDto);
    }

    deleteLocation(id: string) {
        return this.apiClient.delete(id);
    }

    getLocationById(id: string) {
        return this.apiClient.getById(id);
    }
}
