import { BootstrapAlertService } from '../widgets/ng-bootstrap-alert/bootstrap-alert.service';
import { BootstrapAlert } from '../widgets/ng-bootstrap-alert/bootstrap-alert';
import { Injectable } from '@angular/core';

export interface IError {
    message: string;
    response?: any;
}
@Injectable()
export class AlertService {
    constructor(private alertService: BootstrapAlertService) {

    }

    error(error: IError) {
        const message = error.message + ': ' + error.response;
        this.alertService.alert(new BootstrapAlert(message, 'alert-danger'));
        console.log('ERROR:', message);
    }

    success(message) {
        this.alertService.alert(new BootstrapAlert(message, 'alert-success'));
        console.log('Success alert:', message);
    }

    warning(message) {
        this.alertService.alert(new BootstrapAlert(message, 'alert-danger'));
        console.log('Warning:', message);
    }
}
