import { Injectable } from '@angular/core';
import {MatchClient, MatchDetailsDto} from './bpp.webapi/client';

@Injectable()
export class MatchesService {
    constructor(private client: MatchClient) {
    }

    getAllMatches() {
      return this.client.getAll();
    }

    updateMatch(item: MatchDetailsDto) {
      return this.client.update(item);
    }
}
