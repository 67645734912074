import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../../../../config/config.service';
import * as playerHelper from '../../../../helpers/player-helper';
import { AttendeeDto, DashboardItemType } from '../../../../services/bpp.webapi/dashboard.client';
import { Roles } from '../../../../models/enums';
import { TranslateService } from '@ngx-translate/core';
import { IDashboardMessage } from '../../models/dashboard-message.interface';
import { Steps } from '../../stepper/another-stepper';
import { getStepsStatus } from '../../dashboard.helper';
import { ISessionData } from '../../models/session-data.interface';
import { User } from 'src/app/models/user.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { takeUntil } from 'rxjs/operators';
import { mapAdditionalContentTypeUrl } from 'src/app/helpers/common';


@Component({
  selector: 'app-dashboard-info',
  templateUrl: './dashboard-info.component.html',
  styleUrls: ['./dashboard-info.component.scss']
})
export class DashboardInfoComponent implements OnInit {
  isLog = false;
  isAttendees = false;
  resources: {
    review: string,
    details: string
  };
  @Input() data: IDashboardMessage ;
  session: ISessionData
  hasDataConfirmed:boolean;
  user: User;
  items:any[];
  private isSimplified:boolean; 

  constructor(private router: Router, config: ConfigService, private translate: TranslateService,private store: Store<AppState>,) {
    this.isLog = config.isDebug();
    this.isSimplified = config.hasFeature("dashboard.simplified");
  }

  ngOnInit() {
    this.hasDataConfirmed = this.data.confirmed!="";
    this.session = this.data.data;
    this.store.select(state => state.user.currentUser).subscribe(c=>{
      this.user=c;
      this.items = getStepsStatus(this.session,this.user.companyId);
    });
    
    this.isAttendees = this.session.attendees && this.session.attendees.length > 0 && this.session.attendees.filter(a => a.fullName !== null).length > 0;
    this.resources = { review: '', details: ''};
    this.translate.get('ReviewBtn').subscribe(value => this.resources.review = value);
    this.translate.get('DetailsBtn').subscribe(value => this.resources.details = value);
  }

  goToDetail(data: ISessionData) {
    if (data.dashboardItemType === DashboardItemType.AdditionalContent || data.dashboardItemType === DashboardItemType.AdditionalContentRequest){
      if (this.isSimplified){
        this.router.navigate([`/additional-content/${mapAdditionalContentTypeUrl(data.additionalContentType,this.isSimplified)}/detail/${data.eventId}`]);
        return;
      }
    }

    if (data.match && data.match.matchId) {
      if (this.isSimplified){
        this.router.navigate([`/club-interviews/detail/${data.match.matchId}`]);
      }
      else{
        this.router.navigate([`/fixtures/detail/${data.match.matchId}/nonLive/${data.isNonLiveFixtureEvent}`]);
      }
    } else if (data.interviewee && data.monthId) {
      this.router.navigate([`/interviews/detail/${data.monthId}`]);
    } else if (data.eventId) {
      this.router.navigate([`/trainings/detail/${data.eventId}`]);
    }

  }

  isLeagueInterview(session:ISessionData){
    return session.dashboardItemType === 6 || session.dashboardItemType === 7
  }

  onDetailButtonClick(id:string){
    this.goToDetail(this.session)
  }

  isManager(player: AttendeeDto) {
    return playerHelper.isManagerForDashboard(player);
  }

  getReviewButtonText() {
    switch (this.session.userRole) {
      case Roles.Admin:
      case Roles.League:
        return this.resources.review;
      case Roles.Club:
      case Roles.Broadcaster:
      case Roles.PLPBroadcaster:
        return this.resources.details;
      default: return this.resources.details;
    }
  }

  isReviewBtnVisible() {
    if ((this.session.userRole === Roles.League || this.session.userRole === Roles.Admin) && this.session.isRejected === true) {
      return false;
    }
    return true;
  }

  getPlayerRole(player: AttendeeDto) {
    return `(${player.kind})`;
  }

  getPlayers(session:ISessionData){
    let players ="";
    if (session.attendees && session.attendees.length>0){
      session.attendees.forEach((element,index) => {
        players=players.concat(element.fullName)
        if (this.isManager(element)){
          players=players.concat(element.kind);
        }
        if (index!=session.attendees.length-1){
          players= players.concat(", ");
        }
      });
    }
    else if (session.interviewee){
      players= players.concat(session.interviewee);
    }
    
    return players;
  }
}
