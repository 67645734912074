import { FxRequest } from '../store/fx-request/state';
import {
    CreateParticipantDto,
    IFixtureEventDetailsDto,
    CreateLocationDto,
    LocationDetailsDto,
    IUserDetails,
    ParticipantType,
    IInterviewEventDetailsDto,
    RequestDetailsDto,
    CameraOperator,
    Players,
    InterviewEventType
} from './bpp.webapi/client';
import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/user.model';
import { Roles } from '../models/enums';
import { IInterviewDetails } from '../store/interviews/state';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { ConfigService } from '../config/config.service';
import { ViewState } from '../components/additional-content-details/additional-content-details-broadcaster/club-content-session-request-broadcaster/state/view-state';

export interface IValidationResult {
    result: boolean;
    messages: string[];
}

@Injectable()
export class ValidationService {

    resources: { [key: string]: string };
    durationEnabled:boolean;
    timezone:string;
    
    constructor(private alertService: AlertService, private translate: TranslateService,private config: ConfigService) {
        this.timezone = config.get('defaultTimeZone');
        const features = this.config.get("features.additionalContent");
        if(features && features.duration) {
            this.durationEnabled = true;
        }
        translate.get(['invalidRequest', 'invalidParticipant',
            'invalidMandatoryFields', 'invalidFixturePlayers', 'invalidFixtureLanguages',
            'invalidFixtureLocation', 'invalidCameraSection',
            'invalidClubId', 'invalidClubName', 'invalidBroadcasterId', 'invalidSlot',
            'invalidBroadcasterName', 'invalidLocationsLimit', 'invalidInterviewee','invalidDuration'])
            .subscribe(result => {
                this.resources = result;
            });
    }

    isAdditionalContentRequestValid(request:ViewState){
        let isValid = true;
        let message = '';

        const participants = request.sections.filter(x =>
            (x.type === ParticipantType.Reporter || x.type === ParticipantType.Producer) &&
            x.participants.length > 0
        );

        if (participants.length < 1) {
            isValid = false;
            message = this.resources['invalidRequestValue'];
        } else {
            const cameraSection = request.sections.find(s => s.type==ParticipantType.Camera);
            if (cameraSection && cameraSection.require && cameraSection.participants.length < 1) {
                isValid = false;
                message = this.resources['invalidCameraSection'];
            }
        }

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }

    isRequestValid(request: FxRequest) {
        let isValid = true;
        let message = '';

        const participants = request.sections.filter(x =>
            (x.sectionType === ParticipantType.Reporter || x.sectionType === ParticipantType.Producer) &&
            x.items.length > 0
        );

        if (participants.length < 1) {
            isValid = false;
            message = this.resources['invalidRequestValue'];
        } else {
            const cameraSection = request.sections.find(s => s.isCameraSection);
            if (cameraSection && cameraSection.require && cameraSection.items.length < 1) {
                isValid = false;
                message = this.resources['invalidCameraSection'];
            }
        }

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }


    isClubInterviewRequestValid(request: RequestDetailsDto, slotId, interviewType) {
        let isValid = true;
        let message = '';

        const participants = request.participants.filter(x =>
            (x.type === ParticipantType.Reporter || x.type === ParticipantType.Producer)
        );

        if (participants.length < 1) {
            isValid = false;
            message = this.resources['invalidRequestValue'];
        } else if (request.cameraOperator === CameraOperator.BroadcasterCamera && !request.participants.find(x => x.type === ParticipantType.Camera)) {
            isValid = false;
            message = this.resources['invalidCameraSection'];
        }

        if(interviewType === InterviewEventType.MultiSessions && !slotId) {
            isValid = false;
            message = this.resources['invalidSlot'];
        }

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }

    isParticipantValid(item: CreateParticipantDto) {
        let isValid = true;
        const message = this.resources['invalidParticipant'];

        isValid = isValid && item.name && item.name.length > 0;
        isValid = isValid && item.phone && item.phone.length > 0 && this.isPhoneValid(item.phone);
        isValid = isValid && item.email && item.email.length > 0 && this.isEmailValid(item.email);

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }

    isEmailValid(email) {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    isPhoneValid(email) {
        const regularExpression = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    isFixtureValid(fixtureEvent: IFixtureEventDetailsDto) {
        let isValid = true;
        let message = '';

        if (!fixtureEvent.location.locationId) {
            isValid = false;
            message = this.resources['invalidFixtureLocation'];
        }
        if (fixtureEvent.peoples.players.length < 1) {
            isValid = false;
            message = this.resources['invalidFixturePlayers'];
        }

        if (fixtureEvent.languages.length < 1) {
            isValid = false;
            message = this.resources['invalidFixtureLanguages'];
        }

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }

    isClubInterviewValid(clubInterview: IInterviewEventDetailsDto) {
        let isValid = true;
        let message = '';

        if (!clubInterview.location.locationId) {
            isValid = false;
            message = this.resources['invalidFixtureLocation'];
        }

        if (clubInterview.interviewType === InterviewEventType.SingleInterview){
            if (clubInterview.arrivalUtc>clubInterview.startUtc){
                isValid = false;
                message = this.resources['ArrivalGreaterThanStart'];
            }
    
        }
        else {
            if (clubInterview.slots.length>0 && clubInterview.arrivalUtc>clubInterview.slots[0].startTime){
                isValid = false;
                message = this.resources['ArrivalGreaterThanStart'];
            }
        }
       
        if (!(clubInterview.interviewType === InterviewEventType.MultiSessions && clubInterview.players === Players.Multiple) && clubInterview.peoples.length < 1) {
            isValid = false;
            message = this.resources['invalidFixturePlayers'];
        }

        if (clubInterview.interviewType === InterviewEventType.MultiSessions && clubInterview.players === Players.Multiple) {
            const hasEmpty = clubInterview.slots.find(x => x.peoples.length < 1);
            if (hasEmpty) {
                isValid = false;
                message = this.resources['invalidFixturePlayers'];
            }
        }

        if (this.durationEnabled && clubInterview.interviewType!==InterviewEventType.MultiSessions && (!clubInterview.duration || 
            (momentTz.duration(clubInterview.duration.asMinutes()+momentTz.tz(this.timezone).utcOffset()).asMinutes()===0))) {
            isValid = false;
            message = this.resources['invalidDuration'];
        }

        if ((!(clubInterview.interviewType === InterviewEventType.MultiSessions && clubInterview.players === Players.Multiple) && clubInterview.languages.length < 1)) {
            isValid = false;
            message = this.resources['invalidFixtureLanguages'];
        }

        if (clubInterview.interviewType === InterviewEventType.MultiSessions && clubInterview.players === Players.Multiple) {
            const hasEmpty = clubInterview.slots.find(x => x.languages.length < 1);
            if (hasEmpty) {
                isValid = false;
                message = this.resources['invalidFixtureLanguages'];
            }
        }

        if (!isValid) {
            this.alertService.warning(message);
        }

        return isValid;
    }

    isLocationValid(location: CreateLocationDto | LocationDetailsDto) {
        const isValid = location.address1 && location.address1.length > 1
            && location.country && location.country.length > 1
            && location.title && location.title.length > 1
            && location.townCity && location.townCity.length > 1
            && location.postcode && location.postcode.length > 1;

        if (!isValid) {
            this.alertService.warning(this.resources['invalidMandatoryFields']);
        }

        return isValid;
    }

    isInterviewValid(interview: IInterviewDetails): boolean {
        let isValid = true;
        const messageIds = [];

        if (interview.languages.length < 1) {
            isValid = false;
            messageIds.push('invalidFixtureLanguages');
        }

        if (!interview.location.locationId) {
            isValid = false;
            messageIds.push('invalidFixtureLocation');
        }

        if (!interview.interviewee) {
            isValid = false;
            messageIds.push('invalidInterviewee');
        }

        if (this.durationEnabled && (!interview.duration || 
            (momentTz.duration(interview.duration.asMinutes()+momentTz.tz(this.timezone).utcOffset()).asMinutes()===0))) {
            isValid = false;
            messageIds.push('invalidDuration');
        }

        if (!interview.interviewType) {
            isValid = false;
            messageIds.push('invalidInterviewType');
        }

        if (!isValid) {
            this.showAllWarnings(messageIds);
        }

        return isValid;
    }

    private getParticipants(model: FxRequest) {
        const participants = [];
        model.sections.forEach(s => {
            if (s.items) {
                s.items.forEach(item => participants.push(item.id));
            }
        });
        return participants;
    }

    invalidRequestWarning() {
        this.alertService.warning(this.resources['invalidRequest']);
    }

    invalidLocationLimitWarning() {
        this.alertService.warning(this.resources['invalidLocationsLimit']);
    }

    isLoggedUserValid(user: User, details: IUserDetails): IValidationResult {

        this.translate.get(['invalidRequest', 'invalidRequestValue', 'invalidParticipant',
            'invalidMandatoryFields', 'invalidFixturePlayers', 'invalidFixtureLanguages',
            'invalidFixtureLocation', 'invalidCameraSection',
            'invalidClubId', 'invalidClubName', 'invalidBroadcasterId','invalidSlot',
            'invalidBroadcasterName', 'invalidLocationsLimit','invalidDuration','ArrivalGreaterThanStart'])
            .subscribe(result => {
                this.resources = result;
            });

        switch (user.role) {
            case Roles.Club: return this.validateClub(details);
            case Roles.Broadcaster:
            case Roles.Broadcaster: return this.validateBroadcaster(details);
        }

        return { result: true, messages: [] };
    }

    isValidCompanyId(details: IUserDetails) {
        return details.id !== null;
    }

    isValidCompanyName(details: IUserDetails) {
        return details.company && details.company.length > 0;
    }

    validateClub(details: IUserDetails): IValidationResult {

        let isValid = this.isValidCompanyId(details);
        const messages = [];

        if (!isValid) {
            this.alertService.warning(this.resources['invalidClubId']);
            messages.push(this.resources['invalidClubId']);
        }

        isValid = this.isValidCompanyName(details);
        if (!isValid) {
            this.alertService.warning(this.resources['invalidClubName']);
            messages.push(this.resources['invalidClubName']);
        }

        return { result: isValid, messages };
    }

    validateBroadcaster(details: IUserDetails): IValidationResult {
        let isValid = this.isValidCompanyId(details);
        const messages = [];

        if (!isValid) {
            this.alertService.warning(this.resources['invalidBroadcasterId']);
            messages.push(this.resources['invalidBroadcasterId']);
        }

        isValid = this.isValidCompanyName(details);
        if (!isValid) {
            this.alertService.warning(this.resources['invalidBroadcasterName']);
            messages.push(this.resources['invalidBroadcasterName']);
        }

        return { result: isValid, messages };
    }

    private showAllWarnings(textIds: string[]) {
        if (textIds && textIds.length > 0) {
            textIds.forEach(id => {
                const message = this.resources[id] || this.translate.instant(id);
                this.alertService.warning(message);
            });
        }
    }
}

