
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import {
  IFixtureEventDetailsDto, FixtureEventDetailsDto, PlayerDto, UpdateFixtureEventDto, EventStatus, IMatchInfoDto, CreateFixtureEventDto
} from '../../../services/bpp.webapi/client';
import { UpdateFixtureEventAction, GetMatchInfoAction, CreateFixtureEventAction } from '../../../store/fixture/actions';
import { Subject } from 'rxjs';
import { FxRequest, IDentity } from '../../../store/fx-request/state';
import { GetEventsByMatchAction } from '../../../store/fx-request/actions';
import { ConfigService } from '../../../config/config.service';
import { IFixtureHeader } from '../fixture-header/fixture-header.component';
import { GetPlayerListAction } from '../../../store/bpp/actions';
import { Roles } from '../../../models/enums';
import { FixtureMapper } from '../../../mappers/fixture.mapper';
import * as _ from 'lodash';
import * as helper from '../../../helpers/common';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { ValidationService } from '../../../services/validation.service';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import { User } from '../../../models/user.model';
import { AppInsightClientService } from '../../../services/app-insight.service';

@Component({
  selector: 'app-club-fixture-details',
  templateUrl: './club-fixture-details.component.html',
  styleUrls: ['./club-fixture-details.component.scss']
})
export class ClubFixtureDetailsComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isLog = false;
  identity: IDentity;
  matchId;
  fixtureEvent: IFixtureEventDetailsDto;
  originEvent: IFixtureEventDetailsDto;
  requests: FxRequest[];
  teamIds: any[];
  fixtureHeader: IFixtureHeader;
  lastSaved: string;
  isSpinner: boolean;
  isReadOnly = true;
  players: PlayerDto[];
  userRole: Roles;
  clubId: string;
  matchInfo: IMatchInfoDto;
  mapper: FixtureMapper;
  isCreate: boolean;
  isPastFixtureFlag = false;
  user: User;
  nonLiveInUK: boolean;


  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private appInsightsService: AppInsightClientService,
    private config: ConfigService,
    private validator: ValidationService) {
    this.isLog = this.config.isDebug();
    this.mapper = new FixtureMapper(config);
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });
    this.fixtureEvent = undefined;
    this.isReadOnly = true;
    this.store.dispatch(new GetPlayerListAction());

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            if (user) {
              this.userRole = user.role;
              this.clubId = user.companyId;
              this.matchId = params['id'];
              this.nonLiveInUK = params['nonLive'] === 'true';
              // this.store.dispatch(new GetPlayerListByTeamAction([this.clubId]));
              if (this.matchId) {
                this.identity = {
                  matchId: this.matchId,
                  nonLiveInUK: this.nonLiveInUK
                };
                this.store.dispatch(new GetEventsByMatchAction(this.identity));
              }
              this.isSpinner = false;
            } else { this.isSpinner = true; }
          });
      });

    const event$ = new Subject<IFixtureEventDetailsDto>();

    this.store.select(state => state.fxRequests).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (!result.loading) {
          const matchEvents = result.events[this.matchId];
          if (this.isLog) { console.log('result!', matchEvents); }

          if (matchEvents && matchEvents.length > 0) {
            const event = matchEvents[0].details;
            event$.next(event);
            this.requests = matchEvents[0].requests;
            this.lastSaved = helper.getLastSavedText(event.updatedOn, event.updatedByName, StatusHelper.getStatusText(event.status));
          } else {
            this.store.dispatch(new GetMatchInfoAction(this.matchId));

            this.store.select(state => state.fixtures.selectedMatch).pipe(
              takeUntil(this.componentDestroyed))
              .subscribe(match => {
                if (match && match.matchId.toString() === this.matchId.toString()) {
                  this.matchInfo = match;
                  event$.next(this.initNewModel(match));
                  this.requests = [];
                  this.lastSaved = 'To be Saved';
                  this.isReadOnly = false;
                  this.isCreate = true;
                  if (this.isLog) { console.log('no events, create new!'); }
                }
              });
          }
        }
      });

    event$.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(event => {
        if (event) {

          if (this.isPastFixture(event)) { this.isReadOnly = true; }

          this.fixtureHeader = {
            homeTeam: event.homeTeam,
            awayTeam: event.awayTeam,
            matchDate: event.matchDate
          };

          this.fixtureEvent = event;
          this.isSpinner = false;

          if (this.isLog) { console.log('event', this.fixtureEvent); }
          if (this.isLog) { console.log('requests', this.requests); }
        } else {
          this.fixtureEvent = undefined;
        }
      });

    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

  }

  isPastFixture(fixture: IFixtureEventDetailsDto) {
    this.isPastFixtureFlag = fixtureHelper.isPastFixture(fixture);
    return this.isPastFixtureFlag;
  }

  initNewModel(match: IMatchInfoDto): FixtureEventDetailsDto {
    const model = this.mapper.init(match);
    model.clubId = Number(this.clubId);
    model.isNonLiveInUK = this.nonLiveInUK;
    return model;
  }

  saveOrSubmit(status: EventStatus) {
    if (this.validator.isFixtureValid(this.fixtureEvent)) {
      const date = this.fixtureEvent.matchDate.format('YYYY-MM-DD HH:mm');
      const arrival = this.fixtureEvent.arrival.format('YYYY-MM-DD HH:mm');
      const start = this.fixtureEvent.start.format('YYYY-MM-DD HH:mm');
      this.isSpinner = true;
      if (this.isCreate) {
        this.appInsightsService.logEvent('Create Fixture (Club)', {
          status: StatusHelper.getStatusText(status)
        });
        const model = this.createNewModel(this.fixtureEvent, status);
        const arrival2 = model.arrival.format('YYYY-MM-DD HH:mm');
        const start2 = model.start.format('YYYY-MM-DD HH:mm');
        if (this.isLog) { console.log('CreateDto:', model); }
        this.store.dispatch(new CreateFixtureEventAction(model));

      } else {
        this.appInsightsService.logEvent('Update Fixture (Club)', {
          fixtureId: this.fixtureEvent.eventId,
          status: StatusHelper.getStatusText(status)
        });
        const model = this.updateModel(this.fixtureEvent, status);
        if (this.isLog) { console.log('UpdateDto:', model); }
        this.store.dispatch(new UpdateFixtureEventAction(model));
      }

      setTimeout(() => {
        this.store.select(state => state.fixtures.error).subscribe(e => {
          if (e === false) {
            this.router.navigate([`fixtures`]);
          }
        }).unsubscribe();
      }, 1000);
    }
  }

  submitBtnClick() {
    this.saveOrSubmit(EventStatus.Submitted);
  }

  saveBtnClick() {
    this.saveOrSubmit(EventStatus.Draft);
  }

  editBtnClick() {
    this.isReadOnly = false;
    this.originEvent = _.cloneDeep(this.fixtureEvent);
  }

  cancelBtnClick() {
    this.isReadOnly = true;
    this.fixtureEvent = _.cloneDeep(this.originEvent);
  }

  updateModel(fixture: IFixtureEventDetailsDto, status: EventStatus): UpdateFixtureEventDto {

    const model = this.mapper.update(fixture);
    model.status = status;

    return model;
  }

  createNewModel(fixture: IFixtureEventDetailsDto, status: EventStatus): CreateFixtureEventDto {

    const model = this.mapper.create(fixture);
    model.status = status;

    return model;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  messageChange(e) {
    // this.fixtureEvent.l = moment().utc();
  }

  navigateBack() {
    this.router.navigate([`fixtures`]);
  }
}
