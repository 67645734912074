import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { ConfigService } from '../../config/config.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {ClubDto} from '../../services/bpp.webapi/client';
import { AppInsightClientService } from '../../services/app-insight.service';
import { BaseComponent } from '../base-component/base.component';
import {filter, takeUntil} from 'rxjs/operators';
import {GetClubsListAction} from '../../store/clubs/actions';
import {ITimePicker} from '../common/combo-picker/combo-picker.component';

export enum DateRange {
  Upcoming = 0,
  Past = 1
}

@Component({
  selector: 'app-filter-panel-by-club-date-range',
  templateUrl: './filter-panel-by-club-daterange.component.html'
})
export class FilterPanelByClubDaterangeComponent extends BaseComponent implements OnInit, OnDestroy {
  get SelectedClub() {
    return this.selectedClub;
  }

  get SelectedDateRange() {
    return this.selectedDateRange;
  }

  @Output() clubChanged = new EventEmitter();
  @Output() dateRangeChanged = new EventEmitter();

  userSubscription: Subscription;
  isLoading = false;

  clubs: ClubDto[];
  allClubs = new ClubDto({
    id: 0,
    name: 'All'
  });

  selectedClub: ClubDto = this.allClubs;

  upcoming: any = {
    id: DateRange.Upcoming,
    name: 'Upcoming'
  };
  past: any = {
    id: DateRange.Past,
    name: 'Past'
  };
  dateRanges: any = [this.upcoming, this.past];
  selectedDateRange: any = this.upcoming;

  constructor(private store: Store<AppState>,
    private config: ConfigService,
    private appInsightsService: AppInsightClientService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.store.select(e => e.clubs)
      .pipe(takeUntil(this.componentDestroyed),
        filter(e => e !== undefined && e.items !== undefined && e.items.length > 0))
      .subscribe(e => {
        this.clubs = [this.selectedClub, ...e.items];
      });

    this.store.dispatch(new GetClubsListAction());
  }

  selectClub(club: ClubDto) {
    this.selectedClub = club;
    this.clubChanged.emit();
  }

  selectDateRange(dateRange: any) {
    this.selectedDateRange = dateRange;
    this.dateRangeChanged.emit();
  }
}

