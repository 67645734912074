import { Injectable } from '@angular/core';
import { ApiVersionClient, PeopleClient, LanguageClient, ILanguageDto, IPlayerListDto, MatchClient } from './bpp.webapi/client';
import { AuthService } from '../authentication/auth.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class BppService {

    constructor(protected authService: AuthService,
        protected apiClient: ApiVersionClient,
        private peopleClient: PeopleClient,
        private matchClient:MatchClient,
        private languageClient: LanguageClient) {
    }

    getApiVersion() {

        return this.apiClient.get();
    }

    getPlayers(): Observable<IPlayerListDto> {
        return this.peopleClient.getTeamMembers();
    }

    getPlayersByTeamIds(teamIds: any[]) {
        const streams$ = teamIds.map(teamId => this.peopleClient.getById(teamId));
        return forkJoin(streams$);
    }

    getLanguages(): Observable<ILanguageDto[]> {
        return this.languageClient.get();
    }

    getPresentationUrl(): Observable<string> {
        return this.apiClient.getPresentationUrl();
    }

    getMatchRoundNumbers() {

        return this.matchClient.getMatchRoundNumbers();
    }
}
