import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, takeUntil } from "rxjs/operators";
import { PagedListOfTeamMemberDto } from "src/app/services/bpp.webapi/client";
import { AppState } from "src/app/store/app.state";
import { GetTeamMembersAction } from "src/app/store/team-members/actions";
import { BaseComponent } from "../../base-component/base.component";

@Component({
	selector: 'app-club-team-members',
	templateUrl: './club-team-members.component.html',
	styleUrls: ['./club-team-members.component.scss']
})
export class ClubTeamMembersComponent extends BaseComponent implements OnInit {
	data: PagedListOfTeamMemberDto;
	isSpinner: boolean = true;

	constructor(private store: Store<AppState>) {
		super();
	}

	ngOnInit(): void {
		this.store.select(e => e.teamMembers)
			.pipe(takeUntil(this.componentDestroyed),
				filter(e => e !== undefined && e.data !== undefined && e.data.data !== undefined))
			.subscribe(e => {
				this.data = e.data;
				this.isSpinner = false;
			});

		this.store.dispatch(new GetTeamMembersAction(0, 10));
	}

	updateData(skip: number, take: number) {
		this.store.dispatch(new GetTeamMembersAction(skip, take));
	}
}