
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { ReportService } from '../../services/report.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class ReportEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: ReportService,
    protected action$: Actions,
    private errorService: AlertService
  ) { }

  @Effect()
  GetPersonsReport$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetPersonsReportAction>(actions.GET_PERSONS_REPORT),
    switchMap(action => this.service.getPersonsReport(action.payload).pipe(
      switchMap((result) => of(new actions.GetPersonsReportSuccessAction(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetPersonsReportErrorAction(error));
      }))));

  @Effect()
  GetContentSessionTypesReport$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetContentSessionTypeReportAction>(actions.GET_CONTENT_SESSION_TYPE_REPORT),
    switchMap(() => this.service.getContentSessionTypesReport().pipe(
      switchMap(result => of(new actions.GetContentSessionTypeReportSuccessAction(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetContentSessionTypeReportErrorAction(error));
      }))));

  @Effect()
  GetBroadcastersRequests$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetBroadcasterRequestsReportAction>(actions.GET_BROADCASTER_REQUESTS_REPORT),
    switchMap(action => this.service.getBroadcastersRequestsReport(action.payload).pipe(
      switchMap(result => of(new actions.GetBroadcasterRequestsReportSuccessAction(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetBroadcasterRequestsReportErrorAction(error));
      }))));

  @Effect()
  GetInterviewsSummary$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetInterviewsSummaryReportAction>(actions.GET_INTERVIEWS_SUMMARY_REPORT),
    switchMap(() => this.service.getInterviewsSummaryReport().pipe(
      switchMap(result => of(new actions.GetInterviewsSummaryReportSuccessAction(result))),
      catchError(error => {
        this.errorService.error(error);
        return of(new actions.GetInterviewsSummaryReportErrorAction(error));
      })
    )));
}
