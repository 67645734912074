import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'modelJoin',
    pure: false
})
export class ModelJoinPipe implements PipeTransform {

    constructor() { }
    transform(value: any[] | undefined, model: {property: string, maxChars: number} = {property: 'name', maxChars: 0}) {
        if (!value || 0 === value.length) {
            return '';
        }
        const joined = value.map(x => x[model.property]).join(', ');
        if (model.maxChars > 0 && joined.length > model.maxChars) {
            return joined.substr(0, model.maxChars) + '...';
        } else {
            return joined;
        }
    }
}
