import { LocationDto } from '../../services/bpp.webapi/client';
import { AppState } from '../app.state';
import { createSelector } from '@ngrx/store';

export class LocationState {
    loading: boolean;
    error?: boolean;
    items: Array<LocationDto>;
    constructor() {
        this.loading = false;
        this.items = [];
    }
}


const getLocationsResult = (state: AppState) => state.location.items;
const getLocationState = (state: AppState) => state.location;
export const selectLocationById = (id: string) =>
    createSelector(
        getLocationsResult,
        result => {
            if (result && result.length > 0) {
                return result.find(item => item.locationId === id);
            }
        }
    );

export const selectLocationLoading = () =>
    createSelector(
        getLocationState,
        result => result.loading);
