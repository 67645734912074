import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LocationDto, LocationTypeDto } from 'src/app/services/bpp.webapi/client';
import { BaseComponent } from '../../base-component/base.component';
import {filter, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';

@Component({
	selector: 'app-locations-list',
	templateUrl: './locations-list.component.html',
	styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent extends BaseComponent implements OnInit {
	@Input() data: Array<LocationDto>;
  @Output() editLocationClicked = new EventEmitter();

  locationTypes: Array<LocationTypeDto>;

	constructor(private store: Store<AppState>) {
		super();
	}

	ngOnInit(): void {
    this.store.select(e => e.locationTypes)
      .pipe(takeUntil(this.componentDestroyed),
        filter(e => e !== undefined && e.items !== undefined && e.items.length > 0))
      .subscribe(e => {
        this.locationTypes = e.items;
        this.isSpinner = false;
      });
	}

  getLocationType(type: number) {
    if (this.locationTypes && this.locationTypes.length > 0) {
      const locationType = this.locationTypes.find(x => x.id === type);
      return locationType ? locationType.name : '';
    }
    return '';
  }
}
