import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { TeamMemberDto } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetTeamMemberAction, UpdateTeamMemberAction } from 'src/app/store/team-members/actions';
import { BaseComponent } from '../base-component/base.component';
import { DefaultsService, IDefaultsInfo } from '../../services/defaults.service';
import { ConfigService } from 'src/app/config/config.service';

@Component({
	selector: 'app-team-member-details',
	templateUrl: './team-member-details.component.html',
	styleUrls: ['./team-member-details.component.scss']
})
export class TeamMemberDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
	defaultTeamBio: string;

	teamMember: TeamMemberDto = new TeamMemberDto({
		id: 0,
		bios: this.defaultTeamBio,
		firstName: '',
		lastName: ''
	});

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '120px',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'now',
		enableToolbar: true,
		showToolbar: true,
		placeholder: '',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '1',
		uploadUrl: undefined,
		fonts: [
			{ class: 'arial', name: 'Arial' },
			{ class: 'times-new-roman', name: 'Times New Roman' },
			{ class: 'calibri', name: 'Calibri' },
			{ class: 'comic-sans-ms', name: 'Comic Sans MS' }
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule']]
	};

	constructor(private store: Store<AppState>,
		private route: ActivatedRoute,
		private router: Router,
		private defaultsService: DefaultsService,
		protected configService: ConfigService
	) {
		super();
	}

	ngOnInit(): void {
		this.defaultsService.getDefaults().subscribe(res => this.defaultTeamBio = res ? res.teamBio : '');

		this.teamMember = new TeamMemberDto({
			id: 0,
			bios: this.defaultTeamBio,
			firstName: '',
			lastName: ''
		});
		this.isSpinner = true;
		this.store.select(e => e.teamMembers)
			.pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined))
			.subscribe(e => {
				if (e.item === undefined) { return; }
				this.isSpinner = false;
				this.teamMember = e.item;
				if (!this.teamMember.bios || this.teamMember.bios.length < 1) this.teamMember.bios = this.defaultTeamBio;
			});

		this.route.params
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(params => {
				this.store.dispatch(new GetTeamMemberAction(params['id']));
			});
	}

	save() {
		this.store.dispatch(new UpdateTeamMemberAction(this.teamMember));
	}

	cancelBtnClick() {
		this.router.navigate([`team-members`]);
	}
}
