import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-one-column-page',
  templateUrl: './one-column-page.component.html',
  styleUrls: ['./one-column-page.component.scss']
})
export class OneColumnPageComponent implements OnInit {

  constructor(public config: ConfigService) { }

  ngOnInit() {
  }

}
