import { UserState } from './state';
import * as UserActions from './actions';

export type Action = UserActions.All;
const defaultState = new UserState();

export function UserReducer(state = defaultState, action: Action): UserState {

  switch (action.type) {

    case UserActions.GET_LOGGED_USER_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload.user,
        details: action.payload.details,
        userMenu: action.payload.userMenu,
        applicationIsLoading: false,
        loading: false,
        error: false
      };
    }

    case UserActions.GET_LOGGED_USER_ERROR: {
      return {
        ...state,
        currentUser: null,
        loading: false,
        error: true
      };
    }

    case UserActions.UPDATE_USER_NOTIFICATION_TYPES: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case UserActions.UPDATE_USER_NOTIFICATION_TYPES_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          userNotifications: action.payload
        },
        loading: false,
        error: false
      };
    }
    case UserActions.UPDATE_USER_NOTIFICATION_TYPES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    case UserActions.FAILED_TO_GET_USER_DETAILS: {
      return {
        ...state,
        error: true,
        errorMessages: action.payload
      };
    }

    default: {
      return state;
    }

  }
}
