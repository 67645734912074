import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';
import { FxRequest, IDentity } from '../../../store/fx-request/state';
import { IParticipantDto, RequestStatus, NoteDto, ParticipantType, CameraOperator } from '../../../services/bpp.webapi/client';
import { Roles } from '../../../models/enums';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { ConfigService } from '../../../config/config.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getCameraOptionTextId } from 'src/app/helpers/request.helper';

@Component({
  selector: 'app-interview-request-read',
  templateUrl: './interview-request-read.component.html',
  styleUrls: ['./interview-request-read.component.scss']
})
export class InterviewRequestReadComponent extends BaseComponent implements OnInit {
  @Input() request: FxRequest;
  @Input() isPastEvent = false;
  @Input() identity: IDentity;
  @Output() editMode: EventEmitter<boolean> = new EventEmitter();

  participants: IParticipantDto[];
  participantsByType: { [type: number]: IParticipantDto[] };
  isLeagueMode: boolean;
  isBroadcasterMode: boolean;
  containerMode: Roles;
  isEditMode = false;
  isLfp:boolean;


  constructor(private store: Store<AppState>, 
    config: ConfigService, 
    private router: Router,
    private translate: TranslateService) {
    super();
    this.isLog = config.isDebug();
    if (config.hasFeature("environment.lfp")){
      console.log('has feature')
      this.isLfp = true;
    }
  }

  ngOnInit() {
  }

  isCanChange() {
    return this.request.status !== RequestStatus.Withdrawn && this.request.status !== RequestStatus.Reject && this.isPastEvent !== true;
  }

  onEditMode() {
    this.editMode.emit(!this.isEditMode);
  }

  isNotEmptyNotes(notes: NoteDto) {
    return notes && notes.message && notes.message !== '';
  }

  goBack() {
    this.router.navigate([`interviews`]);
  }
  
  getCameraOption(section: any) {
    if (!section.cameraOperator){
      return this.translate.instant('PlpCamera');
    }
    if (section.type === ParticipantType.Camera && section.cameraOperator === CameraOperator.BroadcasterCamera)
      return this.translate.instant('InPerson');
    

    return this.translate.instant(getCameraOptionTextId(section.cameraOperator));
  }
}
