
import { Action } from '@ngrx/store';
import { UserNotificationType } from '../../services/bpp.webapi/client';

export const GET_LOGGED_USER = 'get_logged_user';
export const GET_LOGGED_USER_SUCCESS = 'get_logged_user_success';
export const GET_LOGGED_USER_ERROR = 'get_logged_user_error';
export const GET_LOGGED_USER_MENU = 'get_logged_user_menu';
export const GET_LOGGED_USER_MENU_SUCCESS = 'get_logged_user_menu_success';
export const GET_LOGGED_USER_MENU_ERROR = 'get_logged_user_menu_error';

export const UPDATE_USER_NOTIFICATION_TYPES = 'update_user_notification_types';
export const UPDATE_USER_NOTIFICATION_TYPES_SUCCESS = 'update_user_notification_types_success';
export const UPDATE_USER_NOTIFICATION_TYPES_ERROR = 'update_user_notification_types_error';

export const FAILED_TO_GET_USER_DETAILS = 'user_details_failed';

export class UserDetailsFailedAction implements Action {
  readonly type = FAILED_TO_GET_USER_DETAILS;
  constructor(public payload: any) { }
}


export class GetLoggedUserAction implements Action {
  readonly type = GET_LOGGED_USER;

  constructor() { }
}

export class GetLoggedUserSuccessAction implements Action {
  readonly type = GET_LOGGED_USER_SUCCESS;
  constructor(public payload: any) { }
}

export class GetLoggedUserErrorAction implements Action {
  readonly type = GET_LOGGED_USER_ERROR;
  constructor(public payload: any) { }
}

export class GetLoggedUserMenuAction implements Action {
  readonly type = GET_LOGGED_USER_MENU;
  constructor(public bitwise: number) { }
}

export class GetLoggedUserMenuSuccessAction implements Action {
  readonly type = GET_LOGGED_USER_MENU_SUCCESS;
  constructor(public payload: any) { }
}

export class GetLoggedUserMenuErrorAction implements Action {
  readonly type = GET_LOGGED_USER_MENU_ERROR;
}

export class UpdateUserNotificationTypesAction implements Action {
  readonly type = UPDATE_USER_NOTIFICATION_TYPES;

  constructor(public payload: UserNotificationType[]) { }
}

export class UpdateUserNotificationTypesSuccessAction implements Action {
  readonly type = UPDATE_USER_NOTIFICATION_TYPES_SUCCESS;
  constructor(public payload: UserNotificationType[]) { }
}

export class UpdateUserNotificationTypesErrorAction implements Action {
  readonly type = UPDATE_USER_NOTIFICATION_TYPES_ERROR;
  constructor(public payload: any) { }
}

export type All = GetLoggedUserAction
  | GetLoggedUserSuccessAction
  | GetLoggedUserErrorAction
  | GetLoggedUserMenuAction
  | GetLoggedUserMenuSuccessAction
  | GetLoggedUserMenuErrorAction
  | UserDetailsFailedAction
  | UpdateUserNotificationTypesAction
  | UpdateUserNotificationTypesErrorAction
  | UpdateUserNotificationTypesSuccessAction
  ;

