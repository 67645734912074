
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { DashboardService } from '../../services/dashboard.service';
import { ConfigService } from '../../config/config.service';
import { AppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { GetDashboardListAction } from '../../store/dashboard/actions';
import { IDashboardItemDto } from '../../services/bpp.webapi/dashboard.client';
import { User } from '../../models/user.model';
import { Roles } from '../../models/enums';
import { DashboardColors,DashboardColorUtil } from '../dashboard/models/dashboard-colors';
import { MessageConstructor, MessageConstructorFactory } from '../dashboard/models/message-constructor.factory';
import { TranslateService } from '@ngx-translate/core';

import { IDashboardMessage } from './models/dashboard-message.interface';
import { SimplifiedMessageConstructor } from './constructor/simplified-message.constructor';

import { MomentDatePipe } from '../../pipes/moment.date.pipe';


@Component({
  selector: 'app-dashboard-new-common',
  templateUrl: './dashboard-new-common.component.html',
  styleUrls: ['./dashboard-new-common.component.scss']
})

export class DashboardNewCommonComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isSpinner: boolean;
  isLog = false;
  collection: Array<IDashboardMessage>;
  items: IDashboardItemDto[];
  user: User;
  p = 1;
  pageSize = 10;
  total;
  userRole: Roles;
  msgConstructor: SimplifiedMessageConstructor;
  dashboardColors = DashboardColors;
  private isSimplified:boolean;
  isBroadcaster:boolean;
  isLeague:boolean;

  constructor(private service: DashboardService, private config: ConfigService,
    private translateService:TranslateService,
    private store: Store<AppState>,
    private route: ActivatedRoute) {
    this.isLog = config.isDebug();
    this.isSimplified = config.hasFeature("dashboard.simplified");
  }

  ngOnInit() {
    this.store.select(state => state.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
          this.userRole = state.currentUser.role;
          this.isLeague = this.userRole === Roles.Admin ||   this.userRole === Roles.League;
          this.isBroadcaster= this.userRole === Roles.Broadcaster ||   this.userRole === Roles.PLPBroadcaster
          this.store.dispatch(new GetDashboardListAction(this.p - 1, this.pageSize));
        }
      });

    this.store.select(state => state.dashboard).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Dashboard items: ', state.items); }

          if (state.items && state.items.length > 0) {
            this.items = state.items;
            this.msgConstructor = new SimplifiedMessageConstructor(this.user,this.translateService,new MomentDatePipe(this.config));
            this.collection = this.items.map(item =>
              this.msgConstructor.createMessage(item))
              .filter(m => m !== undefined)
              .sort((a: IDashboardMessage, b: IDashboardMessage) => {
                return a.date.valueOf() > b.date.valueOf() ? -1 : 1;
              });
            this.total = state.list.totalCount;
          }
          this.isSpinner = false;
        }
      });
  }

  isMe(userId: string) {
    return this.user.email === userId;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  onPageChanged(event) {
    this.p = event;
    this.store.dispatch(new GetDashboardListAction(this.p - 1, this.pageSize));
  }

  refreshBoard() {
    this.store.dispatch(new GetDashboardListAction(this.p - 1, this.pageSize));
  }

  getDashboardColorHex(color: DashboardColors){
    if (this.isSimplified){
      return DashboardColorUtil.toLfpHexString(color)
    }
    return DashboardColorUtil.toHexString(color)
  }
}
