import { AccessDeniedComponent } from '../components/access-denied/access-denied.component';
import { Type } from '@angular/core';
import { IUserRole } from '../models/user.model';
import { Roles } from '../models/enums';

export interface IComponetByRole {
    role: Roles;
    component: Type<any>;
}

export function getComponentByRole(components: IComponetByRole[], role: Roles) {
    const match = components.find(c => c.role === role);
    return match.component || AccessDeniedComponent;
}
