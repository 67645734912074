import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocationTypeClient, LocationTypeDto } from './bpp.webapi/client';

@Injectable()
export class LocationTypesService {
    constructor(protected apiClient: LocationTypeClient) {
    }

    getLocationTypes(): Observable<Array<LocationTypeDto>> {
        return this.apiClient.getAll();
    }

  getAvailableLocationTypes(clubId: number): Observable<Array<LocationTypeDto>> {
    return this.apiClient.getAllAvailable(clubId);
  }
}
