import * as actions from './actions';
import { TeamMembersState } from './state';

export type Action = actions.All;
const defaultState = new TeamMembersState();

export function TeamMembersReducer(state = defaultState, action: Action): TeamMembersState {
	switch (action.type) {
		case actions.GET_TEAM_MEMBERS: {
			return {
				...state,
				loading: true,
				error: false
			};
		}

		case actions.GET_TEAM_MEMBERS_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload
			}
		}

		case actions.GET_TEAM_MEMBERS_ERROR: {
			return {
				...state,
				loading: false,
				error: true
			};
		}

		case actions.GET_TEAM_MEMBER: {
			return {
				...state,
				loading: true,
				error: false
			};
		}

		case actions.GET_TEAM_MEMBER_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				item: action.payload
			}
		}

		case actions.GET_TEAM_MEMBER_ERROR: {
			return {
				...state,
				loading: false,
				error: true
			};
		}

		case actions.UPDATE_TEAM_MEMBER: {
			return {
				...state,
				loading: true,
				error: false
			};
		}

		case actions.UPDATE_TEAM_MEMBER_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				item: action.payload
			}
		}

		case actions.UPDATE_TEAM_MEMBER_ERROR: {
			return {
				...state,
				loading: false,
				error: true
			};
		}

		case actions.GET_TEAM_MEMBERS_BY_CLUB_ID: {
			return {
				...state,
				loading: true,
				error: false
			};
		}

		case actions.GET_TEAM_MEMBERS_BY_CLUB_ID_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				data: action.payload
			}
		}

		case actions.GET_TEAM_MEMBERS_BY_CLUB_ID_ERROR: {
			return {
				...state,
				loading: false,
				error: true
			};
		}

		case actions.RESET_TEAM_MEMBER: {
			return {
				...state,
				item: undefined,
				loading: false,
				error: false
			};
		}
	}

	return state;
}