export enum MatchStatus {
    Played = 0,
    ToBePlayed = 1,
    Undefined = 2,
    Suspended = 4,
    Postponed = 7,
    Cancelled = 8,
    Abandoned = 26,
    Lineup = 12
}

export enum Roles {
    League = 'League',
    Admin = 'Admin',
    Broadcaster = 'Broadcaster',
    PLPBroadcaster = 'PLPBroadcaster',
    Club = 'Club'
}
