import {ITrainingDto} from '../services/bpp.webapi/client';
import {DateRange, FilterPanelByClubDaterangeComponent} from '../components/filter-panel-by-club-date-range/filter-panel-by-club-daterange.component';
import * as moment from 'moment';

export function filterTrainings(data: Array<ITrainingDto>, filterPanel: FilterPanelByClubDaterangeComponent) {
  if (filterPanel) {
    const {selectedClub, selectedDateRange} = filterPanel;
    let filtered = data.map(x => x);
    if (selectedClub.id > 0) {
      filtered = filtered.filter(x => x.clubId === selectedClub.id);
    }

    const now = moment().utc();
    if (selectedDateRange.id === DateRange.Upcoming) {
      filtered = filtered.filter(x => x.date.isAfter(now) || x.date.isSame(now));
    } else {
      filtered = filtered.filter(x => x.date.isBefore(now));
    }
    return filtered;
  }
}
