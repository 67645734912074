import { Injectable } from '@angular/core';
import { Roles } from '../models/enums';

@Injectable()
export class MenuService {
    constructor() {
    }

    getMenu(role: Roles): Promise<any> {
        const success = res => res.ok ? res.json() : Promise.resolve({});
        switch(role) {
            case(Roles.Club): return fetch(`assets/menus/club.json?antiCache=${new Date().getTime()}`).then(success);
            case(Roles.Broadcaster): return fetch(`assets/menus/broadcaster.json?antiCache=${new Date().getTime()}`).then(success);
            case(Roles.PLPBroadcaster): return fetch(`assets/menus/plp-broadcaster.json?antiCache=${new Date().getTime()}`).then(success);
            case(Roles.League):
            case(Roles.Admin): return fetch(`assets/menus/default.json?antiCache=${new Date().getTime()}`).then(success);   
        }
    }
}
