import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../config/config.service';
import { GetTrainingEventsAction } from '../../../store/training/actions';
import { Router } from '@angular/router';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { ITrainingDto, EventStatus } from '../../../services/bpp.webapi/client';
import { AppInsightClientService } from '../../../services/app-insight.service';

@Component({
  selector: 'app-trainings-clubs-table',
  templateUrl: './trainings-clubs-table.component.html'
})
export class TrainingsClubsTableComponent implements OnInit, OnDestroy {
  user: any;
  isLog = false;
  trainingSubscription: Subscription;
  userSubscription: Subscription;
  trainings: Array<ITrainingDto>;
  date = moment().utc();
  isLoading = false;

  constructor(private store: Store<AppState>, private config: ConfigService, private appInsightsService: AppInsightClientService, private router: Router) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.trainingSubscription = this.store
      .select(e => e.trainings.result)
      .subscribe(res => {
        this.isLoading = res.loading;
        if (this.isLog) { console.log('GetTrainingEvents: ', res); }
        this.trainings = res.items;
      });

    this.userSubscription = this.store.select(store => store.user.currentUser).subscribe(user => {
      if (user && user.role) {
        this.user = user;
        this.store.dispatch(new GetTrainingEventsAction(user.role));
      }
    });

  }

  ngOnDestroy(): void {
    if (this.trainingSubscription) {
      this.trainingSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  navigateToCreate() {
    const newId = moment().utc().unix();
    this.router.navigate([`trainings/create/${newId}`]);
  }

  navigateToDetail(item: ITrainingDto) {
    if (StatusHelper.isCompeletedEvent(item, moment().utc()) === true) {
      return;
    }
    this.appInsightsService.logEvent('Training details', {
      trainingId: item.id
    });
    this.router.navigate([`trainings/detail/${item.id}`]);
  }

  getStateColor(item: ITrainingDto) {
    return StatusHelper.getStatusColor(item.status);
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }
}
