import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import {AdditionalContentType, EventStatus, IAdditionalContentFixtureDto, ITrainingDto, PlayerDto, RequestStatus} from 'src/app/services/bpp.webapi/client';

@Component({
  selector: 'app-additional-content-group',
  templateUrl: './additional-content-group.component.html',
  styleUrls: ['./additional-content-group.component.scss']
})
export class AdditionalContentGroupComponent implements OnInit {
  @Input() data: IAdditionalContentFixtureDto[];
  @Input() showClub: boolean;
  @Input() showRequestsCount: boolean;
  @Input() players = new Array<PlayerDto>();
  @Output() itemClick: EventEmitter<IAdditionalContentFixtureDto> = new EventEmitter();


  EventStatuses = EventStatus;
  isLfp:boolean;

  constructor(private config:ConfigService) { 
    this.isLfp= config.isLfp();
  }

  ngOnInit() {
    console.log('data',this.data)
  }

  getStateColor(status: EventStatus | RequestStatus) {
    if (this.isLfp){
     return StatusHelper.getLfpRequestStatusColor(status as RequestStatus);
    }
    return StatusHelper.getStatusColor(status as EventStatus);
  }

  navigateToDetail(item: IAdditionalContentFixtureDto) {
    this.itemClick.emit(item);
  }

  getName(data: IAdditionalContentFixtureDto) {
    if(!data) {
      return '';
    }
    if(this.players && (data.type === AdditionalContentType.PreSeason || data.type === AdditionalContentType.MidSeason)) {
      const player = this.players.find(pl => pl.id === data.personId);
      return player ? player.shortName : '';
    }
    else {
      var firstLine = data.participants ? data.participants.split('\n', 1)[0]: '';
      return firstLine;
    }

    return '';
  }

}
