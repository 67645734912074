
import { takeUntil, mergeMap, filter, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { selectInterviewDetailsById, selectInterviewDetailsLoading, IInterviewDetails } from '../../../store/interviews/state';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetInterviewDetailsAction, CreateUpdateInterviewRequestAction } from '../../../store/interviews/actions';
import { ITimePicker } from '../../common/combo-picker/combo-picker.component';
import { ConfigService } from '../../../config/config.service';
import { selectCurrentUserRole, selectUserIsLoading } from '../../../store/user/state';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { BaseComponent } from '../../base-component/base.component';
import { GetInterviewLocationsAction } from '../../../store/location/actions';
import { ValidationService } from '../../../services/validation.service';
import { selectLanguagesByIds, selectLanguagesLoading } from '../../../store/bpp/state';
import { selectLocationById, selectLocationLoading } from '../../../store/location/state';
import { InterviewTypeDto, RequestStatus } from '../../../services/bpp.webapi/client';
import { IDentity, FxRequest } from '../../../store/fx-request/state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getMinDate, getMaxDate } from '../../../helpers/date-helper';
import { InterviewRequestMapper } from '../../../mappers/interview-request.mapper';
import { ConfirmWithdrawPopupComponent } from '../../common/withdraw-request/confirm-withdraw-popup.component';
import {StatusHelper} from '../../../helpers/statuses-helper';
import * as momentTz from 'moment-timezone';

export enum EInterviewView {
  Undefined = 0,
  BroadcasterRead,
  BroadcasterEdit,
}

@Component({
  selector: 'app-interview-details-broadcaster',
  templateUrl: './interview-details-broadcaster.component.html'
})
export class InterviewDetailsBroadcasterComponent extends BaseComponent implements OnInit {
  details$: Observable<IInterviewDetails>;
  isLoading$: Observable<boolean>;
  data: IInterviewDetails;
  broadcasterRequest: FxRequest;
  original: IInterviewDetails;
  lastSaved: string;
  minStart: Subject<ITimePicker> = new Subject();
  maxStart: Subject<ITimePicker> = new Subject();
  minEnd: Subject<ITimePicker> = new Subject();
  maxEnd: Subject<ITimePicker> = new Subject();
  minutesStep = 5;
  isDebug: boolean;
  editMode = false;
  EInterviewView = EInterviewView;
  interviewView: EInterviewView;
  isPastEvent = false;
  minDate: { year: number; month: number; day: number; };
  maxDate: { year: number; month: number; day: number; };
  timeZone: string;
  durationEnabled: boolean;
  _startOfDay :moment.Moment
  _duration: moment.Moment;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    config: ConfigService,
    private valiadtor: ValidationService,
    private modalService: NgbModal) {
    super();
    this.isDebug = config.isDebug();
    this.timeZone = config.getTimezone();
    const features = config.get("features.interviews");
    this._duration= momentTz().tz(this.timeZone).startOf('day');
    this.isLfp = config.isLfp();
    if(features && features.duration) {
      this.durationEnabled = true;
    }
  }

  get duration(): moment.Moment {
    return this._duration;
  }

  set duration(value: moment.Moment) {
    this._duration = value;
    if(!this.data) {
      return;
    }
    this.data.duration = moment.duration(momentTz(value).tz(this.timeZone).diff(momentTz().tz(this.timeZone).startOf('day'), 'milliseconds'), 'milliseconds');
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        const interviewId = params['id'];
        this.store.dispatch(new GetInterviewDetailsAction(interviewId));
        this.details$ = this.store.select(selectInterviewDetailsById(interviewId)).pipe(filter(x => x !== undefined));
      }
    });

    const userRole$ = this.store.select(selectCurrentUserRole());
    const combined$ = this.details$.pipe(
      mergeMap((details) => {
        const languages$ = this.store.select(selectLanguagesByIds(details.languages.map(l => l.languageId)));
        const location$ = this.store.select(selectLocationById(details.location.locationId));
        return combineLatest([of(details), languages$, location$, userRole$]);
      }));

    combined$.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(([details, languages, location, role]) => {
        this._startOfDay =  momentTz().tz(this.timeZone).startOf('day');
        this.isPastEvent = details.isPast;
        this.minDate = getMinDate(details.arrivalDate);
        this.maxDate = getMaxDate(details.arrivalDate);
        this.data = cloneDeep(details);
        this.data.languages = languages || [];
        this.data.location = location ? cloneDeep(location) : details.location;
        this.original = cloneDeep(this.data);
        this.duration = this._startOfDay.add(this.data.duration,'milliseconds')

        const exists = this.data.requests && this.data.requests.length > 0;
        if (exists) {
          this.interviewView = EInterviewView.BroadcasterRead;
          this.broadcasterRequest = this.data.requests[0];
        } else {
          this.interviewView = this.isPastEvent ? EInterviewView.BroadcasterRead : EInterviewView.BroadcasterEdit;
          this.broadcasterRequest = InterviewRequestMapper.getEmptyRequest();
        }
        this.lastSaved = this.broadcasterRequest.lastSaved;
      });

    const dataLoading$ = this.store.select(selectInterviewDetailsLoading());
    const userLoading$ = this.store.select(selectUserIsLoading());
    const locationLoading$ = this.store.select(selectLocationLoading());
    const languagesLoading$ = this.store.select(selectLanguagesLoading());
    this.isLoading$ = combineLatest([dataLoading$, userLoading$, locationLoading$, languagesLoading$]).pipe(
      takeUntil(this.componentDestroyed),
      map(([data, usr, loc, lang]) => data || usr || loc || lang));

    this.store.dispatch(new GetInterviewLocationsAction());
  }

  goBack() {
    this.router.navigate([`interviews`]);
  }

  onCancel() {
    this.data = cloneDeep(this.original);
  }

  // --- Requests
  private saveOrSubmitRequest(request: FxRequest, status: RequestStatus) {
    const model: FxRequest = request;
    if (this.isLfp && !this.valiadtor.isRequestValid(request)){
      return;
    }
    request.status = status;
    request.broadcasterNote.message = request.note;
    this.store.dispatch(new CreateUpdateInterviewRequestAction(model, this.data.interviewId));
    this.interviewView = EInterviewView.BroadcasterRead;
  }

  onSaveRequest(event: { identity: IDentity, request: FxRequest }) {
    this.saveOrSubmitRequest(event.request, RequestStatus.Draft);
  }

  onSubmitRequest(event: { identity: IDentity, request: FxRequest }) {
    this.saveOrSubmitRequest(event.request, RequestStatus.Submitted);
  }

  onWithdrawRequest(event: { identity: IDentity, request: FxRequest }) {

    const modalRef = this.modalService.open(ConfirmWithdrawPopupComponent, { centered: true });
    const instance = modalRef.componentInstance as ConfirmWithdrawPopupComponent;
    const s = instance.withdraw
      .subscribe(doWithdraw => {
        if (doWithdraw === true) {
          this.saveOrSubmitRequest(event.request, RequestStatus.Withdrawn);
          this.interviewView = EInterviewView.BroadcasterRead;
          s.unsubscribe();
        }
      });
  }

  confirmedReject(request) {
    this.store.dispatch(new CreateUpdateInterviewRequestAction(request, this.data.interviewId));
  }

  changeLanguages(event) {
    this.data.languages = event;
  }

  onRequestEditMode() {
    this.interviewView = EInterviewView.BroadcasterEdit;
  }

  onCancelRequest() {
    this.interviewView = EInterviewView.BroadcasterRead;
    this.data.requests = cloneDeep(this.original.requests);
  }

  dateChanged(event: moment.Moment) {
    this.data.arrivalDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.arrivalTime.hour)
      .minutes(this.data.arrivalTime.minute);
    this.data.startDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.startTime.hour)
      .minutes(this.data.startTime.minute);
  }

  onArrivalTimeChanged(event: ITimePicker) {
    this.minEnd.next({
      hour: event.hour,
      minute: event.minute
    });
    this.data.arrivalDate = moment.utc(this.data.arrivalDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }

  getStatusText() {
    return StatusHelper.getRequestStatusText(this.broadcasterRequest.status);
  }
}
