
import { mergeMap, withLatestFrom, catchError, switchMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../app.state';
import { UserInfoService } from '../../authentication/userinfo.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as actions from './actions';
import { AlertService } from '../../services/alert.service';
import { ValidationService } from '../../services/validation.service';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: UserInfoService,
    protected actions$: Actions,
    private alertService: AlertService,
    private validator: ValidationService,
    private router: Router
  ) { }

  @Effect()
  GetCurrentUser$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetLoggedUserAction>(actions.GET_LOGGED_USER),
    switchMap(action => this.service.getUserWithDetails().pipe(
      switchMap(result => {
        const validationResult = this.validator.isLoggedUserValid(result.user, result.details);
        if (!validationResult.result) {
          this.store.dispatch(new actions.UserDetailsFailedAction(validationResult.messages));
          this.router.navigate([`critical`]);
        }
        console.log(`- user: ${result.user.email} [${result.user.role}] (${result.details.company})`);
        return of(new actions.GetLoggedUserSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetLoggedUserErrorAction(error));
      }))));

  @Effect()
  GetUserMenu$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetLoggedUserMenuAction>(actions.GET_LOGGED_USER_MENU),
    mergeMap(action =>
      this.service.getUserMenu().pipe(
        switchMap((data) => of(new actions.GetLoggedUserMenuSuccessAction(data))))));

  @Effect()
  UpdateUserNotificationTypes$: Observable<Action> = this.actions$.pipe(
    ofType<actions.UpdateUserNotificationTypesAction>(actions.UPDATE_USER_NOTIFICATION_TYPES),
    switchMap(action => this.service.updateUserNotificationTypes(action.payload).pipe(
      switchMap(types => {
        const message = `Preferences updated`;
        this.alertService.success(message);
        return of(new actions.UpdateUserNotificationTypesSuccessAction(types));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateUserNotificationTypesErrorAction(error));
      }))));
}
