import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { ApplicationDto, UserClient } from '../../services/bpp.webapi/client';
import { BaseComponent } from '../base-component/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-layout-page',
  templateUrl: './main-layout-page.component.html',
  styleUrls: ['./main-layout-page.component.scss']
})
export class MainLayoutPageComponent extends BaseComponent implements OnInit {
  page: string;
  public applications: Array<ApplicationDto> = [];
  public isLfp: boolean;

  constructor(protected route: ActivatedRoute, private userService: UserClient, public config: ConfigService) {
    super();
    this.isLfp = config.isLfp();
   }

  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      this.page = segments.join('');
    });

    this.userService.getApplications()
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe(apps => {
      this.applications = apps;
    });

  }

  changeApp(app) {
    const w = window.open('');
    w.location.href = app.homeUrl;
  }

}
