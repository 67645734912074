import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform<T>(collection: Array<T>, property: string): { [key: string]: T[]} {
    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((acc: {}, current: T) => {
      if (!acc[current[property]]) {
        acc[current[property]] = [current];
      } else {
        acc[current[property]].push(current);
      }

      return acc;
    }, {});

    return groupedCollection;
  }
}
