import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment-timezone';
import { ConfigService } from '../../../config/config.service';

@Component({
  selector: 'app-time-picker-wrapper',
  templateUrl: './time-picker-wrapper.component.html',
  styleUrls: ['./time-picker-wrapper.component.scss']
})
export class TimePickerWrapperComponent implements OnChanges {

  @Output() timeChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();
  model = {
    hour: moment().utc().hour(),
    minute: moment().utc().minute()
  };
  date = moment().utc();
  timeZone: string;

  @Input()
  get dateTime(): moment.Moment {
    return moment(this.date)
      .tz(this.timeZone)
      .hour(this.model.hour)
      .minute(this.model.minute)
      .utc();
  }
  set dateTime(value) {
    this.model = {
      hour: moment.utc(value).tz(this.timeZone).hour(),
      minute: moment.utc(value).tz(this.timeZone).minute()
    };
    this.date = value;
  }

  @Input() min: moment.Moment;
  @Input() max: moment.Moment;
  @Input() disabled: boolean;

  constructor(private configService: ConfigService) {
    this.timeZone = configService.get('defaultTimeZone');
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.min || changes.max || changes.dateTime) {
      const updated = this.alignTime();
      if(updated) {
        this.timeChange.emit(this.dateTime);
      }
    }
  }

  onPickerChange() {
    console.log('Time before:' + this.date.tz(this.timeZone).toISOString());
    console.log('Time after' + this.dateTime.toISOString());
    this.alignTime();
    this.timeChange.emit(this.dateTime);
  }

  alignTime() {
    if (this.date && this.model && this.min && this.dateTime < this.min) {
      this.dateTime = moment(this.min);
      return true;
    }
    if (this.date && this.model && this.max && this.dateTime > this.max) {
      this.dateTime = moment(this.max);
      return true;
    }
    return false;
  }

}
