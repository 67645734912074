import { AppInsightClientService } from './app-insight.service';
import { Injectable, ErrorHandler, Injector } from '@angular/core';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: any): void {
        const monitoringService = this.injector.get(AppInsightClientService);
        monitoringService.logError(error);
        super.handleError(error);
    }
}
