import { ParticipantType } from "src/app/services/bpp.webapi/client";

export const SECTIONS_LIST = [
    {
      title: "AddReporter",
      name: "Reporter",
      itemName: 'AddNewReporter',
      type: ParticipantType.Reporter,
      property: "isReportersEnabled"
    },
    {
      title: "AddCameraOperator",
      name: "CameraOperator",
      itemName: 'AddNewCameraOperator',
      type: ParticipantType.Camera,
      property: "isCameraOperatorEnabled"
    },
    {
      title: "AddProducer",
      name: "Producer",
      itemName: 'AddNewProducer',
      type: ParticipantType.Producer,
      property: "isProducersEnabled"
    },
    {
      title: "AddOther",
      name: "Other",
      itemName: 'AddNewOther',
      type: ParticipantType.Other,
      property: "isOthersEnabled"
    }
  ];