import { Injectable } from '@angular/core';
import { ClubsClient } from './bpp.webapi/client';

@Injectable()
export class ClubsService {
    constructor(private client: ClubsClient) {

    }

    getClubs() {
        return this.client.get();
    }
}
