import { Component, OnInit, Input } from '@angular/core';
import { IFilmingWeekDto } from '../../../store/filming/state';

@Component({
  selector: 'app-filming-rounds',
  templateUrl: './filming-rounds.component.html',
  styleUrls: ['./filming-rounds.component.scss']
})
export class FilmingRoundsComponent implements OnInit {
  @Input() rounds: IFilmingWeekDto[];
  constructor() { }

  ngOnInit() {
  }

}
