

export class NewsState {
    allNews: any;
    detailNews: any;
    rssNews: any;

    getLoading: boolean;
    isError: boolean;

    constructor() {
    }
}
