import * as moment from 'moment';
import { EventStatus, RequestStatus, NotificationType } from '../services/bpp.webapi/client';
import { Roles } from '../models/enums';
import { APPROVED_COLOR, DRAFT_COLOR, REJECTED_COLOR, SUBMITTED_COLOR, TODO_OR_UNKNOWN_COLOR,WITHDRAWN_COLOR } from '../models/constants';

export class StatusHelper {
  constructor() {

  }

  static getStatusColor(status: EventStatus) {
    switch (status) {
      
      case EventStatus.Unknown: return '#FF005A';
      case EventStatus.Draft: return '#94948D';
      case EventStatus.Submitted: return '#00FF87';
      case EventStatus.Approved: return '#05F0FF';
      case EventStatus.Reject: return '#EBFF00';
      default: return '#FFFFFF';
    }
  }

  static getLfpStatusColor(status: EventStatus) {
    switch (status) {
      
      case EventStatus.Unknown: return TODO_OR_UNKNOWN_COLOR;
      case EventStatus.Draft: return DRAFT_COLOR;
      case EventStatus.Submitted: return SUBMITTED_COLOR;
      case EventStatus.Approved: return APPROVED_COLOR;
      case EventStatus.Reject: return REJECTED_COLOR;
      default: return '#FFFFFF';
    }
  }

  static getStatusText(status: EventStatus) {
    switch (status) {
      case EventStatus.Approved: return 'Approved';
      case EventStatus.Draft: return 'Draft';
      case EventStatus.Reject: return 'Rejected';
      case EventStatus.Submitted: return 'Submitted';
      case EventStatus.Unknown: return '---';
      default: return '---';
    }
  }

  static getEventStatusAction(status: EventStatus) {
    switch (status) {
      case EventStatus.Approved: return 'approve';
      case EventStatus.Draft: return 'draft';
      case EventStatus.Reject: return 'reject';
      case EventStatus.Submitted: return 'submit';
      case EventStatus.Unknown: return '---';
      default: return '---';
    }
  }

  static getRequestStatusColor(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Unknown: return '#FF005A';
      case RequestStatus.Draft: return '#94948D';
      case RequestStatus.Submitted: return '#00FF87';
      case RequestStatus.Approved: return '#05F0FF';
      case RequestStatus.Reject: return '#EBFF00';
      case RequestStatus.Withdrawn: return '#0000FF';
      default: return '#FFFFFF';
    }
  }

  static getLfpRequestStatusColor(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Unknown: return TODO_OR_UNKNOWN_COLOR;
      case RequestStatus.Draft: return DRAFT_COLOR;
      case RequestStatus.Submitted: return SUBMITTED_COLOR;
      case RequestStatus.Approved: return APPROVED_COLOR;
      case RequestStatus.Reject: return REJECTED_COLOR;
      case RequestStatus.Withdrawn: return WITHDRAWN_COLOR;
      default: return '#FFFFFF';
    }
  }

  static getRequestStatusText(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Approved: return 'Approved';
      case RequestStatus.Draft: return 'Draft';
      case RequestStatus.Reject: return 'Rejected';
      case RequestStatus.Submitted: return 'Submitted';
      case RequestStatus.Withdrawn: return 'Withdrawned';
      case RequestStatus.Unknown: return 'To Do';
      default: return 'Unknown';
    }
  }

  static getRequestStatusTextExt(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Approved: return 'Approved';
      case RequestStatus.Draft: return 'Saved as a draft';
      case RequestStatus.Reject: return 'Rejected';
      case RequestStatus.Submitted: return 'Submitted';
      case RequestStatus.Withdrawn: return 'Withdrawned';
      case RequestStatus.Unknown: return 'To Do';
      default: return 'Unknown';
    }
  }

  static getRequestStatusAction(status: RequestStatus) {
    switch (status) {
      case RequestStatus.Approved: return 'approve';
      case RequestStatus.Draft: return 'draft';
      case RequestStatus.Reject: return 'reject';
      case RequestStatus.Submitted: return 'submit';
      case RequestStatus.Withdrawn: return 'withdrawn';
      case RequestStatus.Unknown: return '---';
      default: return 'Unknown';
    }
  }

  static isCompeletedEvent(event, date: moment.Moment) {
    // Trololo!
    if (event.eventDate !== undefined && event.eventDate.year() > 1) {
      if (date.isAfter(event.eventDate) || date.isSame(event.eventDate)) {
        return true;
      } else {
        return false;
      }
    }
    const temp = moment(event.date).endOf('month').utc();
    if (date.isAfter(temp) || date.isSame(temp)) {
      return true;
    } else {
      return false;
    }

  }

  static isCompeletedTrainingDetails(event, date: moment.Moment) {
    const eventMaxDate = moment(event.start).endOf('month');
    if (date.isAfter(eventMaxDate) || date.isSame(eventMaxDate)) {
      return true;
    } else {
      return false;
    }
  }

  static isDatePassed(date: moment.Moment) {
    if (moment().utc().isAfter(date)) {
      return true;
    } else {
      return false;
    }
  }

  static getEventsByCompletion(events: Array<any>, date: moment.Moment, complete: boolean) {
    const eventsList = [];
    events.forEach(function (event) {
      if (this.isCompeletedEvent(event, date) === complete) {
        eventsList.push(event);
      }
    });
    return eventsList;
  }

  static getNotificationTypeNameId(type: NotificationType, role: Roles) {
    if (role === Roles.Club) {
      switch (type) {
        case NotificationType.ApproveTraining: return 'LeagueApproveTrainingSession';
        case NotificationType.ApproveFixture: return 'LeagueApproveFixtureBpp';
        case NotificationType.RejectTraining: return 'LeagueRejectTrainingSession';
        case NotificationType.RejectFixture: return 'LeagueRejectFixturBpp';
        case NotificationType.TrainingSubmitted: return 'TrainingSubmitted';
        case NotificationType.FixtureSubmitted: return 'FixtureSubmitted';
        case NotificationType.ApproveTrainingRequest: return 'LeagueApprovedTrainingRequest';
        case NotificationType.ApproveFixtureRequest: return 'LeagueApprovedFixtureRequest';
        case NotificationType.SubmitTrainingRequest: return 'NewBrRequestForTraining';
        case NotificationType.SubmitFixtureRequest: return 'NewBrRequestForFixture';
        default: return NotificationType[type];
      }
    } else if (role === Roles.League || role === Roles.Admin) {
      switch (type) {
        case NotificationType.ApproveTraining: return 'TrainingSessionApproved';
        case NotificationType.ApproveFixture: return 'FixtureBppApproved';
        case NotificationType.RejectTraining: return 'TrainingSessionRejected';
        case NotificationType.RejectFixture: return 'FixtureBppRejected';
        case NotificationType.TrainingSubmitted: return 'ClubSubmittedTrainingSession';
        case NotificationType.FixtureSubmitted: return 'ClubSubmittedFixtureBpp';
        case NotificationType.ApproveTrainingRequest: return 'TrainingRequestApproved';
        case NotificationType.ApproveFixtureRequest: return 'FixtureRequestApproved';
        case NotificationType.RejectTrainingRequest: return 'TrainingRequestRejected';
        case NotificationType.RejectFixtureRequest: return 'FixtureRequestRejected';
        case NotificationType.SubmitTrainingRequest: return 'BroadcasterSubmittedTR';
        case NotificationType.SubmitFixtureRequest: return 'BroadcasterSubmittedFR';
        case NotificationType.RequestAwaitingInformation: return 'RequestAwaitingInformation';
        case NotificationType.InterviewSubmitted: return 'InterviewSubmitted';
        case NotificationType.InterviewRequestSubmitted: return 'InterviewRequestSubmitted';
        case NotificationType.InterviewRequestApproved: return 'InterviewRequestApproved';
        case NotificationType.InterviewRequestRejected: return 'InterviewRequestRejected';
        case NotificationType.FixtureRequestWithdrawn: return 'Broadcaster withdrew Fixture request';
        case NotificationType.TrainingRequestWithdrawn: return 'Broadcaster withdrew Training request';
        default: return NotificationType[type];
      }
    } else if (role === Roles.Broadcaster || role === Roles.PLPBroadcaster) {
      switch (type) {
        case NotificationType.ApproveTraining: return 'LeagueApprovedTrainingSession';
        case NotificationType.ApproveFixture: return 'LeagueApprovedFixtureBpp';
        case NotificationType.RejectTraining: return 'TrainingSessionRejected';
        case NotificationType.RejectFixture: return 'FixtureBppRejected';
        case NotificationType.TrainingSubmitted: return 'ClubSubmittedTrainingSession';
        case NotificationType.FixtureSubmitted: return 'ClubSubmittedFixtureBpp';
        case NotificationType.ApproveTrainingRequest: return 'LeagueApprovedTrainingRequest';
        case NotificationType.ApproveFixtureRequest: return 'LeagueApprovedFixtureRequest';
        case NotificationType.RejectTrainingRequest: return 'LeagueRejectTrainingRequest';
        case NotificationType.RejectFixtureRequest: return 'LeagueRejectFixtureRequest';
        case NotificationType.SubmitTrainingRequest: return 'TrainingRequestSubmitted';
        case NotificationType.SubmitFixtureRequest: return 'FixtureRequestSubmitted';
        case NotificationType.RequestAwaitingInformation: return 'RequestAwaitingInformation';
        case NotificationType.InterviewSubmitted: return 'InterviewSubmitted';
        case NotificationType.InterviewRequestSubmitted: return 'InterviewRequestSubmitted';
        case NotificationType.InterviewRequestApproved: return 'InterviewRequestApproved';
        case NotificationType.InterviewRequestRejected: return 'InterviewRequestRejected';
        case NotificationType.FixtureRequestWithdrawn: return 'FixtureRequestWithdrawn';
        case NotificationType.TrainingRequestWithdrawn: return 'TrainingRequestWithdrawn';
        case NotificationType.FixtureExtendedICSApproved: return 'FixtureExtendedICSApproved';
        case NotificationType.FixtureRequestExtendedICSSubmitted: return 'FixtureRequestExtendedICSSubmitted';
        case NotificationType.FixtureRequestExtendedICSApproved: return 'FixtureRequestExtendedICSApproved';
        case NotificationType.FixtureRequestExtendedICSRejected: return 'FixtureRequestExtendedICSRejected';
        case NotificationType.News: return 'News';
        default: return NotificationType[type];
      }
    }
  }
}
