import { Action } from '@ngrx/store';
import { ClubDto } from '../../services/bpp.webapi/client';

export const GET_CLUBS_LIST = 'get_Clubs_list';
export const GET_CLUBS_LIST_SUCCESS = 'get_Clubs_list_success';
export const GET_CLUBS_LIST_ERROR = 'get_Clubs_list_error';

export class GetClubsListAction implements Action {
    readonly type = GET_CLUBS_LIST;
    constructor() { }
}

export class GetClubsListActionSuccess implements Action {
    readonly type = GET_CLUBS_LIST_SUCCESS;
    constructor(public payload: Array<ClubDto>) { }
}

export class GetClubsListActionError implements Action {
    readonly type = GET_CLUBS_LIST_ERROR;
    constructor(public payload: any) { }
}

export type All = GetClubsListAction | GetClubsListActionError | GetClubsListActionSuccess;
