import { Action } from '@ngrx/store';

export const GET_NEWS = 'get_news';
export const GET_NEWS_SUCCESS = 'get_news_success';

export const GET_NEWS_BY_ID = 'get_news_by_id';
export const GET_NEWS_BY_ID_SUCCESS = 'get_news_by_id_success';

export const GET_RSS_NEWS = 'get_rss_news';
export const GET_RSS_NEWS_SUCCESS = 'get_rss_news_success';

export const GET_NEWS_ERROR = 'get_news_error';

export class GetNewsAction implements Action {
    readonly type = GET_NEWS;
    constructor() { }
}

export class GetNewsSuccessAction implements Action {
    readonly type = GET_NEWS_SUCCESS;
    constructor(public payload: any) {
    }
}

export class GetNewsByIdAction implements Action {
    readonly type = GET_NEWS_BY_ID;
    constructor(public id: string) { }
}

export class GetNewsByIdSuccessAction implements Action {
    readonly type = GET_NEWS_BY_ID_SUCCESS;
    constructor(public payload: any) {
    }
}

export class GetRssNewsAction implements Action {
    readonly type = GET_RSS_NEWS;
    constructor() { }
}

export class GetRssNewsSuccessAction implements Action {
    readonly type = GET_RSS_NEWS_SUCCESS;
    constructor(public payload: any) {
    }
}

export class GetNewsErrorAction implements Action {
    readonly type = GET_NEWS_ERROR;
    constructor(public payload: any) { }
}


export type All = GetNewsAction
    | GetNewsSuccessAction
    | GetNewsByIdAction
    | GetNewsByIdSuccessAction
    | GetNewsErrorAction
    | GetRssNewsAction
    | GetRssNewsSuccessAction
    ;
