import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-withdraw-popup',
  templateUrl: './confirm-withdraw-popup.component.html'
})
export class ConfirmWithdrawPopupComponent implements OnInit {

  @Output() withdraw: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onWithdraw(value: boolean) {
      this.withdraw.emit(value);
      this.activeModal.close('Close click');
  }

}
