import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IFixtureEventDetailsDto, LocationDto, CreateLocationDto, PlayerDto, FixtureEventType, LocationTypeDto } from '../../../services/bpp.webapi/client';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetLocationsAction, CreateLocationAction } from '../../../store/location/actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLocationComponent } from '../../common/add-location/add-location.component';
import * as moment from 'moment';
import { Roles } from '../../../models/enums';
import { ConfigService } from '../../../config/config.service';
import * as helper from '../../../helpers/date-helper';
import { fixtureEventTypes } from '../../../helpers/fixture-helper';
import { GetAvailableLocationTypesAction, GetLocationTypesAction } from 'src/app/store/location-types/actions';

@Component({
  selector: 'app-fixture-body-details',
  templateUrl: './fixture-body-details.component.html',
  styleUrls: ['./fixture-body-details.component.scss']
})
export class FixtureBodyDetailsComponent implements OnInit, OnDestroy {

  locationSubscription: Subscription;
  fixtureModel: IFixtureEventDetailsDto = null;
  locationTypesSubscription: Subscription;
  locationTypes: LocationTypeDto[];
  teamId: number;
  isLeagueMode: boolean;

  minDate = {
    year: moment().utc().year(),
    month: moment().utc().month(),
    day: moment().utc().date()
  };
  maxDate = {
    year: moment().utc().year(),
    month: moment().utc().month(),
    day: moment().utc().date()
  };

  startDate: moment.Moment;
  arrivalTime: moment.Moment;
  startTime: moment.Moment;

  @Output() modelChange: EventEmitter<IFixtureEventDetailsDto> = new EventEmitter<IFixtureEventDetailsDto>();
  @Input() isReadOnly: boolean;
  @Input() players: PlayerDto[];
  @Input() isPastFixture: boolean;
  fixtureEventTypes: { key: FixtureEventType; name: string; }[];
  selectedLocationType: LocationTypeDto;


  @Input()
  get fixture(): IFixtureEventDetailsDto {
    return this.fixtureModel;
  }
  set fixture(value) {
    this.fixtureModel = value;
    this.startDate = moment(this.fixtureModel.start);
    this.startTime = moment(this.fixtureModel.start);
    this.arrivalTime = moment(this.fixtureModel.arrival);
    this.modelChange.emit(this.fixtureModel);
  }
  @Input() userRole: Roles;

  selectedLocation: LocationDto;
  locations: LocationDto[];
  modalSubscription;
  isLog = false;

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    config: ConfigService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    if (this.isLog) { console.log('Fixture-body init:', this.fixtureModel); }

    this.minDate = helper.getMinDate(this.fixtureModel.arrival);
    this.maxDate = helper.getMaxDate(this.fixtureModel.arrival);

    this.isLeagueMode = this.userRole === Roles.League || this.userRole === Roles.Admin;

    this.teamId = this.fixtureModel.clubId;
    this.locationSubscription = this.store
      .select(e => e.location.items)
      .subscribe(res => {
        if (res && res.length > 0) {
          this.locations = res;
          this.selectedLocation = this.locations.find(x => x.locationId === this.fixture.location.locationId);
          this.store.dispatch(new GetLocationTypesAction());
        }
      });
    this.locationTypesSubscription = this.store
      .select(e => e.locationTypes.items)
      .subscribe(res => {
        if (res && res.length > 0) {
          this.locationTypes = res;
          if (this.selectedLocation && !this.fixtureModel.isNonLiveInUK) {
            this.selectedLocationType = this.locationTypes.find(x => x.id === this.selectedLocation.type);
          }
        }
      });
    this.store.dispatch(new GetLocationsAction(this.teamId));
    this.store.dispatch(new GetLocationTypesAction());
    this.fixtureEventTypes = fixtureEventTypes;
  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  concatDate(date: moment.Moment, time: moment.Moment) {
    const h = time.hour();
    const m = time.minute();
    const result = moment(date, 'YYYY-MM-DD').hour(time.hour()).minute(time.minute());
    const str = result.format('YYYY-MM-DD HH:mm');
    return result;
  }

  printLog(what) {
    if (this.isLog) {
      console.log(`${what} changed`);
      console.log(`[arrival]:`, this.fixtureModel.arrival.toISOString());
      console.log(`[start]:`, this.fixtureModel.start.toISOString());
    }
  }

  dateChanged(e: moment.Moment) {
    this.startDate = e;
    this.arrivalTime = this.concatDate(e, this.arrivalTime);
    this.startTime = this.concatDate(e, this.startTime);
    this.fixtureModel.arrival = this.concatDate(e, this.arrivalTime);
    this.fixtureModel.start = this.concatDate(e, this.startTime);
    this.printLog('Fixture Date');
  }

  arrivalTimeChanged(time: moment.Moment) {
    this.arrivalTime = this.concatDate(this.startDate, time);
    this.fixtureModel.arrival = this.concatDate(this.startDate, time);
    this.printLog('Fixture Arrival');
  }

  startTimeChanged(time: moment.Moment) {
    this.startTime = this.concatDate(this.startDate, time);
    this.fixtureModel.start = this.concatDate(this.startDate, time);
    this.printLog('Fixture Start');
  }

  openAddLocation() {
    const modalRef = this.modalService.open(AddLocationComponent);
    this.modalSubscription = modalRef.componentInstance.save.subscribe(location => this.saveLocation(location));
  }

  saveLocation(location: CreateLocationDto) {
    this.store.dispatch(new CreateLocationAction(location));
    this.store.dispatch(new GetLocationsAction(location.clubId));
    this.modalSubscription.unsubscribe();
  }

  changeLanguages(languages) {
    this.fixtureModel.languages = languages;
  }

  selectPlayer(id) {
    if (this.fixtureModel.peoples && this.fixtureModel.peoples.players.findIndex(x => x.id.toString() === id.toString()) === -1) {
      const player = this.players.find(pl => pl.id === id);
      const newPlayer: PlayerDto = new PlayerDto({
        id: player.id,
        shortName: player.shortName,
        officialName: player.officialName,
        officialSurname: player.officialSurname,
        role: player.role,
        bppCount: player.bppCount,
        appCount: player.appCount
      });
      this.fixtureModel.peoples.players.push(newPlayer);
    }
  }

  deletePlayer(sender) {
    const index = this.findWithAttr(this.fixture.peoples.players, 'id', sender.player.id);
    if (index !== -1) {
      this.fixtureModel.peoples.players.splice(index, 1);
    }
  }

  getFixtureICSSettingTypeText(val: number) {
    if (val !== undefined && this.locationTypes) {
      return this.locationTypes.find(x => x.id === val).name;
    }
    return '';
  }

  getFixtureEventTypeText(val: number) {
    if (val !== undefined) {
      return this.fixtureEventTypes.find(x => x.key === val).name;
    }
    return '';
  }

  onChangeFixtureType(item: { key: number; name: string }) {
    this.fixtureModel.type = item.key;
  }

  onChangeLocationType(item: LocationTypeDto) {
    this.selectedLocationType = item;
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
