
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable()
export class ForgeService {

  constructor(protected configService: ConfigService, protected httpClient: HttpClient) { }

  private _baseUrl: string;
  get baseUrl(): string {
    if (!this._baseUrl) {
      this._baseUrl = this.configService.get('forgeDapiUrl');
    }
    return this._baseUrl;
  }

  getStories(): Observable<any[]> {
    const url = `${this.baseUrl}/stories`;
    return this.httpClient.get<any>(url).pipe(map(response => {
      const filterForNews = this.configService.get('features.news.filterForNews');
      const landscapeFeature = this.configService.get('features.news.landscapeThumbnales');
      if (filterForNews.enabled) {
        response.items = response.items.filter(item => filterForNews.filter.indexOf(item.fields.application) !== -1);
      }
      let items = [];
      if (landscapeFeature.enabled && response.items) {
        // thumbnailUrl: "https://lfp-dev-forge-dapi.azurewebsites.net/v2/photos/raw/marseille-payet-goal/_thumb"
        items = response.items.map(item => {
          if (item.thumbnail && item.thumbnail.thumbnailUrl) {
            return {
              ...item,
              thumbnail: {
                ...item.thumbnail,
                thumbnailUrl: item.thumbnail.thumbnailUrl.replace('_thumb', 'landscape_crop')
              }
            };
          } else {
            return item;
          }
        });
      } else {
        items = response.items.slice();
      }

      return {
        ...response,
        items
      };
    }));
  }

  getStoryById(id: string): Observable<any> {
    const url = `${this.baseUrl}/stories/${id}`;
    return this.httpClient.get<any>(url).pipe(map(response => response));
  }

}
