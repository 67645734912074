import { DashboardItemStatus, DashboardItemType, IDashboardItemDto, AttendeeDto, FixtureEventType } from '../../../services/bpp.webapi/dashboard.client';
import { Roles } from '../../../models/enums';
import { getMonthName } from '../../../helpers/date-helper';

export function getCurrentOperatorRole(source: IDashboardItemDto) {
  if (source.userRoles && source.userRoles.length > 0 && source.userRoles[0] === Roles.League) {
    return Roles.League;
  }
  if (source.dashboardItemStatus === DashboardItemStatus.Approved) { return Roles.League; }
  if (source.dashboardItemStatus === DashboardItemStatus.Rejected) { return Roles.League; }
  if (source.dashboardItemType === DashboardItemType.FixtureRequest) { return Roles.Broadcaster; }
  if (source.dashboardItemType === DashboardItemType.TrainingRequest) { return Roles.Broadcaster; }
  if (source.dashboardItemType === DashboardItemType.InterviewEventRequest) { return Roles.Broadcaster; }

  if (source.dashboardItemType === DashboardItemType.FixtureEvent) { return Roles.Club; }
  if (source.dashboardItemType === DashboardItemType.TrainingEvent) { return Roles.Club; }
  if (source.dashboardItemType === DashboardItemType.InterviewEvent) { return Roles.Club; }
  if (source.dashboardItemType === DashboardItemType.Interview) { return Roles.League; }
  if (source.dashboardItemType === DashboardItemType.InterviewRequest) { return Roles.Broadcaster; }
}

export function getCompany(source: IDashboardItemDto) {
  return source.userCompanyName;
}

export function getOwnerCompany(source: IDashboardItemDto) {
  return source.ownerCompanyName;
}

export function getEventCompany(source: IDashboardItemDto) {
  return source.eventCompanyName;
}

export function getUserName(source: IDashboardItemDto) {
  return source.userName;
}


export function getUserAccount(source: IDashboardItemDto) {
  return `${source.userName} (${source.userCompanyName})`;
}

export function getUserAccountWithoutBrackets(source: IDashboardItemDto) {
  return `${source.userName} ${source.userCompanyName}`;
}

export function getAvatar(clubId) {
  if (clubId) {
    return `/assets/teams/48/t${clubId}.png`;
  } else {
    return undefined;
  }
}

// ------
export function getPlayers(source: IDashboardItemDto) {
  if (source.attendees && source.attendees.length > 0) {
    switch (source.fixtureEventType) {
      case FixtureEventType.Double:
        return source.attendees.map(s => s.fullName).join(' & ');
      default: return source.attendees.map(s => s.fullName).join(', ');
    }
  }
}

export function getIcsTypeText(source: IDashboardItemDto): string {
  if (source.dashboardItemType==DashboardItemType.InterviewEvent || 
    source.dashboardItemType == DashboardItemType.InterviewEventRequest){
    return "Club Interview";
  }
  const prefix = source.isNonLiveFixtureEvent ? 'Non Live ' : '';
  switch (source.fixtureEventType) {
    case FixtureEventType.Standard: return `${prefix}ICS`;
    case FixtureEventType.Double: return `${prefix}Double`;
    case FixtureEventType.Extended: return `${prefix}Extended`;
    case FixtureEventType.DoubleExtended: return `${prefix}Combined Double/Extended`;
    case FixtureEventType.ExtendedBroadcasterExclusive: return `${prefix}Extended Broadcaster Exclusive`;
    case FixtureEventType.ExtendedMasterclass: return `${prefix}Extended Masterclass`;
    default: return 'ICS';
  }
}

export function getLfpIcsTypeText(source: IDashboardItemDto): string {
  if (source.dashboardItemType==DashboardItemType.InterviewEvent || 
    source.dashboardItemType == DashboardItemType.InterviewEventRequest || 
    source.dashboardItemType == DashboardItemType.FixtureRequest || 
    source.dashboardItemType == DashboardItemType.FixtureEvent){
    return "Club Interview";
  }
  const prefix = source.isNonLiveFixtureEvent ? 'Non Live ' : '';
  switch (source.fixtureEventType) {
    case FixtureEventType.Standard: return `${prefix}ICS`;
    case FixtureEventType.Double: return `${prefix}Double`;
    case FixtureEventType.Extended: return `${prefix}Extended`;
    case FixtureEventType.DoubleExtended: return `${prefix}Combined Double/Extended`;
    case FixtureEventType.ExtendedBroadcasterExclusive: return `${prefix}Extended Broadcaster Exclusive`;
    case FixtureEventType.ExtendedMasterclass: return `${prefix}Extended Masterclass`;
    default: return 'ICS';
  }
}

export function getMrText(s: IDashboardItemDto): string {
  if (s.matchData) {
    return `${s.matchData.matchRound}`;
  } else {
    return '';
  }
}

export function getMonth(s: IDashboardItemDto): string {
  return s.start.format('MMMM');
}

export function getMatchInfo(s: IDashboardItemDto): string {
  let matchInfo = ``;
  if (s.matchData) {
    matchInfo = `${s.matchData.homeTeamName} v ${s.matchData.awayTeamName}`;
  } else {
    matchInfo = `Training session`;
  }

  return matchInfo;
}

export function getInterviewName(s: IDashboardItemDto) {
  return s.interviewee;
}

export function getInterviewDate(s: IDashboardItemDto) {
  return (s.arrival.format('YYYY-MM-DD'));
}

export function getUserAccountAfterApproval(source: IDashboardItemDto) {
  return `${source.updatedAfterApprovalUserName} (${source.updatedAfterApprovalUserCompany})`;
}