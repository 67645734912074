import {

    IMatchInfoDto, NoteDto, LocationDto, InterviewEventDetailsDto, CreateInterviewEventDetailsDto, InterviewEventType, EventStatus, RequestDetailsDto, CreateFixtureRequestDto, UpdateFixtureRequestDto, Players
} from '../services/bpp.webapi/client';
import * as moment from 'moment-timezone';
import { ConfigService } from '../config/config.service';

export class ClubInterviewMapper {
    timeZone: any;
    constructor(private config: ConfigService) {
        this.timeZone = config.getTimezone();
    }

    private toUtc(input: moment.Moment) {
        const result = moment(input, 'YYYY-MM-DD HH:mm');
        return result;
    }

    concatDate(date: moment.Moment, time: moment.Moment) {
        const result = moment(date).hour(time.hour()).minute(time.minute());
        return result;
    }

    create(interview: InterviewEventDetailsDto): CreateInterviewEventDetailsDto {
        const model = new CreateInterviewEventDetailsDto(interview);
        return model;
    }

    init(match: IMatchInfoDto): InterviewEventDetailsDto {
        const model = new InterviewEventDetailsDto();
        model.init({
            matchId: match.matchId,
            startUtc: match.date,
            arrivalUtc: match.date,
            duration: moment.duration(0, 'seconds'),
            eventId: '',
            locationId: undefined,
            location: new LocationDto(),
            slotsCount: 0,
            interviewType: InterviewEventType.SingleInterview,
            languages: [],
            peoples: [],
            slots: [],
            leagueNote: this.getNewNote(),
            clubNote: this.getNewNote(),
            updatedOnUtc: moment().utc(),
            updatedBy: '',
            status: EventStatus.Unknown,
            players: Players.Multiple
        });

        model.startUtc = moment(match.date);
        model.arrivalUtc = moment(match.date);

        return model;
    }

    updateNote(note: NoteDto): NoteDto | undefined {
        const newNote = this.getNewNote();
        newNote.init({
            message: note && note.message !== undefined ? note.message : '',
            id: note.id
        });
        return newNote;
    }

    getNewNote(): NoteDto {
        const note = new NoteDto();
        note.init({
            date: moment().utc(),
            message: ''
        });
        return note;
    }

    requestCreate(request: RequestDetailsDto, evt: InterviewEventDetailsDto) {
        return new CreateFixtureRequestDto({
            fixtureEventId: evt.eventId,
            cameraOperator: request.cameraOperator,
            isOthersEnabled: request.isOthersEnabled,
            isProducersEnabled: request.isProducersEnabled,
            isReportersEnabled: request.isReportersEnabled,
            status: request.status,
            note: request.broadcasterNote.message,
            participants: request.participants
          });
    }

    requestUpdate(request: RequestDetailsDto, evt: InterviewEventDetailsDto) {
        return new UpdateFixtureRequestDto({
            fixtureEventId: evt.eventId,
            cameraOperator: request.cameraOperator,
            isOthersEnabled: request.isOthersEnabled,
            isProducersEnabled: request.isProducersEnabled,
            isReportersEnabled: request.isReportersEnabled,
            status: request.status,
            participants: request.participants,
            fixtureRequestId: request.id,
            broadcasterNote: request.broadcasterNote,
            clubNote: request.clubNote,
            leagueNote: request.leagueNote
          });
    }
}
