import { Routes } from '@angular/router';
import { TestRouteComponent } from '../components/Tests/test-route/test-route.component';
import { LoggedInGuard } from '../authentication/logged-in.guard';
import { MainLayoutPageComponent } from '../components/main-layout-page/main-layout-page.component';
import { FixturesWrapperComponent } from '../components/fixtures/fixtures-wrapper/fixtures-wrapper.component';
import { TrainingWrapperComponent } from '../components/training/training-wrapper/training-wrapper.component';
import { DashboardCommonComponent } from '../components/dashboard/dashboard-common/dashboard-common.component';
import { AboutComponent } from '../components/about/about.component';
import { OneColumnPageComponent } from '../widgets/pages/one-column-page/one-column-page.component';
import { TrainingDetailsWrapperComponent } from '../components/training-details/training-details-wrapper.component';
import { FixtureDetailsWrapperComponent } from '../components/fixture-details/fixture-details-wrapper/fixture-details-wrapper.component';
import { CreateClubTrainingComponent } from '../components/training-details/create-club-training/create-club-training.component';
import { LogoutComponent } from '../components/logout/logout.component';
import { SomethingWrongComponent } from '../components/error-handler/something-wrong/something-wrong.component';
import { PersonsReportWrapperComponent } from '../components/reports/persons-report/persons-report-wrapper/persons-report-wrapper.component';
import { NotAuthorizedComponent } from '../components/error-handler/not-authorized/not-authorized.component';
import { UserProfileComponent } from '../components/user-profile/user-profile.component';
import { EventsImportComponent } from '../components/events-import/events-import.component';
import { FirstLoginGuard } from '../authentication/firstLogin.guard';
import { ContentSessionTypesReportWrapperComponent } from '../components/reports/content-session-types-report/content-session-types-report-wrapper/content-session-types-report-wrapper.component';
import { FilmingLocationComponent } from '../components/filming-location/filming-location.component';
import { InterviewsComponent } from '../components/interviews/interviews.component';
import { TeamMembersComponent } from '../components/team-members/team-members.component';
import { TeamMemberDetailsComponent } from '../components/team-member-details/team-member-details.component';
import { MatchesConfigurationComponent } from '../components/matches-configuration/matches-configuration.component';
import { LocationsComponent } from '../components/locations/locations.component';
import { AdditionalContentWrapperComponent } from '../components/additional-content/additional-content-wrapper.component';
import { CreateClubAdditionalContentComponent } from '../components/additional-content-details/additional-content-create-club/create-club-additional-content.component';
import { AdditionalContentDetailsWrapperComponent } from '../components/additional-content-details/additional-content-details-wrapper.component';
import { InterviewCreateLeagueComponent } from '../components/interview-details/interview-create-league/interview-create-league.component';
import { InterviewDetailsWrapperComponent } from '../components/interview-details/interview-details-wrapper.component';
import { BroadcasterRequestsReportComponent } from '../components/reports/broadcaster-requests-report/league-broadcaster-requests-report/league-broadcaster-requests-report.component';
import { InterviewsSummaryReportWarpperComponent } from '../components/reports/interviews-summary-report/interviews-summary-report-warpper/interviews-summary-report-warpper.component';
import { ClubInterviewsWrapperComponent } from '../components/club-interviews/club-interviews-wrapper/club-interviews-wrapper.component';
import { ClubInterviewWrapperComponent } from '../components/club-interview-details/club-interview-wrapper/club-interview-wrapper.component';
import { NewsDetailComponent } from '../components/news/details/news-detail/news-detail.component';
import { NewsDetailRpComponent } from '../components/news/details/news-detail-rp/news-detail-rp.component';
import { TwoColumnPageComponent } from '../widgets/pages/two-column-page/two-column-page.component';
import { NewsComponent } from '../components/news/news/news.component';
import { AdditionalContentDetailsCreateWrapperComponent } from '../components/additional-content-details/additional-content-details-create-wrapper.component';
import { DashboardNewCommonComponent } from '../components/new-dashboard/dashboard-new-common.component';
import { DashboardCommonWrapperComponent } from '../components/dashboard/dashboard-common-wrapper.component';

export const router: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: DashboardCommonWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'fixtures',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: FixturesWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'club-interviews',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: ClubInterviewsWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'trainings',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: TrainingWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'trainings/detail/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: TrainingDetailsWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'trainings/create/:time',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: CreateClubTrainingComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'fixtures/detail/:id/nonLive/:nonLive',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: FixtureDetailsWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'club-interviews/detail/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: ClubInterviewWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },  
  {
    path: 'interviews',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: InterviewsComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    data: {
      expectedFeature: 'interviews'
    },
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'interviews/create/:typeId',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: InterviewCreateLeagueComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    data: {
      expectedFeature: 'interviews'
    },
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'interviews/detail/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: InterviewDetailsWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    data: {
      expectedFeature: 'interviews'
    },
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'news',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: NewsComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'news/detail/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: TwoColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: NewsDetailComponent,
            outlet: 'principal'
          },
          {
            path: '',
            component: NewsDetailRpComponent,
            outlet: 'rightColumn'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'filming',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: FilmingLocationComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'team-members',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: TeamMembersComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'team-member/details/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: TeamMemberDetailsComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'matches-configuration',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: MatchesConfigurationComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'locations',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: LocationsComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'additional-content',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: AdditionalContentWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'additional-content/:type/create',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: AdditionalContentDetailsCreateWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'additional-content/:type/detail/:id',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: AdditionalContentDetailsWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'reports',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: PersonsReportWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'content-session-types-report',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: ContentSessionTypesReportWrapperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'interviews-summary-report',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: InterviewsSummaryReportWarpperComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'broadcaster-requests-report',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: BroadcasterRequestsReportComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  },
  {
    path: 'about',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: AboutComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'logout',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: LogoutComponent,
            outlet: 'principal'
          }
        ]
      }
    ]
  },
  {
    path: 'somethingwrong',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: SomethingWrongComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'critical',
    component: NotAuthorizedComponent,
    children: []
  },
  {
    path: 'import',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: EventsImportComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'profile',
    component: MainLayoutPageComponent,
    children: [
      {
        path: '',
        component: OneColumnPageComponent,
        outlet: 'main',
        children: [
          {
            path: '',
            component: TestRouteComponent,
            outlet: 'header'
          },
          {
            path: '',
            component: UserProfileComponent,
            outlet: 'principal'
          }
        ]
      }
    ],
    canActivate: [LoggedInGuard, FirstLoginGuard]
  }
];
