import { Action } from '@ngrx/store';
import { InterviewTypeDto } from 'src/app/services/bpp.webapi/client';

export const GET_INTERVIEW_TYPES_ACTION = 'get-interview-types-action';
export const GET_INTERVIEW_TYPES_ACTION_SUCCESS = 'get-interview-types-action-success';
export const GET_INTERVIEW_TYPES_ACTION_ERROR = 'get-interview-types-action-error';

export class GetInterviewTypesAction implements Action {
    readonly type = GET_INTERVIEW_TYPES_ACTION;
    constructor() { }
}

export class GetInterviewTypesActionSuccess implements Action {
    readonly type = GET_INTERVIEW_TYPES_ACTION_SUCCESS;
    constructor(public payload: InterviewTypeDto[]) { }
}

export class GetInterviewTypesActionError implements Action {
    readonly type = GET_INTERVIEW_TYPES_ACTION_ERROR;
    constructor(public payload: any) { }
}

export type All = GetInterviewTypesAction
    | GetInterviewTypesActionError
    | GetInterviewTypesActionSuccess;
