import { IFixtureEventDto, IWeekDto, IMatchFixtureEventDto, IMatchInfoDto } from '../../services/bpp.webapi/client';

export interface IFixturesGrouped {
    upcoming: IFixturesByWeek[];
    past: IFixturesByWeek[];
}

export interface IFixtureByMatch {
    key: string;
    value: IFixtureEventDto[];
}

export interface IFixturesByWeek {
    key: string;
    value: IFixtureByMatch[];
}

export class FixtureState {
    loading: boolean;
    updateLoading: boolean;
    error: boolean;
    filter: { isUpcoming: boolean; isNonLiveInUK: boolean; matchRound: number; };

    clubItems: {
        upcoming: Array<IMatchFixtureEventDto>;
        past: Array<IMatchFixtureEventDto>;
    };

    byGameWeek: { [key: string]: IWeekDto };

    broadcasterByGameWeek: { [key: string]: IWeekDto };

    selectedMatch: IMatchInfoDto;

    constructor() {
        this.loading = false;
        this.error = false;
        this.clubItems = { upcoming: [], past: [] };
        this.byGameWeek = {};
        this.filter = {
            isNonLiveInUK: false,
            isUpcoming: true,
            matchRound: 1
        };
    }
}
