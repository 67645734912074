import { TrainingState } from './state';
import * as actions from './actions';

export type Action = actions.All;
const defaultState = new TrainingState();

export function TrainingReducer(state = defaultState, action: Action): TrainingState {
    switch (action.type) {

        case actions.GET_TRAINING_EVENTS: {
            return {
                ...state,
                result: {
                    loading: true,
                    error: false,
                    items: {...state.result.items}
                }
            };
        }

        case actions.GET_TRAINING_EVENTS_SUCCESS: {
          const sorted = action.payload.sort((a, b) => a.date.valueOf() > b.date.valueOf() ? 1 : -1);
            return {
                ...state,
                result: {
                    loading: false,
                    items: sorted,
                    error: false
                }
            };
        }

        case actions.GET_TRAINING_EVENTS_ERROR: {
            return {
                ...state,
                result: {
                    loading: false,
                    error: true,
                    items: []
                }
            };
        }

        case actions.GET_TRAINING_EVENT_BY_ID: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;
            newState.trainingDetails[action.id] = null;
            return newState;
        }

        case actions.GET_TRAINING_EVENT_BY_ID_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = action.payload;
            return newState;
        }

        case actions.GET_TRAINING_EVENT_BY_ID_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;
            return newState;
        }

        case actions.CREATE_TRAINING_EVENT: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;
            newState.createdTraining = '';
            return newState;
        }

        case actions.CREATE_TRAINING_EVENT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = action.payload;
            newState.createdTraining = action.payload.id;
            return newState;
        }

        case actions.CREATE_TRAINING_EVENT_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;
            return newState;
        }

        case actions.UPDATE_TRAINING_EVENT: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = null;
            return newState;
        }

        case actions.UPDATE_TRAINING_EVENT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = action.payload;
            // console.log('update fired');
            return newState;
        }

        case actions.UPDATE_TRAINING_EVENT_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;
            return newState;
        }

        case actions.GET_TRAINING_PARTICIPANTS: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;
            return newState;
        }

        case actions.GET_TRAINING_PARTICIPANTS_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.participants = action.payload;
            newState.error = false;

            return newState;
        }

        case actions.GET_TRAINING_PARTICIPANTS_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;
            newState.participants = [];

            return newState;
        }

        case actions.GET_TRAINING_PARTICIPANTS_BY_COMPANY: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;
            return newState;
        }

        case actions.CREATE_TRAINING_PARTICIPANT: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;

            return newState;
        }

        case actions.CREATE_TRAINING_PARTICIPANT_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.participants.push(action.payload);

            return newState;
        }

        case actions.CREATE_TRAINING_PARTICIPANT_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;

            return newState;
        }

        case actions.CREATE_TRAINING_REQUEST: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;

            return newState;
        }

        case actions.CREATE_TRAINING_REQUEST_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = action.payload;

            return newState;
        }

        case actions.CREATE_TRAINING_REQUEST_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;

            return newState;
        }

        case actions.UPDATE_TRAINING_REQUEST: {
            const newState = { ...state };
            newState.loading = true;
            newState.error = false;

            return newState;
        }

        case actions.UPDATE_TRAINING_REQUEST_SUCCESS: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = false;
            newState.trainingDetails[action.payload.id] = action.payload;

            return newState;
        }

        case actions.UPDATE_TRAINING_REQUEST_ERROR: {
            const newState = { ...state };
            newState.loading = false;
            newState.error = true;

            return newState;
        }
    }
    return state;
}
