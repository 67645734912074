import { Action } from '@ngrx/store';
import {
    ITrainingDto, ICreateTrainingDto, ITrainingDetailsDto, IUpdateTrainingDto, TrainingDetailsDto, IParticipantDto,
    ParticipantType, ICreateParticipantDto, ICreateTrainingRequestDto, IUpdateTrainingRequestDto
} from '../../services/bpp.webapi/client';
import { Roles } from '../../models/enums';


export const GET_TRAINING_EVENTS = 'get_training_events';
export const GET_TRAINING_EVENTS_SUCCESS = 'get_training_events_success';
export const GET_TRAINING_EVENTS_ERROR = 'get_trainings_api_error_action';

export const CREATE_TRAINING_EVENT = 'create_training_event';
export const CREATE_TRAINING_EVENT_SUCCESS = 'create_training_event_success';
export const CREATE_TRAINING_EVENT_ERROR = 'create_training_event_error';

export const GET_TRAINING_EVENT_BY_ID = 'get_training_event_by_id';
export const GET_TRAINING_EVENT_BY_ID_SUCCESS = 'get_training_event_by_id_success';
export const GET_TRAINING_EVENT_BY_ID_ERROR = 'get_training_event_by_id_error';

export const UPDATE_TRAINING_EVENT = 'update_training_event';
export const UPDATE_TRAINING_EVENT_SUCCESS = 'update_training_event_success';
export const UPDATE_TRAINING_EVENT_ERROR = 'update_training_event_error';

export const GET_TRAINING_PARTICIPANTS = 'get_training_participants';
export const GET_TRAINING_PARTICIPANTS_SUCCESS = 'get_training_participants_success';
export const GET_TRAINING_PARTICIPANTS_ERROR = 'get_training_participants_error';

export const GET_TRAINING_PARTICIPANTS_BY_COMPANY = 'get_training_participants_by_company';
export const GET_TRAINING_PARTICIPANTS_BY_COMPANY_SUCCESS = 'get_training_participants_by_company_success';
export const GET_TRAINING_PARTICIPANTS_BY_COMPANY_ERROR = 'get_training_participants_by_company_error';

export const CREATE_TRAINING_PARTICIPANT = 'create_training_participant';
export const CREATE_TRAINING_PARTICIPANT_SUCCESS = 'create_training_participant_success';
export const CREATE_TRAINING_PARTICIPANT_ERROR = 'create_training_participant_error';

export const CREATE_TRAINING_REQUEST = 'create_training_request';
export const CREATE_TRAINING_REQUEST_SUCCESS = 'create_training_request_success';
export const CREATE_TRAINING_REQUEST_ERROR = 'create_training_request_error';

export const UPDATE_TRAINING_REQUEST = 'update_training_request';
export const UPDATE_TRAINING_REQUEST_SUCCESS = 'update_training_request_success';
export const UPDATE_TRAINING_REQUEST_ERROR = 'update_training_request_error';

export class GetTrainingEventsAction implements Action {
    readonly type = GET_TRAINING_EVENTS;
    constructor(public userRole?: Roles) { }
}

export class GetTrainingEventsSuccessAction implements Action {
    readonly type = GET_TRAINING_EVENTS_SUCCESS;
    constructor(public payload: Array<ITrainingDto>) {
    }
}

export class GetTrainingEventsErrorAction implements Action {
    readonly type = GET_TRAINING_EVENTS_ERROR;
    constructor(public payload: any) { }
}

export class GetTrainingEventByIdAction implements Action {
    readonly type = GET_TRAINING_EVENT_BY_ID;
    constructor(public id: string) { }
}

export class GetTrainingEventByIdSuccessAction implements Action {
    readonly type = GET_TRAINING_EVENT_BY_ID_SUCCESS;
    constructor(public payload: ITrainingDetailsDto) {
    }
}

export class GetTrainingEventByIdErrorAction implements Action {
    readonly type = GET_TRAINING_EVENT_BY_ID_ERROR;
    constructor(public payload: any) {
    }
}

export class CreateTrainigEventAction implements Action {
    readonly type = CREATE_TRAINING_EVENT;
    constructor(public payload: ICreateTrainingDto) {
    }
}

export class CreateTrainigEventSuccessAction implements Action {
    readonly type = CREATE_TRAINING_EVENT_SUCCESS;
    constructor(public payload: TrainingDetailsDto) {
    }
}

export class CreateTrainigEventErrorAction implements Action {
    readonly type = CREATE_TRAINING_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateTrainigEventAction implements Action {
    readonly type = UPDATE_TRAINING_EVENT;
    constructor(public payload: IUpdateTrainingDto) {
    }
}

export class UpdateTrainigEventSuccessAction implements Action {
    readonly type = UPDATE_TRAINING_EVENT_SUCCESS;
    constructor(public payload: TrainingDetailsDto) {
    }
}

export class UpdateTrainigEventErrorAction implements Action {
    readonly type = UPDATE_TRAINING_EVENT_ERROR;
    constructor(public payload: any) {
    }
}

export class GetTrainingParticipantsAction implements Action {
    readonly type = GET_TRAINING_PARTICIPANTS;
    constructor() {
    }
}

export class GetTrainingParticipantsSuccessAction implements Action {
    readonly type = GET_TRAINING_PARTICIPANTS_SUCCESS;
    constructor(public payload: Array<IParticipantDto>) {
    }
}

export class GetTrainingParticipantsErrorAction implements Action {
    readonly type = GET_TRAINING_PARTICIPANTS_ERROR;
    constructor(public payload: any) {
    }
}

export class GetTrainingParticipantByCompanyAction implements Action {
    readonly type = GET_TRAINING_PARTICIPANTS_BY_COMPANY;
    constructor(public payload: string) {
    }
}

export class CreateTrainingParticipantAction implements Action {
    readonly type = CREATE_TRAINING_PARTICIPANT;
    constructor(public payload: ICreateParticipantDto) {
    }
}

export class CreateTrainingParticipantSuccessAction implements Action {
    readonly type = CREATE_TRAINING_PARTICIPANT_SUCCESS;
    constructor(public payload: IParticipantDto) {
    }
}

export class CreateTrainingParticipantErrorAction implements Action {
    readonly type = CREATE_TRAINING_PARTICIPANT_ERROR;
    constructor(public payload: any) {
    }
}

export class CreateTrainingRequestAction implements Action {
    readonly type = CREATE_TRAINING_REQUEST;

    constructor(public payload: ICreateTrainingRequestDto) {
    }
}

export class CreateTrainingRequestSuccessAction implements Action {
    readonly type = CREATE_TRAINING_REQUEST_SUCCESS;
    constructor(public payload: ITrainingDetailsDto) {
    }
}

export class CreateTrainingRequestErrorAction implements Action {
    readonly type = CREATE_TRAINING_REQUEST_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateTrainingRequestAction implements Action {
    readonly type = UPDATE_TRAINING_REQUEST;
    constructor(public payload: IUpdateTrainingRequestDto) {
    }
}

export class UpdateTrainingRequestSuccessAction implements Action {
    readonly type = UPDATE_TRAINING_REQUEST_SUCCESS;
    constructor(public payload: ITrainingDetailsDto) {
    }
}

export class UpdateTrainingRequestErrorAction implements Action {
    readonly type = UPDATE_TRAINING_REQUEST_ERROR;
    constructor(public payload: any) {
    }
}

export type All = GetTrainingEventsAction
    | GetTrainingEventsSuccessAction
    | GetTrainingEventsErrorAction
    | CreateTrainigEventAction
    | CreateTrainigEventSuccessAction
    | CreateTrainigEventErrorAction
    | GetTrainingEventByIdAction
    | GetTrainingEventByIdSuccessAction
    | GetTrainingEventByIdErrorAction
    | UpdateTrainigEventAction
    | UpdateTrainigEventErrorAction
    | UpdateTrainigEventSuccessAction
    | GetTrainingParticipantsAction
    | GetTrainingParticipantsSuccessAction
    | GetTrainingParticipantsErrorAction
    | GetTrainingParticipantByCompanyAction
    | CreateTrainingParticipantAction
    | CreateTrainingParticipantSuccessAction
    | CreateTrainingParticipantErrorAction
    | CreateTrainingRequestAction
    | CreateTrainingRequestSuccessAction
    | CreateTrainingRequestErrorAction
    | UpdateTrainingRequestAction
    | UpdateTrainingRequestSuccessAction
    | UpdateTrainingRequestErrorAction;
