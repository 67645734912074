import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ILabelValue } from 'src/app/models/label-value.interface';
import { IPagination } from 'src/app/models/paginator.interface';


@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input() page: IPagination;
  @Output() pageChanged: EventEmitter<IPagination> = new EventEmitter();
  sizes: ILabelValue[] = [
    {
      label: '10',
      value: 10
    },
    {
      label: '20',
      value: 20
    },
    {
      label: '50',
      value: 50
    },
    {
      label: '100',
      value: 100
    }
  ];

  ngOnInit() {

  }

  onPageChanged() {
    this.pageChanged.emit(this.page);
  }

  sizeChanged() {
    this.pageChanged.emit(this.page);
  }
}
