export const TO_BE_SAVED_TEXT = 'To Be Saved';
export const TODO_OR_UNKNOWN_COLOR = '#EBFF00';
export const DRAFT_COLOR = '#94948D';
export const SUBMITTED_COLOR = '#F98500';
export const APPROVED_COLOR = '#90CE39';
export const REJECTED_COLOR = '#ED3E18';
export const WITHDRAWN_COLOR = '#0DF5E8';
export const REQUESTS_COLOR = '#9747FF';
export const COMPLETED_COLOR = '#000000';

