import { Action } from "@ngrx/store";
import { PagedListOfTeamMemberDto, TeamMemberDto } from "src/app/services/bpp.webapi/client";

export const GET_TEAM_MEMBERS = 'get_team_members';
export const GET_TEAM_MEMBERS_SUCCESS = 'get_team_members_success';
export const GET_TEAM_MEMBERS_ERROR = 'get_team_members_error';

export const GET_TEAM_MEMBER = 'get_team_member';
export const GET_TEAM_MEMBER_SUCCESS = 'get_team_member_success';
export const GET_TEAM_MEMBER_ERROR = 'get_team_member_error';

export const UPDATE_TEAM_MEMBER = 'update_team_member';
export const UPDATE_TEAM_MEMBER_SUCCESS = 'update_team_member_success';
export const UPDATE_TEAM_MEMBER_ERROR = 'update_team_member_error';

export const GET_TEAM_MEMBERS_BY_CLUB_ID = 'get_team_members_by_club_id';
export const GET_TEAM_MEMBERS_BY_CLUB_ID_SUCCESS = 'get_team_members_by_club_id_success';
export const GET_TEAM_MEMBERS_BY_CLUB_ID_ERROR = 'get_team_members_by_club_id_error';
export const RESET_TEAM_MEMBER = 'reset_team_member';

export class GetTeamMembersAction implements Action {
	readonly type = GET_TEAM_MEMBERS;
	constructor(public skip: number, public take: number) { }
}

export class GetTeamMembersActionSuccess implements Action {
	readonly type = GET_TEAM_MEMBERS_SUCCESS;
	constructor(public payload: PagedListOfTeamMemberDto) { }
}

export class GetTeamMembersActionError implements Action {
	readonly type = GET_TEAM_MEMBERS_ERROR;
	constructor(public payload: any) { }
}

export class GetTeamMemberAction implements Action {
	readonly type = GET_TEAM_MEMBER;
	constructor(public id: number) { }
}

export class GetTeamMemberActionSuccess implements Action {
	readonly type = GET_TEAM_MEMBER_SUCCESS;
	constructor(public payload: TeamMemberDto) { }
}

export class GetTeamMemberActionError implements Action {
	readonly type = GET_TEAM_MEMBER_ERROR;
	constructor(public payload: any) { }
}

export class UpdateTeamMemberAction implements Action {
	readonly type = UPDATE_TEAM_MEMBER;
	constructor(public model: TeamMemberDto) { }
}

export class UpdateTeamMemberActionSuccess implements Action {
	readonly type = UPDATE_TEAM_MEMBER_SUCCESS;
	constructor(public payload: TeamMemberDto) { }
}

export class UpdateTeamMemberActionError implements Action {
	readonly type = UPDATE_TEAM_MEMBER_ERROR;
	constructor(public payload: any) { }
}

export class GetTeamMembersByClubIdAction implements Action {
	readonly type = GET_TEAM_MEMBERS_BY_CLUB_ID;
	constructor(public clubId: number, public skip: number, public take: number) { }
}

export class GetTeamMembersByClubIdActionSuccess implements Action {
	readonly type = GET_TEAM_MEMBERS_BY_CLUB_ID_SUCCESS;
	constructor(public payload: PagedListOfTeamMemberDto) { }
}

export class GetTeamMembersByClubIdActionError implements Action {
	readonly type = GET_TEAM_MEMBERS_BY_CLUB_ID_ERROR;
	constructor(public payload: any) { }
}

export class ResetTeamMemberAction implements Action {
	readonly type = RESET_TEAM_MEMBER;
	constructor() { }
}

export type All = GetTeamMembersAction
	| GetTeamMembersActionSuccess
	| GetTeamMembersActionError
	| GetTeamMemberAction
	| GetTeamMemberActionSuccess
	| GetTeamMemberActionError
	| UpdateTeamMemberAction
	| UpdateTeamMemberActionSuccess
	| UpdateTeamMemberActionError
	| GetTeamMembersByClubIdAction
	| GetTeamMembersByClubIdActionSuccess
	| GetTeamMembersByClubIdActionError
	| ResetTeamMemberAction
	;
