import { ParticipantType, IParticipantDto, CameraOperator } from '../../../../services/bpp.webapi/client';
import { Subscription } from 'rxjs';

export class Section {
  public require = false;
  public participants = new Array<IParticipantDto>();
  public availableParticipants = new Array<IParticipantDto>();
  public participantsSubscription: Subscription;
  public newParticipantSubscription: Subscription;
  public cameraOperator: CameraOperator;

  public type = ParticipantType.Unknow;
  public get name(): string {
    if (this.type === ParticipantType.Camera) {
      return 'Camera Operator';
    }
    return ParticipantType[this.type];
  }
  public get title(): string {
    return `Add  ${this.name}`;
  }
  public classes = '';

  public static get reporters(): Section {
    const result = new Section();

    result.type = ParticipantType.Reporter;
    result.classes = 'border-top';

    return result;
  }

  public static get cameras(): Section {
    const result = new Section();

    result.type = ParticipantType.Camera;

    return result;
  }

  public static get producers(): Section {
    const result = new Section();

    result.type = ParticipantType.Producer;

    return result;
  }

  public static get others(): Section {
    const result = new Section();

    result.type = ParticipantType.Other;

    return result;
  }
}
