import { IParticipantDto, ParticipantType, IFixtureEventDetailsDto, RequestStatus, NoteDto, CameraOperator } from '../../services/bpp.webapi/client';
import { Moment } from 'moment';

export class FixtureRequests {
    loading: boolean;
    error: boolean;
    events: { [matchId: string]: MatchEvent[] };
    participants: IParticipantDto[];
    participantsByCompany: { [companyId: string]: IParticipantDto[] };
    constructor() {
        this.events = {};
        this.participants = [];
        this.participantsByCompany = {};
    }
}

export interface IDentity {
    matchId?: number;
    nonLiveInUK: boolean;
    eventId?: string;
    companyId?: string;
    sectionKey?: string;
    lastUpdated?: string;
}

export class MatchEvent {
    details: IFixtureEventDetailsDto;
    requests: FxRequest[];
    constructor() {
        this.requests = [];
    }
}

export enum ERequestType {
    Undefined = 0,
    Fixture = 1,
    Training = 2,
    Interview = 3
}

export class FxRequest {
    companyId: string;
    companyName?: string;
    sections: IRequestSection[];
    note: string;
    isReadOnly?: boolean;
    status: RequestStatus;
    id: string;
    lastSaved: string;
    date?: Moment;

    clubNote?: NoteDto;
    leagueNote?: NoteDto;
    broadcasterNote?: NoteDto;
    type: ERequestType;

    constructor() {
        this.sections = [];
        this.isReadOnly = false;
        /*         Object.values(ParticipantType).filter(v => isNaN(parseFloat(v)))
                    .forEach(key => {
                        if (key !== ParticipantType[0]) {
                            this.sections.push(initSection(key, []));
                        }
                    }); */
        this.sections.push(initSection(ParticipantType[ParticipantType.Reporter], []));
        this.sections.push(initSection(ParticipantType[ParticipantType.Camera], []));
        this.sections.push(initSection(ParticipantType[ParticipantType.Producer], []));
        this.sections.push(initSection(ParticipantType[ParticipantType.Other], []));
        this.status = RequestStatus.Draft;
        this.lastSaved = 'To be created';
    }

}

export function initSection(type: string, participants?: IParticipantDto[]): IRequestSection {
    const isCameraSection = type === ParticipantType[ParticipantType.Camera];
    const item = isCameraSection ? 'CameraOperator' : type;
    const cameraOperator = isCameraSection ? CameraOperator.PLPCamera : undefined;
    const value = participants.length > 0; // || isCameraSection
    
    return {
        items: participants,
        sectionType: ParticipantType[type],
        require: value,
        value: value,
        name: item,
        key: type,
        title: 'Add' + item,
        itemName: 'AddNew' + item,
        availableParticipants: [],
        isCameraSection,
        cameraOperator: cameraOperator
    };
}

export interface IRequestSection {
    items: Array<IParticipantDto>;
    sectionType: ParticipantType;
    require: boolean;
    value: boolean;
    name: string;
    key: string;
    title: string;
    itemName: string;
    availableParticipants: IParticipantDto[];
    isCameraSection: boolean;
    cameraOperator?: CameraOperator;
}


export interface IAccordionItem {
    id: string;
    isOpen: boolean;
    isReadOnly: boolean;
    fixtureModel: IFixtureEventDetailsDto;
    requests: FxRequest[];
    notes?: string;
    status: string;
    identity: IDentity;
    fixtureTitle?: string;
    requestStatistics?: string;
    isPastFixture: boolean;
}
