import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import {EventStatus, IAdditionalContentFixtureDto, PlayerDto} from '../../../services/bpp.webapi/client';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {Subscription} from 'rxjs';
import {GetAdditionalContentEventsAction} from '../../../store/additional-content/actions';
import {IAdditionalContentListComponentData} from '../additional-content-list/additional-content-list.component';
import {DateRange, FilterPanelByClubDaterangeComponent} from '../../filter-panel-by-club-date-range/filter-panel-by-club-daterange.component';
import { GetPlayerListAction, GetPlayerListByTeamAction } from 'src/app/store/bpp/actions';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-additional-content-league',
  templateUrl: './additional-content-league.component.html'
})
export class AdditionalContentLeagueComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;

  data: IAdditionalContentListComponentData;
  isLoading = false;
  players = new Array<PlayerDto>();
  showAdd:boolean;
  additionalContentSubscription: Subscription;

  private filterPanel: FilterPanelByClubDaterangeComponent;
  @ViewChild('filterPanel', { static: false }) set panel(filterPanel: FilterPanelByClubDaterangeComponent) {
    if (filterPanel) {
      this.filterPanel = filterPanel;
    }
  }

  constructor(private store: Store<AppState>,private config :ConfigService) {
    super();

    this.data = {
      preSeasonItems: new Array<IAdditionalContentFixtureDto>(),
      midSeasonItems:  new Array<IAdditionalContentFixtureDto>(),
      trainingGroundItems: new Array<IAdditionalContentFixtureDto>(),
      otherClubItems: new Array<IAdditionalContentFixtureDto>(),
    };
    if (config.isLfp()){
      this.showAdd=true
    }
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });

    this.additionalContentSubscription = this.store
      .select(e => e.additionalContent)
      .pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined && e.result !== undefined))
      .subscribe(data => {
        this.isLoading = data.result.loading;
        if (this.isLog) { console.log('GetAdditionalContentEvents: ', data.result); }
        const { preSeasonItems, midSeasonItems, trainingGroundItems, otherClubItems } = data.result;
        this.data = {
          preSeasonItems,
          midSeasonItems,
          trainingGroundItems,
          otherClubItems
        };
      });

    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

    this.store.dispatch(new GetAdditionalContentEventsAction(false, null));
    this.store.dispatch(new GetPlayerListAction());
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }

  onClubSelected() {
   this.reloadData();
  }

  onDateRangeSelected() {
    this.reloadData();
  }

  reloadData() {
    const isPast = this.filterPanel.selectedDateRange.id === DateRange.Past;
    const clubId = this.filterPanel.selectedClub.id > 0 ? this.filterPanel.selectedClub.id : null;
    this.store.dispatch(new GetAdditionalContentEventsAction(isPast, clubId));
  }
}
