import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClubDto} from '../../../services/bpp.webapi/client';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/enums';
import { GetClubsListAction } from 'src/app/store/clubs/actions';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-additional-content-details-header',
  templateUrl: './additional-content-details-header.component.html'
})
export class AdditionalContentDetailsHeaderComponent implements OnInit {
  @Input() club: ClubDto;
  @Input() lastSaved: string;
  @Output() clubChanged = new EventEmitter();

  isLfp:boolean;
  clubLogoImagePath: string;
  clubName: string;
  user: User;
  isLeague:boolean;
  private componentDestroyed: Subject<void> = new Subject();
  clubs: Array<ClubDto> = [];
  allClubs: ClubDto = ClubDto.fromJS({ name: 'Select a club', id: undefined });
  selectedClub: ClubDto = ClubDto.fromJS({ name: 'Select a club', id: undefined });

  constructor(private store: Store<AppState>, public config: ConfigService) {
    this.isLfp = config.isLfp();
    this.store.select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
          this.isLeague = this.user.role === Roles.Admin || this.user.role === Roles.League
          if (this.isLeague){
            this.store.select(state => state.clubs).pipe(
              takeUntil(this.componentDestroyed))
              .subscribe(state => {
                if (!state.loading) {
                  this.clubs = state.items;
                }
              });
            this.store.dispatch(new GetClubsListAction());
          }
        }
      });
  }

  ngOnInit() {
    this.clubName = this.club.name;
    this.clubLogoImagePath = '../../../../assets/teams/48/t' + this.club.id + '.png';
  }

  selectClub(club: ClubDto) {
    this.selectedClub = club;
    this.clubChanged.emit(club);
  }

}
