import { TrainingDetailsDto, NoteDto, LocationDto, ITrainingDetailsDto, CreateTrainingDto, EventStatus, UpdateTrainingDto } from '../services/bpp.webapi/client';
import * as moment from 'moment';

export class TrainingMapper {

    private toUtc(input: moment.Moment) {
        const result = moment(input);
        return result;
    }

    private concatDate(date: moment.Moment, time: moment.Moment) {
        const result = moment(date).hour(time.hour()).minute(time.minute());
        return result;
    }

    init(trainingDate: moment.Moment, clubId: any): TrainingDetailsDto {
        const training = new TrainingDetailsDto();
        const clubNote = this.getClubNote();
        training.init({
            clubId,
            location: new LocationDto(),
            start: this.toUtc(trainingDate),
            arrival: this.toUtc(trainingDate),
            clubNote
        });
        return training;
    }


    create(model: ITrainingDetailsDto): CreateTrainingDto {
        const result = new CreateTrainingDto();
        const note = new NoteDto();
        note.message = model.clubNote ? model.clubNote.message : undefined;
        result.init({
            arrival: this.toUtc(model.arrival),
            start: this.toUtc(this.concatDate(model.arrival, model.start)),
            locationId: model.location ? model.location.locationId : undefined,
            status: EventStatus.Unknown,
            note
        });

        return result;
    }

    update(model: ITrainingDetailsDto): UpdateTrainingDto {
        const result = new UpdateTrainingDto();
        result.init({
            id: model.id,
            arrival: this.toUtc(model.arrival),
            start: this.toUtc(this.concatDate(model.arrival, model.start)),
            locationId: model.location ? model.location.locationId : undefined,
            status: model.status,
            clubNote: model.clubNote,
            leagueNote: model.leagueNote,
            adminLeagueNote: model.adminLeagueNote
        });

        return result;
    }

    getClubNote(): NoteDto {
        const clubNote = new NoteDto();
        clubNote.init({
            date: moment().utc(),
            message: ''
        });
        return clubNote;
    }
}
