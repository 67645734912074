
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { ConfigService } from '../../../config/config.service';
import {
  IFixtureEventDetailsDto,
  RequestStatus, CreateFixtureRequestDto, ICreateFixtureRequestDto,
  UpdateFixtureRequestDto, IUpdateFixtureRequestDto, NoteDto, ParticipantType, CreateFixtureEventDto, FixtureEventType, CameraOperator
} from '../../../services/bpp.webapi/client';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { FxRequest, IDentity, MatchEvent, IAccordionItem, ERequestType } from '../../../store/fx-request/state';
import {
  GetEventsByMatchAction, CreateFixtureRequestAction,
  UpdateFixtureRequestAction
} from '../../../store/fx-request/actions';
import { IFixtureHeader } from '../fixture-header/fixture-header.component';
import * as moment from 'moment';
import { Roles } from '../../../models/enums';
import * as helper from '../../../helpers/common';
import * as fixtureHelper from '../../../helpers/fixture-helper';
import { ConfirmWithdrawPopupComponent } from '../../common/withdraw-request/confirm-withdraw-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-broadcaster-fixture-dd',
  templateUrl: './broadcaster-fixture-details.component.html',
  styleUrls: ['./broadcaster-fixture-details.component.scss']
})
export class BroadcasterFixtureDetailsComponent implements OnInit, OnDestroy {
  private componentDestroyed: Subject<void> = new Subject();
  isSpinner: boolean;
  accordionItems: IAccordionItem[];
  isLog = false;
  modalSubscription: any;
  matchId: any;
  lastSaved = '';
  companyId: string;
  matchEvents: MatchEvent[];
  match: IFixtureEventDetailsDto;
  eventSubscription: Subscription;
  userSubscription: Subscription;
  identity: IDentity;
  fixtureHeader: IFixtureHeader;
  userRole: Roles;
  withDrawSubscription: any;
  user: any;
  nonLiveInUK: boolean = false;

  constructor(public config: ConfigService,
    private route: ActivatedRoute,
    private appInsightsService: AppInsightClientService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private store: Store<AppState>,
    private translate: TranslateService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.isSpinner = true;
    this.checkSubscriptions();

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            if (user && user.companyId) {
              this.user = user;
              this.userRole = user.role;
              this.companyId = user.companyId;
              this.matchId = params['id'];
              this.nonLiveInUK = params['nonLive'] === 'true';
              if (this.matchId) {
                this.identity = {
                  matchId: this.matchId,
                  companyId: this.companyId,
                  nonLiveInUK: this.nonLiveInUK
                };
                this.accordionItems = [];
                this.store.dispatch(new GetEventsByMatchAction(this.identity));
              }
            }
          });
      });
  }

  checkSubscriptions() {
    this.eventSubscription = this.store
      .select(state => state.fxRequests.events).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(result => {

        this.matchEvents = result[this.matchId];
        if (this.matchEvents && this.matchEvents.length > 0) {

          if (this.isLog) { console.log('this match events', this.matchEvents); }

          this.match = this.matchEvents[0].details;
          this.fixtureHeader = {
            homeTeam: this.match.homeTeam,
            awayTeam: this.match.awayTeam,
            matchDate: this.match.matchDate
          };

          this.matchEvents.forEach(matchEvent => {
            const identity: IDentity = {
              matchId: this.matchId,
              eventId: matchEvent.details.eventId,
              companyId: this.companyId,
              nonLiveInUK: this.nonLiveInUK
            };
            const requests = matchEvent.requests;
            if (requests.length === 0) {
              const request = new FxRequest();
              request.type = ERequestType.Fixture;
              request.companyId = identity.companyId;
              request.status = RequestStatus.Unknown;
              requests.push(request);
              request.lastSaved = helper.getLastSavedText(matchEvent.details.updatedOn,
                matchEvent.details.updatedByName,
                StatusHelper.getStatusText(matchEvent.details.status));
              request.isReadOnly = false;
            }
            this.lastSaved = requests[0] ? requests[0].lastSaved : '';
            const item: IAccordionItem = {
              id: matchEvent.details.eventId,
              isOpen: true,
              isReadOnly: true,
              fixtureModel: matchEvent.details,
              requests,
              notes: '',
              status: this.getRequestStatusText(requests[0] ? requests[0].status : 0, matchEvent.details),
              identity,
              isPastFixture: fixtureHelper.isPastFixture(matchEvent.details),
              fixtureTitle: matchEvent.details.clubId === matchEvent.details.homeTeam.id
                ? matchEvent.details.homeTeam.name
                : matchEvent.details.awayTeam.name
            };
            // fixtureHelper.isBroadcasterDeadlinePassed(matchEvent.details.start, this.config.getBrDeadlineHours(), this.config.getTimezone()
            const index = this.accordionItems.findIndex(x => x.id === item.id);
            if (index > -1) {
              this.accordionItems[index].requests = requests;
              this.accordionItems[index].status = this.getRequestStatusText(requests[0].status, matchEvent.details);
              this.accordionItems[index].identity = identity;
            } else {
              this.accordionItems.push(item);
            }

          });

          this.accordionItems.sort((a, b) =>  a.fixtureTitle > b.fixtureTitle ? 1 : -1);

          if (this.isLog) { console.log('accordion items', this.accordionItems); }
          this.isSpinner = false;
        }
      });
  }

  getEventStatusText(status) {
    return `Event ${StatusHelper.getStatusText(status)}`;
  }

  getRequestStatusText(status: RequestStatus, fixture: IFixtureEventDetailsDto) {
    if (fixture.type !== FixtureEventType.Standard && fixture.type !== FixtureEventType.Double && this.userRole === Roles.Broadcaster) {
      return this.translate.instant('noRequestToPerform');
    }
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  getTeamName(event: IFixtureEventDetailsDto) {
    return event.clubId === event.homeTeam.id ? event.homeTeam.name : event.awayTeam.name;
  }

  initRequestModel(eventId: string, model: FxRequest, status: RequestStatus): ICreateFixtureRequestDto | IUpdateFixtureRequestDto {

    const participants = [];
    model.sections.forEach(s => {
      if (s.items) {
        s.items.forEach(item => participants.push(item));
      }
    });

    if (model.status === RequestStatus.Unknown) {
      const request = new CreateFixtureRequestDto();
      request.init({
        status,
        fixtureEventId: eventId,
        participants,
        note: model.note
      });
      model.sections.forEach(s => {
        switch (s.sectionType) {
          case (ParticipantType.Camera): { request.cameraOperator = s.cameraOperator; break; }
          case (ParticipantType.Other): { request.isOthersEnabled = s.require; break; }
          case (ParticipantType.Producer): { request.isProducersEnabled = s.require; break; }
          case (ParticipantType.Reporter): { request.isReportersEnabled = s.require; break; }
        }
      });
      return request;
    } else {
      const request = new UpdateFixtureRequestDto();
      const broadcasterNote = new NoteDto();
      broadcasterNote.init({
        message: model.note,
        date: moment().utc()
      });
      request.init({
        fixtureRequestId: model.id,
        fixtureEventId: eventId,
        status,
        participants,
        broadcasterNote
      });
      model.sections.forEach(s => {
        switch (s.sectionType) {
          case (ParticipantType.Camera): { request.cameraOperator = s.cameraOperator; break; }
          case (ParticipantType.Other): { request.isOthersEnabled = s.require; break; }
          case (ParticipantType.Producer): { request.isProducersEnabled = s.require; break; }
          case (ParticipantType.Reporter): { request.isReportersEnabled = s.require; break; }
        }
      });
      return request;
    }
  }

  submitRequest(event) {
    const trackedEvent = {
      fixtureId: event.fixtureEventId,
      requestId: undefined,
      status: StatusHelper.getRequestStatusText(RequestStatus.Submitted),
    };
    if (event instanceof CreateFixtureRequestDto) {
      this.appInsightsService.logEvent('Create request (Broadcaster)', trackedEvent);
    } else {
      event.requestId = event.fixtureRequestId;
      this.appInsightsService.logEvent('Save request (Broadcaster)', trackedEvent);
    }
    if (this.isLog) { console.log('onSubmit request', event); }
    this.saveOrSubmit(event, RequestStatus.Submitted);
  }

  saveRequest(event) {
    const trackedEvent = {
      fixtureId: event.fixtureEventId,
      requestId: undefined,
      status: StatusHelper.getRequestStatusText(RequestStatus.Draft),
    };
    if (event instanceof CreateFixtureRequestDto) {
      this.appInsightsService.logEvent('Create request (Broadcaster)', trackedEvent);
    } else {
      event.requestId = event.fixtureRequestId;
      this.appInsightsService.logEvent('Save request (Broadcaster)', trackedEvent);
    }
    if (this.isLog) { console.log('onSave request', event); }
    this.saveOrSubmit(event, RequestStatus.Draft);
  }

  withdrawRequest(event) {
    const trackedEvent = {
      fixtureId: event.fixtureEventId,
      requestId: undefined,
      status: StatusHelper.getRequestStatusText(RequestStatus.Withdrawn),
    };
    if (event instanceof CreateFixtureRequestDto) {
      this.appInsightsService.logEvent('Create request (Broadcaster)', trackedEvent);
    } else {
      event.requestId = event.fixtureRequestId;
      this.appInsightsService.logEvent('Save request (Broadcaster)', trackedEvent);
    }
    const modalRef = this.modalService.open(ConfirmWithdrawPopupComponent, { centered: true });
    this.withDrawSubscription = modalRef.componentInstance.withdraw
      .subscribe(doWithdraw => {
        if (doWithdraw === true) {
          if (this.isLog) { console.log('onWithdraw request', event); }
          this.saveOrSubmit(event, RequestStatus.Withdrawn);
          this.withDrawSubscription.unsubscribe();
        }
      });
  }

  saveOrSubmit(event, status: RequestStatus) {

    if (event.type === 2 && this.userRole === 'Broadcaster') {
      this.alertService.warning('You not able to create/change request for this event');
    }
    const request = this.initRequestModel(event.identity.eventId, event.request, status);
    if (this.isLog) { console.log('Request to Save/Submit', request); }

    if (request instanceof CreateFixtureRequestDto) {
      this.store.dispatch(new CreateFixtureRequestAction(request));
    } else if (request instanceof UpdateFixtureRequestDto) {
      this.store.dispatch(new UpdateFixtureRequestAction(request));
    }
    this.isSpinner = true;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public beforeChange() {

    // const id = Number($event.panelId.substr(6, $event.panelId.length));
    // this.accordionItems[id].isOpen = $event.nextState;
  }

  navigateBack() {
    this.router.navigate([`fixtures`]);
  }

}
