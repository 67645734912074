export class UserModel {

    constructor(
      public firstName: string = '',
      public surname: string = '',
      public email: string = '',
      public companyName: string = '',
      public companyId: string = '',
      public thumbnail: any = null,
      public role: string = ''
    ) {}
}
