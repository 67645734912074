import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ITeamDto } from '../../../services/bpp.webapi/client';
import { Moment } from 'moment';
import { ConfigService } from 'src/app/config/config.service';

export interface IFixtureHeader {
  homeTeam: ITeamDto;
  awayTeam: ITeamDto;
  matchDate: Moment;
}

@Component({
  selector: 'app-fixture-header',
  templateUrl: './fixture-header.component.html',
  styleUrls: ['./fixture-header.component.scss']
})
export class FixtureHeaderComponent implements OnInit {
  @Input() header: IFixtureHeader;
  @Input() lastSaved: string;
  isLfp: boolean;

  constructor(public config: ConfigService) { 
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    // console.log('this.header = ', this.header);
  }

}
