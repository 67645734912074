
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Roles } from '../../models/enums';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-filming-location',
  templateUrl: './filming-location.component.html',
  styleUrls: ['./filming-location.component.scss']
})
export class FilmingLocationComponent extends BaseComponent implements OnInit, OnDestroy {

  Roles = Roles;
  role: Roles;

  constructor(private store: Store<AppState>) {
    super();
   }

  ngOnInit() {
    this.store.select(s => s.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(s => {
        this.isSpinner = s.loading;
        if (!s.loading && s.currentUser) {
          this.role = s.currentUser.role;
        }
      });
  }
}
