import { ClubInterviewState } from './state';
import * as actions from './actions';
import * as moment from 'moment';

export type Action = actions.All;
const defaultState = new ClubInterviewState();

export function ClubInterviewsReducer(state = defaultState, action: Action): ClubInterviewState {
    switch (action.type) {

        case actions.GET_CLUB_INTERVIEW_CLUB_EVENTS: {
            return {
                ...state,
                loading: true,
                filter: { matchRound: 1 },
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_CLUB_EVENTS_SUCCESS: {
            let clubItems = action.payload;
            return {
                ...state,
                clubItems,
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_SUCCESS: {
            let detailsForClub = state.detailsForClub;
            detailsForClub[action.matchId] = action.payload;
            return {
                ...state,
                detailsForClub,
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_SUCCESS: {
            let detailsForLeagueBroadcaster = state.detailsForLeagueBroadcaster;
            detailsForLeagueBroadcaster[action.matchId] = action.payload;
            return {
                ...state,
                detailsForLeagueBroadcaster,
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_LEAGUE_EVENTS: {
            return {
                ...state,
                filter: { matchRound: action.matchRound },
                loading: true
            };
        }

        case actions.GET_CLUB_INTERVIEW_LEAGUE_EVENTS_SUCCESS: {

            return {
                ...state,
                byGameWeek: { ...state.byGameWeek, [action.matchRound]: action.payload },
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_BROADCASTER_EVENTS: {
            return {
                ...state,
                loading: true,
                error: false,
                filter: { matchRound: action.matchRound },
            };
        }

        case actions.GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_SUCCESS: {

            return {
                ...state,
                broadcasterByGameWeek: { ...state.broadcasterByGameWeek, [action.matchRound]: action.payload },
                loading: false,
                error: false
            };
        }

        case actions.CREATE_CLUB_INTERVIEW_EVENT: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.CREATE_CLUB_INTERVIEW_EVENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_EVENT: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_EVENT_SUCCESS: {
            
            const detailsForClub = { ...state.detailsForClub };
            if (detailsForClub[action.payload.matchId]) {
                detailsForClub[action.payload.matchId].event = action.payload;
            }
            const detailsForLeagueBroadcaster = { ...state.detailsForLeagueBroadcaster };
            if (detailsForLeagueBroadcaster[action.payload.matchId]) {
                if(
                    detailsForLeagueBroadcaster[action.payload.matchId].awayEvent && 
                    detailsForLeagueBroadcaster[action.payload.matchId].awayEvent.eventId === action.payload.eventId
                ) {
                    detailsForLeagueBroadcaster[action.payload.matchId].awayEvent = action.payload;
                }

                if(
                    detailsForLeagueBroadcaster[action.payload.matchId].homeEvent && 
                    detailsForLeagueBroadcaster[action.payload.matchId].homeEvent.eventId === action.payload.eventId
                ) {
                    detailsForLeagueBroadcaster[action.payload.matchId].homeEvent = action.payload;
                }
            }
            return {
                ...state,
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_MATCH_INFO: {
            return {
                ...state,
                loading: true,
                selectedMatch: null,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_MATCH_INFO_SUCCESS: {
            return {
                ...state,
                loading: false,
                selectedMatch: action.payload,
                error: false
            };
        }

        case actions.CREATE_CLUB_INTERVIEW_REQUEST: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.CREATE_CLUB_INTERVIEW_REQUEST_SUCCESS: {

            const detailsForLeagueBroadcaster = { ...state.detailsForLeagueBroadcaster };
            const matchDetails = detailsForLeagueBroadcaster[action.payload.matchId];
            if (matchDetails && 
                ((matchDetails.awayEvent && matchDetails.awayEvent.eventId === action.payload.eventId ) ||
                (matchDetails.homeEvent &&  matchDetails.homeEvent.eventId === action.payload.eventId )) &&
                action.payload.requestDetails
                ) {
                const eventDetailsLb = matchDetails.awayEvent && matchDetails.awayEvent.eventId === action.payload.eventId ? matchDetails.awayEvent : matchDetails.homeEvent;
                
                eventDetailsLb.requests.push(action.payload.requestDetails[0]);
                const slot = eventDetailsLb.slots.find(x => x.id === action.slotId);
                if (slot) {
                    slot.submittedRequests.push(action.payload.requestDetails[0].id);
                }
            }

            return {
                ...state,
                detailsForLeagueBroadcaster,
                loading: false,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_SUCCESS: {
            const detailsForLeagueBroadcaster = { ...state.detailsForLeagueBroadcaster };
            detailsForLeagueBroadcaster[action.payload.matchId] = action.payload;
            return {
                ...state,
                detailsForLeagueBroadcaster,
                loading: false,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_SUCCESS: {
            const detailsForLeagueBroadcaster = { ...state.detailsForLeagueBroadcaster };
            detailsForLeagueBroadcaster[action.payload.matchId] = action.payload;

            return {
                ...state,
                detailsForLeagueBroadcaster,
                loading: false,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE: {
            return {
                ...state,
                loading: true,
                error: false
            };
        }

        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_SUCCESS: {
            const detailsForLeagueBroadcaster = { ...state.detailsForLeagueBroadcaster };
            detailsForLeagueBroadcaster[action.payload.matchId] = action.payload;

            return {
                ...state,
                detailsForLeagueBroadcaster,
                loading: false,
                error: false
            };
        }

        case actions.GET_CLUB_INTERVIEW_MATCH_INFO_ERROR:
        case actions.GET_CLUB_INTERVIEW_CLUB_EVENTS_ERROR:
        case actions.GET_CLUB_INTERVIEW_LEAGUE_EVENTS_ERROR:
        case actions.GET_CLUB_INTERVIEW_BROADCASTER_EVENTS_ERROR:
        case actions.CREATE_CLUB_INTERVIEW_EVENT_ERROR:
        case actions.UPDATE_CLUB_INTERVIEW_EVENT_ERROR:
        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_CLUB_ERROR:
        case actions.GET_CLUB_INTERVIEW_DETAILS_FOR_LEAGUE_BROADCASTER_ERROR:
        case actions.CREATE_CLUB_INTERVIEW_REQUEST_ERROR:
        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_ERROR:
        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_ERROR:
        case actions.UPDATE_CLUB_INTERVIEW_REQUEST_LEAGUE_NOTE_ERROR: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
    }

    return state;
}
