import { IDashboardItemDto, IPagedListOfDashboardItemDto, PagedListOfDashboardItemDto } from '../../services/bpp.webapi/dashboard.client';

export class DashboardState {
    loading: boolean;
    error: boolean;
    items: IDashboardItemDto[];
    list: IPagedListOfDashboardItemDto;

    constructor() {
        this.items = new Array<IDashboardItemDto>();
        this.list = new PagedListOfDashboardItemDto();
    }
}
