import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddItemComponent } from '../add-item/add-item.component';

@Component({
  selector: 'app-items-picker',
  templateUrl: './items-picker.component.html',
  styleUrls: ['./items-picker.component.scss']
})
export class ItemsPickerComponent implements OnInit, OnDestroy {
  @Output() changeItems = new EventEmitter();
  private subscription;

  @Input() title: string;
  @Input() nameProperty: string;
  @Input() idProperty: string;
  @Input() constItems: Array<any> = [];
  @Input() pickedItems: Array<any>;
  @Input() initAction: any;
  @Input() isSingle: boolean = false;
  @Output() change = new EventEmitter();

  items: Array<any> = [];

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    if (this.pickedItems) {
      this.pickedItems.forEach(item => {
        this.items.push(item);
      });
    }
  }

  openAddItem() {
    const modalRef = this.modalService.open(AddItemComponent);
    modalRef.componentInstance.constItems = this.constItems;
    modalRef.componentInstance.pickedItems = this.pickedItems || [];
    modalRef.componentInstance.title = this.title;
    modalRef.componentInstance.isSingle = this.isSingle;
    modalRef.componentInstance.idProperty = this.idProperty;
    modalRef.componentInstance.nameProperty = this.nameProperty;
    modalRef.componentInstance.initAction = this.initAction;
    this.subscription = modalRef.componentInstance.save.subscribe(items => this.saveItems(items));
  }

  saveItems(items: Array<any>) {
    this.items = items;
    this.pickedItems = this.items;
    this.changeItems.emit(items);
  }

  deleteItem(sender: any) {
    const index = this.findWithAttr(this.items, this.idProperty, sender[this.idProperty]);
    if (index !== -1) {
      this.items.splice(index, 1);
      this.changeItems.emit(this.items);
    }

  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
