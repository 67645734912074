import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable()
export class ImportService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    import(file): Observable<HttpEvent<{}>> {
      const formData = new FormData();
      formData.append(file.name, file);
      const uploadReq = new HttpRequest('POST', this.configService.getApiConnString() + `/api/import`, formData, {
        reportProgress: true,
        withCredentials: true
      });
      return this.http.request(uploadReq);
    }
}
