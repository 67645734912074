
import { takeUntil, mergeMap, filter, map } from 'rxjs/operators';
import { Component, ComponentFactoryResolver, OnInit, SimpleChanges } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { selectInterviewDetailsById, selectInterviewDetailsLoading, IInterviewDetails } from '../../../store/interviews/state';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import {GetInterviewDetailsAction, UpdateInterviewAction, CreateUpdateInterviewRequestAction, CreateInterviewAction} from '../../../store/interviews/actions';
import { ITimePicker } from '../../common/combo-picker/combo-picker.component';
import { ConfigService } from '../../../config/config.service';
import { selectCurrentUserRole, selectUserIsLoading } from '../../../store/user/state';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { BaseComponent } from '../../base-component/base.component';
import { GetInterviewLocationsAction } from '../../../store/location/actions';
import { ValidationService } from '../../../services/validation.service';
import { selectLanguagesByIds, selectLanguagesLoading } from '../../../store/bpp/state';
import { selectLocationById, selectLocationLoading } from '../../../store/location/state';
import { EventStatus, InterviewTypeDto, RequestStatus } from '../../../services/bpp.webapi/client';
import { IDentity, FxRequest } from '../../../store/fx-request/state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getLastSavedText, isGuidNotEmpty } from '../../../helpers/common';
import { isNonEmptyNotes } from '../../../helpers/league.helper';
import { RejectConfirmComponent } from '../../common/reject-confirm/reject-confirm.component';
import { getMinDate, getMaxDate } from '../../../helpers/date-helper';
import { GetInterviewTypesAction } from 'src/app/store/interview-types/actions';
import * as momentTz from 'moment-timezone';


export enum EInterviewView {
  Undefined = 0,
  LeagueRead,
  LeagueUpdate,
}

@Component({
  selector: 'app-interview-details-league',
  templateUrl: './interview-details-league.component.html'
})
export class InterviewDetailsLeagueComponent extends BaseComponent implements OnInit {
  details$: Observable<IInterviewDetails>;
  isLoading$: Observable<boolean>;
  data: IInterviewDetails;
  broadcasterRequest: FxRequest;
  original: IInterviewDetails;
  lastSaved: string;
  minStart: Subject<ITimePicker> = new Subject();
  maxStart: Subject<ITimePicker> = new Subject();
  minEnd: Subject<ITimePicker> = new Subject();
  maxEnd: Subject<ITimePicker> = new Subject();
  minutesStep = 5;
  isDebug: boolean;
  editMode = false;
  EInterviewView = EInterviewView;
  interviewView: EInterviewView;
  isPastEvent = false;
  minDate: { year: number; month: number; day: number; };
  maxDate: { year: number; month: number; day: number; };
  timeZone: string;
  isLfp:boolean;
  durationEnabled: boolean;
  _startOfDay: moment.Moment;
  _duration: moment.Moment;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    public config: ConfigService,
    private valiadtor: ValidationService,
    private modalService: NgbModal) {
    super();
    this.isDebug = config.isDebug();
    this.timeZone = config.getTimezone();
    const features = config.get("features.interviews");
   
    this._duration = momentTz().tz(this.timeZone).startOf('day');
    this.isLfp = config.isLfp();
    if(features && features.duration) {
      this.durationEnabled = true;
    }
  }


  get duration(): moment.Moment {
    return this._duration;
  }

  set duration(value: moment.Moment) {
    this._duration = value;
    if(!this.data) {
      return;
    }
    this.data.duration =moment.duration(momentTz(value).tz(this.timeZone).diff(momentTz().tz(this.timeZone).startOf('day'), 'milliseconds'), 'milliseconds');
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        const interviewId = params['id'];
        this.store.dispatch(new GetInterviewDetailsAction(interviewId));
        this.details$ = this.store.select(selectInterviewDetailsById(interviewId)).pipe(filter(x => x !== undefined));
      }
    });

    const userRole$ = this.store.select(selectCurrentUserRole());
    const combined$ = this.details$.pipe(
      mergeMap((details) => {
        const languages$ = this.store.select(selectLanguagesByIds(details.languages.map(l => l.languageId)));
        const location$ = this.store.select(selectLocationById(details.location.locationId));
        return combineLatest([of(details), languages$, location$, userRole$]);
      }));

    combined$.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(([details, languages, location, role]) => {
        this._startOfDay = moment().utc().startOf('day');
        this.isPastEvent = details.isPast;
        this.minDate = getMinDate(details.arrivalDate);
        this.maxDate = getMaxDate(details.arrivalDate);
        this.data = cloneDeep(details);
        this.data.languages = languages || [];
        this.data.location = location ? cloneDeep(location) : details.location;
        this.original = cloneDeep(this.data);
        this.interviewView = EInterviewView.LeagueRead;
        this.lastSaved = this.getLastSaved();
        const duration = moment.duration(this.data.duration.asMinutes() - momentTz.tz(this.timeZone).utcOffset(),'minutes');
        this.duration = this._startOfDay.add(duration,'milliseconds')
      });

    const dataLoading$ = this.store.select(selectInterviewDetailsLoading());
    const userLoading$ = this.store.select(selectUserIsLoading());
    const locationLoading$ = this.store.select(selectLocationLoading());
    const languagesLoading$ = this.store.select(selectLanguagesLoading());
    this.isLoading$ = combineLatest([dataLoading$, userLoading$, locationLoading$, languagesLoading$]).pipe(
      takeUntil(this.componentDestroyed),
      map(([data, usr, loc, lang]) => data || usr || loc || lang));

    this.store.dispatch(new GetInterviewLocationsAction());
  }

  onDurationChange(event){
    console.log('duration-change',event)
  }
  
  getLastSaved() {
    if (isGuidNotEmpty(this.data.interviewId)) {
      return getLastSavedText(this.data.updatedOnUtc, this.data.updatedBy, EventStatus[this.data.status]);
    } else {
      return 'To be saved';
    }
  }

  goBack() {
    this.router.navigate([`interviews`]);
  }

  onLeagueEdit() {
    this.interviewView = EInterviewView.LeagueUpdate;
  }

  onCancelEdit() {
    this.interviewView = EInterviewView.LeagueRead;
    this.data = cloneDeep(this.original);
  }

  onCancel() {
    this.data = cloneDeep(this.original);
  }

  onSubmit() {
    if (this.valiadtor.isInterviewValid(this.data)) {
      this.store.dispatch(new UpdateInterviewAction(this.data));
      this.interviewView = EInterviewView.LeagueRead;
    }
  }

  onApproveRejectRequest(event: { identity: IDentity, request: FxRequest }) {
    const request = event.request;
    request.leagueNote.message = event.request.note;
    if (request.status === RequestStatus.Reject) {
      if (isNonEmptyNotes(request.leagueNote)) {
        this.confirmedReject(request);
      } else {
        const modalRef = this.modalService.open(RejectConfirmComponent);
        const instance = modalRef.componentInstance as RejectConfirmComponent;
        return instance.submit
          .subscribe((result: boolean) => {
            if (result) {
              this.confirmedReject(request);
            } else {
              request.status = RequestStatus.Submitted;
            }
          });
      }
    } else {
      this.store.dispatch(new CreateUpdateInterviewRequestAction(event.request, this.data.interviewId));
    }
  }

  confirmedReject(request) {
    this.store.dispatch(new CreateUpdateInterviewRequestAction(request, this.data.interviewId));
  }

  changeLanguages(event) {
    this.data.languages = event;
  }

  // Time picking
  onPickerInit() {
    this.initTimePickers(this.data.arrivalTime);
  }

  dateChanged(event: moment.Moment) {
    this.data.arrivalDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.arrivalTime.hour)
      .minutes(this.data.arrivalTime.minute);
    this.data.startDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.startTime.hour)
      .minutes(this.data.startTime.minute);
  }

  onArrivalTimeChanged(event: ITimePicker) {
    this.minEnd.next({
      hour: event.hour,
      minute: event.minute
    });
    this.data.arrivalDate = moment.utc(this.data.arrivalDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }

  onStartTimeChanged(event: ITimePicker) {
    this.data.startDate = moment.utc(this.data.startDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }

  initTimePickers(model?: ITimePicker) {
    this.minStart.next({
      hour: 0,
      minute: 0
    });
    this.maxStart.next({
      hour: 23,
      minute: 59
    });
    if (model && model.hour && model.minute) {
      this.minEnd.next({
        hour: model.hour,
        minute: model.minute
      });
    } else {
      this.minEnd.next({
        hour: 0,
        minute: 0
      });
    }
    this.maxEnd.next({
      hour: 23,
      minute: 59
    });
  }

  onTypeChange(type) {
    this.data.interviewType = JSON.parse(JSON.stringify(type.interviewType));
  }
  
  navigateBack() {
    this.router.navigate([`interviews`]);
  }
}
