import { Roles } from './enums';

export class User {
  public id: string;
  public givenName: string;
  public surname: string;
  public email: string;
  public photo: any;
  public companyName: string;
  public companyId: string;
  public role: Roles;
  public isCompanyEnabledForNonLiveInUK: boolean;

  constructor() {

  }
}

export interface IUserRole {
  id: string;
  name: string;
  description: string;
  level: number;
}
