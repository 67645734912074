import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { getCookie } from 'tiny-cookie';
import { ConfigService } from '../config/config.service';
import { Roles } from '../models/enums';
import { AuthService } from './auth.service';

@Injectable()
export class FirstLoginGuard implements CanActivate {
  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (this.configService.hasFeature('enableFirstLoginVideo')) {
      const role = this.authService.getUserRole();
      if (role === Roles.Broadcaster || role === Roles.PLPBroadcaster) {
        const cookieName = this.configService.get('firstLoginCookieName');
        const cookie = getCookie(cookieName) === 'true';
        if (!cookie) {
          this.router.navigate(['about']);
          return false;
        }
      }
      return true;
    }
    return true;
  }
}
