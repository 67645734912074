import { Moment } from 'moment';
import { ISessionData } from './session-data.interface';

export class IDashboardMessage {
    avatar: string;
    account: string;
    // status: string;
    data: ISessionData;
    date: Moment;
    clubId?: number;
    subject: string;
    body: string;
    confirmed: string;
    color:string;
}
