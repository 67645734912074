import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { IDashboardMessage } from '../../models/dashboard-message.interface';
import { MessageInfoComponent } from '../message-info/message-info.component';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-message',
  templateUrl: './dashboard-message.component.html',
  styleUrls: ['./dashboard-message.component.scss']
})
export class DashboardMessageComponent implements OnInit {

  @ViewChild('messageContainer', { read: ViewContainerRef, static: true }) container;

  @Input() data: IDashboardMessage;
  nowTime;
  duration;
  pastTime;
  componentRef;

  constructor(private resolver: ComponentFactoryResolver) { }

  ngOnInit() {

    this.pastTime = this.hoursBetween(this.data.date, moment());
    this.createComponent(this.data.data);
  }

  hoursBetween = (date1: moment.Moment, date2: moment.Moment) => {
    const time = moment(date2, 'DD/MM/YYYY HH:mm:ss').diff(moment(date1, 'DD/MM/YYYY HH:mm:ss'));
    const totalHours = moment.duration(time).asHours();
    if (totalHours < 1) {
      if (moment.duration(time).asMinutes() < 10) {
        return Math.floor(moment.duration(time).asMinutes()) + ' m';
      }
      return moment.utc(time).format('mm') + ' m';
    }
    const days = Math.floor(totalHours / 24);
    if (days < 1) {
      return Math.floor(totalHours) + ' h';
    } else {
      return date1.local().format('DD/MM/YYYY | HH:mm');
    }
  }

  createComponent(data) {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(MessageInfoComponent);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.data = data;
  }

}
