import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    PersonReportDataDto, ReportClient,
    FileResponse, ContentSessionTypeDataDto, BroadcasterRequestsDataDto, InterviewsSummaryDataDto
} from './bpp.webapi/client';

@Injectable()
export class ReportService {
    constructor(protected apiClient: ReportClient) {
    }

    getPersonsReport(clubId: number): Observable<Array<PersonReportDataDto>> {
        return this.apiClient.getPersonsReport(clubId);
    }

    getPersonsExcelReport(clubId: number): Observable<FileResponse> {
        return this.apiClient.getPersonsExcelReport(clubId);
    }

    getFixtureRequestsReport(matchId: number, fixtureId: string, nonLiveInUK: boolean = false): Observable<FileResponse> {
        return this.apiClient.getFixtureRequestsReportWord(matchId, fixtureId, nonLiveInUK);
    }

    getTrainingRequestsReport(trainingId: string): Observable<FileResponse> {
        return this.apiClient.getTrainingRequestsReportWord(trainingId);
    }

    getContentSessionTypesReport(): Observable<Array<ContentSessionTypeDataDto>> {
        return this.apiClient.getContentSessionTypesReport();
    }

    getContextSessionTypesExcelReport(): Observable<FileResponse> {
        return this.apiClient.getContentSessionTypesExcelReport();
    }

    getBroadcastersRequestsReport(clubId: number): Observable<Array<BroadcasterRequestsDataDto>> {
        return this.apiClient.getBroadcasterRequestsData(clubId);
    }

    getBroadcastersRequestsExcelReport(clubId: number): Observable<FileResponse> {
        return this.apiClient.getBroadcasterRequests(clubId);
    }

    getBroadcastersRequestsPdfReport(clubId: number): Observable<FileResponse> {
        return this.apiClient.getBroadcasterRequestsPdfReport(clubId);
    }

    getInterviewsSummaryReport(): Observable<Array<InterviewsSummaryDataDto>> {
        return this.apiClient.getInterviewsSummaryData();
    }

    getInterviewsSummaryExcelReport(): Observable<FileResponse> {
        return this.apiClient.getInterviewsSummary();
    }

    getInterviewsSummaryPdfReport(): Observable<FileResponse> {
        return this.apiClient.getInterviewsSummaryPdfReport();
    }
}
