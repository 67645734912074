import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {Roles} from 'src/app/models/enums';
import {AppState} from 'src/app/store/app.state';
import {BaseComponent} from '../../base-component/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateAdditionalContentEventAction, GetAdditionalContentEventByIdAction, UpdateAdditionalContentEventAction} from '../../../store/additional-content/actions';
import {User} from '../../../models/user.model';
import {AdditionalContentType, ClubDto, EventStatus, IAdditionalContentFixtureDetailsDto, NoteDto, PlayerDto} from '../../../services/bpp.webapi/client';
import {Subscription} from 'rxjs';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {AppInsightClientService} from '../../../services/app-insight.service';
import {AdditionalContentMapper} from '../../../mappers/additional-content.mapper';
import {AlertService} from '../../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../config/config.service';
import * as helper from '../../../helpers/common';
import {DateBinder} from '../dateBinder';
import {GetPlayerListByTeamAction} from '../../../store/bpp/actions';
import {mapAdditionalContentType, mapAdditionalContentTypeUrl} from '../../../helpers/common';
import {RejectConfirmComponent} from '../../common/reject-confirm/reject-confirm.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isNonEmptyNotes} from '../../../helpers/league.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-additional-content-details-league',
  templateUrl: './additional-content-details-league.component.html'
})
export class AdditionalContentDetailsLeagueComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  AdditionalContentTypes = AdditionalContentType;
  EventStatuses = EventStatus;
  role: Roles;
  isLog = true;
  isLoading = true;
  isReadOnly = true;
  user: User;
  userRole: Roles;
  clubId;
  additionalContent: IAdditionalContentFixtureDetailsDto;
  additionalContentId: string;
  additionalContentType: AdditionalContentType;
  additionalContentSubscription: Subscription;
  players = new Array<PlayerDto>();
  mapper: AdditionalContentMapper;
  club: ClubDto;
  lastSaved: string;
  dateBinder: DateBinder;
  features: any;
  canManageRequest:boolean;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
              private route: ActivatedRoute,
              private appInsightsService: AppInsightClientService,
              private alertService: AlertService,
              private translationService: TranslateService,
              private router: Router,
              private modalService: NgbModal,
              private config: ConfigService) {
    super();
    this.isLog = this.config.isDebug();
    this.mapper = new AdditionalContentMapper();
    this.features = this.config.get("features.additionalContent");
    if (this.config.hasFeature('environment.lfp')){
      this.isLfp= true;
    }
  }

  ngOnInit() {
    this.isLog = true;

    this.store
      .select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });

    this.route.params.subscribe(
      params => {
        const eventType = mapAdditionalContentType(params['type'].toLowerCase(), !!(this.features && this.features.alternativeNames));
        this.additionalContentSubscription = this.store
          .select(e => e.additionalContent && e.additionalContent.additionalContentDetails[params['id']])
          .pipe(takeUntil(this.componentDestroyed), filter(r => r !== undefined))
          .subscribe(res => {
            if (res) {
              if (this.isLog) { console.log('GetAdditionalContentEventDetails: ', res); }

              if (!res.clubNote) {
                res.clubNote = new NoteDto();
              }
              this.additionalContent = res;
              this.canManageRequest = (this.additionalContent !== undefined && (this.additionalContent.type === this.AdditionalContentTypes.Club || this.isLfp));
              this.club = new ClubDto({ id: this.additionalContent.clubId, name: this.additionalContent.club });
              if (this.additionalContent !== undefined) {
                this.store.select(state => state.bpp.playersByTeam).pipe(
                  takeUntil(this.componentDestroyed), filter(state => state !== undefined))
                  .subscribe(playersByTeam => {
                    if (playersByTeam && playersByTeam[this.additionalContent.clubId]) {
                      this.isSpinner = false;
                      this.isLoading = false;
                      this.players = playersByTeam[this.additionalContent.clubId];
                      if (this.isLog) { console.log('this.players', this.players); }
                    }
                  });

                this.dateBinder = new DateBinder(this.additionalContent.start, this.additionalContent.arrival, this.config.get('defaultTimeZone'));
                if (eventType === AdditionalContentType.PreSeason || eventType === AdditionalContentType.MidSeason) {
                  this.isSpinner = true;
                  this.store.dispatch(new GetPlayerListByTeamAction([this.additionalContent.clubId]));
                } else {
                  this.isSpinner = false;
                  this.isLoading = false;
                }
              }

              this.lastSaved = helper.getLastSavedText(this.additionalContent.updatedOn, this.additionalContent.updatedByName, StatusHelper.getStatusText(this.additionalContent.status));
            }
          });

        this.isSpinner = true;
        this.store.dispatch(new GetAdditionalContentEventByIdAction(params['id'], eventType));
      });
  }

  saveOrSubmitEvent(status) {
    this.dateBinder.bindToDetailsModel(this.additionalContent);
    if (!this.validateModel()) {
      return;
    }
    this.isSpinner = true;
    this.appInsightsService.logEvent('Create additional content (club)', {
      trainingDate: this.additionalContent.start.format('YYYY/MM/dd HH:mm'),
      status: StatusHelper.getStatusText(status)
    });
    this.additionalContent.status = status;
    if (status === EventStatus.Unknown) {
      const model = this.mapper.create(this.additionalContent);
      model.status = status;
      this.store.dispatch(new CreateAdditionalContentEventAction(model));
    } else {
      const model = this.mapper.update(this.additionalContent);
      this.updateAndRedirectToDetails(model);
    }
  }

  private validateModel(): boolean {
    if (this.additionalContent.location === undefined || this.additionalContent.location.locationId === undefined) {
      this.translationService.get('invalidTrainingLocation').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    if (this.isLfp && (this.additionalContent.duration === undefined || this.additionalContent.duration===moment.duration(0,'milliseconds'))){
      this.translationService.get('invalidDuration').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    return true;
  }

  startEditing() {
    this.isReadOnly = false;
  }

  cancelEditing() {
    this.isReadOnly = true;
  }

  goBack() {
    this.router.navigate([`additional-content`]);
  }

  reject() {
    if (isNonEmptyNotes(this.additionalContent.leagueNote)) {
      this.confirmedEventReject();
    } else {
    const modalRef = this.modalService.open(RejectConfirmComponent);
    const instance = modalRef.componentInstance as RejectConfirmComponent;
    return instance.submit
      .subscribe((result: boolean) => {
        if (result) {
          this.confirmedEventReject();
        }
      });
    }
  }

  confirmedEventReject() {
    const status = StatusHelper.getStatusText(EventStatus.Reject);
    const eventName = 'Reject ICS (Additional Content) (League)';
    this.additionalContent.status = EventStatus.Reject;
    const model = this.mapper.update(this.additionalContent);
    if (this.isLog) { console.log('Reject additional content:', model); }
    this.isSpinner = true;
    this.store.dispatch(new UpdateAdditionalContentEventAction(model));

    setTimeout(() => {
      this.store.select(state => state.additionalContent.error).subscribe(e => {
        if (e === false) {
          this.appInsightsService.logEvent(eventName, { eventId: this.additionalContent.eventId, status });
          this.router.navigate(['additional-content']);
        }
      }).unsubscribe();
    }, 1000);
  }

  approve() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Approve additional content (League)', {
      eventId: this.additionalContent.eventId,
      status: StatusHelper.getStatusText(EventStatus.Approved)
    });
    this.additionalContent.status = EventStatus.Approved;
    const model = this.mapper.update(this.additionalContent);
    if (this.isLog) { console.log('Approve additional content:', model); }
    this.updateAndRedirectToDetails(model);
  }

  private updateAndRedirectToDetails(model) {
    this.store.dispatch(new UpdateAdditionalContentEventAction(model));

    this.store.select(e => e.additionalContent).pipe(
      takeUntil(this.componentDestroyed), filter(r => r !== undefined))
      .subscribe(res => {
        if (res.loading) {
        } else {
          this.isSpinner = false;
          this.isReadOnly = true;
          this.router.navigate([`additional-content/${mapAdditionalContentTypeUrl(this.additionalContent.type, !!(this.features && this.features.alternativeNames))}/detail/${this.additionalContent.eventId}`]);
        }
      });
  }
}
