import { User } from '../../models/user.model';
import { IUserDetails } from '../../services/bpp.webapi/client';
import { AppState } from '../app.state';
import { createSelector } from '@ngrx/store';

export class UserState {
  loading: boolean;
  currentUser: User;
  userMenu: any;
  menu: any;
  errorMessages: string[];
  error: boolean;
  details: IUserDetails;
  applicationIsLoading: boolean;

  constructor() {
    this.loading = false;
    this.currentUser = null,
      this.menu = {
        show: false,
        Bitwise: 0,
        Items: [],
      };
    this.applicationIsLoading = true;
  }
}

const getCurrentUserResult = (state: AppState) => state.user.currentUser;
const getUserState = (state: AppState) => state.user;

export const selectCurrentUser = () =>
  createSelector(
    getCurrentUserResult,
    result => result
  );

export const selectCurrentUserRole = () =>
  createSelector(
    getCurrentUserResult,
    result => {
      if (result) {
        return result.role;
      }
    }
  );

export const selectUserIsLoading = () =>
  createSelector(
    getUserState,
    result => result.loading
  );
