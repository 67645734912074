import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "../../models/user.model";
import { AppState } from "../../store/app.state";
import { MenuBaseComponent } from "../../widgets/menu/menu-base.component";

@Component({
  selector: "app-menu",
  template: `<app-menu-base
      [showName]="false"
      [data]="data"
      [vertical]="true"
      [templateRef]="nodeItem"
      [location]="url"
    ></app-menu-base>
    <ng-template #nodeItem let-node="node">
      <div
        class="nav-link d-flex"
        *ngIf="node.icon || node.desc"
        (click)="goTo(node.route)"
      >
        <span class="{{ node.icon }}"></span>
        <span>{{ node.desc }}</span>
      </div>
    </ng-template>`,
})
export class MenuComponent extends MenuBaseComponent implements OnInit {
  @Input() link: string;
  protected routerSubscription: Subscription;
  url: string;
  user: User;
  private componentDestroyed: Subject<void> = new Subject();

  constructor(private store: Store<AppState>, protected router: Router) {
    super();
  }

  ngOnInit() {
    this.url = this.router.url;
    this.store
      .select((state) => state.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((state) => {
        if (!state.loading) {
          this.user = state.currentUser;
        }
      });
  }

  goTo(link): void {
    this.router.navigateByUrl(link);
  }
}
