import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILanguageDto, LanguageDto } from '../../../services/bpp.webapi/client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-languages',
  templateUrl: './add-languages.component.html',
  styleUrls: ['./add-languages.component.scss']
})
export class AddLanguagesComponent implements OnInit {

  @Input() constLanguages: Array<ILanguageDto>;
  @Input() pickedLanguages: Array<ILanguageDto>;
  @Output() save: EventEmitter<Array<ILanguageDto>> = new EventEmitter<Array<ILanguageDto>>();

  languages: Array<ILanguageDto> = [];
  checkBox: Array<any> = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

    this.checkBox = this.constLanguages.map(item => {
      const index = this.findWithAttr(this.pickedLanguages, 'languageId', item.languageId);

      return { checked: index !== -1, languageId: item.languageId, name: item.name };
    });

  }

  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  changeCheckbox(e) {
    e.checked = !e.checked;
  }

  onSave() {
    this.checkBox.forEach(item => {
      if (item.checked) {
        this.languages.push(LanguageDto.fromJS({ languageId: item.languageId, name: item.name}));
      }
    });

    this.save.emit(this.languages);
    this.activeModal.close();
  }

}
