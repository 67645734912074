import {MatchExtInfoDto} from '../../../services/bpp.webapi/client';
import * as moment from 'moment';

export class MatchItem {
  public nonLiveInUK: boolean;
  public edit = false;

  constructor(public item: MatchExtInfoDto) {
    this.nonLiveInUK = item.nonLiveInUK;
  }

  get homeTeamName(): string {
    return this.item.homeTeamName;
  }

  get homeTeamId(): number {
    return this.item.homeTeamId;
  }

  get awayTeamName(): string {
    return this.item.awayTeamName;
  }

  get awayTeamId(): number {
    return this.item.awayTeamId;
  }

  get date(): moment.Moment {
    return this.item.date;
  }

  public rollback() {
    this.nonLiveInUK = this.item.nonLiveInUK;
  }

  public commit() {
    this.item.nonLiveInUK = this.nonLiveInUK;
  }
}
