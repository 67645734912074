import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetTrainingParticipantsAction, GetTrainingParticipantByCompanyAction } from '../../../../store/training/actions';
import { Subscription } from 'rxjs';
import { ParticipantType, ParticipantDto, UpdateTrainingRequestDto, RequestDetailsDto } from '../../../../services/bpp.webapi/client';

@Component({
  selector: 'app-league-edit-training-request',
  templateUrl: './league-edit-training-request.component.html',
  styleUrls: ['./league-edit-training-request.component.scss']
})
export class LeagueEditTrainingRequestComponent implements OnInit {
  participantsSubscription: Subscription;
  trainingRequest: RequestDetailsDto;
  dataLoaded: boolean;
  participants: any;
  participantTypes = [ParticipantType.Reporter, ParticipantType.Camera, ParticipantType.Producer, ParticipantType.Other];
  save: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<AppState>, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  subscribeToParticipants() {
    this.participantsSubscription = this.store
      .select(e => e.trainings.participants)
      .subscribe(res => {
        // console.log('participants', res);
        this.participants = res;
        this.dataLoaded = true;
      });
    this.store.dispatch(new GetTrainingParticipantByCompanyAction(this.trainingRequest.companyId));
  }

  onSave() {
    this.save.emit(this.trainingRequest);
    this.activeModal.close('Close click');
  }

  selectParticipant(value: string) {
    for (const item of this.participants) {
      if (item.id === value) {
        if (this.trainingRequest.participants.filter(x => x.id === item.id).length === 0) {
          this.trainingRequest.participants.push(item);
        }
      }
    }
  }

  deleteParticipant(item: ParticipantDto) {
    this.trainingRequest.participants.splice(this.trainingRequest.participants.indexOf(item), 1);
  }

  getParcipicantsTypeString(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'Cameras';
      case (ParticipantType.Reporter): return 'Reporters';
      case (ParticipantType.Producer): return 'Producers';
      case (ParticipantType.Other): return 'Others';
      default: return 'Unknown';
    }
  }

}
