import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { IComponetByRole, getComponentByRole } from '../../../helpers/component-by-role';
import { Subscription } from 'rxjs';
import { IUserRole } from '../../../models/user.model';
import { Roles } from '../../../models/enums';
import { ClubFixtureDetailsComponent } from '../club-fixture-details/club-fixture-details.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { LeagueFixtureDetailComponent } from '../league-fixture-detail/league-fixture-detail.component';
import { BroadcasterFixtureDetailsComponent } from '../broadcaster-fixture-details/broadcaster-fixture-details.component';

@Component({
  selector: 'app-fixture-details-wrapper',
  templateUrl: './fixture-details-wrapper.component.html',
  styleUrls: ['./fixture-details-wrapper.component.scss']
})
export class FixtureDetailsWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) fixtureRef;
  interval: any;
  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: LeagueFixtureDetailComponent},
    { role: Roles.Admin, component: LeagueFixtureDetailComponent},
    { role: Roles.Broadcaster, component: BroadcasterFixtureDetailsComponent},
    { role: Roles.PLPBroadcaster, component: BroadcasterFixtureDetailsComponent},
    { role: Roles.Club, component: ClubFixtureDetailsComponent}
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>) { }

  ngOnInit() {
    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
        }
      });
  }

  loadComponent(role: Roles) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.fixtureRef.clear();
    const ref = this.fixtureRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userInfo && this.userInfo.closed) {
      this.userInfo.unsubscribe();
    }
  }

}
