import { Component, OnInit } from '@angular/core';
import { IFilmingFixturesResult } from '../../../store/filming/state';
import { BaseComponent } from '../../base-component/base.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetLeagueFilmingFixturesAction, GetFilmingLocationsAction } from '../../../store/filming/actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-league-filming',
  templateUrl: './league-filming.component.html',
  styleUrls: ['./league-filming.component.scss']
})
export class LeagueFilmingComponent extends BaseComponent implements OnInit {

  fixtures: IFilmingFixturesResult;
  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    const dataStream$ = this.store
      .select(s => s.filming.fixtures.items).pipe(
        takeUntil(this.componentDestroyed));

    const dataLoading$ = this.store
      .select(s => s.filming.fixtures.loading).pipe(
        takeUntil(this.componentDestroyed));

    dataStream$.subscribe(res => this.fixtures = res);
    dataLoading$.subscribe(res => this.isSpinner = res);

    this.store.dispatch(new GetLeagueFilmingFixturesAction());
    this.store.dispatch(new GetFilmingLocationsAction());
  }
}
