import { User } from "src/app/models/user.model"
import { IDashboardItemDto } from "src/app/services/bpp.webapi/dashboard.client"
import { ExtendedMessageConstructor } from "../constructor/extended-message.constructor"
import { SimplifiedMessageConstructor } from "../constructor/simplified-message.constructor"
import { IDashboardMessage } from "./dashboard-message.interface"
import { TranslateService } from "@ngx-translate/core"

export interface MessageConstructor {
    createMessage(s: IDashboardItemDto): IDashboardMessage
}

export class MessageConstructorFactory {
    static getMessageConstructor(simplified:boolean,user:User,translateService:TranslateService):MessageConstructor {
        if (simplified){
            return new SimplifiedMessageConstructor(user,translateService);
        }
        else {
            return new ExtendedMessageConstructor(user,translateService);
        }
    }
}