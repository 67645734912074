import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitlebarComponent } from './titlebar/titlebar.component';
import { SidebarComponent, SidebarBodyComponent, SidebarFooterComponent, SidebarHeaderComponent } from './sidebar/sidebar.component';
import { SelectComponent, SelectOptionsComponent, SelectOptionComponent, SelectedValueComponent } from './select/select.component';
import { LoginInfoComponent } from './login/login-info.component';
import { OneColumnPageComponent } from './pages/one-column-page/one-column-page.component';
import { RouterModule } from '@angular/router';
import { MenuFilterPipe } from './menu/menu-filter.pipe';
import { TwoColumnPageComponent } from './pages/two-column-page/two-column-page.component';
import { MenuBaseComponent } from './menu/menu-base.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapAlertComponent } from './ng-bootstrap-alert/bootstrap-alert.component';
import { BootstrapAlertService } from './ng-bootstrap-alert/bootstrap-alert.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  declarations: [TitlebarComponent, SidebarComponent, SidebarBodyComponent, SidebarFooterComponent, SidebarHeaderComponent,
    SelectComponent, SelectOptionsComponent, SelectOptionComponent, SelectedValueComponent, LoginInfoComponent,
    OneColumnPageComponent, TwoColumnPageComponent, MenuBaseComponent, MenuFilterPipe, BootstrapAlertComponent],
  exports: [TitlebarComponent, SidebarComponent, SidebarBodyComponent, SidebarFooterComponent, SidebarHeaderComponent,
    SelectComponent, SelectOptionsComponent, SelectOptionComponent, SelectedValueComponent, LoginInfoComponent,
    OneColumnPageComponent, TwoColumnPageComponent, MenuBaseComponent, BootstrapAlertComponent],
  providers: [
    BootstrapAlertService,
  ]

})
export class WidgetsModule { }
