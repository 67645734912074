import { takeUntil, filter } from 'rxjs/operators';
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import * as moment from 'moment';
import { ConfigService } from '../../../../config/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { StatusHelper } from '../../../../helpers/statuses-helper';
import { ReportService } from '../../../../services/report.service';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { NotesService } from '../../../../services/notes.service';
import { AlertService } from '../../../../services/alert.service';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { RejectConfirmComponent } from '../../../common/reject-confirm/reject-confirm.component';
import { isNonEmptyNotes } from '../../../../helpers/league.helper';
import { BaseComponent } from '../../../base-component/base.component';
import { getCameraOptionTextId } from '../../../../helpers/request.helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  IAdditionalContentFixtureDetailsDto,
  IRequestDetailsDto, NoteDto,
  ParticipantDto,
  ParticipantType,
  RequestDetailsDto,
  RequestStatus,
  UpdateAdditionalContentSessionRequestDto,
} from '../../../../services/bpp.webapi/client';
import {UpdateClubContentSessionRequestAction} from '../../../../store/additional-content/actions';

@Component({
  selector: 'app-club-content-session-requests-league',
  templateUrl: './club-content-session-requests-league.component.html'
})
export class ClubContentSessionRequestsLeagueComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() additionalContent: IAdditionalContentFixtureDetailsDto;
  participantsTypes  = [ParticipantType.Reporter, ParticipantType.Camera, ParticipantType.Producer, ParticipantType.Other];

  constructor(private config: ConfigService,
              private activatedRoute: ActivatedRoute,
              private appInsightsService: AppInsightClientService,
              private store: Store<AppState>,
              private modalService: NgbModal,
              private router: Router,
              private alertService: AlertService,
              private translationService: TranslateService,
              private notesService: NotesService,
              private reportService: ReportService) {
    super();
    this.isLog = config.isDebug();

  }

  ngOnInit() {
    this.additionalContent.requests.forEach(x => {
      if (x.leagueNote === undefined || x.leagueNote === null) {
        x.leagueNote = new NoteDto();
        x.leagueNote.date = moment();
      }
    });
  }

  isCompletedEvent(additionalContentEvent) {
    const result = StatusHelper.isCompeletedTrainingDetails(additionalContentEvent, moment().utc());
    return result;
  }

  approveRequest(request: RequestDetailsDto) {
    this.isSpinner = true;
    const status = StatusHelper.getRequestStatusText(RequestStatus.Approved);
    const eventName = 'Approve ICS Request (Club Content Session) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Approved);
    this.store.dispatch(new UpdateClubContentSessionRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  rejectRequest(request: RequestDetailsDto) {
    if (isNonEmptyNotes(request.leagueNote)) {
      this.confirmedRequestReject(request);
    } else {
      const modalRef = this.modalService.open(RejectConfirmComponent);
      const instance = modalRef.componentInstance as RejectConfirmComponent;
      return instance.submit
        .subscribe((result: boolean) => {
          if (result) {
            this.confirmedRequestReject(request);
          }
        });
    }
  }

  confirmedRequestReject(request: RequestDetailsDto) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Reject);
    const eventName = 'Reject ICS Request (Club Content Session) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Reject);
    this.isSpinner = true;
    this.store.dispatch(new UpdateClubContentSessionRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  onUnReject(request: RequestDetailsDto) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Submitted);
    const eventName = 'Unreject ICS Request (Club Content Session) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Submitted);
    this.isSpinner = true;
    this.store.dispatch(new UpdateClubContentSessionRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  transformToUpdateRequestModel(request, status) {
    const updateModel = new UpdateAdditionalContentSessionRequestDto();
    updateModel.init({
      id: request.id,
      eventId: this.additionalContent.eventId,
      status: status,
      participants: this.getParticipants(request),
      leagueNote: request.leagueNote,
      additionalContentType: this.additionalContent.type
    });
    return updateModel;
  }

  getParticipants(request): Array<ParticipantDto> {
    const result = new Array<ParticipantDto>();
    for (const item of request.participants) {
      result.push(item);
    }

    return result;
  }

  getRequestStatusText(status: RequestStatus) {
    return StatusHelper.getRequestStatusText(status);
  }

  setIsOpen(evt) {
    const request = this.additionalContent.requests.find(x => x.id === evt.panelId);
    (<any>request).isViewPanelOpen = evt.nextState;
  }

  participantsExists(participants, type) {
    if (participants === undefined) {
      return false;
    }
    return participants.filter(x => x.type === type).length > 0;
  }

  getParcipicantsTypeString(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'Camera Operator';
      case (ParticipantType.Reporter): return 'Reporter';
      case (ParticipantType.Producer): return 'Producers';
      case (ParticipantType.Other): return 'Others';
      default: return 'Unknown';
    }
  }

  getRequestsReport() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Club Content Session Requests Report (League)', {
      eventId: this.additionalContent.eventId
    });
    this.reportService.getTrainingRequestsReport(this.additionalContent.eventId)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = 'OpenClubContentSessionCallSheet.docx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  getCameraOption(request: IRequestDetailsDto) {
    return this.translationService.instant(getCameraOptionTextId(request.cameraOperator));
  }
}
