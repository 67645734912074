import { InterviewsRequestDto, NoteDto, ParticipantType, CreateInterviewsRequestDto, ICreateInterviewsRequestDto, RequestStatus, CameraOperator } from '../services/bpp.webapi/client';
import { FxRequest, initSection, ERequestType, IRequestSection } from '../store/fx-request/state';
import { getLastSavedText } from '../helpers/common';
import { StatusHelper } from '../helpers/statuses-helper';
import { IMapper } from './common.mapper';

export class InterviewRequestMapper implements IMapper<FxRequest, InterviewsRequestDto> {

    static getEmptyRequest() {
        const request = new FxRequest();
        request.type = ERequestType.Interview;
        request.broadcasterNote = new NoteDto();
        // request.leagueNote = new NoteDto();
        return request;
    }

    mapModel(dto: InterviewsRequestDto): FxRequest {
        const request: FxRequest = new FxRequest();
        request.type = ERequestType.Interview;
        request.id = dto.interviewRequestId;
        const p = dto.requestParticipant || [];
        request.companyId = dto.companyId;
        request.companyName = dto.broadcaster;
        request.status = dto.status;
        request.id = dto.interviewRequestId;
        request.broadcasterNote = dto.broadcasterNote || new NoteDto();
        request.leagueNote = dto.leagueNote || new NoteDto();
        request.lastSaved = getLastSavedText(dto.updatedOnUtc, dto.updatedBy, StatusHelper.getRequestStatusText(dto.status));
        request.date = dto.updatedOnUtc;
        const sections = request.sections.map(s => initSection(s.key, p.filter(x => x.type === s.sectionType)));
        request.sections = sections;
        request.sections.forEach(s => {
            switch (s.sectionType) {
                case (ParticipantType.Camera): { 
                    s.value = dto.isCamerasEnabled; 
                    s.cameraOperator = dto.cameraOperator;
                    break; 
                }
                case (ParticipantType.Other): { s.value = dto.isOthersEnabled; break; }
                case (ParticipantType.Producer): { s.value = dto.isProducersEnabled; break; }
                case (ParticipantType.Reporter): { s.value = dto.isReportersEnabled; break; }
            }
        });
        request.note = '';
        request.lastSaved = getLastSavedText(dto.updatedOnUtc, dto.updatedBy, RequestStatus[dto.status]);
        return request;
    }

    mapDto(model: FxRequest): InterviewsRequestDto {
        const dto = new InterviewsRequestDto();

        const requestParticipant = [];
        
        model.sections.forEach(s => {
            if (s.require && s.items) {
                s.items.forEach(item => requestParticipant.push(item));
            }
        });

        dto.init({
            interviewRequestId: model.id,
            requestParticipant,
            status: model.status,
            companyId: model.companyId,
            leagueNote: model.leagueNote,
            broadcasterNote: model.broadcasterNote
        } as ICreateInterviewsRequestDto);

        model.sections.forEach(s => {
            switch (s.sectionType) {
                case (ParticipantType.Camera): { 
                    dto.isCamerasEnabled = s.value; 
                    dto.cameraOperator =s.cameraOperator;
                   
                }
                break; 
                case (ParticipantType.Other): { dto.isOthersEnabled = s.value; break; }
                case (ParticipantType.Producer): { dto.isProducersEnabled = s.value; break; }
                case (ParticipantType.Reporter): { dto.isReportersEnabled = s.value; break; }
            }
        });

        return dto;
    }


}


