import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../base-component/base.component';

@Component({
	selector: 'app-matches-configuration',
	templateUrl: './matches-configuration.component.html',
	styleUrls: []
})
export class MatchesConfigurationComponent extends BaseComponent implements OnInit, OnDestroy {

	Roles = Roles;
	role: Roles;

	constructor(private store: Store<AppState>) {
		super();
	}

	ngOnInit() {
		this.store.select(e => e.user)
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(e => {
				this.isSpinner = e.loading;
				if (!e.loading && e.currentUser) {
					this.role = e.currentUser.role;
				}
			});
	}
}
