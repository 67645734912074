import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {Roles} from 'src/app/models/enums';
import {AppState} from 'src/app/store/app.state';
import {BaseComponent} from '../../base-component/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateAdditionalContentEventAction, GetAdditionalContentEventByIdAction, UpdateAdditionalContentEventAction} from '../../../store/additional-content/actions';
import {User} from '../../../models/user.model';
import {AdditionalContentType, ClubDto, EventStatus, IAdditionalContentFixtureDetailsDto, NoteDto, PlayerDto} from '../../../services/bpp.webapi/client';
import {Subscription} from 'rxjs';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {AppInsightClientService} from '../../../services/app-insight.service';
import {AdditionalContentMapper} from '../../../mappers/additional-content.mapper';
import {AlertService} from '../../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../config/config.service';
import * as helper from '../../../helpers/common';
import {DateBinder} from '../dateBinder';
import {GetPlayerListAction} from '../../../store/bpp/actions';
import {mapAdditionalContentType, mapAdditionalContentTypeUrl} from '../../../helpers/common';
import * as moment from 'moment';

@Component({
  selector: 'app-additional-content-details-club',
  templateUrl: './additional-content-details-club.component.html'
})
export class AdditionalContentDetailsClubComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;
  isLog = true;
  isLoading = true;
  isReadOnly = true;
  user: User;
  userRole: Roles;
  clubId;
  additionalContent: IAdditionalContentFixtureDetailsDto;
  additionalContentId: string;
  additionalContentType: AdditionalContentType;
  additionalContentSubscription: Subscription;
  players = new Array<PlayerDto>();
  mapper: AdditionalContentMapper;
  club: ClubDto;
  lastSaved: string;
  dateBinder: DateBinder;
  features: any;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
              private route: ActivatedRoute,
              private appInsightsService: AppInsightClientService,
              private alertService: AlertService,
              private translationService: TranslateService,
              private router: Router,
              private config: ConfigService) {
    super();
    this.isLog = this.config.isDebug();
    this.features = this.config.get("features.additionalContent");
    this.mapper = new AdditionalContentMapper();
    if (this.config.hasFeature('environment.lfp')){
      this.isLfp= true;
    }
  }

  ngOnInit() {
    this.isLog = true;

    this.store
      .select(e => e.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
          this.club = new ClubDto({ id: parseInt(state.currentUser.companyId, 10), name: state.currentUser.companyName });
        }
      });

    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

    this.route.params.subscribe(
      params => {

        const eventType = mapAdditionalContentType(params['type'].toLowerCase(), !!(this.features && this.features.alternativeNames));
        if (eventType === AdditionalContentType.PreSeason || eventType === AdditionalContentType.MidSeason) {
          this.store.dispatch(new GetPlayerListAction());
        }

        this.additionalContentSubscription = this.store
          .select(e => e.additionalContent && e.additionalContent.additionalContentDetails[params['id']])
          .pipe(takeUntil(this.componentDestroyed), filter(r => r !== undefined))
          .subscribe(res => {
            if (res) {
              this.isSpinner = false;
              if (this.isLog) { console.log('GetAdditionalContentEventDetails: ', res); }

              if (!res.clubNote) {
                res.clubNote = new NoteDto();
              }
              this.additionalContent = res;
              if (this.additionalContent !== undefined) {
                this.dateBinder = new DateBinder(this.additionalContent.start, this.additionalContent.arrival, this.config.get('defaultTimeZone'));
              }

              this.lastSaved = helper.getLastSavedText(this.additionalContent.updatedOn, this.additionalContent.updatedByName, StatusHelper.getStatusText(this.additionalContent.status));
              this.isLoading = false;
            }
          });

        this.isSpinner = true;
        this.store.dispatch(new GetAdditionalContentEventByIdAction(params['id'], eventType));
      });
  }

  saveOrSubmitEvent(status) {
    this.dateBinder.bindToDetailsModel(this.additionalContent);
    if (!this.validateModel()) {
      return;
    }
    this.isSpinner = true;
    this.appInsightsService.logEvent('Create additional content (club)', {
      trainingDate: this.additionalContent.start.format('YYYY/MM/dd HH:mm'),
      status: StatusHelper.getStatusText(status)
    });
    this.additionalContent.status = status;
    if (status === EventStatus.Unknown) {
      const model = this.mapper.create(this.additionalContent);
      model.status = status;
      this.store.dispatch(new CreateAdditionalContentEventAction(model));
    } else {
      const model = this.mapper.update(this.additionalContent);
      this.store.dispatch(new UpdateAdditionalContentEventAction(model));

      this.store.select(e => e.additionalContent).pipe(
        takeUntil(this.componentDestroyed), filter(r => r !== undefined))
        .subscribe(res => {
          if (res.loading) {
          } else {
            this.isSpinner = false;
            this.isReadOnly = true;
            this.router.navigate([`additional-content/${mapAdditionalContentTypeUrl(this.additionalContent.type, !!(this.features && this.features.alternativeNames))}/detail/${this.additionalContent.eventId}`]);
          }
        });
    }
  }

  private validateModel(): boolean {
    if (this.additionalContent.location === undefined || this.additionalContent.location.locationId === undefined) {
      this.translationService.get('invalidAdditionalContentLocation').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    if (this.isLfp && (this.additionalContent.duration === undefined || this.additionalContent.duration===moment.duration(0,'milliseconds'))){
      this.translationService.get('invalidDuration').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    return true;
  }

  startEditing() {
    this.isReadOnly = false;
  }

  cancelEditing() {
    this.isReadOnly = true;
  }

  goBack() {
    this.router.navigate([`additional-content`]);
  }
}
