import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ContentSessionTypeClient } from 'src/app/services/bpp.webapi/client';

@Component({
  selector: 'app-state-control',
  templateUrl: './state-control.component.html',
  styleUrls: ['./state-control.component.scss']
})
export class StateControlComponent implements OnInit {

  @Input() color: string;
  @Input() content: string;
  @ViewChild('stateIcon',null) stateIcon : ElementRef;

  constructor() { }

  ngOnInit() {

  }

  
  ngAfterViewInit() {
    // console.log('content',this.stateIcon.nativeElement)
    // this.stateIcon.nativeElement.style.setProperty('--state-content',this.content);
  }

}
