import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MatchExtInfoDto } from 'src/app/services/bpp.webapi/client';
import { BaseComponent } from '../../base-component/base.component';
import {MatchItem} from './match-item';

@Component({
	selector: 'app-matches-list',
	templateUrl: './matches-list.component.html',
	styleUrls: ['./matches-list.component.scss']
})
export class MatchesListComponent extends BaseComponent implements OnInit {
	@Input()
	data: MatchExtInfoDto[];
	@Input()
	editAllowed = false;

  @Output() saveItem: EventEmitter<MatchItem> = new EventEmitter<MatchItem>();

	constructor() {
		super();
	}

	ngOnInit(): void {
	}

  editMatch(item: MatchItem) {
    item.edit = true;
  }

  rollback(item: MatchItem): void {
    item.rollback();
    item.edit = false;
  }

  saveEntry(item: MatchItem): void {
    console.log('saveEntry');
    item.commit();
    this.saveItem.emit(item);
  }
}
