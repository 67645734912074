import { Action } from '@ngrx/store';
import {MatchDetailsDto, MatchExtInfoDto} from '../../services/bpp.webapi/client';

export const GET_MATCHES_LIST = 'get_matches_list';
export const GET_MATCHES_LIST_SUCCESS = 'get_matches_list_success';
export const GET_MATCHES_LIST_ERROR = 'get_matches_list_error';

export const UPDATE_MATCH = 'update_match';
export const UPDATE_MATCH_SUCCESS = 'update_match_success';
export const UPDATE_MATCH_ERROR = 'update_match_error';

export class GetMatchesListAction implements Action {
    readonly type = GET_MATCHES_LIST;
    constructor() { }
}

export class GetMatchesListActionSuccess implements Action {
    readonly type = GET_MATCHES_LIST_SUCCESS;
    constructor(public payload: Array<MatchExtInfoDto>) { }
}

export class GetMatchesListActionError implements Action {
    readonly type = GET_MATCHES_LIST_ERROR;
    constructor(public payload: any) { }
}

export class UpdateMatchAction implements Action {
  readonly type = UPDATE_MATCH;

  constructor(public item: MatchDetailsDto) { }
}

export class UpdateMatchSuccessAction implements Action {
  readonly type = UPDATE_MATCH_SUCCESS;

  constructor() { }
}

export class UpdateMatchErrorAction implements Action {
  readonly type = UPDATE_MATCH_ERROR;

  constructor(public payload: any) { }
}

export type All = GetMatchesListAction | GetMatchesListActionError | GetMatchesListActionSuccess | UpdateMatchAction | UpdateMatchErrorAction | UpdateMatchSuccessAction;
