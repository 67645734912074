import { Component, OnInit, ViewChild, ViewContainerRef, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { Roles } from '../../../../models/enums';
import { IComponetByRole, getComponentByRole } from '../../../../helpers/component-by-role';
import { UnderConstructComponent } from '../../../under-construct/under-construct.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { ConfigService } from '../../../../config/config.service';
import { Subscription } from 'rxjs';
import { ClubPersonsReportComponent } from '../club-persons-report/club-persons-report.component';
import { LeaguePersonsReportComponent } from '../league-persons-report/league-persons-report.component';

@Component({
  selector: 'app-persons-report-wrapper',
  templateUrl: './persons-report-wrapper.component.html'
})
export class PersonsReportWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: true }) trainingRef;
  interval: any;
  userInfo: Subscription;
  role: Roles;

  private components: IComponetByRole[] = [
    { role: Roles.League, component: LeaguePersonsReportComponent},
    { role: Roles.Admin, component: LeaguePersonsReportComponent},
    { role: Roles.Broadcaster, component: UnderConstructComponent},
    { role: Roles.PLPBroadcaster, component: UnderConstructComponent},
    { role: Roles.Club, component: ClubPersonsReportComponent}
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private store: Store<AppState>, private config: ConfigService) { }

  ngOnInit() {
    this.userInfo = this.store
      .select(state => state.user.currentUser)
      .subscribe(u => {
        if (u.role) {
          this.role = u.role;
          this.loadComponent(this.role);
          if (this.config.isDebug()) {
            console.log('Loading trainings for role: ', this.role);
          }
        }
      });
  }

  loadComponent(role) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(getComponentByRole(this.components, role));
    this.trainingRef.clear();
    const ref = this.trainingRef.createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.userInfo && this.userInfo.closed) {
      this.userInfo.unsubscribe();
    }
  }

}
