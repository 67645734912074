import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { Roles } from 'src/app/models/enums';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppInsightClientService } from 'src/app/services/app-insight.service';
import { PlayerDto, EventStatus, IRequestTotal, NoteDto, RequestStatus, InterviewEventDetailsDto, MatchInterviewEventDetailsDto, IInterviewEventDetailsDto, RequestDetailsDto, TeamDto } from 'src/app/services/bpp.webapi/client';
import { NotesService } from 'src/app/services/notes.service';
import { ReportService } from 'src/app/services/report.service';
import { ValidationService } from 'src/app/services/validation.service';
import { AppState } from 'src/app/store/app.state';
import { GetPlayerListByTeamAction } from 'src/app/store/bpp/actions';
import { IFixtureHeader } from '../../fixture-details/fixture-header/fixture-header.component';
import * as _ from 'lodash';
import * as momentTz from 'moment-timezone'
import * as helper from '../../../helpers/common';
import { ClubInterviewMapper } from 'src/app/mappers/club-interview-event.mapper';
import { IClubInterviewAccordionItem, IClubInterviewIdentity } from 'src/app/models/club-interview-accordion-item';
import { GetClubInterviewDetailsForLeagueBroadcasterAction, UpdateClubInterviewEventAction, UpdateClubInterviewRequestLeagueAction, UpdateClubInterviewRequestLeagueNoteAction } from 'src/app/store/club-interview/actions';
import { TO_BE_SAVED_TEXT } from 'src/app/models/constants';
import { ApproveRejectConfirmComponentLfp } from '../../common/reject-confirm/lfp/approve-reject-confirm.component';

@Component({
  selector: 'app-club-interview-league',
  templateUrl: './club-interview-league.component.html',
  styleUrls: ['./club-interview-league.component.scss']
})
export class ClubInterviewLeagueComponent implements OnInit, OnDestroy {

  private interviewSubscription: Subscription;
  private playersSubscription: Subscription;
  private eventSubscription: Subscription;
  private componentDestroyed: Subject<any> = new Subject();


  isLog = false;
  isSpinner = false;
  isReadOnly = true;
  isEditMessage = true;
  isAdminLeagueMode: boolean;
  mapper: ClubInterviewMapper;
  isPrivateNoteEditable: any = {};
  adminLeagueNoteMessages: any = {};
  requestmap: { [reuqestId: string]: boolean; } = {};
  userRole: Roles;
  user: User;

  matchId: any;
  header: IFixtureHeader;
  originEvents: MatchInterviewEventDetailsDto;
  accordionItems: IClubInterviewAccordionItem[];
  players: { [teamId: string]: PlayerDto[] } = {};
  requests: any;
  timezone: string;
  activeIds: string[] = [];

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private config: ConfigService,
    private appInsightsService: AppInsightClientService,
    private router: Router,
    private alertService: AlertService,
    private translationService: TranslateService,
    private notesService: NotesService,
    private validator: ValidationService,
    private reportService: ReportService,
    private modalService: NgbModal) {
    this.timezone = config.get('defaultTimeZone');
    this.isLog = this.config.isDebug();
    this.mapper = new ClubInterviewMapper(config);
  }

  ngOnInit() {

    this.isSpinner = true;

    this.route.params.pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.store.select(state => state.user.currentUser).pipe(
          takeUntil(this.componentDestroyed))
          .subscribe(user => {
            this.user = user;
            this.isAdminLeagueMode = this.user.role === Roles.Admin;
            this.userRole = user.role;
            if (user && (user.role === Roles.League || user.role === Roles.Admin)) {
              this.matchId = params['id'];
              if (this.matchId) {
                this.accordionItems = [];
                this.originEvents = undefined;
                this.store.dispatch(new GetClubInterviewDetailsForLeagueBroadcasterAction(this.matchId));
              }
            }
          });
      });

    this.checkSubscriptions();

    this.store.select(state => state.bpp.playersByTeam).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(p => {
        this.players = p;
      });
  }

  createEventCopy(event: IInterviewEventDetailsDto) {
    const eventCopy = new InterviewEventDetailsDto();
    eventCopy.init(event);
    if (this.user.role === Roles.Admin || this.user.role === Roles.League) {
      eventCopy.leagueNote = eventCopy.leagueNote || new NoteDto({
        id: undefined,
        date: undefined,
        status: undefined,
        user: this.user.id,
        userName: `${this.user.givenName} ${this.user.surname}`,
        message: '',
      });
    }
    return eventCopy;
  }


  showApproveBtn(item: IClubInterviewAccordionItem) {
    return (!item.isReadOnly) || (item.isReadOnly && item.model.status !== EventStatus.Approved);
  }

  checkSubscriptions() {

    this.eventSubscription = this.store
      .select(state => state.clubInterviews).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(result => {
        if (!result.loading) {
          const matchEvents = result.detailsForLeagueBroadcaster[this.matchId];
          if (matchEvents && (matchEvents.awayEvent || matchEvents.homeEvent)) {
            if (this.isLog) { console.log('match events', matchEvents); }

            this.originEvents = matchEvents;
            this.header = {
              homeTeam: matchEvents.homeTeam,
              awayTeam: matchEvents.awayTeam,
              matchDate: matchEvents.matchDate
            };

            let eventList: InterviewEventDetailsDto[] = [];
            if (this.originEvents.homeEvent) {
              eventList.push(this.originEvents.homeEvent)
            }
            if (this.originEvents.awayEvent) {

              eventList.push(this.originEvents.awayEvent)
            }

            eventList.forEach((event: InterviewEventDetailsDto) => {
              this.requests = [];
              const identity: IClubInterviewIdentity = {
                matchId: this.matchId,
                eventId: event.eventId,
                lastUpdated: event.updatedOnUtc ? helper.getLastSavedText(event.updatedOnUtc, event.updatedBy, StatusHelper.getStatusText(event.status)) : TO_BE_SAVED_TEXT
              };

              // event.requests.forEach(r => {
              //   if (r.status > 0) {
              //     this.requests.push(r);
              //   }
              // });
              this.store.dispatch(new GetPlayerListByTeamAction([this.originEvents.homeTeam.id, this.originEvents.awayTeam.id]));

              const title = event.clubId === this.originEvents.homeTeam.id ? GetClubName(this.originEvents.homeTeam) : GetClubName(this.originEvents.awayTeam);

              const index = this.accordionItems.findIndex(x => x.id === event.eventId);
              if (index > -1) {
                this.accordionItems[index].status = this.getEventStatusText(event.status);
                this.accordionItems[index].identity = identity;
                this.accordionItems[index].model = this.createEventCopy(event);
                this.accordionItems[index].title = title;
                if (this.accordionItems[index].model.requests) {
                  this.accordionItems[index].model.requests.forEach(c => {
                    this.requestmap[c.id] = false;
                  })
                  console.log('requestmap', this.requestmap)
                }

                // this.accordionItems[index].requestStatistics = requestStatistics;
              } else {
                this.accordionItems.push({
                  id: event.eventId,
                  isOpen: false,
                  isReadOnly: true,
                  model: this.createEventCopy(event),
                  identity,
                  status: this.getEventStatusText(event.status),
                  title,
                  requestStatistics: undefined,
                  isPastEvent: false
                });
                if (this.accordionItems[this.accordionItems.length - 1].model.requests) {
                  this.accordionItems[this.accordionItems.length - 1].model.requests.forEach(c => {
                    this.requestmap[c.id] = false;
                  })
                  console.log('requestmap', this.requestmap)
                }
              }
            });
            this.accordionItems.sort((a, b) => a.title > b.title ? -1 : 1);
            if (this.config.isLfp() && this.accordionItems.length === 1) {
              this.activeIds = ["panel-0"];
              this.accordionItems[0].isOpen = true;
            }

            this.isSpinner = false;
            if (this.isLog) { console.log('accordionItems:', this.accordionItems); }
          }
        } else {
          this.isSpinner = true;
        }
      });
  }

  getRequestStatistics(total: IRequestTotal) {
    const received = total.requested;
    const rejected = total.rejected;
    const approved = total.approved;
    const pending = total.requested - rejected - approved;
    if (received > 0) {
      const s = `${received} requests received: ${pending} pending, ${rejected} rejected, ${approved} approved`;
      return s;
    } else {
      return '';
    }
  }


  setIsOpen(evt) {
    if (evt.panelId) {
      const ids = evt.panelId.split('_')[1];
      if (ids) {
        const itemIndex = ids.split('-')[0];
        const requestIndex = ids.split('-')[1];
        if (this.accordionItems[itemIndex] && this.accordionItems[itemIndex].model.requests && this.accordionItems[itemIndex].model.requests[requestIndex]) {
          this.toggle(this.accordionItems[itemIndex].model.requests[requestIndex].id);
        }
      }
    }
  }

  toggle(id: string) {
    this.requestmap[id] = !this.requestmap[id];
  }

  updateRequestNote(model: { request: RequestDetailsDto, slotId: string }, evt: InterviewEventDetailsDto) {
    this.store.dispatch(new UpdateClubInterviewRequestLeagueNoteAction(
      this.mapper.requestUpdate(model.request, evt),
      this.matchId
    ));
  }

  updateRequest(model: { request: RequestDetailsDto, slotId: string }, evt: InterviewEventDetailsDto) {
    if (this.isLog) { console.log('Request model for Approve/Reject:', model.request); }
    this.store.dispatch(new UpdateClubInterviewRequestLeagueAction(
      this.mapper.requestUpdate(model.request, evt),
      undefined,
      this.matchId
    ));
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    const id = Number($event.panelId.substr(6, $event.panelId.length));
    this.accordionItems[id].isOpen = $event.nextState;
  }

  getEventStatusText(status) {
    return `Event ${StatusHelper.getStatusText(status)}`;
  }

  getRequestStatusText(status: RequestStatus) {
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  cancelEditPrivateNote(model: InterviewEventDetailsDto) {
    this.isPrivateNoteEditable[model.eventId] = false;
    this.adminLeagueNoteMessages[model.eventId] = '';
  }

  editBtnClick(index) {
    this.accordionItems[index].isReadOnly = false;
  }

  rejectBtnClick(index) {
    if (!this.validator.isClubInterviewValid(this.accordionItems[index].model)) {
      return;
    }
    this.showConfirm(index, true);
  }

  isNotDraft(request: RequestDetailsDto) {
    return request.status != RequestStatus.Draft;

  }

  onUpdate(index) {
    if (!this.validator.isClubInterviewValid(this.accordionItems[index].model)) {
      return;
    }
    this.accordionItems[index].isReadOnly = true;
    const updatedModel = this.updateModel(this.accordionItems[index].model, this.accordionItems[index].model.status);
    if (this.isLog) { console.log('Update model:', updatedModel); }
    this.isSpinner = true;
    this.store.dispatch(new UpdateClubInterviewEventAction(updatedModel));
  }

  confirmedReject(index) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Reject);
    const eventName = 'Reject Club Interview (League)';
    const eventId = this.accordionItems[index].model.eventId;
    this.accordionItems[index].isReadOnly = true;

    const updatedModel = this.updateModel(this.accordionItems[index].model, EventStatus.Reject);
    if (this.isLog) { console.log('Reject model:', updatedModel); }

    this.isSpinner = true;
    this.store.dispatch(new UpdateClubInterviewEventAction(updatedModel));
    this.navigateBack();
    this.appInsightsService.logEvent(eventName, { eventId, status });
  }

  confirmedApprove(index) {
    const status = StatusHelper.getStatusText(EventStatus.Approved);
    const eventName = 'Approve Club Interview (League)';
    const eventId = this.accordionItems[index].model.eventId;
    this.appInsightsService.logEvent(eventName, { eventId, status });
    this.accordionItems[index].isReadOnly = true;
    const model = this.updateModel(this.accordionItems[index].model, EventStatus.Approved);
    if (this.isLog) { console.log('Approve model:', model); }
    this.isSpinner = true;
    this.store.dispatch(new UpdateClubInterviewEventAction(model));
  }

  approveBtnClick(index) {
    if (!this.validator.isClubInterviewValid(this.accordionItems[index].model)) {
      return;
    }
    this.showConfirm(index, false);
  }

  cancelBtnClick(index) {
    const event = this.originEvents.homeEvent && this.originEvents.homeEvent.eventId === this.accordionItems[index].id ?
      this.originEvents.homeEvent : this.originEvents.awayEvent;
    this.accordionItems[index].isReadOnly = true;
    this.accordionItems[index].model = this.createEventCopy(event);
  }

  updateModel(interview: IInterviewEventDetailsDto, status: EventStatus): InterviewEventDetailsDto {
    const model = new InterviewEventDetailsDto();
    model.init(interview);
    model.duration = model.duration ? momentTz.duration(model.duration.asMinutes() + momentTz.tz(this.timezone).utcOffset(), 'minutes') : null
    model.status = status;
    return model;
  }

  getRequestsReport(matchId: number, fixtureId: string) {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Club Interview Requests Report (League)', {
      fixtureId: fixtureId,
      userRole: this.user.role,
      userEmail: this.user.email,
      companyId: this.user.companyId,
      companyName: this.user.companyName
    });
    this.reportService.getFixtureRequestsReport(matchId, fixtureId)
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = 'ClubInterviewCallSheet.docx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();

    if (this.interviewSubscription) {
      this.interviewSubscription.unsubscribe();
    }

    if (this.playersSubscription) {
      this.playersSubscription.unsubscribe();
    }

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  showConfirmRequest(model: { request: RequestDetailsDto, slotId: string }, evt: InterviewEventDetailsDto, isReject: boolean) {
    const modalRef = this.modalService.open(ApproveRejectConfirmComponentLfp);
    const instance = modalRef.componentInstance as ApproveRejectConfirmComponentLfp;
    instance.note = model.request.leagueNote ? model.request.leagueNote.message : "";
    instance.isReject = isReject;
    return instance.submit
      .subscribe((result: boolean) => {
        if (result) {
          model.request.leagueNote.message = instance.note;
          this.store.dispatch(new UpdateClubInterviewRequestLeagueAction(
            this.mapper.requestUpdate(model.request, evt),
            model.slotId,
            this.matchId
          ));
        }
      });
  }

  showConfirm(index: number, isReject: boolean) {
    const model = this.accordionItems[index].model;

    const modalRef = this.modalService.open(ApproveRejectConfirmComponentLfp);
    const instance = modalRef.componentInstance as ApproveRejectConfirmComponentLfp;
    instance.note = model.leagueNote ? model.leagueNote.message : "";
    instance.isReject = isReject;
    return instance.submit
      .subscribe((result: boolean) => {
        if (result) {
          model.leagueNote.message = instance.note;
          if (isReject) {
            this.confirmedReject(index);
          }
          else {
            this.confirmedApprove(index);
          }
        }
      });
  }

  navigateBack() {
    this.router.navigate([`club-interviews`]);
  }

  getRequestStyleColor(status: number): string {
    if (status === RequestStatus.Reject || status === RequestStatus.Withdrawn) {
      return 'status-rejected';
    } else if (status === RequestStatus.Submitted || status === RequestStatus.Draft) {
      return 'status-submitted';
    } else if (status === RequestStatus.Approved) {
      return 'status-approved';
    }
    return '';
  }


}
function GetClubName(team: TeamDto) {
  return team.name != null ? team.name : team.officialName;
}



