import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFilmingLocationDto } from '../../../services/bpp.webapi/client';
import { IFilmingLocation, IFilmingLocationItem } from '../../../store/filming/state';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ITimeLimits, IFilmingItemFormConfig } from '../../filming-location/filming-form/filming-form-item/filming-form-item.component';
import { ConfigService } from '../../../config/config.service';
import { getHoursMinutes } from '../../../helpers/date-helper';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import { FilmingWarningModalComponent } from '../filming-warning-modal/filming-warning-modal.component';
import { takeUntil, filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-filming-global-modal',
  templateUrl: './filming-global-modal.component.html',
  styleUrls: ['./filming-global-modal.component.scss']
})
export class FilmingGlobalModalComponent extends BaseComponent implements OnInit {

  @Output() submit: EventEmitter<IFilmingLocation> = new EventEmitter<IFilmingLocation>();
  get isValid() {
    return this.model && this.model.items
      && this.model.items.filter(item => item.invalid).length === 0
      && this.model.items.map(item => this.isValidItemTime(item)).filter(res => !res).length === 0
      && this.model.items.length > 1;
  }

  modelValue: IFilmingLocation;
  @Input() get model() {
    return this.modelValue;
  }
  @Output() modelChange = new EventEmitter<IFilmingLocation>();
  set model(value) {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  locationSource: IFilmingLocationDto[] = [];
  locations: IFilmingLocationDto[] = [];
  submitted = false;
  timeZone: string;
  timeLimits: ITimeLimits;
  maxIndex: number;
  locationFiltering = false;
  itemConfig: IFilmingItemFormConfig = {
    startLabelId: 'filming.startOffset',
    endLabelId: 'filming.endOffset',
    flexDirectionRow: false,
    invertedTimeLogic: true
  };

  constructor(config: ConfigService,
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private modalService: NgbModal) {
    super();
    this.timeZone = config.get('defaultTimeZone');
  }

  ngOnInit() {
    const clubId$ = this.store.select(s => s.user.currentUser)
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(res => res !== undefined),
        map(res => res.companyId));
    const locations$ = this.store.select(s => s.filming.locations.byClubId)
      .pipe(
        filter(res => res !== undefined),
        takeUntil(this.componentDestroyed));

    combineLatest([clubId$, locations$]).pipe(
      map(([clubId, locations]) => locations[clubId]))
      .subscribe(res => {
        if (res) {
          this.maxIndex = res.length - 1;
          this.locationSource = res;
        }
      });

    this.timeLimits = {
      start: {
        min: moment().startOf('day'),
        max: moment().hours(12).minutes(0)
      },
      end: {
        min: moment().startOf('day'),
        max: moment().hours(11).minutes(30)
      }
    };
    
    if (this.modelValue.items.length === 0) {
      this.add();
      this.add();
    } else if (this.modelValue.items.length === 1) {
      this.add();
    } else {
      this.updateLocationSource();
    }
  }

  onSubmit() {
    const modalRef = this.modalService.open(FilmingWarningModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm',
    });
    const instance = modalRef.componentInstance as FilmingWarningModalComponent;
    instance.confirm
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.submit.emit(this.model);
          this.activeModal.close();
        }
      });
  }

  onCancel() {
    this.activeModal.close();
  }

  add() {
    if (this.model.items.length <= this.maxIndex) {
      this.modelValue.items.push({
        startTime: getHoursMinutes(this.timeLimits.start.min, this.timeZone),
        endTime: getHoursMinutes(this.timeLimits.end.max, this.timeZone),
        locationId: 0,
        title: ''
      });
    }
    this.updateLocationSource();
    this.validateModel();
  }

  remove(i: number) {
    if (this.model.items.length > 2) {
      this.model.items.splice(i, 1);
      this.updateLocationSource();
    }
  }

  updateLocationSource() {
    if (this.locationFiltering) {
      const selected = this.model.items.map(item => item.locationId);
      this.locations = this.locationSource.filter(x => !selected.includes(x.locationId));
    } else if (this.locations.length === 0) {
      this.locations = this.locationSource.slice();
    }
  }

  validateModel() {
    this.model.items.forEach(item => {
      item.invalid = item.locationId <= 0 || this.model.items
        .filter(f => f.locationId === item.locationId).length > 1;
    });
  }

  locationChanged() {
    this.updateLocationSource();
    this.validateModel();
  }

  isValidTime(item: IFilmingLocationItem) {
    if (item && item.startTime && item.endTime && item.startTime) {
      return this.isValidItemTime(item);
    }
    return true;
  }

  private isValidItemTime(item) {
    if (item.startTime.hour > item.endTime.hour) {
      return true;
    }
    if (item.startTime.hour === item.endTime.hour) {
      return item.startTime.minute > item.endTime.minute;
    }
    return false;
  }
}
