import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { IFilmingLocationDto } from '../../../services/bpp.webapi/client';
import { IFilmingLocation } from '../../../store/filming/state';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ConfigService } from '../../../config/config.service';
import { ITimeLimits, IFilmingItemFormConfig } from './filming-form-item/filming-form-item.component';
import { getHoursMinutes } from '../../../helpers/date-helper';


@Component({
  selector: 'app-filming-form',
  templateUrl: './filming-form.component.html',
  styleUrls: ['./filming-form.component.scss']
})
export class FilmingFormComponent implements OnInit {
  @Input() locations$: Observable<IFilmingLocationDto[]>;
  @Input() matchDate: Moment;
  modelValue: IFilmingLocation;
  @Input() get model() {
    return this.modelValue;
  }
  @Output() modelChange = new EventEmitter<IFilmingLocation>();
  set model(value) {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  locationSource: IFilmingLocationDto[] = [];
  locations: IFilmingLocationDto[] = [];
  submitted = false;
  timeZone: string;
  timeLimits: ITimeLimits;
  maxIndex: number;

  // Activate this feature for location source filtering
  locationFiltering = false;
  itemConfig: IFilmingItemFormConfig = {
    startLabelId: 'filming.startTime',
    endLabelId: 'filming.endTime',
    flexDirectionRow: true,
    timeHintTextId: 'filming.timeTip'
  };

  constructor(config: ConfigService) {
    this.timeZone = config.get('defaultTimeZone');
  }

  onSubmit() { this.submitted = true; }

  ngOnInit() {
    this.locations$.subscribe(res => {
      if (res) {
        this.maxIndex = res.length - 1;
        this.locationSource = res;
      }
    });

    this.timeLimits = {
      start: {
        min: moment().startOf('day'), // moment.utc(this.matchDate).tz(this.timeZone).add(-5, 'hours'),
        max: moment().endOf('day'), // moment.utc(this.matchDate).tz(this.timeZone).add(-3, 'hours')
      },
      end: {
        min: moment().startOf('day'), // moment.utc(this.matchDate).tz(this.timeZone).add(-5, 'hours'),
        max: moment().endOf('day'), // moment.utc(this.matchDate).tz(this.timeZone).add(-2, 'hours')
      }
    };

    if (this.modelValue.items.length === 0) {
      this.add();
      this.add();
    } else if (this.modelValue.items.length === 1) {
      this.add();
    } else {
      this.updateLocationSource();
    }
  }

  add() {
    if (this.model.items.length <= this.maxIndex) {
      this.modelValue.items.push({
        startTime: getHoursMinutes(this.timeLimits.start.min, this.timeZone),
        endTime: getHoursMinutes(this.timeLimits.end.max, this.timeZone),
        locationId: 0,
        title: ''
      });
    }
    this.updateLocationSource();
    this.validateModel();
  }

  remove(i: number) {
    if (this.model.items.length > 1) {
      this.model.items.splice(i, 1);
      this.updateLocationSource();
    }
  }

  updateLocationSource() {
    if (this.locationFiltering) {
      const selected = this.model.items.map(item => item.locationId);
      this.locations = this.locationSource.filter(x => !selected.includes(x.locationId));
    } else if (this.locations.length === 0) {
      this.locations = this.locationSource.slice();
    }
  }

  validateModel() {
    this.model.items.forEach(item => {
      item.invalid = item.locationId <= 0 || this.model.items
        .filter(f => f.locationId === item.locationId).length > 1;
    });
  }

  locationChanged() {
    this.updateLocationSource();
    this.validateModel();
  }
}
