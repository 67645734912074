
import { takeUntil, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ConfigService } from '../../../config/config.service';
import {
  ITrainingDetailsDto, NoteDto, EventStatus, LocationDto, RequestStatus,
  RequestDetailsDto, UpdateTrainingRequestDto, ParticipantType, ParticipantDto, SaveNoteDto, NoteStatus, CameraOperator, IRequestDetailsDto
} from '../../../services/bpp.webapi/client';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store/app.state';
import { Store } from '@ngrx/store';
import { GetTrainingEventByIdAction, UpdateTrainigEventAction, UpdateTrainingRequestAction } from '../../../store/training/actions';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { GetLocationsAction } from '../../../store/location/actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeagueEditTrainingRequestComponent } from './league-edit-training-request-details/league-edit-training-request.component';
import { TrainingMapper } from '../../../mappers/training.mapper';
import * as helper from '../../../helpers/common';
import * as dateHelper from '../../../helpers/date-helper';
import { DateBinder } from '../dateBinder';
import { ReportService } from '../../../services/report.service';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { User } from '../../../models/user.model';
import { Roles } from '../../../models/enums';
import { NotesService } from '../../../services/notes.service';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { RejectConfirmComponent } from '../../common/reject-confirm/reject-confirm.component';
import { isNonEmptyNotes } from '../../../helpers/league.helper';
import { BaseComponent } from '../../base-component/base.component';
import { getCameraOptionTextId } from '../../../helpers/request.helper';

@Component({
  selector: 'app-league-training-details',
  templateUrl: './league-training-details.component.html'
})
export class LeagueTrainingDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  locations: LocationDto[];
  trainingCopy: ITrainingDetailsDto;
  requestSubscription: Subscription;
  subscription: any;
  editOpened: boolean;
  training: ITrainingDetailsDto;
  clubName;
  clubLogoImagePath = '';
  dataLoaded;
  editState = false;
  minDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() };
  participantsTypes  = [ParticipantType.Reporter, ParticipantType.Camera, ParticipantType.Producer, ParticipantType.Other];
  private mapper: TrainingMapper;
  lastSaved = 'To Be Saved';
  isSpinner: boolean;
  dateBinder;
  user: User;
  isAdminMode = false;
  isPrivateNoteEditable = false;
  adminLeagueNoteMessage: any;
  trainingId: any;
  isLfp: boolean;

  constructor(private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private appInsightsService: AppInsightClientService,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private translationService: TranslateService,
    private notesService: NotesService,
    private reportService: ReportService) {
    super();
    this.isLog = config.isDebug();
    this.mapper = new TrainingMapper();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
          this.isAdminMode = this.user.role === Roles.Admin;
        }
      });
    if (this.isLog) { console.log('League TrainigDetails component'); }

    this.activatedRoute.params.pipe(
      takeUntil(this.componentDestroyed),
      filter(e => e !== undefined))
      .subscribe(params => {
        if (this.isLog) { console.log('Router params', params); }
        this.trainingId = params['id'];
        this.store.dispatch(new GetTrainingEventByIdAction(this.trainingId));
      });

    this.store.select(e => e.trainings)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(result => {
        this.isSpinner = result.loading;
        if (result.loading) {
        } else {
          const res = result.trainingDetails[this.trainingId];
          if (this.isLog) { console.log('GetTrainingEventDetails (league): ', res); }
          if (res) {
            if (res.leagueNote === null || res.leagueNote === undefined) {
              res.leagueNote = new NoteDto();
              res.leagueNote.date = moment();
            }
            if (res.adminLeagueNote === null || res.adminLeagueNote === undefined) {
              res.adminLeagueNote = new NoteDto();
              res.adminLeagueNote.date = moment();
            }
            res.requests.forEach(x => {
              if (x.leagueNote === undefined || x.leagueNote === null) {
                x.leagueNote = new NoteDto();
                x.leagueNote.date = moment();
              }
            });
            this.training = res;
            this.clubName = this.training.club;
            this.clubLogoImagePath = '../../../../assets/teams/48/t' + this.training.clubId + '.png';
            this.dataLoaded = true;
            this.lastSaved = helper.getLastSavedText(this.training.updatedOn, this.training.updatedByName, StatusHelper.getStatusText(this.training.status));
            this.store.dispatch(new GetLocationsAction(this.training.clubId));
          }
        }
      });

    this.store.select(e => e.location)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(loc => {
        if (!loc.loading && loc.items && this.training && this.training.clubId) {
          this.locations = loc.items.filter(x => x.clubId === this.training.clubId);
        }
      });
  }

  generateTrainingName(date: moment.Moment) {
    return `${this.clubName} Training Session: ${date.format('MMMM')}`;
  }

  isCompletedEvent(training) {
    const result = StatusHelper.isCompeletedTrainingDetails(training, moment().utc());
    return result;
  }

  editPrivateNote() {
    this.isPrivateNoteEditable = true;
    this.adminLeagueNoteMessage = this.training.adminLeagueNote.message;
  }

  cancelEditPrivateNote() {
    this.isPrivateNoteEditable = false;
    this.adminLeagueNoteMessage = '';
  }

  savePrivateNote() {
    this.isSpinner = true;
    this.isPrivateNoteEditable = false;
    this.training.adminLeagueNote.message = this.adminLeagueNoteMessage;
    this.adminLeagueNoteMessage = '';
    this.notesService.saveLeagueAdminBPPNote(
      SaveNoteDto.fromJS({
        status: NoteStatus.Draft,
        message: this.training.adminLeagueNote.message,
        objectId: this.training.id
      }))
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          this.translationService.get('NoteUpdated').subscribe((value) => {
            this.alertService.success(value);
          });
          this.training.adminLeagueNote = resp;
        }
      }, (err) => {
        this.isSpinner = false;
        if (this.config.isDebug()) {
          console.log(err);
        }
        this.alertService.error(err);
      });
  }

  approveTraining() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Approve training (League)', {
      trainingId: this.training.id,
      status: StatusHelper.getStatusText(EventStatus.Approved)
    });
    this.training.status = EventStatus.Approved;
    const model = this.mapper.update(this.training);
    if (this.isLog) { console.log('Approve training:', model); }
    this.store.dispatch(new UpdateTrainigEventAction(model));
  }

  rejectTraining() {
    if (isNonEmptyNotes(this.training.leagueNote)) {
      this.confirmedEventReject();
    } else {
      const modalRef = this.modalService.open(RejectConfirmComponent);
      const instance = modalRef.componentInstance as RejectConfirmComponent;
      return instance.submit
        .subscribe((result: boolean) => {
          if (result) {
            this.confirmedEventReject();
          }
        });
    }
  }

  confirmedEventReject() {
    const status = StatusHelper.getStatusText(EventStatus.Reject);
    const eventName = 'Reject ICS (Training) (League)';
    this.training.status = EventStatus.Reject;
    const model = this.mapper.update(this.training);
    if (this.isLog) { console.log('Reject training:', model); }
    this.isSpinner = true;
    this.store.dispatch(new UpdateTrainigEventAction(model));
    this.router.navigate(['trainings']);
    this.appInsightsService.logEvent(eventName, { eventId: this.training.id, status });
  }

  approveRequest(request: RequestDetailsDto) {
    this.isSpinner = true;
    const status = StatusHelper.getRequestStatusText(RequestStatus.Approved);
    const eventName = 'Approve ICS Request (Training) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Approved);
    this.store.dispatch(new UpdateTrainingRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  rejectRequest(request: RequestDetailsDto) {
    if (isNonEmptyNotes(request.leagueNote)) {
      this.confirmedRequestReject(request);
    } else {
      const modalRef = this.modalService.open(RejectConfirmComponent);
      const instance = modalRef.componentInstance as RejectConfirmComponent;
      return instance.submit
        .subscribe((result: boolean) => {
          if (result) {
            this.confirmedRequestReject(request);
          }
        });
    }
  }

  confirmedRequestReject(request: RequestDetailsDto) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Reject);
    const eventName = 'Reject ICS Request (Training) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Reject);
    this.isSpinner = true;
    this.store.dispatch(new UpdateTrainingRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  onUnReject(request: RequestDetailsDto) {
    const status = StatusHelper.getRequestStatusText(RequestStatus.Submitted);
    const eventName = 'Unreject ICS Request (Training) (League)';
    const updateModel = this.transformToUpdateRequestModel(request, RequestStatus.Submitted);
    this.isSpinner = true;
    this.store.dispatch(new UpdateTrainingRequestAction(updateModel));
    this.appInsightsService.logEvent(eventName, { eventId: request.id, status });
  }

  transformToUpdateRequestModel(request, status) {
    const updateModel = new UpdateTrainingRequestDto();
    updateModel.init({
      id: request.id,
      trainingId: this.training.id,
      status: status,
      participants: this.getParticipants(request),
      leagueNote: request.leagueNote
    });
    return updateModel;
  }

  showEdit() {
    this.trainingCopy = this.training;
    // this.trainingCopy.status = 2;
    if (this.training !== undefined) {
      this.dateBinder = new DateBinder(this.trainingCopy.start, this.trainingCopy.arrival, this.config.get('defaultTimeZone'));
    }
    this.editState = true;
  }

  goBack() {
    this.router.navigate([`trainings`]);
  }

  saveEvent(status) {
    this.isSpinner = true;
    this.dateBinder.bindToTraining(this.trainingCopy);
    const model = this.mapper.update(this.trainingCopy);
    model.status = status;
    this.store.dispatch(new UpdateTrainigEventAction(model));
    // this.subscription.unsubscribe();
    this.editState = false;
  }

  cancelEdit() {
    this.editState = false;
  }

  showEditRequest(request: RequestDetailsDto) {
    const modalRef = this.modalService.open(LeagueEditTrainingRequestComponent);
    const initialData = new RequestDetailsDto();
    initialData.init(request);
    modalRef.componentInstance.trainingRequest = initialData;
    modalRef.componentInstance.subscribeToParticipants();
    this.requestSubscription = modalRef.componentInstance.save.subscribe(r => this.saveRequest(r));
  }

  saveRequest(request: UpdateTrainingRequestDto): any {
    this.isSpinner = true;
    const updateModel = new UpdateTrainingRequestDto();
    updateModel.status = RequestStatus.Submitted;
    updateModel.trainingId = this.training.id;
    updateModel.participants = this.getParticipants(request);
    updateModel.id = request.id;
    updateModel.leagueNote = request.leagueNote;
    this.store.dispatch(new UpdateTrainingRequestAction(updateModel));
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

  getParticipants(request): Array<ParticipantDto> {
    const result = new Array<ParticipantDto>();
    for (const item of request.participants) {
      result.push(item);
    }

    return result;
  }

  getRequestStatusText(status: RequestStatus) {
    return StatusHelper.getRequestStatusText(status);
  }

  setIsOpen(evt) {
    const request = this.training.requests.find(x => x.id === evt.panelId);
    (<any>request).isViewPanelOpen = evt.nextState;
  }

  participantsExists(participants, type) {
    if (participants === undefined) {
      return false;
    }
    return participants.filter(x => x.type === type).length > 0;
  }

  getParcipicantsTypeString(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'Camera Operator';
      case (ParticipantType.Reporter): return 'Reporter';
      case (ParticipantType.Producer): return 'Producers';
      case (ParticipantType.Other): return 'Others';
      default: return 'Unknown';
    }
  }

  getRequestsReport() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Training Requests Report (League)', {
      trainingId: this.training.id
    });
    this.reportService.getTrainingRequestsReport(this.training.id)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = 'OpenTrainingCallSheet.docx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  dateChanged(e) {
    this.dateBinder.dateChanged(e);
  }

  getCameraOption(request: IRequestDetailsDto) {
    return this.translationService.instant(getCameraOptionTextId(request.cameraOperator));
  }
}
