import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { getBroadcasterDeadlineStr, getClubInterviewEventType } from 'src/app/helpers/fixture-helper';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { RequestStatus, PlayerDto, MatchInterviewEventDto, InterviewEventDto } from 'src/app/services/bpp.webapi/client';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-club-interview-week-view',
  templateUrl: './club-interview-week-view.component.html'
})
export class ClubInterviewWeekViewComponent extends BaseComponent {

  @Input() matches: Array<MatchInterviewEventDto>;
  @Input() user: any;
  @Input() isBroadcaster = false;

  constructor(private router: Router, private config: ConfigService) {
    super()
  }

  navigate(item: MatchInterviewEventDto) {
    if (item.awayEvent !== undefined || item.homeEvent !== undefined) {
      if (this.isLog) { console.log('Navigated item:', item); }
      this.router.navigate([`club-interviews/detail/${item.matchId}`]);
    } else {
      if (this.isLog) { console.log('Match has no ICS Events'); }
    }
  }

  getDeadline(event: InterviewEventDto) {
    if (event) {
      return getBroadcasterDeadlineStr(event.arrivalUtc, this.config.getBrDeadlineHours());
    } else {
      return undefined;
    }
  }

  getStatusColor(item) {
    return StatusHelper.getLfpStatusColor(item.status);
  }

  getStatusText(event: InterviewEventDto) {
    // const status = event.requestDetails.length > 0 ? event.requestDetails[0].status : 0;
    // if (status === RequestStatus.Unknown) { return ''; }
    // return `Request ${StatusHelper.getRequestStatusText(status)}`;
    return '';
  }

  getPeoplesString(peoples: PlayerDto[]) {
    const list = new Array<string>();
    peoples.forEach(p => {
      if (p) {
        if (this.isManager(p)) {
          list.push(p.officialName + ' ' + p.officialSurname);
        } else {
          list.push(p.shortName);
        }
      }
    });
    return list.join(', ');
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }

  getClubInterviewType(event: any) {
    return event && getClubInterviewEventType(event.type) || '-';
  }

}
