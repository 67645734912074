
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../../../config/config.service';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { IPersonReportDataDto } from '../../../../services/bpp.webapi/client';
import { Subject } from 'rxjs';
import { GetPersonsReportAction } from '../../../../store/report/actions';
import { ReportTableModel } from '../../../common/report-table/report-table-model/model';
import { ReportService } from '../../../../services/report.service';
import * as moment from 'moment';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-club-persons-report',
  templateUrl: './club-persons-report.component.html'
})
export class ClubPersonsReportComponent implements OnInit, OnDestroy {
  reportTableModel: ReportTableModel;
  private isLog: boolean;
  private pageSize = 20;
  private componentDestroyed: Subject<void> = new Subject();
  isSpinner: boolean;
  items: Array<IPersonReportDataDto>;
  currentDate = moment().utc();

  columns: any[] = [
    {
      rowClass: 'text-center',
      headerClass: 'text-center white-bg sticky-header',
      property: 'fullName',
      title: 'Name',
    },
    {
      rowClass: 'text-center',
      headerClass: 'text-center white-bg sticky-header',
      property: 'appCount',
      title: 'Appearances',
    },
    {
      rowClass: 'text-center',
      headerClass: 'text-center white-bg sticky-header',
      property: 'bppCount',
      title: this.translateService.instant('ICSs'),
    }
  ];
  user: any;

  constructor(config: ConfigService,
    private store: Store<AppState>,
    private appInsightsService: AppInsightClientService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private translateService: TranslateService) {
    this.isLog = config.isDebug();
  }

  ngOnInit(): void {
    this.store
    .select(e => e.user).pipe(
    takeUntil(this.componentDestroyed))
    .subscribe(state => {
      if (state.loading) {
      } else if (state.currentUser) {
        this.user = state.currentUser;
      }
    });
    this.store.select(state => state.report).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.isSpinner = false;
          this.items = state.items;
          this.reportTableModel = {
            orderBy: '',
            isAsc: false,
            totalCount: this.items.length,
            takeBy: this.pageSize,
            items: this.items.slice(0, this.pageSize)
          };
        }
      });
    this.isSpinner = true;
    this.store.dispatch(new GetPersonsReportAction(null));
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  loadMore(evt: ReportTableModel) {
    this.reportTableModel.items = this.items.slice(0, this.reportTableModel.items.length + this.reportTableModel.takeBy);
  }

  order(evt: ReportTableModel) {
    this.items = this.items.sort((i1, i2) => {
      if (i1[evt.orderBy] > i2[evt.orderBy]) {
        return evt.isAsc ? 1 : -1;
      }
      if (i1[evt.orderBy] < i2[evt.orderBy]) {
        return evt.isAsc ? -1 : 1;
      }
      return 0;
    });
    this.reportTableModel.items = this.items.slice(0, this.reportTableModel.takeBy);
  }

  getExcel() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Peoples Report (Club)', {});
    this.reportService.getPersonsExcelReport(null)
      .subscribe((resp) => {
        this.isSpinner = false;
        if (resp) {
          const url = window.URL.createObjectURL(resp.data);

          const link = document.createElement('a');
          link.download = 'ClubSummaryReport.xlsx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, (err) => {
        this.isSpinner = false;
      });
  }

  print() {
    this.reportTableModel.items = this.items;
    setTimeout(() => { window.print(); }, 500);
  }
}
