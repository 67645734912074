import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IInterviewDetails, selectInterviewDetailsLoading } from '../../../store/interviews/state';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { CreateInterviewAction } from '../../../store/interviews/actions';
import { ITimePicker } from '../../common/combo-picker/combo-picker.component';
import { ConfigService } from '../../../config/config.service';
import { selectCurrentUserRole, selectUserIsLoading } from '../../../store/user/state';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { BaseComponent } from '../../base-component/base.component';
import { GetInterviewLocationsAction } from '../../../store/location/actions';
import { ValidationService } from '../../../services/validation.service';
import { selectLanguagesByIds, selectLanguagesLoading } from '../../../store/bpp/state';
import { selectLocationById, selectLocationLoading } from '../../../store/location/state';
import { EventStatus, InterviewTypeDto } from '../../../services/bpp.webapi/client';
import { FxRequest } from '../../../store/fx-request/state';
import { getLastSavedText, isGuidNotEmpty } from '../../../helpers/common';
import { getMaxDate, getMinDate } from '../../../helpers/date-helper';
import { InterviewDetailsMapper } from '../../../mappers/interview-details.mapper';
import { GetInterviewTypesAction } from 'src/app/store/interview-types/actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-interview-create-league',
  templateUrl: './interview-create-league.component.html',
  styleUrls: ['./interview-create-league.component.scss']
})
export class InterviewCreateLeagueComponent extends BaseComponent implements OnInit {
  
  @Input() set interviewTypeId(id: string) {
    this.typeId = id;
  };

  isLoading$: Observable<boolean>;
  data: IInterviewDetails;
  broadcasterRequest: FxRequest;
  original: IInterviewDetails;
  lastSaved: string;
  minStart: Subject<ITimePicker> = new Subject();
  maxStart: Subject<ITimePicker> = new Subject();
  minEnd: Subject<ITimePicker> = new Subject();
  maxEnd: Subject<ITimePicker> = new Subject();
  minutesStep = 5;
  isDebug: boolean;
  editMode = false;
  isPastEvent = false;
  minDate: { year: number; month: number; day: number; };
  timeZone: string;
  detailsMapper: InterviewDetailsMapper;
  types: InterviewTypeDto[] = [];
  typeId: any;
  durationEnabled: boolean = false;
  isLfp:boolean;

  _duration: moment.Moment ;


  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    config: ConfigService,
    public activeModal: NgbActiveModal,
    private valiadtor: ValidationService) {
    super();
    this.isDebug = config.isDebug();
    this.timeZone = config.getTimezone();
    this._duration=momentTz().tz(this.timeZone).startOf('day');
    this.detailsMapper = new InterviewDetailsMapper(this.timeZone, config.getSeasonYear());
    this.data = this.detailsMapper.getEmptyModel();
    const features = config.get("features.interviews");
    this.isLfp = config.isLfp();
    if(features && features.duration) {
      this.durationEnabled = true;
    }
  }


  get duration(): moment.Moment {
    return this._duration;
  }

  set duration(value: moment.Moment) {
    this._duration = value;
    if(!this.data) {
      return;
    }
    this.data.duration =moment.duration(value.diff(moment().utc().startOf('day'), 'milliseconds'), 'milliseconds');
  }

  ngOnInit() {
    this.store.dispatch(new GetInterviewTypesAction());
    const mindate = momentTz().utc();
    this.minDate = { year: mindate.year(), month: mindate.month() + 1, day: mindate.date() };
    if (!this.typeId) {
      this.route.params.pipe(takeUntil(this.componentDestroyed)).subscribe(p => {
        this.typeId = p.typeId;
      })
    }

    this.store.select(e => e.interviewTypes)
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe(e => {
      if (e.list) {
        this.types = e.list;
        if(this.typeId && this.types.length > 0) {
          this.data.interviewType = this.types.find(x => x.interviewTypeId === this.typeId);
        }
      }
    });

    this.initLoading();
    
    this.store.dispatch(new GetInterviewLocationsAction());
  }

  initLoading() {
    const dataLoading$ = this.store.select(selectInterviewDetailsLoading());
    const userLoading$ = this.store.select(selectUserIsLoading());
    const locationLoading$ = this.store.select(selectLocationLoading());
    const languagesLoading$ = this.store.select(selectLanguagesLoading());
    this.isLoading$ = combineLatest([dataLoading$, userLoading$, locationLoading$, languagesLoading$]).pipe(
      takeUntil(this.componentDestroyed),
      map(([data, usr, loc, lang]) => data || usr || loc || lang));
  }

  getLastSaved() {
    if (isGuidNotEmpty(this.data.interviewId)) {
      return getLastSavedText(this.data.updatedOnUtc, this.data.updatedBy, EventStatus[this.data.status]);
    } else {
      return 'To be saved';
    }
  }

  goBack() {
    this.router.navigate([`interviews`]);
    this.activeModal.close();
  }

  onLeagueCreate() {
    if (this.valiadtor.isInterviewValid(this.data)) {
      this.data.duration =  this.data.duration ? momentTz.duration(this.data.duration.asMinutes()+momentTz.tz(this.timeZone).utcOffset(),'minutes') :  null
      this.data.status = this.isLfp ? EventStatus.Approved : EventStatus.Submitted;
      this.isLoading$ = of(true);

      this.store.dispatch(new CreateInterviewAction(this.data));

      this.store.select(e => e.interviews).pipe(
        takeUntil(this.componentDestroyed), filter(r => r !== undefined && r.created !== undefined))
        .subscribe(res => {
          if (res.created.loading) {
          } else {
            this.isLoading$ = of(false);
            if (res.created.interviewId !== '') {
              const id = res.created.interviewId;
              res.created.interviewId = '';
              this.router.navigate([`interviews/detail/${id}`]);
            }
          }
        });
    }
  }

  changeLanguages(event) {
    this.data.languages = event;
  }

  // Time picking
  onPickerInit() {
    this.initTimePickers(this.data.arrivalTime);
  }

  dateChanged(event: moment.Moment) {
    this.data.arrivalDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.arrivalTime.hour)
      .minutes(this.data.arrivalTime.minute);
    this.data.startDate = moment.utc(event, 'YYYY-MM-DD HH:mm:ss')
      .hour(this.data.startTime.hour)
      .minutes(this.data.startTime.minute);
  }

  onArrivalTimeChanged(event: ITimePicker) {
    this.minEnd.next({
      hour: event.hour,
      minute: event.minute
    });
    this.data.arrivalDate = moment.utc(this.data.arrivalDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }

  onStartTimeChanged(event: ITimePicker) {
    this.data.startDate = moment.utc(this.data.startDate.hours(event.hour).minutes(event.minute), 'YYYY-MM-DD HH:mm:ss');
  }

  initTimePickers(model?: ITimePicker) {
    this.minStart.next({
      hour: 0,
      minute: 0
    });
    this.maxStart.next({
      hour: 23,
      minute: 59
    });
    if (model && model.hour && model.minute) {
      this.minEnd.next({
        hour: model.hour,
        minute: model.minute
      });
    } else {
      this.minEnd.next({
        hour: 0,
        minute: 0
      });
    }
    this.maxEnd.next({
      hour: 23,
      minute: 59
    });
  }

  onTypeChange(type) {
    this.data.interviewType = JSON.parse(JSON.stringify(type));
  }

  getTypeName(id) {
    const type = this.types.find(x => x.interviewTypeId === id);
    return type ? type.interviewTypeName : '';
  }
}
