import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../base-component/base.component';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-create-additional-content',
  templateUrl: './additional-content-details-create-wrapper.component.html'
})
export class AdditionalContentDetailsCreateWrapperComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;

  isLoading = false;
  isLfp:boolean;

  constructor(private store: Store<AppState>,private config: ConfigService) {
    super();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });
  }
}
