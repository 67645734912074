import { Action } from '@ngrx/store';
import { LocationDto, ICreateLocationDto, ILocationDetailsDto } from '../../services/bpp.webapi/client';


export const LOCATION_API_ERROR_ACTION = 'bpp_location_error_action';
export const GET_LOCATIONS = 'get_locations_action';
export const GET_LOCATIONS_SUCCESS = 'get_locations_success';

export const CREATE_LOCATION = 'create_location_action';
export const CREATE_LOCATION_SUCCESS = 'create_location_action_success';
export const CREATE_LOCATION_ERROR = 'create_location_action_error';

export const UPDATE_LOCATION = 'update_location_action';
export const UPDATE_LOCATION_SUCCESS = 'update_location_action_success';
export const UPDATE_LOCATION_ERROR = 'update_location_action_error';

export const DELETE_LOCATION = 'delete_location_action';
export const DELETE_LOCATION_SUCCESS = 'delete_location_action_success';
export const DELETE_LOCATION_ERROR = 'delete_location_action_error';
export const GET_INTERVIEW_LOCATIONS = 'get-interview-locations-action';
export const GET_INTERVIEW_LOCATIONS_SUCCESS = 'get-interview-locations-action-success';
export const GET_INTERVIEW_LOCATIONS_ERROR = 'get-interview-locations-action-error';

export class GetLocationsAction implements Action {
    readonly type = GET_LOCATIONS;
    constructor(public clubId: number) { }
}

export class GetLocationsSuccessAction implements Action {
    readonly type = GET_LOCATIONS_SUCCESS;
    constructor(public payload: Array<LocationDto>) {
    }
}

export class LocationApiErrorAction implements Action {
    readonly type = LOCATION_API_ERROR_ACTION;
    constructor(public payload: string) { }
}

export class CreateLocationAction implements Action {
    readonly type = CREATE_LOCATION;
    constructor(public payload: ICreateLocationDto) {
    }
}

export class CreateLocationSuccessAction implements Action {
    readonly type = CREATE_LOCATION_SUCCESS;
    constructor(public payload: any) {
    }
}

export class CreateLocationErrorAction implements Action {
    readonly type = CREATE_LOCATION_ERROR;
    constructor(public payload: any) {
    }
}

export class UpdateLocationAction implements Action {
    readonly type = UPDATE_LOCATION;
    constructor(public payload: ILocationDetailsDto) {
    }
}

export class UpdateLocationSuccessAction implements Action {
    readonly type = UPDATE_LOCATION_SUCCESS;
    constructor(public payload: any) {
    }
}

export class UpdateLocationErrorAction implements Action {
    readonly type = UPDATE_LOCATION_ERROR;
    constructor(public payload: any) {
    }
}

export class DeleteLocationAction implements Action {
    readonly type = DELETE_LOCATION;
    constructor(public locationId, public clubId) {
    }
}

export class DeleteLocationSuccessAction implements Action {
    readonly type = DELETE_LOCATION_SUCCESS;
    constructor(public payload: any) {
    }
}

export class DeleteLocationErrorAction implements Action {
    readonly type = DELETE_LOCATION_ERROR;
    constructor(public payload: any) {
    }
}

export class GetInterviewLocationsAction implements Action {
    readonly type = GET_INTERVIEW_LOCATIONS;
    constructor() {}
}
export class GetInterviewLocationsActionSuccess implements Action {
    readonly type = GET_INTERVIEW_LOCATIONS_SUCCESS;
    constructor(public payload: Array<LocationDto>) {}
}
export class GetInterviewLocationsActionError implements Action {
    readonly type = GET_INTERVIEW_LOCATIONS_ERROR;
    constructor(public payload: any) {}
}

export type All = LocationApiErrorAction
    | GetLocationsAction
    | GetLocationsSuccessAction
    | CreateLocationAction
    | CreateLocationSuccessAction
    | CreateLocationErrorAction
    | UpdateLocationAction
    | UpdateLocationSuccessAction
    | UpdateLocationErrorAction
    | DeleteLocationAction
    | DeleteLocationSuccessAction
    | DeleteLocationErrorAction
    | GetInterviewLocationsAction
    | GetInterviewLocationsActionError
    | GetInterviewLocationsActionSuccess;
