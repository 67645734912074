import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base-component/base.component';
import { IFilmingClubMatchesResult, IFilmingLocation } from '../../../store/filming/state';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { GetClubFilmingMatchesAction, GetFilmingLocationsAction, UpdateForAllMatches } from '../../../store/filming/actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilmingGlobalModalComponent } from '../../common/filming-global-modal/filming-global-modal.component';
import { ConfigService } from '../../../config/config.service';
import { takeUntil, filter } from 'rxjs/operators';


@Component({
  selector: 'app-club-filming',
  templateUrl: './club-filming.component.html',
  styleUrls: ['./club-filming.component.scss']
})
export class ClubFilmingComponent extends BaseComponent implements OnInit {

  matches: IFilmingClubMatchesResult;
  globalFilmingLocation: IFilmingLocation;
  filmingModalRef: any;
  hasLongBookingFeature = false;
  constructor(private store: Store<AppState>, private modalService: NgbModal, config: ConfigService) {
    super();
    this.hasLongBookingFeature = config.hasFeature('filmingLongSeason');
  }

  ngOnInit() {
    const dataStream$ = this.store
      .select(s => s.filming.clubMatches.items)
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(f => f !== undefined));

    const dataLoading$ = this.store
      .select(s => s.filming.clubMatches.loading)
      .pipe(takeUntil(this.componentDestroyed));

    dataStream$.subscribe(res => this.matches = res);
    dataLoading$.subscribe(res => this.isSpinner = res);
    this.store.dispatch(new GetClubFilmingMatchesAction());
    this.store.dispatch(new GetFilmingLocationsAction());

    if (!this.globalFilmingLocation) {
      this.globalFilmingLocation = { items: [] };
    }
  }

  onSeasonLongClick() {
    this.filmingModalRef = this.modalService.open(FilmingGlobalModalComponent, {
      backdrop: 'static',
      keyboard: false
    });
    const instance = this.filmingModalRef.componentInstance as FilmingGlobalModalComponent;
    instance.model = this.globalFilmingLocation;
    instance.submit
      .subscribe((result: IFilmingLocation) => {
        if (result) {
          this.saveChanges(result);
        }
      });
  }

  saveChanges(model: IFilmingLocation) {
    this.store.dispatch(new UpdateForAllMatches(model));
  }
}
