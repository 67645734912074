import { LocationTypesState } from './state';
import * as actions from './actions';

export type Action = actions.All;
const defaultState = new LocationTypesState();

export function LocationTypesReducer(state = defaultState, action: Action): LocationTypesState {
    switch (action.type) {
        case actions.GET_LOCATION_TYPES: {
            return {
                ...state,
                loading: true
            };
        }

        case actions.GET_LOCATION_TYPES_SUCCESS: {

            return {
                ...state,
                loading: false,
                items: action.payload
            };
        }

      case actions.GET_AVAILABLE_LOCATION_TYPES: {
        return {
          ...state,
          loading: true
        };
      }

      case actions.GET_AVAILABLE_LOCATION_TYPES_SUCCESS: {

        return {
          ...state,
          loading: false,
          availableItems: action.payload
        };
      }

        case actions.GET_LOCATION_TYPES_ERROR:
        case actions.GET_AVAILABLE_LOCATION_TYPES_ERROR: {
            return {
                ...state,
                loading: false,
                error: true
            };
        }
    }

    return state;
}
