import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as playerHelper from '../../../helpers/player-helper';

@Component({
  selector: 'app-player-info-card',
  templateUrl: './player-info-card.component.html'
})
export class PlayerInfoCardComponent implements OnInit {

  @Input() player;
  @Output() valueChange = new EventEmitter();
  isManager = false;

  constructor() { }

  ngOnInit() {
    if (this.player ) {
      this.isManager = playerHelper.isManager(this.player);
    }
  }

  playerClick() {
    this.valueChange.emit(this.player.id);
  }

}
