import { InterviewService } from '../../services/interviews.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AlertService } from '../../services/alert.service';
import * as actions from './actions';
import { of } from 'rxjs';
import { switchMap, catchError, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class InterviewsEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: InterviewService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetInterviews$ = this.action$.pipe(
    ofType<actions.GetInterviewsAction>(actions.GET_INTERVIEWS_ACTION),
    switchMap(action => this.service.getInterviews(action.includePast).pipe(
      switchMap(res => of(new actions.GetInterviewsActionSuccess(res))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetInterviewsActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetInterviewsActionError(error));
    }));

  @Effect()
  GetInterviewDetails$ = this.action$.pipe(
    ofType<actions.GetInterviewDetailsAction>(actions.GET_INTERVIEW_DETAILS_ACTION),
    switchMap(action => this.service.getInterviewDetails(action.id).pipe(
      switchMap((details) => {
        return of(new actions.GetInterviewDetailsActionSuccess(details));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetInterviewDetailsActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.GetInterviewDetailsActionError(error));
    }));

  @Effect()
  CreateInterview$ = this.action$.pipe(
    ofType<actions.CreateInterviewAction>(actions.CREATE_INTERVIEW_ACTION),
    switchMap(action => this.service.createInterview(action.model).pipe(
      switchMap(res => of(new actions.CreateInterviewActionSuccess(res))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateInterviewActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.CreateInterviewActionError(error));
    }));

  @Effect()
  UpdateInterview$ = this.action$.pipe(
    ofType<actions.UpdateInterviewAction>(actions.UPDATE_INTERVIEW_ACTION),
    switchMap(action => this.service.updateInterview(action.model).pipe(
      switchMap(res => of(new actions.UpdateInterviewActionSuccess(res))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateInterviewActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.UpdateInterviewActionError(error));
    }));

  @Effect()
  CreateUpdateInterviewRequest$ = this.action$.pipe(
    ofType<actions.CreateUpdateInterviewRequestAction>(actions.CREATE_UPDATE_INTERVIEW_REQUEST),
    switchMap(action => this.service.createUpdateInterviewRequest(action.model, action.interviewId).pipe(
      switchMap(res => of(new actions.GetInterviewDetailsAction(action.interviewId))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateUpdateInterviewRequestActionError(error));
      }))),
    catchError(error => {
      this.alertService.error(error);
      return of(new actions.CreateUpdateInterviewRequestActionError(error));
    }));
}
