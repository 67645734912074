import {
  IInterviewDetailsDto, LocationDto, LanguageDto, NoteDto,
  InterviewDetailsDto, IInterviewsLanguageDto,
  EventStatus, InterviewsRequestsInfoDto
} from '../services/bpp.webapi/client';
import { IInterviewDetails } from '../store/interviews/state';
import { getMonthName, getHoursMinutes } from '../helpers/date-helper';
import * as moment from 'moment';
import { IMapper } from './common.mapper';
import { getEmptyGuid } from '../helpers/common';
import { Moment } from 'moment';

export class InterviewDetailsMapper implements IMapper<IInterviewDetails, IInterviewDetailsDto> {
    timeZone: string;
    seasonYear: number;
    constructor(timeZone: string, seasonYear: number) {
        this.timeZone = timeZone;
        this.seasonYear = seasonYear;
    }

    mapModel(dto: IInterviewDetailsDto): IInterviewDetails {
        const location: LocationDto = new LocationDto();
        location.init({
            locationId: dto.locationId,
            title: dto.locationTitle
        });
        const languages = dto.interviewLanguages.map(item => {
            const lang = new LanguageDto();
            lang.init({
                languageId: item.languageId
            });
            return lang;
        });
        const leagueNotes = dto.leagueNote ? dto.leagueNote : new NoteDto();
        const result: IInterviewDetails = {
            interviewId: dto.interviewId,
            interviewType: JSON.parse(JSON.stringify(dto.interviewType)),
            arrivalDate: moment.utc(dto.arrival, 'YYYY-MM-DD HH:mm:ss'),
            startDate: moment.utc(dto.start, 'YYYY-MM-DD HH:mm:ss'),
            arrivalTime: getHoursMinutes(dto.arrival),
            startTime: getHoursMinutes(dto.start),
            leagueNote: leagueNotes,
            status: dto.status,
            interviewee: dto.interviewee,
            languages,
            location,
            updatedOnUtc: dto.updatedOnUtc,
            createdOnUtc: dto.createdOnUtc,
            updatedBy: dto.updatedBy,
            createdBy: dto.createdBy,
            requestsInfo: dto.requestsInfo,
            requestsInfoStr: this.getRequestStatistics(dto.requestsInfo),
            duration: dto.duration
        };
        return result;
    }

    mapDto(model: IInterviewDetails): InterviewDetailsDto {
        const dto: InterviewDetailsDto = new InterviewDetailsDto();
        const languages: IInterviewsLanguageDto[] = model.languages.map(l => ({ languageId: l.languageId }));
        const arrival = moment(model.arrivalDate.hours(model.arrivalTime.hour).minute(model.arrivalTime.minute), 'YYYY-MM-DD HH:mm:ss').utc();
        const start = moment(model.startDate.hours(model.startTime.hour).minute(model.startTime.minute), 'YYYY-MM-DD HH:mm:ss').utc();
        dto.init({
            interviewId: model.interviewId,
            arrival,
            start,
            interviewType: model.interviewType,
            interviewee: model.interviewee,
            locationId: model.location ? model.location.locationId : undefined,
            leagueNote: model.leagueNote,
            status: model.status,
            interviewLanguages: languages,
            duration: model.duration,
        } as IInterviewDetailsDto);
        return dto;
    }

    getEmptyModel() {
        const now = moment.utc();
        const leagueNotes = new NoteDto();
        const location = new LocationDto();
        const arrivalDate = now;
        const startDate = now;
        const details: IInterviewDetails = {
            interviewId: getEmptyGuid(),
            interviewType: undefined,
            arrivalDate,
            startDate,
            arrivalTime: getHoursMinutes(arrivalDate,this.timeZone),
            startTime: getHoursMinutes(startDate,this.timeZone),
            interviewee: '',
            status: EventStatus.Draft,
            languages: [],
            location,
            leagueNote: leagueNotes,
            requests: [],
            updatedOnUtc: moment.utc(),
            createdOnUtc: moment.utc(),
            requestsInfoStr: 'No requests'
        };
        return details;
    }

    private getRequestStatistics(total: InterviewsRequestsInfoDto) {
        if (!total) { return 'No requests'; }
        const received = total.requested;
        const rejected = total.rejected;
        const approved = total.approved;
        const pending = total.requested - rejected - approved;
        if (received > 0) {
            const s = `${received} requests received: ${pending} pending, ${rejected} rejected, ${approved} approved`;
            return s;
        } else {
            return '';
        }
    }
}
