import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import {EventStatus, IAdditionalContentFixtureDto, PlayerDto} from '../../../services/bpp.webapi/client';
import {StatusHelper} from '../../../helpers/statuses-helper';
import {GetAdditionalContentEventsAction} from '../../../store/additional-content/actions';
import {IAdditionalContentListComponentData} from '../additional-content-list/additional-content-list.component';
import {Subscription} from 'rxjs';
import { GetPlayerListAction } from 'src/app/store/bpp/actions';

@Component({
  selector: 'app-additional-content-club',
  templateUrl: './additional-content-club.component.html'
})
export class AdditionalContentClubComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;

  data: IAdditionalContentListComponentData;
  isLoading = false;
  players = new Array<PlayerDto>();

  additionalContentSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    super();

    this.data = {
      preSeasonItems: new Array<IAdditionalContentFixtureDto>(),
      midSeasonItems:  new Array<IAdditionalContentFixtureDto>(),
      trainingGroundItems: new Array<IAdditionalContentFixtureDto>(),
      otherClubItems: new Array<IAdditionalContentFixtureDto>(),
    };
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });

    this.additionalContentSubscription = this.store
      .select(e => e.additionalContent)
      .pipe(takeUntil(this.componentDestroyed), filter(e => e !== undefined && e.result !== undefined))
      .subscribe(data => {
        this.isLoading = data.result.loading;
        if (this.isLog) { console.log('GetAdditionalContentEvents: ', data.result); }
        const { preSeasonItems, midSeasonItems, trainingGroundItems, otherClubItems } = data.result;
        this.data = {
          preSeasonItems,
          midSeasonItems,
          trainingGroundItems,
          otherClubItems
        };
      });

    this.store.select(state => state.bpp.clubPlayers).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state) {
          this.players = state.players;
          if (this.isLog) { console.log('this.players', this.players); }
        }
      });

    this.store.dispatch(new GetPlayerListAction());
    this.store.dispatch(new GetAdditionalContentEventsAction(false, 0));
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }
}
