import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../base-component/base.component";

@Component({
	selector: 'app-broadcaster-team-members',
	templateUrl: './broadcaster-team-members.component.html'
})
export class BroadcasterTeamMemberComponent extends BaseComponent implements OnInit {
	ngOnInit(): void {
	}
}