import * as moment from 'moment';
import {AdditionalContentType} from '../services/bpp.webapi/client';

export const isGuidNotEmpty = (guid) => {

    return guid && guid !== '00000000-0000-0000-0000-000000000000';
};

export const isEmptyGuid = (guid) => {

  return guid && guid === '00000000-0000-0000-0000-000000000000';
};

export function getLastSavedText(updatedOn: moment.Moment, updatedBy: string, status?: string) {
    const updated = moment.utc(updatedOn).local().format('DD/MM/YYYY | HH:mm');
    if (status) {
        return `${updated} by ${updatedBy} with status: ${status}`;
    } else {
        return `${updated} by ${updatedBy}`;
    }
}

export const getEmptyGuid = () => {
    return '00000000-0000-0000-0000-000000000000';
};

export const mapAdditionalContentType = (urlType: string, alternativeName: boolean = false): AdditionalContentType => {
  if(alternativeName) {
    return mapAdditionalContentTypeAlternative(urlType);
  } else {
    return mapAdditionalContentTypeStandard(urlType)
  }
};

export const mapAdditionalContentTypeUrl = (type: AdditionalContentType, alternativeName: boolean = false): string => {
  if(alternativeName) {
    return mapAdditionalContentTypeUrlAlternative(type);
  } else {
    return mapAdditionalContentTypeUrlStandard(type)
  }
};

export const mapAdditionalContentTypeStandard = (urlType: string): AdditionalContentType => {
  const types = {
    'pre-season': AdditionalContentType.PreSeason,
    'mid-season': AdditionalContentType.MidSeason,
    'club': AdditionalContentType.Club,
    'training-ground': AdditionalContentType.TrainingGround,
  };

  return types[urlType];
};

export const mapAdditionalContentTypeUrlStandard = (type: AdditionalContentType): string => {
  const urls: { [value in AdditionalContentType]: string} = {
    0 : 'pre-season',
    1 : 'mid-season',
    2 : 'club',
    3 : 'training-ground',
  } ;
  return urls[type];
};


export const mapAdditionalContentTypeAlternative = (urlType: string): AdditionalContentType => {
  const types = {
    'media-days': AdditionalContentType.PreSeason,
    'ligue-2': AdditionalContentType.MidSeason,
    'club': AdditionalContentType.Club,
    'another': AdditionalContentType.TrainingGround,
  };

  return types[urlType];
};

export const mapAdditionalContentTypeUrlAlternative = (type: AdditionalContentType): string => {
  const urls: { [value in AdditionalContentType]: string} = {
    0 : 'media-days',
    1 : 'ligue-2',
    2 : 'club',
    3 : 'another',
  } ;
  return urls[type];
};