import { OnDestroy, OnInit, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { AppInsightClientService } from '../../../../services/app-insight.service';
import { ReportService } from '../../../../services/report.service';
import { ReportTableModel } from '../../../common/report-table/report-table-model/model';
import { Subject } from 'rxjs';
import { ContentSessionTypeClient, ContentSessionTypeDataDto, IContentSessionTypeDataDto } from '../../../../services/bpp.webapi/client';
import { GetContentSessionTypeReportAction } from '../../../../store/report/actions';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-content-session-types-report',
  templateUrl: './content-session-types-report.component.html'
})
export class ContentSessionTypesReportComponent implements OnInit, OnDestroy {
  reportTableModel: ReportTableModel;
  isSpinner: boolean;
  columns: any[];
  user: any;
  items: Array<IContentSessionTypeDataDto>;
  private componentDestroyed: Subject<void> = new Subject();
  @ViewChild('imageColumn', { static: true }) imageColumn;

  constructor(private store: Store<AppState>,
    private appInsightsService: AppInsightClientService,
    private config: ConfigService,
    private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.store
      .select(e => e.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });

    this.store.select(state => state.report).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading || !state.contentSessionTypeItems) {
          return;
        }
        console.log(state.contentSessionTypeItems)
        this.columns =this.createColumns(state.contentSessionTypeItems[0] ? state.contentSessionTypeItems[0].data : null)

        this.isSpinner = false;
        this.items = state.contentSessionTypeItems.map(o=>this.deconstructData(o));
        this.reportTableModel = {
          orderBy: '',
          isAsc: false,
          totalCount: this.items.length,
          takeBy: this.items.length,
          items: this.items
        };
      });

    this.isSpinner = true;
    this.store.dispatch(new GetContentSessionTypeReportAction());
  }

  createColumns(items: Record<string,number>): any[] {
    if (!items){
      return;
    }
    let baseColumns = [{
      rowClass: 'text-right',
      headerClass: 'text-right white-bg sticky-header',
      template: this.imageColumn,
      property: 'clubId',
      title: '',
    },
    {
      rowClass: 'text-left',
      headerClass: 'text-left white-bg sticky-header',
      property: 'clubName',
      title: 'Club',
    }]

    for(const key in items) {
      baseColumns.push(createColumnByName(key));
    }
    return baseColumns;
  }

  deconstructData (obj:IContentSessionTypeDataDto):any {
     let newObj: any ={};
    for (const key in obj.data) {
        newObj[key] = obj.data[key]
    }
    newObj.id = obj.clubId
    newObj.clubName = obj.clubName
    return newObj
}

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  order(evt: ReportTableModel) {
    this.items = this.items.sort((i1, i2) => {
      if (i1[evt.orderBy] > i2[evt.orderBy]) {
        return evt.isAsc ? 1 : -1;
      }
      if (i1[evt.orderBy] < i2[evt.orderBy]) {
        return evt.isAsc ? -1 : 1;
      }
      return 0;
    });
    this.reportTableModel.items = this.items.slice(0, this.reportTableModel.takeBy);
  }

  print() {
    this.reportTableModel.items = this.items;
    setTimeout(() => { window.print(); }, 500);
  }

  getExcel() {
    this.isSpinner = true;
    this.appInsightsService.logEvent('Get Content Session Types Report (League)', {});

    this.reportService.getContextSessionTypesExcelReport()
      .subscribe(resp => {
        this.isSpinner = false;

        if (resp) {
          const url = window.URL.createObjectURL(resp.data);
          const link = document.createElement('a');
          link.download = 'ICSTypeByClubReport.xlsx';
          link.href = url;
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        }
      }, err => {
        this.isSpinner = false;
      });
  }
}

function createColumnByName(key: string): { rowClass: string; headerClass: string; template: any; property: string; title: string; subtitle: string; subtitleClass:string; } | { rowClass: string; headerClass: string; property: string; title: string; template?: undefined; } {
  switch (key) {
    case 'Standard':
      return {
        rowClass: 'text-center grayscale-bg',
        headerClass: 'text-center grayscale-bg width-12-per sticky-header',
        property: 'Standard',
        title: 'Standard',
        subtitle: '',
        subtitleClass: 'font-weight-normal'
      }
    case 'Double':
      return {
        rowClass: 'text-center grayscale-bg',
        headerClass: 'text-center grayscale-bg width-12-per sticky-header',
        property: 'Double',
        title: 'Double',
        subtitle: '(Requirement: 4)',
        subtitleClass: 'font-weight-normal'
      }
    case 'Extended':
      return {
        rowClass: 'text-center grayscale-bg',
        headerClass: 'text-center grayscale-bg width-12-per sticky-header',
        property: 'Extended',
        title: 'Extended',
        subtitle: '(Requirement: 2)',
        subtitleClass: 'font-weight-normal'
      }
    case 'ExtendedBroadcasterExclusive':
      return {
        rowClass: 'text-center grayscale-bg',
        headerClass: 'text-center grayscale-bg width-12-per sticky-header',
        property: 'ExtendedBroadcasterExclusive',
        title: 'Extended Broadcaster Exclusive',
        subtitle: '(Requirement: 1)',
        subtitleClass: 'font-weight-normal'
      }
    case 'ExtendedMasterclass':
      return {
        rowClass: 'text-center grayscale-bg',
        headerClass: 'text-center grayscale-bg width-12-per sticky-header',
        property: 'ExtendedMasterclass',
        title: 'Extended Masterclass',
        subtitle: '(Requirement: 1)',
        subtitleClass: 'font-weight-normal'
      }
      case 'Offsite':
        return {
          rowClass: 'text-center',
          headerClass: 'text-center white-bg sticky-header',
          property: 'Offsite',
          title: 'Offsite',
          subtitle: '(Requirement: 5)',
          subtitleClass: 'font-weight-normal'
        }
        case 'SingleInterview':
        return {
          rowClass: 'text-center',
          headerClass: 'text-center white-bg sticky-header',
          property: 'SingleInterview',
          title: 'Single Interview',
        }
        case 'MultiPlayers':
        return {
          rowClass: 'text-center',
          headerClass: 'text-center white-bg sticky-header',
          property: 'MultiPlayers',
          title: 'Multi Players',
        }
        case 'MultiSessions':
          return {
            rowClass: 'text-center',
            headerClass: 'text-center white-bg sticky-header',
            property: 'MultiSessions',
            title: 'Multi Sessions',
          }
        default:
          break;
  }  
}

