
import { of, Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as actions from './actions';
import { BppService } from '../../services/bpp.service';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class BppEffects {
  constructor(private service: BppService, private actions$: Actions, private errorService: AlertService) { }

  @Effect()
  GetApiVersion$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetApiVersionAction>(actions.GET_API_VERSION),
    switchMap((action) =>
      this.service.getApiVersion().pipe(
        switchMap((result) => of(new actions.GetApiVersionSuccessAction(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.BppApiErrorAction(error));
        }))));

  @Effect()
  GetPlayerList$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetPlayerListAction>(actions.GET_PLAYER_LIST),
    switchMap((action) =>
      this.service.getPlayers().pipe(
        switchMap((result) => of(new actions.GetPlayerListSuccessAction(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.BppApiErrorAction(error));
        }))));

  @Effect()
  GetPlayerListByTeam$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetPlayerListByTeamAction>(actions.GET_PLAYER_LIST_BY_TEAM),
    switchMap((action) =>
      this.service.getPlayersByTeamIds(action.teamIds).pipe(
        switchMap((result) => of(new actions.GetPlayerListByTeamSuccessAction(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.GetPlayerListByTeamErrorAction(error));
        }))));

  @Effect()
  GetLanguageList$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetLanguageListAction>(actions.GET_LANGUAGE_LIST),
    switchMap((action) =>
      this.service.getLanguages().pipe(
        switchMap((result) => of(new actions.GetLanguageListSuccessAction(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.BppApiErrorAction(error));
        }))));

  @Effect()
  GetPresentationUrl$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetPresentationUrlAction>(actions.GET_PRESENTATION_URL),
    switchMap(action =>
      this.service.getPresentationUrl().pipe(
        switchMap(result => of(new actions.GetPresentationUrlActionSuccess(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.GetPresentationUrlActionError(error));
        })
      )),
    catchError(error => {
      this.errorService.error(error);
      return of(new actions.GetPresentationUrlActionError(error));
    })
  );

  @Effect()
  GetCurrentMatchNumber$: Observable<Action> = this.actions$.pipe(
    ofType<actions.GetMatchRoundNumbersAction>(actions.GET_MATCH_ROUND_NUMBERS),
    switchMap((action) =>
      this.service.getMatchRoundNumbers().pipe(
        switchMap((result) => of(new actions.GetMatchRoundNumbersActionSuccess(result))),
        catchError(error => {
          this.errorService.error(error);
          return of(new actions.GetMatchRoundNumbersActionError(error));
        }))));

}
