import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { getBroadcasterDeadlineStr, getFixtureType } from 'src/app/helpers/fixture-helper';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { FixtureEventDetailsDto, IFixtureEventDto, IMatchDto, PlayerDto, RequestStatus } from 'src/app/services/bpp.webapi/client';
import { BaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html'
})
export class WeekViewComponent extends BaseComponent {

  @Input() matches: Array<IMatchDto>;
  @Input() user: any;
  @Input() isBroadcaster = false;
  @Input() isNonLiveInUK = false;

  constructor(private router: Router, private config: ConfigService) {
    super()
  }

  navigate(item: IMatchDto) {
    if (item.awayTeamEvent !== undefined || item.homeTeamEvent !== undefined) {
      if (this.isLog) { console.log('Navigated item:', item); }
      this.router.navigate([`fixtures/detail/${item.matchId}/nonLive/${this.isNonLiveInUK}`]);
    } else {
      if (this.isLog) { console.log('Match has no ICS Events'); }
    }
  }

  getDeadline(event: IFixtureEventDto) {
    if (event) {
      return getBroadcasterDeadlineStr(event.date, this.config.getBrDeadlineHours());
    } else {
      return undefined;
    }
  }

  getStatusColor(item) {
    return StatusHelper.getStatusColor(item.status);
  }

  getStatusText(event: FixtureEventDetailsDto) {
    const status = event.requestDetails.length > 0 ? event.requestDetails[0].status : 0;
    if (status === RequestStatus.Unknown) { return ''; }
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  getPeoplesString(peoples: PlayerDto[]) {
    const list = new Array<string>();
    peoples.forEach(p => {
      if (p) {
        if (this.isManager(p)) {
          list.push(p.officialName + ' ' + p.officialSurname);
        } else {
          list.push(p.shortName);
        }
      }
    });
    return list.join(', ');
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }

  getFixtureType(event: IFixtureEventDto) {
    return event && getFixtureType(event.type) || '-';
  }

}
