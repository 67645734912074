import { Component, OnInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ReportTableModel } from './report-table-model/model';
import { ConfigService } from 'src/app/config/config.service';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html'
})
export class ReportTableComponent implements OnInit {
  @Input() data: ReportTableModel;
  @Input() columns: any[];
  @Input() stickyHeader: boolean = true;
  @Input() customHeaderTemplate: TemplateRef<any>;
  @Output() loadMoreEvent: EventEmitter<ReportTableModel> = new EventEmitter<ReportTableModel>();
  @Output() orderEvent: EventEmitter<ReportTableModel> = new EventEmitter<ReportTableModel>();

  constructor(
    protected config: ConfigService
  ) { }

  ngOnInit() {
  }

  loadMore() {
    if (this.data === undefined || this.data.totalCount === 0 || this.data.items.length >= this.data.totalCount) {
      return;
    }
    this.loadMoreEvent.emit(this.data);
  }

  order(name) {
    if (this.data === undefined || this.data.totalCount === 0) {
      return;
    }
    if (this.data.orderBy === name) {
      this.data.isAsc = !this.data.isAsc;
    } else {
      this.data.isAsc = true;
    }
    this.data.orderBy = name;
    this.orderEvent.emit(this.data);
  }

}
