import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFilmingLocation, IFilmingMatchDto } from '../../../store/filming/state';
import { IFilmingLocationDto } from '../../../services/bpp.webapi/client';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ConfigService } from '../../../config/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filming-modal',
  templateUrl: './filming-modal.component.html',
  styleUrls: ['./filming-modal.component.scss']
})
export class FilmingModalComponent implements OnInit {

  @Input() match: IFilmingMatchDto;
  @Input() locations$: Observable<IFilmingLocationDto[]>;
  @Output() submit: EventEmitter<IFilmingLocation> = new EventEmitter<IFilmingLocation>();
  model: IFilmingLocation;
  get isValid() {
    return this.model
      && this.model.items
      && this.model.items.filter(item => item.invalid).length === 0
      && this.model.items.length > 1;

  }

  constructor(public activeModal: NgbActiveModal, private translate: TranslateService) {

  }

  ngOnInit() {
    if (this.match && this.match.filmingLocation) {
      this.model = cloneDeep(this.match.filmingLocation);
    }
  }

  onSubmit() {
    this.model.matchId = this.match.matchId;
    this.submit.emit(this.model);
    this.activeModal.close();
  }

  onCancel() {
    this.activeModal.close();
  }
}
