import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-additional-content',
  templateUrl: './additional-content-details-wrapper.component.html'
})
export class AdditionalContentDetailsWrapperComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  role: Roles;

  isLoading = false;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.store.select(e => e.user)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(e => {
        this.isSpinner = e.loading;
        if (!e.loading && e.currentUser) {
          this.role = e.currentUser.role;
        }
      });
  }
}
