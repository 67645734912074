import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements OnInit {

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  filter: string;

  constructor() { }

  ngOnInit() {
  }

  searchClick() {
    this.search.emit(this.filter);
  }

}
