import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateParticipantDto, ParticipantDto, ParticipantType } from '../../../services/bpp.webapi/client';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {

  // @Input() location: CreateLocationDto;

  contact: CreateParticipantDto;
  @Input() type: ParticipantType;
  @Output() save: EventEmitter<CreateParticipantDto> = new EventEmitter<CreateParticipantDto>();

  isEdit = false;
  caption = '';

  constructor(public activeModal: NgbActiveModal, private validator: ValidationService) { }

  ngOnInit() {

    this.contact = new CreateParticipantDto();
    this.caption = `AddNew${ParticipantType[this.type]}`;
  }

  onSave() {
    if (this.validator.isParticipantValid(this.contact)) {
      this.contact.type = this.type;
      this.save.emit(this.contact);
      this.activeModal.close('Close click');
    }
  }
}

