import { AuthService } from '../../authentication/auth.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/config.service';

@Injectable()
export class ClientBase {

  constructor(private configService: ConfigService) {
  }

  protected transformOptions(options: any) {
    options.withCredentials = true;
    return Promise.resolve(options);
  }

  protected getBaseUrl(arg): string {
    return this.configService.getApiConnString();
  }

}
