import { Action } from '@ngrx/store';
import { IInterviewDetails, IInterviewsResult } from './state';
import { FxRequest } from '../fx-request/state';

export const GET_INTERVIEWS_ACTION = 'get-interviews-action';
export const GET_INTERVIEWS_ACTION_SUCCESS = 'get-interviews-action-success';
export const GET_INTERVIEWS_ACTION_ERROR = 'get-interviews-action-error';

export const GET_INTERVIEW_DETAILS_ACTION = 'get-interview-details-action';
export const GET_INTERVIEW_DETAILS_ACTION_SUCCESS = 'get-interview-details-action-success';
export const GET_INTERVIEW_DETAILS_ACTION_ERROR = 'get-interview-details-action-error';

export const CREATE_INTERVIEW_ACTION = 'create-interview-action';
export const CREATE_INTERVIEW_ACTION_SUCCESS = 'create-interview-action-success';
export const CREATE_INTERVIEW_ACTION_ERROR = 'create-interview-action-error';

export const UPDATE_INTERVIEW_ACTION = 'update-interview-action';
export const UPDATE_INTERVIEW_ACTION_SUCCESS = 'update-interview-action-success';
export const UPDATE_INTERVIEW_ACTION_ERROR = 'update-interview-action-error';

export const CREATE_UPDATE_INTERVIEW_REQUEST = 'create-update-interview-request-action';
export const CREATE_UPDATE_INTERVIEW_REQUEST_SUCCESS = 'create-update-interview-request-action-success';
export const CREATE_UPDATEA_INTERVIEW_REQUEST_ERROR = 'create-update-interview-request-action-error';

export class GetInterviewsAction implements Action {
    readonly type = GET_INTERVIEWS_ACTION;
    constructor(public includePast: boolean) { }
}

export class GetInterviewsActionSuccess implements Action {
    readonly type = GET_INTERVIEWS_ACTION_SUCCESS;
    constructor(public payload: IInterviewsResult) { }
}

export class GetInterviewsActionError implements Action {
    readonly type = GET_INTERVIEWS_ACTION_ERROR;
    constructor(public payload: any) { }
}

export class GetInterviewDetailsAction implements Action {
    readonly type = GET_INTERVIEW_DETAILS_ACTION;
    constructor(public id: string) { }
}

export class GetInterviewDetailsActionSuccess implements Action {
    readonly type = GET_INTERVIEW_DETAILS_ACTION_SUCCESS;
    constructor(public payload: IInterviewDetails) { }
}

export class GetInterviewDetailsActionError implements Action {
    readonly type = GET_INTERVIEW_DETAILS_ACTION_ERROR;
    constructor(public payload: any) { }
}

export class CreateInterviewAction implements Action {
    readonly type = CREATE_INTERVIEW_ACTION;
    constructor(public model: IInterviewDetails) {}
}

export class CreateInterviewActionSuccess implements Action {
    readonly type = CREATE_INTERVIEW_ACTION_SUCCESS;
    constructor(public model: IInterviewDetails) {}
}
export class CreateInterviewActionError implements Action {
    readonly type = CREATE_INTERVIEW_ACTION_ERROR;
    constructor(public payload: any) {}
}

export class UpdateInterviewAction implements Action {
  readonly type = UPDATE_INTERVIEW_ACTION;
  constructor(public model: IInterviewDetails) {}
}

export class UpdateInterviewActionSuccess implements Action {
  readonly type = UPDATE_INTERVIEW_ACTION_SUCCESS;
  constructor(public model: IInterviewDetails) {}
}
export class UpdateInterviewActionError implements Action {
  readonly type = UPDATE_INTERVIEW_ACTION_ERROR;
  constructor(public payload: any) {}
}

export class CreateUpdateInterviewRequestAction implements Action {
    readonly type = CREATE_UPDATE_INTERVIEW_REQUEST;
    constructor(public model: FxRequest, public interviewId: string) {}
}

export class CreateUpdateInterviewRequestActionSuccess implements Action {
    readonly type = CREATE_UPDATE_INTERVIEW_REQUEST_SUCCESS;
    constructor(public payload: FxRequest, public monthId: number) {}
}

export class CreateUpdateInterviewRequestActionError implements Action {
    readonly type = CREATE_UPDATEA_INTERVIEW_REQUEST_ERROR;
    constructor(public payload: any) {}
}

export type All = GetInterviewsAction
    | GetInterviewsActionError
    | GetInterviewsActionSuccess
    | GetInterviewDetailsAction
    | GetInterviewDetailsActionError
    | GetInterviewDetailsActionSuccess
    | CreateInterviewAction
    | CreateInterviewActionError
    | CreateInterviewActionSuccess
    | UpdateInterviewAction
    | UpdateInterviewActionError
    | UpdateInterviewActionSuccess
    | CreateUpdateInterviewRequestAction
    | CreateUpdateInterviewRequestActionError
    | CreateUpdateInterviewRequestActionSuccess;
