import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil, take } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { Roles } from 'src/app/models/enums';
import { User } from 'src/app/models/user.model';
import { ClubMatchInterviewEventDto, EventStatus, InterviewEventDto, MatchInterviewEventDto } from 'src/app/services/bpp.webapi/client';
import { AppState } from 'src/app/store/app.state';
import { GetClubInterviewBroadcasterEventsAction } from 'src/app/store/club-interview/actions';
import { BaseComponent } from '../../base-component/base.component';
import { GetClubInterviewLeagueEventsAction } from 'src/app/store/club-interview/actions';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { getClubInterviewEventType } from 'src/app/helpers/fixture-helper';

@Component({
  selector: 'app-club-interviews-broadcaster',
  templateUrl: './club-interviews-broadcaster.component.html'
})
export class ClubInterviewsBroadcasterComponent extends BaseComponent implements OnInit {
  dataLoaded = false;
  isLog = false;
  isSpinner: boolean;
  byGameWeek: { [key: string]: ClubMatchInterviewEventDto[] } = {};
  rounds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  round: number = 1;
  user: User;
  isBroadcaster: boolean;

  get matches() {
    if (!this.round || !this.byGameWeek[this.round]) {
      return [];
    }
    return this.byGameWeek[this.round];
  }

  constructor(private store: Store<AppState>, private router: Router, private config: ConfigService) {
    super();
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store
    this.isLog = this.config.isDebug();

    this.store
      .select(state => state.clubInterviews).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Club interview broadcaster:', state.broadcasterByGameWeek); }
          if (state.broadcasterByGameWeek) {
            this.dataLoaded = true;
            this.isSpinner = false;
            this.byGameWeek = state.broadcasterByGameWeek;
          }
        }
      });

    this.store.select(c=>c.bpp.matchRoundNumbers).subscribe(c=>{
        this.rounds=c;
    })

    this.store.select(x => x.fixtures.filter).pipe(take(1)).subscribe(x => {
      this.round = x.matchRound;
      if (this.config.isLfp()){
        this.selectMatchRound(x.matchRound);
      }
    });

    this.store.select(state => state.user).pipe(
      takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
          this.isBroadcaster = state.currentUser.role === Roles.Broadcaster || state.currentUser.role === Roles.PLPBroadcaster;
          this.store.dispatch(new GetClubInterviewBroadcasterEventsAction(this.round));
        }
      });
  }

  selectionChange() {
    this.store.dispatch(new GetClubInterviewBroadcasterEventsAction(this.round));
  }

  selectMatchRound(matchRound: number) {
    this.store.select(c=>c.bpp.currentMatchRoundNumber).subscribe(c => {
      if (matchRound === 1 && c!==matchRound){
        this.round = c;
        this.store.dispatch(new GetClubInterviewBroadcasterEventsAction(this.round));
      }
      else{
        this.store.dispatch(new GetClubInterviewBroadcasterEventsAction(this.round));
      }
    });
  }

  getLocation(item: InterviewEventDto) {
    if (item && item.location){
      return item.location.title;
    }
    return "";
  }

  getStatusColor(item: InterviewEventDto) {
    if (item){
      if (item.requests && item.requests.length>0){
          let minorStatus = item.requests[0].status;
          for (let index = 1; index < item.requests.length; index++) {
            minorStatus = minorStatus < item.requests[index].status ? minorStatus : item.requests[index].status;
          }
          return StatusHelper.getLfpRequestStatusColor(minorStatus);
      }
      else if (item.status===EventStatus.Reject) {
        return StatusHelper.getLfpRequestStatusColor(4);
      }
      else{
        return StatusHelper.getLfpRequestStatusColor(0);
      }

    }
    return StatusHelper.getLfpRequestStatusColor(null);
  }


  navigate(item: MatchInterviewEventDto) {
    if (this.canNavigate(item)) {
      if (this.isLog) { console.log('Navigated item:', item); }
      this.router.navigate([`club-interviews/detail/${item.matchId}`]);
    } else {
      if (this.isLog) { console.log('Match has no ICS Events'); }
    }
  }

  canNavigate(item: MatchInterviewEventDto):boolean {
    let away = true;
    let home = true;
    if (!item.awayEvent && !item.homeEvent) {
      return false;
    }
    if (!item.awayEvent || (this.hasNoRequests(item.awayEvent) && item.awayEvent.status ===EventStatus.Reject) ){
        away = false;
      }
    if (!item.homeEvent || (this.hasNoRequests(item.homeEvent) && item.homeEvent.status ===EventStatus.Reject)){
        home = false;
    }
    return away || home;
  }
  
  hasNoRequests(item: InterviewEventDto):boolean {
   return (!item.requests || item.requests.length==0) 
  }

  getRequestsNumber(item: MatchInterviewEventDto) {
    let requestCount = 0;
    if (item.awayEvent && item.awayEvent.requestTotal){
      requestCount+=item.awayEvent.requestTotal.requested;
    }
    if (item.homeEvent && item.homeEvent.requestTotal){
      requestCount+=item.homeEvent.requestTotal.requested;
    }
    return requestCount;
  }

  getClubInterviewType(event: any) {
    return event && getClubInterviewEventType(event.type) || '-';
  }

  
  getLanguagesString(item: InterviewEventDto) {
    const list = new Array<string>();
    if (item && item.languages){
      item.languages.forEach(p => {
        if (p) {
          list.push(p.name);
        }
      });  
    }
    return list.join(', ');
  }

  getPeoplesString(item: InterviewEventDto) {
    const list = new Array<string>();
    if (item && item.peoples){
      item.peoples.forEach(p => {
        if (p) {
          if (this.isManager(p)) {
            list.push(p.officialName + ' ' + p.officialSurname);
          } else {
            list.push(p.shortName);
          }
        }
      });  
    }
    return list.join(', ');
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }
  
  getLegendaColor(status) {
    if (this.config.isLfp()){
      return StatusHelper.getLfpRequestStatusColor(status);
    }
    return StatusHelper.getRequestStatusColor(status);
  }

}
 
 

