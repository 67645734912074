import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { Roles } from 'src/app/models/enums';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateAdditionalContentEventAction, GetAdditionalContentEventByIdAction, UpdateAdditionalContentEventAction } from '../../../store/additional-content/actions';
import { User } from '../../../models/user.model';
import { AdditionalContentType, ClubDto, EventStatus, IAdditionalContentFixtureDetailsDto, NoteDto, PlayerDto } from '../../../services/bpp.webapi/client';
import { Subscription } from 'rxjs';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { AdditionalContentMapper } from '../../../mappers/additional-content.mapper';
import { AlertService } from '../../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../config/config.service';
import * as helper from '../../../helpers/common';
import { DateBinder } from '../dateBinder';
import { GetPlayerListByTeamAction } from '../../../store/bpp/actions';
import { mapAdditionalContentType } from '../../../helpers/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { configServiceFactory } from 'src/app/app.module';

@Component({
  selector: 'app-additional-content-details-broadcaster',
  templateUrl: './additional-content-details-broadcaster.component.html'
})
export class AdditionalContentDetailsBroadcasterComponent extends BaseComponent implements OnInit, OnDestroy {
  Roles = Roles;
  AdditionalContentTypes = AdditionalContentType;
  role: Roles;
  isLog = true;
  isLoading = true;
  isReadOnly = true;
  user: User;
  userRole: Roles;
  clubId;
  additionalContent: IAdditionalContentFixtureDetailsDto;
  additionalContentId: string;
  additionalContentType: AdditionalContentType;
  additionalContentSubscription: Subscription;
  players = new Array<PlayerDto>();
  mapper: AdditionalContentMapper;
  club: ClubDto;
  lastSaved: string;
  dateBinder: DateBinder;
  features: any;
  canManageRequest:boolean;
  isLfp:boolean;

  constructor(private store: Store<AppState>,
    private route: ActivatedRoute,
    private appInsightsService: AppInsightClientService,
    private alertService: AlertService,
    private translationService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private config: ConfigService) {
    super();
    this.isLog = this.config.isDebug();
    this.features = this.config.get("features.additionalContent");
    if (this.config.hasFeature('environment.lfp')){
      this.isLfp= true;
    }
    this.mapper = new AdditionalContentMapper();
  }

  ngOnInit() {
    this.isLog = true;

    this.store
      .select(e => e.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });

    this.route.params.subscribe(
      params => {
        const eventType = mapAdditionalContentType(params['type'].toLowerCase(), !!(this.features && this.features.alternativeNames));
        this.additionalContentSubscription = this.store
          .select(e => e.additionalContent && e.additionalContent.additionalContentDetails[params['id']])
          .pipe(takeUntil(this.componentDestroyed), filter(r => r !== undefined))
          .subscribe(res => {
            if (res) {
              if (this.isLog) { console.log('GetAdditionalContentEventDetails: ', res); }

              if (!res.clubNote) {
                res.clubNote = new NoteDto();
              }
              this.additionalContent = res;
              this.canManageRequest = (
                this.additionalContent !== undefined 
                && 
                (this.additionalContent.type === this.AdditionalContentTypes.Club || this.isLfp)
                && this.canVisualize()
              );
              this.club = new ClubDto({ id: this.additionalContent.clubId, name: this.additionalContent.club });
              if (this.additionalContent !== undefined) {
                this.store.select(state => state.bpp.playersByTeam).pipe(
                  takeUntil(this.componentDestroyed), filter(state => state !== undefined && state[this.additionalContent.clubId] !== undefined && state[this.additionalContent.clubId].length > 0))
                  .subscribe(playersByTeam => {
                    if (playersByTeam && playersByTeam[this.additionalContent.clubId]) {
                      this.isSpinner = false;
                      this.players = playersByTeam[this.additionalContent.clubId];
                      if (this.isLog) { console.log('this.players', this.players); }
                      this.isLoading = false;
                    }
                  });

                this.dateBinder = new DateBinder(this.additionalContent.start, this.additionalContent.arrival, this.config.get('defaultTimeZone'));
                if (eventType === AdditionalContentType.PreSeason || eventType === AdditionalContentType.MidSeason) {
                  this.isSpinner = true;
                  this.store.dispatch(new GetPlayerListByTeamAction([this.additionalContent.clubId]));
                } else {
                  this.isSpinner = false;
                  this.isLoading = false;
                }
              }

              if (this.additionalContent.requests.length > 0) {
                const s = StatusHelper.getRequestStatusText(this.additionalContent.requests[0].status);
                this.lastSaved = helper.getLastSavedText(this.additionalContent.requests[0].updatedOn, this.additionalContent.requests[0].updatedByName, s);
              } else {
                this.lastSaved = helper.getLastSavedText(this.additionalContent.updatedOn, this.additionalContent.updatedByName, StatusHelper.getStatusText(this.additionalContent.status));
              }
            }
          });

        this.isSpinner = true;
        this.store.dispatch(new GetAdditionalContentEventByIdAction(params['id'], eventType));
      });
  }

  private validateModel(): boolean {
    if (this.additionalContent.location === undefined || this.additionalContent.location.locationId === undefined) {
      this.translationService.get('invalidAdditionalContentLocation').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    if (this.isLfp && (this.additionalContent.duration === undefined || this.additionalContent.duration===moment.duration(0,'milliseconds'))){
      this.translationService.get('invalidDuration').subscribe(value => this.alertService.error({ message: value, response: '' }));
      return false;
    }
    return true;
  }

  canVisualize(){
    return this.additionalContent.requests.length > 0 || !StatusHelper.isDatePassed(this.additionalContent.start);
  }

  goBack() {
    this.router.navigate([`additional-content`]);
  }
}
