import { Action } from '@ngrx/store';
import { ILanguageDto, IPlayerListDto, PlayerListDto } from '../../services/bpp.webapi/client';

export const BPP_API_ERROR_ACTION = 'bpp_webapi_error_action';
export const GET_API_VERSION = 'bpp_webapi_get_api_version';
export const GET_API_VERSION_SUCCESS = 'bpp_webapi_get_api_version_success';
export const GET_PLAYER_LIST = 'bpp_webapi_get_player_list';
export const GET_PLAYER_LIST_SUCCESS = 'bbpp_webapi_get_player_list_success';
export const GET_LANGUAGE_LIST = 'bpp_webapi_get_language_list';
export const GET_LANGUAGE_LIST_SUCCESS = 'bbpp_webapi_get_language_list_success';
export const GET_PLAYER_LIST_BY_TEAM = 'bpp_webapi_get_player_list_by_team';
export const GET_PLAYER_LIST_BY_TEAM_SUCCESS = 'bbpp_webapi_get_player_list_by_team_success';
export const GET_PLAYER_LIST_BY_TEAM_ERROR = 'bbpp_webapi_get_player_list__by_team_error';
export const GET_PRESENTATION_URL = 'get-presentation-url-action';
export const GET_PRESENTATION_URL_SUCCESS = 'get-presentation-url-action-success';
export const GET_PRESENTATION_URL_ERROR = 'get-presentation-url-action-error';
export const GET_MATCH_ROUND_NUMBERS = 'get-match-round-numbers-action';
export const GET_MATCH_ROUND_NUMBERS_SUCCESS = 'get-match-round-numbers-action-success';
export const GET_MATCH_ROUND_NUMBERS_ERROR = 'get-match-round-numbers-action-error';

export class GetApiVersionAction implements Action {
    readonly type = GET_API_VERSION;
    constructor() { }
}

export class GetApiVersionSuccessAction implements Action {
    readonly type = GET_API_VERSION_SUCCESS;
    constructor(public payload: string) { }
}

export class GetPlayerListAction implements Action {
    readonly type = GET_PLAYER_LIST;
    constructor() { }
}

export class GetPlayerListSuccessAction implements Action {
    readonly type = GET_PLAYER_LIST_SUCCESS;
    constructor(public payload: IPlayerListDto) { }
}

export class GetLanguageListAction implements Action {
    readonly type = GET_LANGUAGE_LIST;
    constructor() { }
}

export class GetLanguageListSuccessAction implements Action {
    readonly type = GET_LANGUAGE_LIST_SUCCESS;
    constructor(public payload: Array<ILanguageDto>) { }
}

export class BppApiErrorAction implements Action {
    readonly type = BPP_API_ERROR_ACTION;
    constructor(public payload: any) { }
}

export class GetPlayerListByTeamAction implements Action {
    readonly type = GET_PLAYER_LIST_BY_TEAM;
    constructor(public teamIds: any[]) { }
}

export class GetPlayerListByTeamSuccessAction implements Action {
    readonly type = GET_PLAYER_LIST_BY_TEAM_SUCCESS;
    constructor(public payload: PlayerListDto[]) { }
}

export class GetPlayerListByTeamErrorAction implements Action {
    readonly type = GET_PLAYER_LIST_BY_TEAM_ERROR;
    constructor(public payload: any) { }
}

export class GetPresentationUrlAction implements Action {
    readonly type = GET_PRESENTATION_URL;
    constructor() {}
}

export class GetPresentationUrlActionSuccess implements Action {
    readonly type = GET_PRESENTATION_URL_SUCCESS;
    constructor(public payload: string) {}
}

export class GetPresentationUrlActionError implements Action {
    readonly type = GET_PRESENTATION_URL_ERROR;
    constructor(public payload: any) {}
}

export class GetMatchRoundNumbersAction implements Action {
    readonly type = GET_MATCH_ROUND_NUMBERS;
    constructor() {}
}

export class GetMatchRoundNumbersActionSuccess implements Action {
    readonly type = GET_MATCH_ROUND_NUMBERS_SUCCESS;
    constructor(public payload: any) {}
}

export class GetMatchRoundNumbersActionError implements Action {
    readonly type = GET_MATCH_ROUND_NUMBERS_ERROR;
    constructor(public payload: any) {}
}

export type All = GetApiVersionAction
    | GetApiVersionSuccessAction
    | BppApiErrorAction
    | GetPlayerListAction
    | GetPlayerListSuccessAction
    | GetLanguageListAction
    | GetLanguageListSuccessAction
    | GetPlayerListByTeamAction
    | GetPlayerListByTeamSuccessAction
    | GetPlayerListByTeamErrorAction
    | GetPresentationUrlAction
    | GetPresentationUrlActionSuccess
    | GetPresentationUrlActionError
    | GetMatchRoundNumbersAction
    | GetMatchRoundNumbersActionSuccess
    | GetMatchRoundNumbersActionError;
