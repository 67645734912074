import * as actions from './actions';
import { InterviewTypesState } from './state';

export type Action = actions.All;
const defaultState = new InterviewTypesState();

export function InterviewTypesReducer(state = defaultState, action: Action): InterviewTypesState {

    switch (action.type) {
        case actions.GET_INTERVIEW_TYPES_ACTION: {
            return {
                ...state
            };
        }
        case actions.GET_INTERVIEW_TYPES_ACTION_SUCCESS: {
            return {
                ...state,
                list: action.payload
            };
        }
        case actions.GET_INTERVIEW_TYPES_ACTION_ERROR: {
            return {
                ...state,
                list: []
            };
        }
    }

    return state;
}
