export enum EventCase {
    ClubSubmitFixture = 1,
    ClubEditApprovedFixture ,
    ClubSubmitTraining,
    ClubEditApprovedTraining,
    LeagueApproveFixture,
    LeagueRejectFixture,
    LeagueApproveTraining,
    LeagueRejectTraining,
    LeagueApproveFixtureChanges,
    LeagueApproveTrainingChanges,
    LeagueApproveFixtureRequest,
    LeagueRejectFixtureRequest,
    LeagueApproveTrainingRequest,
    LeagueRejectTrainingRequest,
    BroadcasterRequestFixture,
    BroadcasterRequestTraining,
    BroadcasterEditApprovedFixture,
    BroadcasterEditApprovedTraining,
    LeagueCreateInterview,
    LeagueUpdateInterview,
    BroadcasterRequestInterview,
    BroadcasterEditApprovedInterview,
    LeagueApproveInterviewRequest,
    LeagueRejectInterviewRequest,
    BroadcasterWithdrawnFixture,
    BroadcasterWithdrawnTraining,
    BroadcasterWithdrawnInterview,
    LeagueUnRejectFixtureRequest,
    LeagueUnRejectTrainingRequest,
    LeagueUnRejectInterviewRequest,
    LeagueUpdateFixture
}
