
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IMatchDto, FixtureEventDetailsDto, RequestStatus, IFixtureEventDto, PlayerDto, IWeekDto } from '../../../services/bpp.webapi/client';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { Router } from '@angular/router';
import { ConfigService } from '../../../config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Subject } from 'rxjs';
import { Roles } from '../../../models/enums';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { getBroadcasterDeadlineStr, getFixtureType } from '../../../helpers/fixture-helper';

@Component({
  selector: 'app-fixtures-table',
  templateUrl: './fixtures-table.component.html'  
})
export class FixturesTableComponent implements OnInit, OnDestroy {
  private componetDestroyed: Subject<any> = new Subject();

  @Input() items: Array<IWeekDto>;
  @Input() title: string;
  @Input() subtitle: string;
  isLog = false;
  isBroadcaster = false;
  user: any;

  constructor(private store: Store<AppState>, private router: Router, private appInsightsService: AppInsightClientService, private config: ConfigService) {
    this.isLog = config.isDebug();
  }

  ngOnInit() {
    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
        }
      });
    if (this.isLog) { console.log('Fixtures table init:', this.title, this.subtitle, this.items); }
    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (state.loading) {

        } else {
          this.isBroadcaster = state.currentUser.role === Roles.Broadcaster || state.currentUser.role === Roles.PLPBroadcaster;
        }
      });
  }

  navigate(item: IMatchDto) {
    if (item.awayTeamEvent !== undefined || item.homeTeamEvent !== undefined) {
      this.appInsightsService.logEvent('Fixture details', {
        matchId: item.matchId.toFixed()
      });
      if (this.isLog) { console.log('Navigated item:', item); }
      this.router.navigate([`fixtures/detail/${item.matchId}`]);
    } else {
      if (this.isLog) { console.log('Match has no ICS Events'); }
    }
  }

  getDeadline(event: IFixtureEventDto) {
    if (event) {
      return getBroadcasterDeadlineStr(event.date, this.config.getBrDeadlineHours());
    } else {
      return undefined;
    }
  }

  getStatusColor(item) {

    return StatusHelper.getStatusColor(item.status);
  }

  getStatusText(event: FixtureEventDetailsDto) {
    const status = event.requestDetails.length > 0 ? event.requestDetails[0].status : 0;
    if (status === RequestStatus.Unknown) { return ''; }
    return `Request ${StatusHelper.getRequestStatusText(status)}`;
  }

  getPeoplesString(peoples: PlayerDto[]) {
    const list = new Array<string>();
    peoples.forEach(p => {
      if (p) {
        if (this.isManager(p)) {
          list.push(p.officialName + ' ' + p.officialSurname);
        } else {
          list.push(p.shortName);
        }
      }
    });
    return list.join(', ');
  }

  isManager(player) {
    if (player.role) {
      return player.role.toLowerCase() === 'staff'
        || player.role.toLowerCase() === 'manager'
        || player.role.toLowerCase() === 'assistant manager';
    }
    return false;
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

  getFixtureType(event: IFixtureEventDto) {
    return event && getFixtureType(event.type) || '-';
  }
}
