
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../../config/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import {
  ITrainingDetailsDto, ParticipantType, ParticipantDto, RequestStatus, CameraOperator
} from '../../../services/bpp.webapi/client';
import { GetTrainingEventByIdAction, GetTrainingParticipantsAction } from '../../../store/training/actions';
import * as moment from 'moment';
import { Section } from './state/section';
import { UpdateState } from './state/update-state';
import { ViewState } from './state/view-state';
import { AddState } from './state/add-state';
import { StatusHelper } from '../../../helpers/statuses-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddContactComponent } from '../../common/add-contact/add-contact.component';
import * as helper from '../../../helpers/common';
import { ValidationService } from '../../../services/validation.service';
import { ConfirmWithdrawPopupComponent } from '../../common/withdraw-request/confirm-withdraw-popup.component';
import { User } from '../../../models/user.model';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { IRequestSection } from '../../../store/fx-request/state';
import { getCameraOptionTextId } from '../../../helpers/request.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-broadcasters-training-details',
  templateUrl: './broadcasters-training-details.component.html',
  styleUrls: ['./broadcasters-training-details.component.scss']
})
export class BroadcastersTrainingDetailsComponent implements OnInit, OnDestroy {
  private componetDestroyed: Subject<any> = new Subject();
  modalSubscription: any;
  withDrawSubscription: any;
  private isLog = false;
  private routerQuery: Subscription;
  private routerParams: Subscription;
  private trainingSubscription: Subscription;
  private participantsSubscription: Subscription;
  private training: ITrainingDetailsDto;
  private dataLoaded;
  lastSaved = '';
  isSpinner = false;
  CameraOperator = CameraOperator;
  hasCameraOptionPlp = true;
  hasCameraOptionBroadcaster = true;
  hasCameraOptionAudio = true;
  isLfp: boolean;

  session = {
    name: 'Test session'
  };

  public state = new ViewState();
  componentDestroyed: Subject<void> = new Subject();
  user: User;

  constructor(private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private appInsightsService: AppInsightClientService,
    private store: Store<AppState>,
    private router: Router,
    private modalService: NgbModal,
    private validator: ValidationService,
    private translate: TranslateService) {
    this.isLog = config.isDebug();
    this.isLfp = config.isLfp();
  }

  ngOnInit() {
    this.store
      .select(e => e.user).pipe(
        takeUntil(this.componentDestroyed))
      .subscribe(state => {
        if (state.loading) {
        } else if (state.currentUser) {
          this.user = state.currentUser;
        }
      });
    this.routerParams = this.activatedRoute.params.subscribe(params => {
      // console.log('Route params', params);

      this.trainingSubscription = this.store
        .select(e => e.trainings.trainingDetails[params['id']])
        .subscribe(res => {
          if (res) {
            this.isSpinner = false;
            this.training = res;
            if (this.isLog) { console.log('Training loaded'); }
            if (!this.isCompletedEvent(this.training)) {
              if (this.training.requests.length > 0) {
                this.state = new UpdateState(this.training, this.training.requests[0]);
                const s = StatusHelper.getRequestStatusText(this.training.requests[0].status);
                this.lastSaved = helper.getLastSavedText(this.training.requests[0].updatedOn, this.training.requests[0].updatedByName, s);
              } else {
                this.state = new AddState(this.training);
                this.lastSaved = helper.getLastSavedText(this.training.updatedOn, this.training.updatedByName, StatusHelper.getStatusText(this.training.status));
              }
            } else {
              this.state.isNew = this.training.requests.length === 0;
              this.state.setTraining(this.training);
            }
            this.participantsSubscription = this.store.select(e => e.trainings.participants)
              .subscribe(participants => {
                this.isSpinner = false;
                if (participants) {
                  this.state.participants = participants;
                }
                this.dataLoaded = true;
              });
            this.store.dispatch(new GetTrainingParticipantsAction());
          }

          this.dataLoaded = true;
        });
      this.isSpinner = true;
      this.store.dispatch(new GetTrainingEventByIdAction(params['id']));
    });
    this.hasCameraOptionPlp = this.config.hasFeature('requestCameraOptions.plp');
    this.hasCameraOptionBroadcaster = this.config.hasFeature('requestCameraOptions.broadcaster');
    this.hasCameraOptionAudio = this.config.hasFeature('requestCameraOptions.audioOnly');
  }

  isCompletedEvent(training) {
    const result = StatusHelper.isCompeletedTrainingDetails(training, moment().utc());
    return result;
  }

  ngOnDestroy(): void {
    if (this.trainingSubscription) {
      this.trainingSubscription.unsubscribe();
    }
    if (this.routerParams) {
      this.routerParams.unsubscribe();
    }
    if (this.routerQuery) {
      this.routerQuery.unsubscribe();
    }
    if (this.participantsSubscription) {
      this.participantsSubscription.unsubscribe();
    }
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

  changeSelection(value: string, section: Section) {
    section.require = value === 'Yes';
    if (value === 'No') {
      section.participants = [];
    }
  }

  onCameraSection(option: CameraOperator, section: Section) {
    const require = option === CameraOperator.BroadcasterCamera;
    section.cameraOperator = option;
    section.require = require;
    if (!require) {
      section.participants = [];
    }
  }

  selectParticipant(value: string, section: Section) {
    const item = this.state.participants.find(x => x.id === value);
    if (item) {
      if (section.participants.findIndex(x => x.id === item.id) === -1) {
        section.participants.splice(section.participants.length, 0, item);
      }

    }
  }

  showAddParticipantModal(contactType) {
    const modalRef = this.modalService.open(AddContactComponent, { centered: true });
    modalRef.componentInstance.type = contactType;
    this.modalSubscription = modalRef.componentInstance.save.pipe(takeUntil(this.componetDestroyed))
      .subscribe(contact => this.addParticipant(contact));
  }

  addParticipant(contact) {
    if (contact.name === '' || contact.name === undefined || contact.phone === '' || contact.phone === undefined) {
      return;
    }
    const participant = ParticipantDto.fromJS({
      id: '00000000-0000-0000-0000-000000000000',
      name: contact.name,
      phone: contact.phone,
      type: contact.type
    });
    const section = this.state.sections.find(s => s.type === participant.type);
    section.participants.push(participant);
  }

  deleteParticipant(section: Section, item: ParticipantDto) {
    section.participants.splice(section.participants.indexOf(item), 1);
  }

  saveRequest() {
    const event = {
      trainingId: this.state.trainingId,
      requestId: undefined,
      status: StatusHelper.getRequestStatusText(RequestStatus.Draft)
    };
    if (this.state instanceof UpdateState) {
      event.requestId = (<UpdateState>this.state).requestId;
      this.appInsightsService.logEvent('Save request (Broadcaster)', event);
    } else {
      this.appInsightsService.logEvent('Create request (Broadcaster)', event);
    }
    this.storeRequest(RequestStatus.Draft);
  }

  submitRequest() {
    const event = {
      trainingId: this.state.trainingId,
      requestId: undefined,
      status: StatusHelper.getRequestStatusText(RequestStatus.Submitted)
    };
    if (this.state instanceof UpdateState) {
      event.requestId = (<UpdateState>this.state).requestId;
      this.appInsightsService.logEvent('Submit request (Broadcaster)', event);
    } else {
      this.appInsightsService.logEvent('Create request (Broadcaster)', event);
    }
    this.storeRequest(RequestStatus.Submitted);
  }

  withdrawRequest() {
    const modalRef = this.modalService.open(ConfirmWithdrawPopupComponent, { centered: true });
    this.withDrawSubscription = modalRef.componentInstance.withdraw
      .subscribe(doWithdraw => {
        if (doWithdraw === true) {
          this.appInsightsService.logEvent('Withdraw request (Broadcaster)', {
            trainingId: this.state.trainingId,
            requestId: (<UpdateState>this.state).requestId,
            status: StatusHelper.getRequestStatusText(RequestStatus.Withdrawn)
          });
          this.storeRequest(RequestStatus.Withdrawn);
          this.withDrawSubscription.unsubscribe();
        }
      });
  }

  storeRequest(status: RequestStatus) {
    // if (this.state.getParticipants().length > 0) {
    const action = this.state.getAction(status);
    this.isSpinner = true;
    this.store.dispatch(action);
    // } else {
    //   this.validator.invalidRequestWarning();
    // }
  }

  getRequestStatusText(status: RequestStatus) {
    return StatusHelper.getRequestStatusText(status);
  }

  getAddButtonText(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'AddNewCameraOperator';
      case (ParticipantType.Reporter): return 'AddNewReporter';
      case (ParticipantType.Producer): return 'AddNewProducer';
      case (ParticipantType.Other): return 'AddNewOther';
      default: return 'Other';
    }
  }

  getSelectText(type: ParticipantType) {
    switch (type) {
      case (ParticipantType.Camera): return 'SelectCameraOperator';
      case (ParticipantType.Reporter): return 'SelectReporter';
      case (ParticipantType.Producer): return 'SelectProducer';
      case (ParticipantType.Other): return 'SelectOther';
      case (ParticipantType.Unknow): return 'Unknown';
    }
  }

  getCameraOption(section: IRequestSection) {
    if (section && section.cameraOperator !== undefined) {
      return this.translate.instant(getCameraOptionTextId(section.cameraOperator));
    }
  }

  goBack() {
    this.router.navigate([`trainings`]);
  }
}
