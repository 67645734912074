import * as moment from 'moment';
import {
  AdditionalContentFixtureDetailsDto,
  AdditionalContentFixtureDto, AdditionalContentType, CreateAdditionalContentFixtureDto,
  EventStatus,
  IAdditionalContentFixtureDetailsDto,
  LocationDto, MemberTypeDto,
  NoteDto, PeopleDto, PlayerDto, UpdateAdditionalContentFixtureDto
} from '../services/bpp.webapi/client';
import * as playerHelper from '../helpers/player-helper';

export class AdditionalContentMapper {
  private toUtc(input: moment.Moment) {
    const result = moment(input);
    return result;
  }

  private concatDate(date: moment.Moment, time: moment.Moment) {
    const result = moment(date).hour(time.hour()).minute(time.minute());
    return result;
  }

  init(contentDate: moment.Moment, clubId: any, type: AdditionalContentType): AdditionalContentFixtureDetailsDto {
    const content = new AdditionalContentFixtureDetailsDto();
    const clubNote = this.getNewNote();
    content.init({
      clubId,
      type,
      location: new LocationDto(),
      start: this.toUtc(contentDate),
      arrival: this.toUtc(contentDate),
      peoples: [],
      languages: [],
      status: EventStatus.Draft,
      clubNote
    });
    return content;

  }

  create(model: IAdditionalContentFixtureDetailsDto): CreateAdditionalContentFixtureDto {
    const result = new CreateAdditionalContentFixtureDto();
    const note = new NoteDto();
    const leagueNote = new NoteDto();
    note.message = model.clubNote ? model.clubNote.message : undefined;
    leagueNote.message = model.leagueNote ? model.leagueNote.message : undefined;
    result.init({
      type: model.type,
      arrival: this.toUtc(model.arrival),
      start: this.toUtc(this.concatDate(model.arrival, model.start)),
      duration: model.duration,
      locationId: model.location ? model.location.locationId : undefined,
      peoples: model.peoples,
      languages: model.languages,
      participants: model.participants || '',
      status: EventStatus.Unknown,
      clubNote: note,
      clubId:model.clubId>0 ? model.clubId : undefined,
      leagueNote:leagueNote

    });

    return result;
  }

  update(model: IAdditionalContentFixtureDetailsDto): UpdateAdditionalContentFixtureDto {
    const result = new UpdateAdditionalContentFixtureDto();
    const clubNote = new NoteDto();
    clubNote.message = model.clubNote ? model.clubNote.message : undefined;
    const leagueNote = new NoteDto();
    leagueNote.message = model.leagueNote ? model.leagueNote.message : undefined;

    result.init({
      eventId: model.eventId,
      type: model.type,
      status: model.status,
      arrival: this.toUtc(model.arrival),
      start: this.toUtc(this.concatDate(model.arrival, model.start)),
      duration: model.duration,
      locationId: model.location ? model.location.locationId : undefined,
      peoples: model.peoples,
      languages: model.languages,
      participants: model.participants || '',
      clubId: model.clubId>0 ? model.clubId : undefined,
      clubNote,
      leagueNote
    });

    return result;
  }

  getNewNote(): NoteDto {
    const clubNote = new NoteDto();
    clubNote.init({
      date: moment().utc(),
      message: ''
    });
    return clubNote;
  }

  updateNote(note: NoteDto): NoteDto | undefined {
    const newNote = this.getNewNote();
    newNote.init({
      message: note && note.message !== undefined ? note.message : '',
      id: note.id
    });
    return newNote;
  }

  mapPlayer(player: PlayerDto): PeopleDto {
    const memberType: MemberTypeDto = playerHelper.isManager(player) ? MemberTypeDto.Staff : MemberTypeDto.Player;
    return new PeopleDto({
      personId: player.id,
      memberType
    });
  }

  mapPlayers(players: PlayerDto[]): PeopleDto[] {
    return players.map(player => this.mapPlayer(player));
  }
}
