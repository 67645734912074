
import { of, Observable } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Injectable } from '@angular/core';
import * as actions from './actions';
import { FixtureService } from '../../services/fixture.service';
import { StatusHelper } from '../../helpers/statuses-helper';
import { AlertService } from '../../services/alert.service';
import { switchMap, catchError } from 'rxjs/operators';
import { GetEventsByMatchAction } from '../fx-request/actions';
import { getLastSavedText } from 'src/app/helpers/common';
import { IDentity } from '../fx-request/state';

@Injectable()
export class FixtureEffects {
  constructor(
    protected store: Store<AppState>,
    protected service: FixtureService,
    protected action$: Actions,
    private alertService: AlertService
  ) { }

  @Effect()
  GetClubEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetClubEventsAction>(actions.GET_CLUB_EVENTS),
    switchMap(action => this.service.getClubEvents(action.isUpcoming, action.nonLiveInUK).pipe(
      switchMap((result) => of(new actions.GetClubEventsSuccessAction(action.isUpcoming, result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetClubEventsErrorAction(error));
      }))));

  @Effect()
  GetLeagueEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetLeagueEventsAction>(actions.GET_LEAGUE_EVENTS),
    switchMap(action => this.service.getLeagueEvents(action.matchRound, action.nonLiveInUK).pipe(
      switchMap((result) => of(new actions.GetLeagueEventsSuccessAction(action.matchRound, result.length > 0 ? result[0] : undefined))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetLeagueEventsErrorAction(error));
      }))));

  @Effect()
  GetBroadcasterEvents$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetLeagueEventsAction>(actions.GET_BROADCASTER_EVENTS),
    switchMap(action => this.service.getBroadcasterEvents(action.matchRound, action.nonLiveInUK).pipe(
      switchMap((result) => of(new actions.GetBroadcasterEventsSuccessAction(action.matchRound, result.length > 0 ? result[0] : undefined))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetBroadcasterEventsErrorAction(error));
      }))));

  @Effect()
  CreateFixtureEvent$ = this.action$.pipe(
    ofType<actions.CreateFixtureEventAction>(actions.CREATE_FIXTURE_EVENT),
    switchMap(action => this.service.createEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Fixture event created, status: ${StatusHelper.getStatusText(result.status)}`;
        this.alertService.success(message);
        return of(new actions.CreateFixtureEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.CreateFixtureEventErrorAction(error));
      }))));

  @Effect()
  UpdateFixtureEvent$ = this.action$.pipe(
    ofType<actions.UpdateFixtureEventAction>(actions.UPDATE_FIXTURE_EVENT),
    switchMap(action => this.service.updateEvent(action.payload).pipe(
      switchMap((result) => {
        const message = `Fixture status updated.`;
        this.alertService.success(message);
        const identity: IDentity = {
          matchId: result.matchId,
          eventId: result.eventId,
          companyId: result.companyId.toString(),
          nonLiveInUK: result.isNonLiveInUK,
          lastUpdated: getLastSavedText(result.updatedOn, result.updatedByName, StatusHelper.getStatusText(result.status))
        };
        this.store.dispatch(new GetEventsByMatchAction(identity));
        return of(new actions.UpdateFixtureEventSuccessAction(result));
      }),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.UpdateFixtureEventErrorAction(error));
      }))));

  @Effect()
  GetMatchInfo$: Observable<Action> = this.action$.pipe(
    ofType<actions.GetMatchInfoAction>(actions.GET_MATCH_INFO),
    switchMap(action => this.service.getMatchInfo(action.matchId).pipe(
      switchMap((result) => of(new actions.GetMatchInfoActionSuccess(result))),
      catchError(error => {
        this.alertService.error(error);
        return of(new actions.GetMatchInfoActionError(error));
      }))));
}
