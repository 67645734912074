import { Component, OnInit, ViewEncapsulation, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { ContentChildren, ContentChild, AfterContentInit, QueryList, ViewChild, AfterViewInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const UI_SELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => SelectComponent),
  multi: true
};

@Component({
  selector: 'app-d3-option',
  template: '<div class="dropdown-item {{(disabled)?\'disabled\':\'\'}}" (click)="changeValue(value)"><ng-content></ng-content></div>',
  styleUrls: ['./select.component.scss']
})
export class SelectOptionComponent {
  elem: ElementRef;
  _disabled: boolean;
  _value: any;

  private _show = false;
  public get show(): boolean {
    return this._show;
  }
  public set show(theBar: boolean) {
    this._show = theBar;
  }

  @Input() set value(v: any) {
    this._value = v;
  }

  get value() {
    return this._value;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v !== false;
  }

  get disabled() {
    return this._disabled;
  }

  protected onClickCallback = (v: any, e: ElementRef) => { };

  registerClickHandler(fn: any) {
    this.onClickCallback = fn;
  }

  changeValue(value: any) {
    this.onClickCallback(value, this.elem);
  }

  constructor(elem: ElementRef) {
    this.show = false;
    this.elem = elem;
  }

}

@Component({
  selector: 'app-d3-selected-value',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./select.component.scss']
})
export class SelectedValueComponent {
}



@Component({
  selector: 'app-d3-options',
  template: `<div style="position: relative; z-index: 3000">
                <div class="dropdown-menu {{(show)?\'show\':\'\'}}">
                  <ng-content></ng-content>
                </div>
             </div>`,
  styleUrls: ['./select.component.scss']
})
export class SelectOptionsComponent implements OnInit, AfterContentInit {

  public value: any;
  @ContentChildren(SelectOptionComponent) options: QueryList<SelectOptionComponent>;
  private _show = false;

  setSelectedCallback = (v: any) => { };
  setSelectedViewCallback = (v: any) => { };

  setSelectedValue(value: any) {
    this.setSelectedCallback(value);
  }

  setSelectedView(htmlView: any) {
    this.setSelectedViewCallback(htmlView);
  }

  registerSetSelectedCallback(fn) {
    this.setSelectedCallback = fn;
  }

  registerSetSelectedViewCallback(fn) {
    this.setSelectedViewCallback = fn;
  }

  ngAfterContentInit(): void {
    this.options.forEach((option) => {
      if (this.value === option.value) {
        this.setSelectedView(option.elem.nativeElement.firstChild);
      }
      option.registerClickHandler((value, elem) => {
        this.setSelectedValue(value);
        this.setSelectedView(elem.nativeElement.firstChild);
      });
    });
  }
  get show(): boolean {
    return this._show;
  }
  set show(theBar: boolean) {
    this._show = theBar;
  }


  constructor() {
    this.show = false;
  }

  ngOnInit() {
  }

}


@Component({
  selector: 'app-d3-select',
  template: `<div class="dropdown">
                    <button class="dropdown-toggle {{(show)?'show':''}} {{(classes)?classes:''}}"
                    type="button" (click)="onClick($event)"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <ng-content select="app-d3-selected-value"></ng-content>
                    </button>
                    <ng-content select="app-d3-options"></ng-content>
                </div>`,
  styleUrls: ['./select.component.scss'],
  providers: [UI_SELECT_CONTROL_VALUE_ACCESSOR]
})
export class SelectComponent implements AfterContentInit, ControlValueAccessor {
  protected _value: any = null;
  @Input() selectedItems: Array<any>;
  @Input() _selectedValue: any;
  @ViewChild('selectedValue', { static: false }) selectedValue;
  @Output() change = new EventEmitter<boolean>();
  @Output() menuOpened = new EventEmitter<boolean>();
  classes: string;
  show: boolean;
  @ContentChild(SelectOptionsComponent, { static: true }) dropdownMenu: SelectOptionsComponent;

  onTouchedCallback: any = (fn: any) => { };
  onChangeCallback: any = (fn: any) => { };

  writeValue(obj: any): void {
    if (obj !== this.value) {
      this.value = obj;
    }
  }
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  @Input() set value(v: any) {
    const oldV = this._value;
    if (oldV !== v) {
      if (this.dropdownMenu && this.dropdownMenu.options) {
        this._value = v;
        // console.log('set: ' + this._value);
        this.change.emit(this.value);
        this.onChangeCallback(this.value);
        this.onTouchedCallback(this.value);
      }
    }
  }

  get value() {
    return this._value;
  }




  constructor(elm: ElementRef) {
    this.show = false;
    this.classes = elm.nativeElement.getAttribute('class');
    elm.nativeElement.removeAttribute('class');
  }




  @HostListener('document:click', ['$event']) clickedOutside(e) {
    if (this.show) {
      this.onClick(e);
    }
  }


  onClick(e) {
    /*     e.stopPropagation();
        this.show = !this.show;
        this.dropdownMenu.show = this.show;
        this.menuOpened.emit(this.show);
        return false; */
  }

  ngAfterContentInit() {
    if (this.dropdownMenu) {
      this.dropdownMenu.registerSetSelectedCallback((value) => {
        this.value = value;
      });
      this.dropdownMenu.registerSetSelectedViewCallback((view) => {
        this.selectedValue.nativeElement.innerHTML = view.innerHTML;
      });
    }

  }

}

