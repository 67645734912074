
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { GetLeagueEventsAction } from '../../../store/fixture/actions';

import { ConfigService } from '../../../config/config.service';
import { IMatchDto, IWeekDto } from '../../../services/bpp.webapi/client';
import { Roles } from 'src/app/models/enums';
import { User } from 'src/app/models/user.model';


@Component({
  selector: 'app-fixtures-league-table',
  templateUrl: './fixtures-league-table.component.html'  
})
export class FixturesLeagueTableComponent implements OnInit, OnDestroy {

  private componetDestroyed: Subject<any> = new Subject();
  private isLog = false;
  byGameWeek: { [key: string]: IWeekDto } = {};
  dataLoaded = false;
  rounds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52];
  round: number = 1;
  nonLiveInUK: boolean = false;
  user: User;
  isBroadcaster: boolean;
  isSpinner: boolean;
  isMatchFilterEnabled: boolean=false;

  get matches() {
    if (!this.round || !this.byGameWeek[this.round]) {
      return [];
    }
    return this.byGameWeek[this.round].matches;
  }

  constructor(private store: Store<AppState>, private config: ConfigService) { }

  ngOnInit() {
    this.isLog = this.config.isDebug();

    this.store
      .select(state => state.fixtures).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (state.loading) {
          this.isSpinner = true;
        } else {
          if (this.isLog) { console.log('Fixtures-league:', state.byGameWeek); }
          if (state.byGameWeek) {
            this.dataLoaded = true;
            this.isSpinner = false;
            this.byGameWeek = state.byGameWeek;
            this.isMatchFilterEnabled=this.config.hasFeature("matchFilter.league");
          }
        }
      });

    this.store.select(state => state.user).pipe(
      takeUntil(this.componetDestroyed))
      .subscribe(state => {
        if (!state.loading) {
          this.user = state.currentUser;
          this.isBroadcaster = state.currentUser.role === Roles.Broadcaster || state.currentUser.role === Roles.PLPBroadcaster;
        }
      });

    this.store.select(x => x.fixtures.filter).pipe(take(1)).subscribe(x => {
      this.round = x.matchRound;
      this.nonLiveInUK = !!x.isNonLiveInUK;
      this.store.dispatch(new GetLeagueEventsAction(this.round, this.nonLiveInUK));
    });
  }

  selectionChange() {
    this.store.dispatch(new GetLeagueEventsAction(this.round, this.nonLiveInUK));
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }
}
