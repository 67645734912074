import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { StatusHelper } from 'src/app/helpers/statuses-helper';
import { RequestStatus } from 'src/app/services/bpp.webapi/client';
/**
 * Steps components is an indicator for the steps in a wizard workflow.
 * @group Components
 */
@Component({
    selector: 'another-stepper',
    template: `
        <ol class="c-stepper">
                <li #step
                    *ngFor="let item of model; let i = index"
                    class="c-stepper__item"
                    role="tab"
                >
                
                    <span style="float:left" class="stepper__title">{{ item.label ? item.label : i+1}}</span>
                    <div *ngIf="item.extendedData && item.extendedData.length>0" ngbDropdown class="club-picker">
                        <div ngbDropdownToggle class="front">
                            <span class="icon-arrow-down arrow"></span>
                        </div>
                        <div ngbDropdownMenu style="position:relative!important" class="dropdown-menu">
                            <div *ngFor="let data of item.extendedData" style="cursor: pointer;">
                                <div class="row menu-row">
                                    <div class="col-md-7 broadcaster-name">
                                        {{data.companyName}} 
                                    </div> 
                                    <div *ngIf="!item.isCompleted" class="col-md-1 menu-box" [style.background-color]="getColorFromStatus(data.status)">
                                    </div>
                                    <div class="col-md-4 btn-align-center">
                                    <button class="btn btn-light ml-1" (click)="onDetailClick(data.eventId)">{{'Details' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
    `,
   
    styleUrls: ['./another-stepper.scss']
})
export class Steps implements OnInit, OnDestroy {
    
    @Input() activeIndex: number = 0;
    @Input() color:string;
    /**
     * An array of menu items.
     * @group Props
     */
    @Input() model: any;
    /**
     * Whether the items are clickable or not.
     * @group Props
     */
    @Input() readonly: boolean = true;
    /**
     * Inline style of the component.
     * @group Props
     */
    @Input() style: { [klass: string]: any } | null | undefined;
    /**
     * Style class of the component.
     * @group Props
     */
    @Input() styleClass: string | undefined;
    @Input() maxlength: number | undefined;
    /**
     * Callback to invoke when the new step is selected.
     * @param {number} number - current index.
     * @group Emits
     */
    @Output() activeIndexChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() onDetailButtonClick : EventEmitter<string> = new EventEmitter();


    constructor(private router: Router, private route: ActivatedRoute, private cd: ChangeDetectorRef) {}

    subscription: Subscription | undefined;

    ngOnInit() {
        this.subscription = this.router.events.subscribe(() => this.cd.markForCheck());
    }

    @ViewChildren('step') steps : QueryList<ElementRef>;

    ngAfterViewInit() {
       if (this.model){
        this.model.forEach((e,i)=>{
            const elem = this.steps.toArray()[i];
            if (e.color)
            elem.nativeElement.style.setProperty('--stepper-color',e.color);
            if (e.content)
            elem.nativeElement.style.setProperty('--stepper-content',e.content.toString());
          })
       }
    }
    getFlex(length:number){
     return 1/length;
    }

    onDetailClick(id:string){
        this.onDetailButtonClick.emit(id);
    }

    getColorFromStatus(status:RequestStatus){
  
        switch (status) {
            case RequestStatus.Approved:
                return StatusHelper.getLfpRequestStatusColor(RequestStatus.Approved);
            case RequestStatus.Reject:
                return StatusHelper.getLfpRequestStatusColor(RequestStatus.Reject);
            case RequestStatus.Withdrawn:
                return StatusHelper.getLfpRequestStatusColor(RequestStatus.Withdrawn);
            case RequestStatus.Submitted:
                return StatusHelper.getLfpRequestStatusColor(RequestStatus.Submitted);
            default:
                break;
        }
    }
   
    isActive(item: any, index: number) {
        if (item.routerLink) {
            let routerLink = Array.isArray(item.routerLink) ? item.routerLink : [item.routerLink];

            return this.router.isActive(this.router.createUrlTree(routerLink, { relativeTo: this.route }).toString(), false);
        }

        return index === this.activeIndex;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}