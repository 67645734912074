import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import { AdditionalContentType, EventStatus, IAdditionalContentFixtureDto, ITrainingDto, PlayerDto } from '../../../services/bpp.webapi/client';
import { StatusHelper } from '../../../helpers/statuses-helper';
import * as moment from 'moment';
import { AppInsightClientService } from '../../../services/app-insight.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { mapAdditionalContentTypeUrl } from '../../../helpers/common';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/authentication/auth.service';
import { Roles } from 'src/app/models/enums';

export interface IAdditionalContentListComponentData {
  preSeasonItems: Array<IAdditionalContentFixtureDto>;
  midSeasonItems: Array<IAdditionalContentFixtureDto>;
  trainingGroundItems: Array<IAdditionalContentFixtureDto>;
  otherClubItems: Array<IAdditionalContentFixtureDto>;
}

@Component({
  selector: 'app-additional-content-list',
  templateUrl: './additional-content-list.component.html',
  styleUrls: ['./additional-content-list.component.scss']
})
export class AdditionalContentListComponent extends BaseComponent implements OnInit, OnDestroy {
  user: any;

  @Input() data: IAdditionalContentListComponentData;
  @Input() showAdd = false;
  @Input() showClub = false;
  @Input() isBroadcaster = false;
  @Input() showRequestsCount = false;
  @Input() players = new Array<PlayerDto>();

  date = moment().utc();
  isLoading = false;

  PreSeason = AdditionalContentType.PreSeason;
  MidSeason = AdditionalContentType.MidSeason;
  TrainingGround = AdditionalContentType.TrainingGround;
  OtherClub = AdditionalContentType.Club;

  userSubscription: Subscription;
  features: any;
  environment: any;
  isLfp:boolean;
  isClub:boolean;

  constructor(private appInsightsService: AppInsightClientService,
    private config: ConfigService,
    private authService: AuthService,
    
    private router: Router) {
    super();
    this.features = this.config.get("features.additionalContent");
    this.isClub = this.authService.getUserRole()==Roles.Club;
    if (this.config.hasFeature("environment.lfp")){
      this.isLfp = true;
    }
  }

  ngOnInit() {

  }

  navigateToCreate(type: AdditionalContentType) {
    this.router.navigate([`additional-content/${mapAdditionalContentTypeUrl(type, !!(this.features && this.features.alternativeNames))}/create`]);
  }

  navigateToDetail(item: IAdditionalContentFixtureDto) {
    if (StatusHelper.isCompeletedEvent(item, moment().utc()) === true) {
      return;
    }
    this.appInsightsService.logEvent('Additional content', {
      additionalContentId: item.eventId
    });
    this.router.navigate([`additional-content/${mapAdditionalContentTypeUrl(item.type, !!(this.features && this.features.alternativeNames))}/detail/${item.eventId}`]);
  }

  getLegendaColor(status: EventStatus) {
    return StatusHelper.getStatusColor(status);
  }

  getStateColor(item: ITrainingDto) {
    return StatusHelper.getStatusColor(item.status);
  }
}
