import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { Router } from '@angular/router';
import { UserModel } from '../../widgets/login/user.model';
import { environment } from '../../../environments/environment';
import { Roles } from '../../models/enums';
import { ConfigService } from '../../config/config.service';
import { ApplicationDto } from '../../services/bpp.webapi/client';
import { BppService } from '../../services/bpp.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {

  navbarOpen = false;
  public applications: Array<ApplicationDto> = [];
  appName: any;
  private componetDestroyed: Subject<void> = new Subject();
  protected userSubscription: Subscription;
  protected webApiSubscription: Subscription;
  public isLfp: boolean;

  protected menu: Array<any> = [];
  loggedUser: UserModel;
  protected userId: string;
  menuVerticale: any;
  protected apiVersion: string;
  isProduction: boolean;
  isopen: boolean;
  template: string;

  constructor(protected micService: BppService,
    private store: Store<AppState>,
    protected authService: AuthService,
    protected router: Router,
    private configService: ConfigService,
    private menuService: MenuService,
  ) { 
    this.isLfp = configService.isLfp();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {

    this.loggedUser = new UserModel();
    this.menuVerticale = {
      show: false,
      Bitwise: 0,
      Items: [],
    };

    if (environment.production) {
      this.isProduction = true;
    }

    const appConfig = this.configService.get("app");
    this.appName = {
      desc: appConfig.name,
      subDesc1: appConfig.description[0],
      subDesc2: appConfig.description[1],
      subDesc3: appConfig.description[2],
      link: '',
      icon: 'bigLion'
    };
    this.template = appConfig.template || 'default';

    this.userSubscription = this.store
      .select(state => state.user).pipe(
        takeUntil(this.componetDestroyed))
      .subscribe(state => {

        if (state && state.currentUser && !state.loading) {
          this.loggedUser.firstName = state.currentUser.givenName ? state.currentUser.givenName : '';
          this.loggedUser.surname = state.currentUser.surname ? state.currentUser.surname : '';
          this.loggedUser.email = state.currentUser.email;
          this.loggedUser.thumbnail = state.currentUser.photo;
          this.loggedUser.companyId = state.currentUser.companyId;
          this.loggedUser.companyName = state.currentUser.companyName;
          this.loggedUser.role = state.currentUser.role;
          this.applications = state.details.applications;
          let menu = state.userMenu;
            // Filtering menu items according to taggle features
            const filtered = [];
            menu['Items']
              .map(item => {
                if (this.checkMenuFeatures(item)) {
                  const subItems = [];
                  item['Items'].forEach(sub => {
                    if (this.checkMenuFeatures(sub)) {
                      subItems.push(sub);
                    }
                  });
                  filtered.push({
                    ...item,
                    Items: subItems
                  });
                } else {
                  filtered.push({
                    ...item,
                    Items: []
                  });
                }
              });

            this.menuVerticale = {
              ...menu,
              Items: filtered
            };
        }
      });

    this.webApiSubscription = this.store
      .select(state => state.bpp.apiVersion)
      .subscribe(r => {
        if (r) {
          this.apiVersion = r;
          console.log('WebApi Version: ', r);
        }
      }
      );
  }

  private checkMenuFeatures(item) {
    const expectedFeature = item['expectedFeature'];
    if (expectedFeature) {
      return expectedFeature && this.configService.hasFeature(expectedFeature);
    } else {
      return true;
    }
  }

  logout(evt): void {
    this.authService.logout();
  }

  edit(evt): void {
    this.router.navigateByUrl('/profile');
  }

  changeApp(app) {
    console.log(app);

    const w = window.open('');
    w.location.href = app.homeUrl;
  }

  toggleMenu(e) {
    this.isopen = e;
  }

  ngOnDestroy(): void {
    this.componetDestroyed.next();
    this.componetDestroyed.unsubscribe();
  }

}
