import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IInterviewDto, InterviewTypeDto } from '../../../services/bpp.webapi/client';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { BaseComponent } from '../../base-component/base.component';
import { GetInterviewTypesAction } from 'src/app/store/interview-types/actions';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InterviewCreateLeagueComponent } from '../../interview-details/interview-create-league/interview-create-league.component';

@Component({
  selector: 'app-interviews-table',
  templateUrl: './interviews-table.component.html',
  styleUrls: ['./interviews-table.component.scss']
})
export class InterviewsTableComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() items: IInterviewDto[];
  @Input() showAdd = false;
  @Input() showStatus = false;
  @Input() showRequestsCount = false;
  groups: {[key: string]: { name: string, items: IInterviewDto[] }} = {};
  types: InterviewTypeDto[];

  constructor(private router: Router, private store: Store<AppState>, private config: ConfigService, private modalService: NgbModal) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.groups = {};
      changes.items.currentValue.forEach(x => {
        this.groups[x.type.interviewTypeId] =  this.groups[x.type.interviewTypeId] || { name: x.type.interviewTypeName, items: [] };
        this.groups[x.type.interviewTypeId].items.push(x);
      });
    }
  }

  ngOnInit() {
    this.store.dispatch(new GetInterviewTypesAction());
    this.store.select(e => e.interviewTypes)
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe(e => {
      if (e.list) {
        this.types = e.list;
      }
    });
  }

  navigateToDetail(item: IInterviewDto) {
    this.router.navigate(['/interviews/detail/' + item.interviewId]);
  }

  navigateToCreate(typeId) {
    if (this.config.isLfp()) {
      const modalRef = this.modalService.open(InterviewCreateLeagueComponent);
      const instance = modalRef.componentInstance as InterviewCreateLeagueComponent;
      instance.interviewTypeId = typeId;
    } else {
      this.router.navigate([`interviews/create/` + typeId]);
    }
  }
}
