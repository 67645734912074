import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { InterviewTypeDto, InterviewTypesClient } from './bpp.webapi/client';

@Injectable()
export class InterviewTypesService {

    constructor(private client: InterviewTypesClient) {
    }

    public getTypes(): Observable<InterviewTypeDto[]> {
        return this.client.getAll();
    }
}
